import React, { useContext, useEffect, useState } from 'react'

import TextareaAutosize from '@mui/material/TextareaAutosize';
import AdminAddStudentReport from '../../actions/student/AdminAddStudentReport';
import { AuthContext } from '../../context/AuthContext';
import AdminGetStudentReports from '../../actions/student/AdminGetStudentReports';
import { useParams } from 'react-router-dom';
import DefaultModal from '../../components/modals/DefaultModal';
import { Close, Mic, VoiceChat } from '@material-ui/icons';
import DefaultToast from '../../components/toasts/DefaultToast';
import { useActionsContext } from '../../context/ActionsContext';
import { Voicemail } from '@mui/icons-material';
const StudentReports = ({ profile }) => {
  const params = useParams()
  const purchase_id = params.student_id
  const { user } = useContext(AuthContext)



  const GetReports = async () => {

    const res = await AdminGetStudentReports({
      payload: {
        user: user.SessionId,
        user_id: profile.user_id,
        course_url: profile.course_url


      }
    })
    if (res.status === "success" && res.reports !== null) {
      setreports(res.reports)
    }
  }

  useEffect(() => {
    GetReports()
  }, [purchase_id])
  const [reports, setreports] = useState(null)

  const [report, setreport] = useState(null)
  const AddReport = async (e) => {
    e.preventDefault()
    const res = await AdminAddStudentReport({
      payload: {
        user: user.SessionId,
        user_id: profile.user_id,
        course_url: profile.course_url,
        report: report,
        purchase_id: profile.purchase_id

      }
    })
    if (res.status === "success") {
      settoastdata({ title: "Successful", message: "Changes saved successfully" })
      settoast(true)
      setreport(null)
      setaddreport(false)
      GetReports()

    }
  }
  const [toast, settoast] = useState(false)
  const [toastdata, settoastdata] = useState(null)
  const [addreport, setaddreport] = useState(false)

  const { voiceinput, setvoiceinput, StartListening, StopListening, listening } = useActionsContext()

  useEffect(() => {
    setreport(voiceinput)
    setvoiceinput(voiceinput)
    if (listening) {

    } else {

    }
  }, [voiceinput])
  return (
    <>
      <DefaultToast show={toast} toastdata={toastdata} ToastHandleClose={() => settoast(false)} />
      <DefaultModal show={addreport} HandleClose={() => setaddreport(false)}
        content={<>

          <div className="p-3 rounded">
            <div className="modal-header">
              <h4 className=' mb-3'>Add Student Report</h4>
              <Close onClick={() => setaddreport(false)} />
            </div>
            <hr></hr>
            <form onSubmit={(e) => AddReport(e)}>

              <TextareaAutosize
                maxRows={4}
                minRows={4}
                required
                value={report}
                onChange={(e) => setreport(e.target.value)}
                aria-label="maximum height"
                placeholder="Enter student report"
                defaultValue=""
                className="w-100 border p-2 rounded"
              />
              <div className="d-flex justify-content-end">

                <Mic className={listening ? 'text-success' : 'text-danger'} onClick={() => { listening ? StopListening() : StartListening() }} />
                <button type="submit" className="btn btn-sm mt-3 btn-primary rounded-pill">Add Report</button>

              </div>

            </form>

          </div>

        </>}

      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0  me-2">
                Student Report
              </h4>
              <div className="flex-shrink-0 ms-auto">
                <ul
                  className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  <button className='btn btn-danger' onClick={() => setaddreport(true)}>Add Report</button>
                </ul>
              </div>
            </div>
            <div className="card-body">
              <div className="tab-content text-muted">




                {reports ?
                  <>
                    {reports.map((item) => (
                      <>
                        <div
                          className="tab-pane"
                          id="monthly"
                          role="tabpanel"
                        >
                          <div className="profile-timeline">
                            <div
                              className="accordion accordion-flush"
                              id="monthlyExample"
                            >
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading11"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse11"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-success rounded-circle material-shadow">
                                          M
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Megan Elmore
                                        </h6>
                                        <small className="text-muted">
                                          Adding a new event with
                                          attachments - 1 month Ago.
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse11"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading11"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    <div className="row g-2">
                                      <div className="col-auto">
                                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                                          <div className="flex-shrink-0">
                                            <i className="ri-image-2-line fs-17 text-danger" />
                                          </div>
                                          <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-0">
                                              <a
                                                href="javascript:void(0);"
                                                className="stretched-link"
                                              >
                                                Business Template -
                                                UI/UX design
                                              </a>
                                            </h6>
                                            <small>685 KB</small>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                                          <div className="flex-shrink-0">
                                            <i className="ri-file-zip-line fs-17 text-info" />
                                          </div>
                                          <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-0">
                                              <a
                                                href="javascript:void(0);"
                                                className="stretched-link"
                                              >
                                                Bank Management System
                                                - PSD
                                              </a>
                                            </h6>
                                            <small>8.78 MB</small>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                                          <div className="flex-shrink-0">
                                            <i className="ri-file-zip-line fs-17 text-info" />
                                          </div>
                                          <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-0">
                                              <a
                                                href="javascript:void(0);"
                                                className="stretched-link"
                                              >
                                                Bank Management System
                                                - PSD
                                              </a>
                                            </h6>
                                            <small>8.78 MB</small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading12"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse12"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-2.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Jacqueline Steve
                                        </h6>
                                        <small className="text-muted">
                                          We has changed 2 attributes
                                          on 3 month Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse12"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading12"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    In an awareness campaign, it is
                                    vital for people to begin put 2
                                    and 2 together and begin to
                                    recognize your cause. Too much or
                                    too little spacing, as in the
                                    example below, can make things
                                    unpleasant for the reader. The
                                    goal is to make your text as
                                    comfortable to read as possible. A
                                    wonderful serenity has taken
                                    possession of my entire soul, like
                                    these sweet mornings of spring
                                    which I enjoy with my whole heart.
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading13"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse13"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-5.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          New ticket received
                                        </h6>
                                        <small className="text-muted mb-2">
                                          User{" "}
                                          <span className="text-secondary">
                                            Erica245
                                          </span>{" "}
                                          submitted a ticket - 5 month
                                          Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading14"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse14"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-muted rounded-circle material-shadow">
                                          <i className="ri-user-3-fill" />
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Nancy Martino
                                        </h6>
                                        <small className="text-muted">
                                          Commented on 24 Nov, 2021.
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse14"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading14"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5 fst-italic">
                                    " A wonderful serenity has taken
                                    possession of my entire soul, like
                                    these sweet mornings of spring
                                    which I enjoy with my whole heart.
                                    Each design is a new, unique piece
                                    of art birthed into this world,
                                    and while you have the opportunity
                                    to be creative and make your own
                                    style choices. "
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading15"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse15"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-7.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Lewis Arnold
                                        </h6>
                                        <small className="text-muted">
                                          Create new project buildng
                                          product - 8 month Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse15"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading15"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    <p className="text-muted mb-2">
                                      Every team project can have a
                                      velzon. Use the velzon to share
                                      information with your team to
                                      understand and contribute to
                                      your project.
                                    </p>
                                    <div className="avatar-group">
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item material-shadow"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Christi"
                                      >
                                        <img
                                          src="assets/images/users/avatar-4.jpg"
                                          alt=""
                                          className="rounded-circle avatar-xs"
                                        />
                                      </a>
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item material-shadow"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Frank Hook"
                                      >
                                        <img
                                          src="assets/images/users/avatar-3.jpg"
                                          alt=""
                                          className="rounded-circle avatar-xs"
                                        />
                                      </a>
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item material-shadow"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title=" Ruby"
                                      >
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-light text-primary">
                                            R
                                          </div>
                                        </div>
                                      </a>
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item material-shadow"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="more"
                                      >
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle">
                                            2+
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*end accordion*/}
                          </div>
                        </div>

                      </>
                    ))}
                  </> :
                  <>

                  </>}




                <div
                  className="tab-pane"
                  id="weekly"
                  role="tabpanel"
                >
                  <div className="profile-timeline">
                    <div
                      className="accordion accordion-flush"
                      id="weeklyExample"
                    >
                      <div className="accordion-item border-0">
                        <div
                          className="accordion-header"
                          id="heading6"
                        >
                          <a
                            className="accordion-button p-2 shadow-none"
                            data-bs-toggle="collapse"
                            href="#collapse6"
                            aria-expanded="true"
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <img
                                  src="assets/images/users/avatar-3.jpg"
                                  alt=""
                                  className="avatar-xs rounded-circle material-shadow"
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h6 className="fs-14 mb-1">
                                  Joseph Parker
                                </h6>
                                <small className="text-muted">
                                  New people joined with our
                                  company - Yesterday
                                </small>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div
                          id="collapse6"
                          className="accordion-collapse collapse show"
                          aria-labelledby="heading6"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body ms-2 ps-5">
                            It makes a statement, it’s
                            impressive graphic design.
                            Increase or decrease the letter
                            spacing depending on the situation
                            and try, try again until it looks
                            right, and each letter has the
                            perfect spot of its own.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border-0">
                        <div
                          className="accordion-header"
                          id="heading7"
                        >
                          <a
                            className="accordion-button p-2 shadow-none"
                            data-bs-toggle="collapse"
                            href="#collapse7"
                            aria-expanded="false"
                          >
                            <div className="d-flex">
                              <div className="avatar-xs">
                                <div className="avatar-title rounded-circle bg-light text-danger material-shadow">
                                  <i className="ri-shopping-bag-line" />
                                </div>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h6 className="fs-14 mb-1">
                                  Your order is placed{" "}
                                  <span className="badge bg-success-subtle text-success align-middle">
                                    Completed
                                  </span>
                                </h6>
                                <small className="text-muted">
                                  These customers can rest
                                  assured their order has been
                                  placed - 1 week Ago
                                </small>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="accordion-item border-0">
                        <div
                          className="accordion-header"
                          id="heading8"
                        >
                          <a
                            className="accordion-button p-2 shadow-none"
                            data-bs-toggle="collapse"
                            href="#collapse8"
                            aria-expanded="true"
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0 avatar-xs">
                                <div className="avatar-title bg-light text-success rounded-circle material-shadow">
                                  <i className="ri-home-3-line" />
                                </div>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h6 className="fs-14 mb-1">
                                  Velzon admin dashboard
                                  templates layout upload
                                </h6>
                                <small className="text-muted">
                                  We talked about a project on
                                  linkedin - 1 week Ago
                                </small>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div
                          id="collapse8"
                          className="accordion-collapse collapse show"
                          aria-labelledby="heading8"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body ms-2 ps-5 fst-italic">
                            Powerful, clean &amp; modern
                            responsive bootstrap 5 admin
                            template. The maximum file size
                            for uploads in this demo :
                            <div className="row mt-2">
                              <div className="col-xxl-6">
                                <div className="row border border-dashed gx-2 p-2">
                                  <div className="col-3">
                                    <img
                                      src="assets/images/small/img-3.jpg"
                                      alt=""
                                      className="img-fluid rounded material-shadow"
                                    />
                                  </div>
                                  {/*end col*/}
                                  <div className="col-3">
                                    <img
                                      src="assets/images/small/img-5.jpg"
                                      alt=""
                                      className="img-fluid rounded material-shadow"
                                    />
                                  </div>
                                  {/*end col*/}
                                  <div className="col-3">
                                    <img
                                      src="assets/images/small/img-7.jpg"
                                      alt=""
                                      className="img-fluid rounded material-shadow"
                                    />
                                  </div>
                                  {/*end col*/}
                                  <div className="col-3">
                                    <img
                                      src="assets/images/small/img-9.jpg"
                                      alt=""
                                      className="img-fluid rounded material-shadow"
                                    />
                                  </div>
                                  {/*end col*/}
                                </div>
                                {/*end row*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border-0">
                        <div
                          className="accordion-header"
                          id="heading9"
                        >
                          <a
                            className="accordion-button p-2 shadow-none"
                            data-bs-toggle="collapse"
                            href="#collapse9"
                            aria-expanded="false"
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <img
                                  src="assets/images/users/avatar-6.jpg"
                                  alt=""
                                  className="avatar-xs rounded-circle material-shadow"
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h6 className="fs-14 mb-1">
                                  New ticket created{" "}
                                  <span className="badge bg-info-subtle text-info align-middle">
                                    Inprogress
                                  </span>
                                </h6>
                                <small className="text-muted mb-2">
                                  User{" "}
                                  <span className="text-secondary">
                                    Jack365
                                  </span>{" "}
                                  submitted a ticket - 2 week
                                  Ago
                                </small>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="accordion-item border-0">
                        <div
                          className="accordion-header"
                          id="heading10"
                        >
                          <a
                            className="accordion-button p-2 shadow-none"
                            data-bs-toggle="collapse"
                            href="#collapse10"
                            aria-expanded="true"
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <img
                                  src="assets/images/users/avatar-5.jpg"
                                  alt=""
                                  className="avatar-xs rounded-circle material-shadow"
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h6 className="fs-14 mb-1">
                                  Jennifer Carter
                                </h6>
                                <small className="text-muted">
                                  Commented - 4 week Ago
                                </small>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div
                          id="collapse10"
                          className="accordion-collapse collapse show"
                          aria-labelledby="heading10"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body ms-2 ps-5">
                            <p className="text-muted fst-italic mb-2">
                              " This is an awesome admin
                              dashboard template. It is
                              extremely well structured and
                              uses state of the art components
                              (e.g. one of the only templates
                              using boostrap 5.1.3 so far). I
                              integrated it into a Rails 6
                              project. Needs manual
                              integration work of course but
                              the template structure made it
                              easy. "
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end accordion*/}
                  </div>
                </div>

              </div>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
        {/* end col */}
      </div>

      <div className=" mt-2">
        {reports ?
          <>
            {reports.map((report) => (
              <>

                <div className="dashed-border p-2 mb-2">

                  <p className="mb-2">{report.message}</p>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0 extra-small text-secondary">Reported by : {report.reported_by}</p>
                    <p className="extra-small mb-0 text-secondary">{report.id} |  {report.create_datetime}</p>
                  </div>
                </div>

              </>
            ))}
          </> : null}


      </div>




    </>
  )
}

export default StudentReports