import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

import { useContext } from 'react'

import TextField from '@mui/material/TextField';

import Checkbox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import axios from 'axios'
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../context/EndPoint'
import SecurityHeaders from '../../context/SecurityHeaders'
import AdminDeleteQuiz from '../../actions/quiz/AdminDeleteQuiz'
import DetectDevice from '../../context/DetectDevice';

const InternalQuizManagement = ({ course_url }) => {
     const device = DetectDevice()
     const { user } = useContext(AuthContext)
     const getQuizzes = async () => {
          const formData = new FormData()
          formData.append('session', user.SessionId)
          formData.append('course_url',course_url)

          const res = await axios.post(ENDPOINT + 'admin-get-quizzes.php', formData, SecurityHeaders)
          if (res) {
               if (res.data.status === "success") {
                    setposts(res.data.quizzes)
               }
          }
     };
     useEffect(() => {
          getQuizzes();

     }, []);

     const [question, setQuestion] = useState(null)

     const [answer1, setAnswer1] = useState(false)
     const [answer2, setAnswer2] = useState(false)
     const [answer3, setAnswer3] = useState(false)
     const [answer4, setAnswer4] = useState(false)

     const [options, setOptions] = useState({
          option1: '',
          option2: '',
          option3: '',
          option4: ''
     })
     const [questionType, setQuestionType] = useState('')

     const [answer, setAnswer] = useState(null)

     const [posts, setposts] = useState(null)

     const [blankAns, setBlankAns] = useState('')


     const AddQuiz = async (e) => {
          e.preventDefault()
          const data = new FormData()
          data.append("question", question)
          data.append("option1", options.option1)
          data.append("option2", options.option2)
          data.append("option3", options.option3)
          data.append("option4", options.option4)
          data.append("answer", answer)
          data.append("session", user.SessionId)
          data.append('course_url',course_url)
          const res = await axios.post(ENDPOINT + "admin-add-quiz.php", data, SecurityHeaders)

          if (res) {
                // console.log(" quiz " + JSON.stringify(res))
               if (res.data.status === "success") {
                    getQuizzes()
                    setOptions({
                         option1: '',
                         option2: '',
                         option3: '',
                         option4: ''
                    })
                    setQuestion('')
                    setAnswer1(false)
                    setAnswer2(false)
                    setAnswer3(false)
                    setAnswer4(false)

               }

          }
     }

     const HandleOptions = (e) => {
          setOptions({
               ...options,
               [e.target.name]: e.target.value
          })
     }

     const DeleteQuiz = async ({ quiz_id }) => {
          const res = await AdminDeleteQuiz({
               payload: {
                    user: user.SessionId,
                    quiz_id: quiz_id
               }
          })
          if (res.status === "success") {
               getQuizzes()
          }
     }

     useEffect(() => {
          if (answer1) {
               setAnswer("option1")
               setAnswer2(false)
               setAnswer3(false)
               setAnswer4(false)
          }
          if (answer2) {
               setAnswer("option2")
               setAnswer1(false)

               setAnswer3(false)
               setAnswer4(false)
          }
          if (answer3) {
               setAnswer("option3")
               setAnswer1(false)
               setAnswer2(false)

               setAnswer4(false)
          }
          if (answer4) {
               setAnswer("option4")
               setAnswer1(false)
               setAnswer2(false)
               setAnswer3(false)

          }
     }, [answer1, answer2, answer3, answer4])



     const blankQuestion = async (e) => {
          e.preventDefault();
          const formData = new FormData();
          formData.append('course_url', course_url);
          formData.append('question', question);
          formData.append('answer', blankAns);
          formData.append('question_type', questionType);
          const res = await axios.post(ENDPOINT + 'add-blank-quizzes.php', formData, SecurityHeaders);
          if (res) {
               if (res.data.status === 'success') {
                    getQuizzes()
                    setBlankAns('')
                    setQuestion('')
               }

          }

     }

     return (



             
                    <>
                         {device==="mobile"?
                         <>
                            <div className="">
                                
                                <div className="col-lg-5 p-3">
                                    <div className='d-flex justify-content-evenly align-items-center mb-3'>
                                        <h5 className='pb-0 mb-0'>New Quiz</h5>
                                        <select onChange={(e) => setQuestionType(e.target.value)} className="custom-select ">
                                            <option selected>Choose Question type</option>
                                            <option value='mcq'>MCQ</option>
                                            <option value='blank'>BLANK</option>
                                        </select>
                                    </div>

                                    {/* <button onClick={()=>ConvertImage()}>Convert</button> */}
                                    <form className=" mt-3" onSubmit={(e) => { questionType === 'blank' ? blankQuestion(e) : AddQuiz(e) }}>

                                        <TextareaAutosize
                                            maxRows={4}
                                            minRows={4}
                                            aria-label="maximum height"
                                            placeholder="Enter your question"
                                            defaultValue=""
                                            value={question}
                                            className="mb-2 w-100 border"
                                            onChange={(e) => { setQuestion(e.target.value) }}
                                            required
                                        />

                                        {
                                            questionType === 'blank' ? <>
                                                    <TextField
                                                        className="mb-3 mt-2 w-100"
                                                        label="Answer"
                                                        required
                                                        value={blankAns}
                                                        onChange={(e) => { setBlankAns(e.target.value) }}
                                                    />
                                            </> : <>   <div className="d-flex">
                                                    <Checkbox checked={answer1} onChange={() => { setAnswer1(!answer1) }} />
                                                    <TextField
                                                        className="mb-2 w-100"
                                                        label="Option 1"
                                                        name='option1'
                                                        required
                                                        value={options.option1}
                                                        onChange={HandleOptions}
                                                    />
                                            </div>

                                                    <div className="d-flex">
                                                        <Checkbox checked={answer2} onChange={() => { setAnswer2(!answer2) }} />
                                                        <TextField
                                                            className="mb-2 w-100"
                                                            label="Option 2"
                                                            name='option2'
                                                            required
                                                            onChange={HandleOptions}
                                                            value={options.option2}

                                                        />
                                                    </div>

                                                    <div className="d-flex">
                                                        <Checkbox checked={answer3} onChange={() => { setAnswer3(!answer3) }} />
                                                        <TextField
                                                            className="mb-2 w-100"
                                                            label="Option 3"
                                                            required
                                                            onChange={HandleOptions}
                                                            name='option3'
                                                            value={options.option3}
                                                        />

                                                    </div>

                                                    <div className="d-flex">
                                                        <Checkbox checked={answer4} onChange={() => { setAnswer4(!answer4) }} />
                                                        <TextField
                                                            className="mb-2 w-100"
                                                            label="Option 4"
                                                            required
                                                            onChange={HandleOptions}
                                                            name='option4'
                                                            value={options.option4}
                                                        />

                                                    </div>
                                            </>
                                        }


                                        <div className='d-flex justify-content-center'>
                                            <button type="submit" className="btn btn-primary w-75 ">Add Quiz</button>

                                        </div>

                                    </form>
                                </div>

                                <div className="col-lg-7 p-3">
                                    <h4>Latest Posts</h4>

                                    <div className="overflow-y vh-75 p-3">

                                        {posts ?
                                            <>
                                                    {posts.map((quiz) => (
                                                        <>
                                                            <div className="  border   border-1 mt-3 p-3 rounded">
                                                                <div className="d-flex justify-content-between">
                                                                        <h6>#{quiz.quiz_id} :{quiz.question}</h6>
                                                                        <DeleteIcon className="click"
                                                                            onClick={() => DeleteQuiz({ quiz_id: quiz.quiz_id })}
                                                                        />
                                                                </div>

                                                                <div className="d-flex">
                                                                        {
                                                                            quiz.question_type === 'blank' ? <><p className='text-success'>Answer: {quiz.answer}</p></> : <div className="col">
                                                                                <p className={quiz.answer === "option1" ? "text-success mb-0" : "mb-0"}>A : {quiz.option1}</p>
                                                                                <p className={quiz.answer === "option2" ? "text-success mb-0" : "mb-0"}>B : {quiz.option2}</p>
                                                                                <p className={quiz.answer === "option3" ? "text-success mb-0" : "mb-0"}>C : {quiz.option3}</p>
                                                                                <p className={quiz.answer === "option4" ? "text-success mb-0" : "mb-0"}>D : {quiz.option4}</p>
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                            </> : <h3 className='mt-4 mx-auto'>Add quizzes</h3>}
                                    </div>

                                </div>

                            </div>
                         </>:
                         <>
                            <div className="flex justify-content-between min-vh-90 p-3">


                                <div className="col-lg-5 p-3">
                                    <div className='d-flex justify-content-evenly align-items-center mb-3'>
                                        <h5 className='pb-0 mb-0'>New Quiz</h5>
                                        <select onChange={(e) => setQuestionType(e.target.value)} className="custom-select ">
                                            <option selected>Choose Question type</option>
                                            <option value='mcq'>MCQ</option>
                                            <option value='blank'>BLANK</option>
                                        </select>
                                    </div>

                                    {/* <button onClick={()=>ConvertImage()}>Convert</button> */}
                                    <form className=" mt-3" onSubmit={(e) => { questionType === 'blank' ? blankQuestion(e) : AddQuiz(e) }}>

                                        <TextareaAutosize
                                            maxRows={4}
                                            minRows={4}
                                            aria-label="maximum height"
                                            placeholder="Enter your question"
                                            defaultValue=""
                                            value={question}
                                            className="mb-2 w-100 border"
                                            onChange={(e) => { setQuestion(e.target.value) }}
                                            required
                                        />

                                        {
                                            questionType === 'blank' ? <>
                                                    <TextField
                                                        className="mb-3 mt-2 w-100"
                                                        label="Answer"
                                                        required
                                                        value={blankAns}
                                                        onChange={(e) => { setBlankAns(e.target.value) }}
                                                    />
                                            </> : <>   <div className="d-flex">
                                                    <Checkbox checked={answer1} onChange={() => { setAnswer1(!answer1) }} />
                                                    <TextField
                                                        className="mb-2 w-100"
                                                        label="Option 1"
                                                        name='option1'
                                                        required
                                                        value={options.option1}
                                                        onChange={HandleOptions}
                                                    />
                                            </div>

                                                    <div className="d-flex">
                                                        <Checkbox checked={answer2} onChange={() => { setAnswer2(!answer2) }} />
                                                        <TextField
                                                            className="mb-2 w-100"
                                                            label="Option 2"
                                                            name='option2'
                                                            required
                                                            onChange={HandleOptions}
                                                            value={options.option2}

                                                        />
                                                    </div>

                                                    <div className="d-flex">
                                                        <Checkbox checked={answer3} onChange={() => { setAnswer3(!answer3) }} />
                                                        <TextField
                                                            className="mb-2 w-100"
                                                            label="Option 3"
                                                            required
                                                            onChange={HandleOptions}
                                                            name='option3'
                                                            value={options.option3}
                                                        />

                                                    </div>

                                                    <div className="d-flex">
                                                        <Checkbox checked={answer4} onChange={() => { setAnswer4(!answer4) }} />
                                                        <TextField
                                                            className="mb-2 w-100"
                                                            label="Option 4"
                                                            required
                                                            onChange={HandleOptions}
                                                            name='option4'
                                                            value={options.option4}
                                                        />

                                                    </div>
                                            </>
                                        }


                                        <div className='d-flex justify-content-center'>
                                            <button type="submit" className="btn btn-primary w-75 ">Add Quiz</button>

                                        </div>

                                    </form>
                                </div>

                                <div className="col-lg-7">
                                    <h4>Latest Posts</h4>

                                    <div className="overflow-y vh-75 p-3">

                                        {posts ?
                                            <>
                                                    {posts.map((quiz) => (
                                                        <>
                                                            <div className="  border   border-1 mt-3 p-3 rounded">
                                                                <div className="d-flex justify-content-between">
                                                                        <h6>#{quiz.quiz_id} :{quiz.question}</h6>
                                                                        <DeleteIcon className="click"
                                                                            onClick={() => DeleteQuiz({ quiz_id: quiz.quiz_id })}
                                                                        />
                                                                </div>

                                                                <div className="d-flex">
                                                                        {
                                                                            quiz.question_type === 'blank' ? <><p className='text-success'>Answer: {quiz.answer}</p></> : <div className="col">
                                                                                <p className={quiz.answer === "option1" ? "text-success mb-0" : "mb-0"}>A : {quiz.option1}</p>
                                                                                <p className={quiz.answer === "option2" ? "text-success mb-0" : "mb-0"}>B : {quiz.option2}</p>
                                                                                <p className={quiz.answer === "option3" ? "text-success mb-0" : "mb-0"}>C : {quiz.option3}</p>
                                                                                <p className={quiz.answer === "option4" ? "text-success mb-0" : "mb-0"}>D : {quiz.option4}</p>
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                            </> : <h3 className='mt-4 mx-auto'>Add quizzes</h3>}
                                    </div>

                                </div>


                            </div>
                         </>}

                         




          </>
     )
}

export default InternalQuizManagement







