import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';

const GetBatchReports = async({payload}) => {

            
    
        const formData = new FormData();
        
        
        //formData.append('whyus',document.getElementById('whyus').value)
        
        formData.append('session',payload.session);
        formData.append("course_url",payload.course_url)
          formData.append('batch_id',payload.batch_id);
        
        
        const res = await axios.post(ENDPOINT+"get-batch-reports.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
         
        return res.data
        }
        }

export default GetBatchReports