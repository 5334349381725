import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ENDPOINT from '../../context/EndPoint'
import SecurityHeaders from '../../context/SecurityHeaders'
import dateFormat from 'dateformat'
import Toast from 'react-bootstrap/Toast';
import CopyToClipboard from 'react-copy-to-clipboard'
import { Skeleton } from '@mui/material'
import Localbase from 'localbase'
const RecentRecordings = () => {


  const [recordings, setrecordings] = useState(null)
  const [loading, setloading] = useState(true)
  const db = new Localbase("db")



  const Recordings = async () => {
    const data = new FormData();

    try {
      const res = await axios.post(ENDPOINT + "recent-recordings.php", data, SecurityHeaders);

      if (res) {
        setloading(false)
      }
      if (res && res.data.status === "success") {
        setrecordings(res.data.data);
        db.collection("paths").add({ recent_recordings: res.data.data }, "recent_recordings")
      }

    } catch (error) {
      if (error.code === "ERR_NETWORK") {

      }
    }
  };


//-------------offline func.....

  useEffect(() => {

    db.collection("recent_recordings").doc("recent_recordings").get().then(data => {

      if(data){
        setrecordings(data)
      }
    })
    Recordings()
  }, [])

  const [toast, setToast] = useState(false)


  //offline func...........

  var getOfflinedata= ()=>{

    db.collection("paths").doc("recent_recordings").get().then((data)=>{
      if(data){
        setrecordings(data.recent_recordings)
      }
    })

    
  }

  useEffect(()=>{

    getOfflinedata()

  },[])


  //offline func....... end....
  
  return (
    <div className='container-fluid p-0'>

      <div className="toast-container">
        <Toast show={toast} className='bg-warning' onClose={() => setToast(false)}>
          <Toast.Header className='bg-warning'>

            <strong className="me-auto text-dark">Video Copied</strong>

          </Toast.Header>

        </Toast>
      </div>






      <div className='d-none d-md-block'>
        <h3>Recent Recordings</h3>

      </div>

      <div className="border">

        <div className="d-none d-md-flex bg-dark text py-3 px-2 light align-items-center justify-content-between justify-content-lg-evenly">

          <div className="col-lg-4">
            <h6 className="text-light m-0">Topic</h6>
          </div>
          <div className="col-lg-2">
            <h6 className="text-light m-0">Class Time</h6>
          </div>
          <div className="col-lg-2">
            <h6 className="text-light m-0">Uploaded Time</h6>
          </div>

          <div className="col-lg-2 text-center">
            <h6 className="text-light m-0">Action</h6>
          </div>
        </div>

        {recordings ? <>



          {recordings.map((item) => (
            <>
              <div className="d-flex p-2 flex-wrap align-content-center justify-content-md-between justify-content-lg-evenly align-items-center">

                <div className="col-lg-4 col-12">
                  <h6>{item.topic}</h6>
                </div>
                <div className="col-lg-2 col-6">
                  <h6>{dateFormat(item.class_time, "dS mmm hh:mm tt")}</h6>

                </div>
                <div className="col-lg-2 d-none d-md-block col-3">
                  <h6>{dateFormat(item.uploaded_time, "dS mmm hh:mm tt")}</h6>

                </div>

                <div className="col-lg-2 col-6 text-center">

                  <CopyToClipboard className="btn btn-primary btn-sm rounded rounded-pill" text={item.recording_link}
                    onCopy={() => { setToast(true) }}>
                    <span>Copy Recording Link</span>
                  </CopyToClipboard>
                </div>
              </div>
              <hr className='my-2'></hr>
            </>
          ))}
        </> :
          <>
            {loading ? <>

              <Skeleton height={100} className='w-100' />
              <Skeleton height={100} className='w-100' />
              <Skeleton height={100} className='w-100' />
              <Skeleton height={100} className='w-100' />
              <Skeleton height={100} className='w-100' />
              <Skeleton height={100} className='w-100' />

              <Skeleton height={100} className='w-100' />
              <Skeleton height={100} className='w-100' />
              <Skeleton height={100} className='w-100' />

            </> :
              <>

              </>}

          </>}
      </div>



    </div>
  )
}

export default RecentRecordings