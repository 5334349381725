import Avatar from '@mui/material/Avatar';
import React, { useContext, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import AdminDeleteTickets from '../../actions/student/AdminDeleteTickets';
import { Check, Delete, MoreVert } from '@material-ui/icons';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminUpdateDoubtRequest from '../../actions/support/AdminUpdateDoubtRequest';
import { AuthContext } from '../../context/AuthContext';
import { Pending } from '@mui/icons-material';
import DefaultToast from '../../components/toasts/DefaultToast';
import AdminUpdatePlacementRequest from '../../actions/support/AdminUpdatePlacementRequest';
import AdminDeletePlacementRequest from '../../actions/student/AdminDeletePlacementRequest';
import { useActionsContext } from '../../context/ActionsContext';
import NoPermissionModal from '../../components/modals/NoPermissionModal';

const PlacementSupportTicketItem = ({item,Reload,options}) => {
const {user} = useContext(AuthContext)
const {permission} = useActionsContext()
const [deletemodal,setdeletemodal] = useState(false)
const [toastdata,setoastdata] = useState(null)
const [toast,settoast] = useState(false)
  const DeleteRequest = async () => {
    const res = await AdminDeletePlacementRequest({
      payload: {
        session:user.SessionId,
        id: item.id,
      },
    });
    if (res) {
      
setoastdata(res.data)
setdeletemodal(false)
      if (res.status === "success") {
       setdeletemodal(false)
      Reload()
      } else {
      
      }
    }
  };
  const UpdateRequest = async ({status})=>{


    const res = await AdminUpdatePlacementRequest({payload:{
      session:user.SessionId,
      id:item.id,
      status:status
    }})
    if(res){

setoastdata(res.data)
if(res.status==="success"){

Reload()
}
else{


}

    }
  }

  const [nopermission,setnopermission]= useState(false)
  return (
<>
<DefaultToast toastdata={toastdata} show={toast}/>
<NoPermissionModal show={nopermission} HandleClose={()=>setnopermission(false)}/>
<DeleteModal show={deletemodal} HandleClose={()=>setdeletemodal(false)} HandleDelete={DeleteRequest}/>
<tr>
                
                <td className='col-lg-3'>
                  <div className="d-flex  align-items-center">
                    <div className="flex-shrink-0 me-2">
                    <Avatar src={item.profile_pic?item.profile_pic:null}/>
                    </div>
                    <div className="flex-grow-1 ">
                      {item.first_name+" "+item.last_name}
                    </div>
                  </div>
                </td>

                {options?.course ===false?<></>:<td>{item.course_url}</td>}       

         <td>
                  <span className="badge bg-success-subtle text-success">
                  {item.status}
                  </span>
                </td>
              
              
              
                <td>
                <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
    
  
  <Dropdown.Item onClick={()=>{parseInt(permission.initiate_placement_support)?UpdateRequest({status:"initiated"}):setnopermission(true)}}  ><Pending/> Initiate</Dropdown.Item>

  <Dropdown.Item   onClick={()=>{parseInt(permission.close_placement_support)?UpdateRequest({status:"closed"}):setnopermission(true)}} ><Check/>Closed</Dropdown.Item>
  
  <Dropdown.Item   onClick={()=>{parseInt(permission.delete_placement_support)?UpdateRequest({status:"deleted"}):setnopermission(true)}} ><Delete/>Delete</Dropdown.Item>
 </Dropdown.Menu>
</Dropdown>
                </td>
                <td>
                  <Link to={"/students-support/"+item.id} className="btn btn-outline-primary">View Details</Link>
                </td>
              </tr>
</>
  )
}

export default PlacementSupportTicketItem