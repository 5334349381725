import React, { useContext, useEffect, useState } from 'react'
import dateFormat from 'dateformat'
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../context/EndPoint'
import axios from 'axios'
import { Dropdown } from 'react-bootstrap'
import { ActionsProvider, useActionsContext } from '../../context/ActionsContext'
import { Close, Edit, MoreVert } from '@material-ui/icons'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import refund from '../course-registrations/images/refund.png'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import active from '../course-registrations/images/methodology (4).png';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import DeletePaymentHistory from '../../actions/payments/DeletePaymentHistory'
import ModalStyle from '../../context/ModalStyle'
import SecurityHeaders from '../../context/SecurityHeaders'
import BasicToast from '../../components/toasts/BasicToast'
const CourseRegistrationItem = ({ item, Reload, notification }) => {

  const { user } = useContext(AuthContext)
  const { permission } = useActionsContext()
  const updateCourseEnrollment = async ({ pstatus }) => {


    const formData = new FormData();


    //formData.append('whyus',document.getElementById('whyus').value)

    if (notification) {
      formData.append('notification_send', notification)
    }

    formData.append('session', user.SessionId);



    formData.append('purchase_id', item.purchase_id);
    formData.append('course_status', pstatus)
    formData.append("reason", reason)
    formData.append("instruction", instruction)



    const res = await axios.post(ENDPOINT + "update-student-course-status.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {
      //("course status "+JSON.stringify(res.data))
      // console.log(res)
      if (res.data.status === "success") {

        setmodal(false)
        Reload()
      }
      else {

      }
    }
  }
  const Delete = async () => {

    const res = await DeletePaymentHistory({
      payload: {
        session: user.SessionId,
        purchase_id: item.purchase_id
      }
    })
    if (res) {
      if (res.status === "success") {
        Reload()
      }
    }
  }
  const [modal, setmodal] = useState(false)

  const PauseTraining = () => {
    setmodal(true)

  }



  const [reason, setreason] = useState(null)
  const [instruction, setinstruction] = useState(null);
  const [update, setUpdate] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({
    url: item.course_url,
    mode: item.mode,
  });

  useEffect(() => {
    setUpdateDetails({
      url: item.course_url,
      mode: item.mode,
    })
  }, [update])

  const [toast, setToast] = useState({
    show: false,
    message: '',

  })
  const handelUpdate = (e) => {
    setUpdateDetails({
      ...updateDetails,
      [e.target.name]: e.target.value
    })
  }

  const editCourse = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('session', user.SessionId);



    formData.append('purchase_id', item.purchase_id);
    formData.append('course_url', updateDetails.url)
    formData.append("mode", updateDetails.mode)
    formData.append("instruction", instruction)



    const res = await axios.post(ENDPOINT + "update-course-registration.php", formData, SecurityHeaders);
    if (res) {

      // console.log(res)
      if (res.data.status === "success") {

        setUpdate(false)
        setToast({ ...toast, show: true, message: res.data.message })
        Reload()
      }
      else {

      }
    }
  }
  return (
    <>
      <BasicToast visibility={toast.show} item={toast.message} ToastHandleClose={() => setToast({ ...toast, show: false })} />

      <Modal
        className='col-lg-12 col-12'
        open={update}
        onClose={() => setUpdate(false)}

      >

        <Box sx={ModalStyle} className="col-5 p-3 rounded">
          <div className="d-flex col-lg-12 justify-content-between">
            <h4>Update Details</h4>
            <Close className="click" onClick={() => setUpdate(false)} />
          </div>
          <hr />
          <input placeholder="Enter Url" name='url' value={updateDetails.url} className="mt-2 form-control mb-3" onChange={handelUpdate}></input>
          <input placeholder="Enter Mode" name='mode' value={updateDetails.mode} className="mt-2 form-control mb-3" onChange={handelUpdate}></input>
          <button className="btn btn-primary w-100" onClick={(e) => editCourse(e)}>Update</button>
        </Box>
      </Modal>


      <Modal
        open={modal}
        onClose={() => setmodal(false)}

      >

        <Box sx={ModalStyle} className="p-3 rounded">
          <div className="d-flex justify-content-between ">
            <h4>Enter Details</h4>
            <Close className="click" onClick={() => setmodal(false)} />
          </div>
          <hr />
          <input placeholder="Enter reason" className="mt-2 form-control mb-3" onChange={(e) => setreason(e.target.value)}></input>
          <input placeholder="Enter instructions" className="mt-2 form-control mb-3" onChange={(e) => setinstruction(e.target.value)}></input>
          <button className="btn btn-primary w-100" onClick={() => (updateCourseEnrollment({ pstatus: "paused" }))}>Pause Training</button>
        </Box>
      </Modal>

      <div className="card product col-12 mb-2 mb-md-2">
        <div className="card-body">
          <div className="d-flex justify-content-between gy-3">
            <div className='d-flex col-8'>
              <div className="col-sm-auto col-lg-2 col-xl-2 col-md-2 col-sm-2 col-3 d-sm-block d-none">
                <div className=" bg-light h-100 rounded p-1 col-12">
                  <img
                    src={item.profile_pic ? item.profile_pic : item.course_cover_url}
                    alt=""
                    className="img-fluid d-block h-100 w-auto"
                  />
                </div>
              </div>
              <div className="col-sm col-12 px-2">
                <h5 className="fs-14 fs-sm-14 text-truncate">
                  <a
                    href="ecommerce-product-detail.html"
                    className="text-body"
                  >
                    {item.course_title}
                  </a>
                </h5>
                <ul className="list-inline m-0 text-muted">
                  <li className="list-inline-item ">
                    Mode : <span className="fw-medium">{item.mode}</span>
                  </li>
                  <li className="list-inline-item">
                    S ID : <span className="fw-medium">{item.purchase_id}</span>
                  </li>
                </ul>
                <div className="d-flex flex-wrap ">
                  <h6 className="text-secondary m-0">{item.first_name + " " + item.last_name}</h6>
                </div>
              </div>
            </div>

            <div className="col-sm-auto">
              <div className="text-end">

                <div className="d-flex align-items-center xl-flex d-lg-flex d-md-flex  justify-content-end">
                <span className={`badge ${item.purchase_status==="success"?"bg-success-subtle text-success":"bg-warning-subtle text-warning"} `}>
                {item.purchase_status}
              </span>
                  <div className="ml ms-2">
                    <Dropdown className="options-dropdown ml-4">
                      <Dropdown.Toggle className="bg-transparent options p-0 w-fit h-fit  border-0" >
                        <MoreVert className="text-dark" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setUpdate(true)}  > <Edit className='m-1 p-1 w-22' /> Edit Course</Dropdown.Item>

                        {item.purchase_status === "pending" || item.purchase_status === "paused" ?
                          <>

                            {parseInt(permission.activate_course) ?
                              <>

                                <Dropdown.Item onClick={() => { updateCourseEnrollment({ pid: item.purchase_id, pstatus: "success" }) }}  > <img src={active} width={25} className='m-1 p-1' ></img>Activate Course</Dropdown.Item>

                              </> :
                              <>



                              </>}

                          </> : null}
                        {item.purchase_status === "pending" ?
                          <>

                            {parseInt(permission.mark_course_not_interested) ?
                              <>
                                <Dropdown.Item onClick={() => { updateCourseEnrollment({ pid: item.purchase_id, pstatus: "not-interested" }) }}   > <NotInterestedIcon className='m-1 p-1 w-22' />Course Not Interested</Dropdown.Item>


                              </> :
                              <>


                              </>}

                          </> :
                          <>
                            {item.purchase_status === "success" ?
                              <>



                                {parseInt(permission.refund_course) ?
                                  <>
                                    <Dropdown.Item onClick={() => { updateCourseEnrollment({ pid: item.purchase_id, pstatus: "refund" }) }}  > <AutorenewIcon className='m-1 p-1 w-22' />Course Refund</Dropdown.Item>

                                  </> :
                                  <>



                                  </>}


                                {parseInt(permission.mark_course_refunded) ?
                                  <>
                                    <Dropdown.Item onClick={() => { updateCourseEnrollment({ pid: item.purchase_id, pstatus: "refunded" }) }}   ><img src={refund} width={22} className='m-1 p-1' ></img>Course Refunded</Dropdown.Item>

                                  </> :
                                  <>




                                  </>}



                                {parseInt(permission.pause_course) ?
                                  <>

                                    <Dropdown.Item onClick={() => { PauseTraining(item.purchase_id) }}   ><PauseCircleOutlineIcon className='m-1 p-1 w-22' />Pause Course</Dropdown.Item>

                                  </> :
                                  <>



                                  </>}


                              </> :
                              <>
                                {item.purchase_status === "refund" ?
                                  <>
                                    {parseInt(permission.mark_course_refunded) === 1 ?
                                      <>
                                        <Dropdown.Item onClick={() => { updateCourseEnrollment({ pid: item.purchase_id, pstatus: "refunded" }) }}   ><DoneAllIcon className='m-1 p-1 w-22' />Mark Refunded</Dropdown.Item>
                                      </> :
                                      <>


                                      </>}



                                  </> :
                                  <>

                                    {parseInt(permission.activate_course) === 1 ?
                                      <>
                                        <Dropdown.Item onClick={() => { updateCourseEnrollment({ pid: item.purchase_id, pstatus: "success" }) }}  ><img src={active} width={22} className='m-1 p-1'></img>Activate Course</Dropdown.Item>

                                      </> :
                                      <>

                                      </>}


                                  </>}

                              </>}

                          </>}

                        {parseInt(permission.delete_course_payment) === 1 ?
                          <>

                            <Dropdown.Item onClick={() => { Delete({ purchase_id: item.purchase_id }) }} > <DeleteIcon className='m-1 p-1 w-22' />Delete Payment History</Dropdown.Item>

                          </> :
                          <>


                          </>}
                      </Dropdown.Menu>
                    </Dropdown>

                  </div>

                </div>
                <h5 className="fs-14 m-0">

                  <span id="ticket_price" className="product-price">
                    <s className='text-danger'>{item.purchase_price}</s>
                  </span>
                </h5>
                <h5 className="fs-14">

                  <span id="ticket_price" className="product-date">
                    {dateFormat(item.purchase_datetime, "dS mmm yy hh:MM TT")}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/* card body */}

      </div>

    </>
  )
}

export default CourseRegistrationItem