import axios from 'axios';
import React, { useState } from 'react'
import SecurityHeaders from '../../context/SecurityHeaders';

const UploadImage = () => {
    const [image, setImage] = useState(null);          
  const [title, setTitle] = useState(null);            
  const [description, setDescription] = useState(null); 
  const [page, setPage] = useState(null);  
    
    const data=new FormData()
    data.append("image_url",image)
    data.append("title",title)
    data.append("description",description)
    data.append("page",page)
     const uploadimage=async()=>{
        const response=await axios.post("https://skillsuprise.com/insert-photos.php",data,SecurityHeaders)
        console.log(response)

          
     }
     const handleImageChange = (e) => {
        setImage(e.target.files[0]);
      };
    
      const handleTitleChange = (e) => {
        setTitle(e.target.value); 
      };
    
      const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
      };
    
      const handlePageChange = (e) => {
        setPage(e.target.value); 
      };
    





  return (
   <>
    <div style={{ 
  maxWidth: '400px', 
  margin: '50px auto', 
  padding: '20px', 
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', 
  borderRadius: '8px' 
}}>
  <input
    type="file"
    onChange={handleImageChange}
    style={{ 
      marginBottom: '15px', 
      display: 'block', 
      width: '100%' 
    }}
  />
  <input
    type="text"
    placeholder="Enter Title"
    value={title}
    onChange={handleTitleChange}
    style={{ 
      marginBottom: '10px', 
      padding: '10px', 
      width: '100%', 
      borderRadius: '4px', 
      border: '1px solid #ccc' 
    }}
  />
  <textarea
    placeholder="Enter Description"
    value={description}
    onChange={handleDescriptionChange}
    style={{ 
      marginBottom: '10px', 
      padding: '10px', 
      width: '100%', 
      borderRadius: '4px', 
      border: '1px solid #ccc', 
      resize: 'none' 
    }}
    rows="4"
  />
  <input
    type="text"
    placeholder="Enter Page"
    value={page}
    onChange={handlePageChange}
    style={{ 
      marginBottom: '15px', 
      padding: '10px', 
      width: '100%', 
      borderRadius: '4px', 
      border: '1px solid #ccc' 
    }}
  />
  <button
    onClick={uploadimage}
    style={{ 
      width: '100%', 
      padding: '10px', 
      backgroundColor: '#4CAF50', 
      color: 'white', 
      border: 'none', 
      borderRadius: '4px', 
      cursor: 'pointer', 
      fontWeight: 'bold' 
    }}
  >
    Upload
  </button>
</div>

   
   
   
   
   
   
   
   </>
  )
}

export default UploadImage
