import React from 'react'
import BadgeItem from './BadgeItem'

const PersonalBadgesList = ({profile,badges}) => {
  return (
   <>
   
   <div className="d-flex flex-wrap">

{badges.map((item)=>(
    <>
    
    <div className="col-lg-2">
        <BadgeItem item={item} course={false}/>
    </div>
    
    </>
))}

    
   </div>
   
   
   
   </>
  )
}

export default PersonalBadgesList