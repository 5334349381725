import React, { useContext, useEffect, useState } from 'react'
import CourseRegistrationItem from './CourseRegistrationItem'
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import { AuthContext } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';

import DeletePaymentHistory from '../../actions/payments/DeletePaymentHistory'
import styled from 'styled-components';
import RegistrationsTimelineGraph from './RegistrationsTimelineGraph';
import Localbase from 'localbase';
import { useActionsContext } from '../../context/ActionsContext';
const CourseRegistrations = () => {

  const { user } = useContext(AuthContext)
  const userdata = user;
  const params = useParams();
  const payment_status = "all"
  const [payments, setpayments] = useState(null);
  const [query, setquery] = useState(null)
  const db = new Localbase("db")
  const [pay, setPay] = useState(null);



  const getpayments = async () => {


    const formData = new FormData();


    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append('session', user.SessionId);

    formData.append('status', payment_status);
    if (query !== null) {
      formData.append('query', query);

    }
    //  // console.log("pay "+payment_status)
    const res = await axios.post(ENDPOINT + "admin-get-payments.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {
      //  // console.log("payments "+JSON.stringify(res))
      if (res.data.status === "success") {
db.collection("paths").add({course_registrations:res.data.data},"course_registrations")
        setInitialData(res.data.data)
        setResponse(res.data.data)
        setpayments(res.data.data)
        setPay(res.data)
      }
      else {

      }
    }
    // console.log(pay ? pay.success_count.success_count : null);
  }


  useEffect(() => {
    
    db.collection("paths").doc("course_registrations").get().then(data=>{
      if(data){
        setResponse(data.course_registrations)
        setpayments(data.course_registrations)
        setInitialData(data.course_registrations)
        setResponse(data.course_registrations)
      }
    })
    getpayments();

  }

    , [payment_status])




  const [toast, settoast] = useState(false)
  const [toasttitle, settoasttitle] = useState(null)
  const [toastmessage, settoastmessage] = useState(null)







  const updateCourseEnrollment = async ({ pid, pstatus }) => {


    const formData = new FormData();


    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append('session', user.SessionId);



    formData.append('purchase_id', pid);
    formData.append('course_status', pstatus)
    if (pstatus === "paused") {
      formData.append("reason", reason)
      formData.append("instruction", instruction)
    }
    if (query !== null) {
      formData.append('query', query)
    }


    const res = await axios.post(ENDPOINT + "update-student-course-status.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {
      //("course status "+JSON.stringify(res.data))
      // console.log(res)
      if (res.data.status === "success") {

        settoasttitle(res.data.title)
        settoastmessage(res.data.message + pstatus)
        getpayments()
        settoast(true)
        setmodal(false)
      }
      else {

      }
    }
  }


  const getpayments2 = (e) => {
    e.preventDefault()
    getpayments()
  }


  const Delete = async ({ purchase_id }) => {

    const res = await DeletePaymentHistory({
      payload: {
        session: user.SessionId,
        purchase_id: purchase_id
      }
    })
    if (res) {
      if (res.status === "success") {
        getpayments()
      }
    }

  }

  const [modal, setmodal] = useState(false)
  const PauseTraining = (purchase_id) => {

    setpurchaseid(purchase_id)
    setmodal(true)
  }
  const [instruction, setinstruction] = useState(false)
  const [reason, setreason] = useState(null)
  const [purchaseid, setpurchaseid] = useState(null)

  const [initialData, setInitialData] = useState('')


  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');
  const {searchquery} = useActionsContext()
  useEffect(()=>{
    handleSearch({term:searchquery})
  },[searchquery])
  const handleSearch = ({term}) => {
      if(!term)
      {
        setResponse(initialData)
        setSearchTerm("")
        return
      }
      if(term && initialData)
      {
        const filteredData = initialData.filter((item) => {
          return (
            (item.user_email && item.user_email.toLowerCase().includes(term)) ||
            (item.first_name && item.first_name.toLowerCase().includes(term)) ||
            (item.last_name && item.last_name.toLowerCase().includes(term)) ||
            (item.course_title && item.course_title.toLowerCase().includes(term)) ||
            (item.purchase_status && item.purchase_status.toLowerCase().includes(term))
          );
        });
    
        setResponse(filteredData);
        setSearchTerm(term);
      }

  };

  const [isToggled, setIsToggled] = useState(true);

  const handleChange = () => {
    setIsToggled(!isToggled);
  };

  return (
    <>

      <div className="container-fluid">



        <div className="row mb-3">

          <div className="col-xl-8 col-sm-12 col-12 px-0 px-md-2">





            {response ?
              <>
                {response.map((item) => (
                  <>
                    <CourseRegistrationItem notification={isToggled} Reload={getpayments} item={item} />

                  </>
                ))}

              </> :
              <>

              </>}


          </div>
          {/* end col */}
          <div className="col-xl-4 col-sm-12 col-12">

            
            <div className="sticky-side-div">
              <div className="card">

   
               {/* Previous Search Filters */}
              {/* <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block card-header border-bottom-dashed">
                  <h5 className="card-title mb-0">Search Course Registrations</h5>
                </div>
                <div className="card-header bg-light-subtle border-bottom-dashed ">

                  <div className="search-box d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    <input
                        onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                      value={searchTerm}
                      type="text"
                      className="form-control search"
                      placeholder="Search for course name phone email  status or something..."
                    />
                    <i className="ri-search-line search-icon" />
                  </div>
                </div> */}

                <div className="row">
                  <div className=" col-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                              <i className="ri-arrow-down-circle-fill align-middle" />
                            </span>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                              Success{" "}
                            </p>
                            <h4 className=" mb-0">

                              <span
                                className="counter-value"
                                data-target="14799.44"
                              >
                                {pay ? pay.success_count.success_count : null}
                              </span>
                            </h4>
                          </div>
                          <div className="flex-shrink-0 align-self-end d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            <span className="badge bg-danger-subtle text-danger">
                              <i className="ri-arrow-down-s-fill align-middle me-1" />
                              4.80 %<span> </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}

                  {/* end col */}
                  <div className=" col-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                              <i className="ri-arrow-down-circle-fill align-middle" />
                            </span>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                              Pending{" "}
                            </p>
                            <h4 className=" mb-0">

                              <span
                                className="counter-value"
                                data-target="14799.44"
                              >
                                {pay ? pay.pending_count.pending_count : null}
                              </span>
                            </h4>
                          </div>
                          <div className="flex-shrink-0 align-self-end d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            <span className="badge bg-danger-subtle text-danger">
                              <i className="ri-arrow-down-s-fill align-middle me-1" />
                              4.80 %<span> </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}

                  {/* end col */}

                  {/* end col */}
                </div>
              
                <div className="card-body pt-2">

                  {/* <RegistrationsTimelineGraph/>
                  <div className="table-responsive">
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td>Sub Total :</td>
                          <td className="text-end" id="cart-subtotal">
                            $ 359.96
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Discount{" "}
                            <span className="text-muted">(VELZON15)</span> :{" "}
                          </td>
                          <td className="text-end" id="cart-discount">
                            - $ 53.99
                          </td>
                        </tr>
                        <tr>
                          <td>Shipping Charge :</td>
                          <td className="text-end" id="cart-shipping">
                            $ 65.00
                          </td>
                        </tr>
                        <tr>
                          <td>Estimated Tax (12.5%) : </td>
                          <td className="text-end" id="cart-tax">
                            $ 44.99
                          </td>
                        </tr>
                        <tr className="table-active">
                          <th>Total (USD) :</th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              $415.96
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                  {/* end table-responsive */}
                </div>
              </div>
              {/* <div className="alert border-dashed alert-danger" role="alert">
                <div className="d-flex align-items-center">
                  <lord-icon
                    src="https://cdn.lordicon.com/nkmsrxys.json"
                    trigger="loop"
                    colors="primary:#121331,secondary:#f06548"
                    style={{ width: 80, height: 80 }}
                  />
                  <div className="ms-2">
                    <h5 className="fs-14 text-danger fw-semibold">
                      {" "}
                      Buying for a loved one?
                    </h5>
                    <p className="text-body mb-1">
                      Gift wrap and personalized message on card, <br />
                      Only for <span className="fw-semibold">
                        $9.99
                      </span> USD{" "}
                    </p>
                    <button
                      type="button"
                      className="btn ps-0 btn-sm material-shadow-none btn-link text-danger text-uppercase"
                    >
                      Add Gift Wrap
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
            {/* end stickey */}
          </div>
        </div>
        {/* end row */}
      </div>


    </>
  )
}

export default CourseRegistrations

export const CourseStyle = styled.div`
.toggle-button {
  padding: 8px 16px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.toggle-button.on {
  background-color: #4CAF50;
  color: white;
}

.toggle-button.off {
  background-color: #f44336;
  color: white;
}
`