import React, { useContext, useEffect, useState } from 'react'
import BatchesList from './BatchesList'
import InternalChaptersManagement from './ChaptersManagement'
import ResourceManagement from './ResourceManagement'
import CertificateManagement from '../certifications/CertificateManagement'
import MockTestsManagement from '../mock-tests/MockTestsManagement'
import CourseBatchesList from './CourseBatchesList'
import GetBatches from '../../actions/course/GetBatches'
import { Link, useParams } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import GetBatchClassSchedules from '../../actions/course/GetBatchClassSchedules'
import { useSelector } from 'react-redux'
import InternalQuizManagement from '../quizzes/InternalQuizManagement'
import DetectDevice from '../../context/DetectDevice'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

const CourseManagement = () => {
  const device = DetectDevice()
  const courseDetails = useSelector((state) => {
    return state.courseDetails;
  })

   // console.log(courseDetails);
  const [section, setsection] = useState(JSON.parse(sessionStorage.getItem("course_section")) || "overview")
  const params = useParams()
  const { user } = useContext(AuthContext)
  const course_id = params.course_id
  const course_url = params.course_url
  useEffect(() => {

    var option = sessionStorage.getItem("course_section")
    if (option) {

      setsection(JSON.parse(option))
    }
  }, [section])

  const [batches, setbatches] = useState(null)
  const GetNewBatches = async () => {

    const res = await GetBatches({
      payload: {
        course_id: course_id,
        session: user.SessionId
      }
    })
    if (res) {

      if (res.status === "success") {

        setbatches(res.data)
      }
    }
  }
  useEffect(() => {

    if (section === "batches") {

      GetNewBatches()
    }

  }, [section])

  const ChangeSection = ({ section }) => {

    setsection(section)
    sessionStorage.setItem("course_section", JSON.stringify(section))
  }

  return (
    <>

      <div className="container-fluid">
        {device==="mobile"?
        <>
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg" style={{height:"20vh"}}>
              <img src="assets/images/profile-bg.jpg" alt="" className="profile-wid-img" />
            </div>
            
          </div>
          <div className="pt-4 mb-4 profile-wrapper">
            <div className="row">
              <div className='d-flex align-items-center'>
                <div style={{ height: 95, width: 130 }} className='d-flex align-items-center'>
                  <img src={courseDetails.course_image} alt="user-img" className="img-thumbnail d-flex align-items-center"/>
                </div>
                {/*end col*/}
                <div className="p-2">
                  <h3 className="text-white mb-1 fs-15">{courseDetails.course_name}</h3>
                  <h5 className="text-white text-opacity-75 fs-12">
                    Mode : {courseDetails.course_mode}
                  </h5>
                </div>
                <div className='edit'>
                  <Link to={"/edit-course/" + course_id} className="btn btn-success px-2 py-1">
                    <i className="ri-edit-box-line align-bottom" />
                  </Link>
                </div>
              </div>
            </div>
            {/*end row*/}
          
          </div>
        
        </>:
        <>
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img src="assets/images/profile-bg.jpg" alt="" className="profile-wid-img" />
            </div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-3 profile-wrapper">
          <div className="row g-4 align-items-center">
            <div className="col-auto">
              <div style={{ height: 96, width: 180 }} className="col-lg-2">
                <img
                  src={courseDetails.course_image}
                  alt="user-img"
                  className="img-thumbnail "
                />
              </div>
            </div>
            {/*end col*/}
            <div className="col">
              <div className="p-2">
                <h3 className="text-white mb-1">{courseDetails.course_name}</h3>
                <h5 className="text-white text-opacity-75">
                  Mode : {courseDetails.course_mode}
                </h5>
              </div>
            </div>
            {/* end col*/}
            <div className="col-12 col-lg-auto order-last order-lg-0">
              <div className="row text text-white-50 text-center">
                <div className="col-lg-6 col-4">
                  {/* <div className="p-2">
                      <h4 className="text-white mb-1">24.3K</h4>
                      <p className="fs-14 mb-0">Followers</p>
                    </div> */}
                </div>
                <div className="col-lg-6 col-4">
                  {/* <div className="p-2">
                      <h4 className="text-white mb-1">1.3K</h4>
                      <p className="fs-14 mb-0">Following</p>
                    </div> */}
                </div>
              </div>
            </div>
            {/*end col */}
          </div>
          {/*end row*/}
        </div>
        </>}
        
        <div className="row">
          <div className="col-lg-12">
            <div>
              {device==="mobile"?
              <>
                <div className="d-flex profile-wrapper">
                {/* Nav tabs */}
                <ul className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1 justify-content-evenly align-items-center" role="tablist">
                  <div onClick={() => ChangeSection({ section: "overview" })} className="col-3 rounded rounded-3 shadow fade-in-bottom delay2s nav-item d-flex align-items-center">
                    <a className="nav-link fs-14 text-center active overbox " data-bs-toggle="tab" role="tab">
                      <AccountCircleOutlinedIcon></AccountCircleOutlinedIcon>
                      <p className="m-0 fs-12">Overview</p>
                    </a>
                  </div>

                  <div className="col-3 rounded rounded-3 shadow nav-item d-flex align-items-center ">
                    <a className="nav-link fs-14 text-center overbox" onClick={() => ChangeSection({ section: "batches" })} data-bs-toggle="tab" role="tab">
                      <GroupsOutlinedIcon></GroupsOutlinedIcon>
                      <p className="m-0 fs-12">
                        Batches
                      </p>
                    </a>
                  </div>
                  <div onClick={() => ChangeSection({ section: "syllabus" })} className="col-3 rounded rounded-3 shadow nav-item d-flex align-items-center ">
                    <a className="nav-link fs-14 text-center overbox" data-bs-toggle="tab" href="#activities" role="tab">
                      <AutoStoriesOutlinedIcon></AutoStoriesOutlinedIcon>
                      <p className="m-0 fs-12">
                        Syllabus
                      </p>
                    </a>
                  </div>
                  <div className="col-3 rounded rounded-3 shadow nav-item d-flex align-items-center ">
                    <a className="nav-link fs-14 text-center overbox" onClick={() => ChangeSection({ section: "resources" })} data-bs-toggle="tab" role="tab">
                      <PictureAsPdfOutlinedIcon></PictureAsPdfOutlinedIcon>
                      <p className="m-0 fs-12">
                        Resources
                      </p>
                    </a>
                  </div>
                  <div className="col-3 rounded rounded-3 shadow nav-item d-flex align-items-center ">
                    <a className="nav-link fs-14 text-center overbox" onClick={() => ChangeSection({ section: "mock-tests" })} data-bs-toggle="tab" role="tab">
                      <InventoryOutlinedIcon></InventoryOutlinedIcon>
                      <p className="m-0 fs-12">
                        Mock Tests
                      </p>
                    </a>
                  </div>

                  <div className="col-3 rounded rounded-3 shadow nav-item d-flex align-items-center ">
                    <a className="nav-link fs-14 text-center overbox" onClick={() => ChangeSection({ section: "technical-quizzes" })} data-bs-toggle="tab" role="tab">
                      <FactCheckOutlinedIcon></FactCheckOutlinedIcon>
                      <p className="m-0 fs-12">
                        Technical Quizzes
                      </p>
                    </a>
                  </div>
                  <div className="col-3 rounded rounded-3 shadow nav-item d-flex align-items-center ">
                    <a className="nav-link fs-14 text-center overbox px-0" onClick={() => ChangeSection({ section: "certifications" })} data-bs-toggle="tab" role="tab"> 
                      <CardMembershipOutlinedIcon></CardMembershipOutlinedIcon>
                      <p className="m-0 fs-12">
                        Certifications
                      </p>
                    </a>
                  </div>
                </ul>
              </div>
              </>:
              <>
                <div className="d-flex profile-wrapper">
                {/* Nav tabs */}
                <ul className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                  <li onClick={() => ChangeSection({ section: "overview" })} className="nav-item">
                    <a className="nav-link fs-14 active" role="tab">
                      <i className="ri-airplay-fill d-inline-block d-md-none" />{" "}
                      <span className="d-none d-md-inline-block">
                        Overview
                      </span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link fs-14" onClick={() => ChangeSection({ section: "batches" })} role="tab">
                      <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                      <span className="d-none d-md-inline-block">
                        Batches
                      </span>
                    </a>
                  </li>
                  <li onClick={() => ChangeSection({ section: "syllabus" })} className="nav-item">
                    <a className="nav-link fs-14" data-bs-toggle="tab" href="#activities" role="tab">
                      <i className="ri-list-unordered d-inline-block d-md-none" />{" "}
                      <span className="d-none d-md-inline-block">
                        Syllabus
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fs-14" onClick={() => ChangeSection({ section: "resources" })} role="tab">
                      <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                      <span className="d-none d-md-inline-block">
                        Resources
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fs-14" onClick={() => ChangeSection({ section: "certifications" })} role="tab">
                      <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                      <span className="d-none d-md-inline-block">
                        Certifications
                      </span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link fs-14" onClick={() => ChangeSection({ section: "mock-tests" })} role="tab">
                      <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                      <span className="d-none d-md-inline-block">
                        Mock Tests
                      </span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link fs-14" onClick={() => ChangeSection({ section: "technical-quizzes" })} role="tab">
                      <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                      <span className="d-none d-md-inline-block">
                        Technical Quizzes
                      </span>
                    </a>
                  </li>
                </ul>
                <div className="flex-shrink-0">
                  <Link to={"/edit-course/" + course_id} className="btn btn-success">
                    <i className="ri-edit-box-line align-bottom" /> Edit Profile
                  </Link>
                </div>
              </div>
              </>}
              
              {/* Tab panes */}
              <div className="tab-content pt-4 text-muted">
                {section === "overview" ?
                  <>

                    <div
                      className="tab-pane active p-3"
                      id="overview-tab"
                      role="tabpanel"
                    >
                      <div className="row">

                        <div className="card">

                          <hr></hr>

                          {batches ?
                            <>
                              <CourseBatchesList batches={batches} addbatch={true} />


                            </> :
                            <>

                            </>}








                        </div>
                        {/*end col*/}
                      </div>
                      {/*end row*/}
                    </div>
                  </> :
                  <>

                  </>}







                {section === "batches" ?
                  <>
                    <div
                      className="tab-pane active p-3"

                      role="tabpanel"
                    >
                      <div className="card">


                        {batches ?
                          <>
                            <CourseBatchesList batches={batches} addbatch={true} />


                          </> :
                          <>

                          </>}
                        {/*end card-body*/}
                      </div>
                      {/*end card*/}
                    </div>



                  </> :
                  <>


                  </>}













                {section === "syllabus" ?
                  <>
                    <div
                      className="tab-pane active p-3"

                      role="tabpanel"
                    >
                      <div className="card">


                        <InternalChaptersManagement />


                        {/*end card-body*/}
                      </div>
                      {/*end card*/}
                    </div>



                  </> :
                  <>


                  </>}

                {section === "resources" ?
                  <>
                    <div
                      className="tab-pane active "

                    >
                      <div className="card">

                        <ResourceManagement />



                        {/*end card-body*/}
                      </div>
                      {/*end card*/}
                    </div>

                  </> :
                  <>


                  </>}








                {section === "certifications" ?
                  <>
                    <div
                      className="tab-pane active "

                    >
                      <div className="card">

                        <MockTestsManagement test_type={"course-certification"} />



                        {/*end card-body*/}
                      </div>
                      {/*end card*/}
                    </div>

                  </> :
                  <>


                  </>}
                {section === "mock-tests" ?
                  <>
                    <div
                      className="tab-pane active "

                    >
                      <div className="card">

                        <MockTestsManagement test_type={"mock-test"} />



                        {/*end card-body*/}
                      </div>
                      {/*end card*/}
                    </div>

                  </> :
                  <>


                  </>}





                {section === "technical-quizzes" ?
                  <>
                    <div
                      className="tab-pane active "

                    >
                      <div className="card  pt-3">

                        {/* <MockTestsManagement test_type={"technical-quiz"} /> */}
                        <InternalQuizManagement course_url={course_url} />



                        {/*end card-body*/}
                      </div>
                      {/*end card*/}
                    </div>

                  </> :
                  <>


                  </>}

























                {/*end tab-pane*/}
                <div className="tab-pane fade" id="projects" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none profile-project-warning material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Chat App Update
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      2 year Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-warning-subtle text-warning fs-10">
                                    Inprogress
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-1.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-3.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-light text-primary">
                                            J
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none profile-project-success material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      ABC Project Customization
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      2 month Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-primary-subtle text-primary fs-10">
                                    {" "}
                                    Progress
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-8.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-7.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-6.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-primary">
                                            2+
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none profile-project-info material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Client - Frank Hook
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      1 hr Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-info-subtle text-info fs-10">
                                    New
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        {" "}
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-4.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-light text-primary">
                                            M
                                          </div>
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-3.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none profile-project-primary material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Velzon Project
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      11 hr Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-success-subtle text-success fs-10">
                                    Completed
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-7.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-5.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none profile-project-danger material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Brand Logo Design
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      10 min Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-info-subtle text-info fs-10">
                                    New
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-7.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-6.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-light text-primary">
                                            E
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none profile-project-primary material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Chat App
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      8 hr Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-warning-subtle text-warning fs-10">
                                    Inprogress
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-light text-primary">
                                            R
                                          </div>
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-3.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-8.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none profile-project-warning material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Project Update
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      48 min Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-warning-subtle text-warning fs-10">
                                    Inprogress
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-6.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-5.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-4.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none profile-project-success material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Client - Jennifer
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      30 min Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-primary-subtle text-primary fs-10">
                                    Process
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        {" "}
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-1.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none mb-xxl-0 profile-project-info material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Bsuiness Template - UI/UX design
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      7 month Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-success-subtle text-success fs-10">
                                    Completed
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-2.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-3.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-4.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-primary">
                                            2+
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none mb-xxl-0  profile-project-success material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Update Project
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      1 month Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-info-subtle text-info fs-10">
                                    New
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-7.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-light text-primary">
                                            A
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none mb-sm-0  profile-project-danger material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      Bank Management System
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      10 month Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-success-subtle text-success fs-10">
                                    Completed
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-7.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-6.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-5.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-primary">
                                            2+
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-sm-6">
                          <div className="card profile-project-card shadow-none mb-0  profile-project-primary material-shadow">
                            <div className="card-body p-4">
                              <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                  <h5 className="fs-14 text-truncate">
                                    <a href="#" className="text-body">
                                      PSD to HTML Convert
                                    </a>
                                  </h5>
                                  <p className="text-muted text-truncate mb-0">
                                    Last Update :{" "}
                                    <span className="fw-semibold text-body">
                                      29 min Ago
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <div className="badge bg-info-subtle text-info fs-10">
                                    New
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <div>
                                      <h5 className="fs-12 text-muted mb-0">
                                        Members :
                                      </h5>
                                    </div>
                                    <div className="avatar-group">
                                      <div className="avatar-group-item material-shadow">
                                        <div className="avatar-xs">
                                          <img
                                            src="assets/images/users/avatar-7.jpg"
                                            alt=""
                                            className="rounded-circle img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/*end col*/}
                        <div className="col-lg-12">
                          <div className="mt-4">
                            <ul className="pagination pagination-separated justify-content-center mb-0">
                              <li className="page-item disabled">
                                <a
                                  href="javascript:void(0);"
                                  className="page-link"
                                >
                                  <i className="mdi mdi-chevron-left" />
                                </a>
                              </li>
                              <li className="page-item active">
                                <a
                                  href="javascript:void(0);"
                                  className="page-link"
                                >
                                  1
                                </a>
                              </li>
                              <li className="page-item">
                                <a
                                  href="javascript:void(0);"
                                  className="page-link"
                                >
                                  2
                                </a>
                              </li>
                              <li className="page-item">
                                <a
                                  href="javascript:void(0);"
                                  className="page-link"
                                >
                                  3
                                </a>
                              </li>
                              <li className="page-item">
                                <a
                                  href="javascript:void(0);"
                                  className="page-link"
                                >
                                  4
                                </a>
                              </li>
                              <li className="page-item">
                                <a
                                  href="javascript:void(0);"
                                  className="page-link"
                                >
                                  5
                                </a>
                              </li>
                              <li className="page-item">
                                <a
                                  href="javascript:void(0);"
                                  className="page-link"
                                >
                                  <i className="mdi mdi-chevron-right" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end card-body*/}
                  </div>
                  {/*end card*/}
                </div>
                {/*end tab-pane*/}
                <div className="tab-pane fade" id="documents" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-4">
                        <h5 className="card-title flex-grow-1 mb-0">
                          Documents
                        </h5>
                        <div className="flex-shrink-0">
                          <input
                            className="form-control d-none"
                            type="file"
                            id="formFile"
                          />
                          <label
                            htmlFor="formFile"
                            className="btn btn-danger"
                          >
                            <i className="ri-upload-2-fill me-1 align-bottom" />{" "}
                            Upload File
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="table-responsive">
                            <table className="table table-borderless align-middle mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th scope="col">File Name</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Size</th>
                                  <th scope="col">Upload Date</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="avatar-sm">
                                        <div className="avatar-title bg-primary-subtle text-primary rounded fs-20 material-shadow">
                                          <i className="ri-file-zip-fill" />
                                        </div>
                                      </div>
                                      <div className="ms-3 flex-grow-1">
                                        <h6 className="fs-15 mb-0">
                                          <a href="javascript:void(0)">
                                            Artboard-documents.zip
                                          </a>
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>Zip File</td>
                                  <td>4.57 MB</td>
                                  <td>12 Dec 2021</td>
                                  <td>
                                    <div className="dropdown">
                                      <a
                                        href="javascript:void(0);"
                                        className="btn btn-light btn-icon"
                                        id="dropdownMenuLink15"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                      >
                                        <i className="ri-equalizer-fill" />
                                      </a>
                                      <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuLink15"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-eye-fill me-2 align-middle text-muted" />
                                            View
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                            Download
                                          </a>
                                        </li>
                                        <li className="dropdown-divider" />
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="avatar-sm">
                                        <div className="avatar-title bg-danger-subtle text-danger rounded fs-20 material-shadow">
                                          <i className="ri-file-pdf-fill" />
                                        </div>
                                      </div>
                                      <div className="ms-3 flex-grow-1">
                                        <h6 className="fs-15 mb-0">
                                          <a href="javascript:void(0);">
                                            Bank Management System
                                          </a>
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>PDF File</td>
                                  <td>8.89 MB</td>
                                  <td>24 Nov 2021</td>
                                  <td>
                                    <div className="dropdown">
                                      <a
                                        href="javascript:void(0);"
                                        className="btn btn-light btn-icon"
                                        id="dropdownMenuLink3"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                      >
                                        <i className="ri-equalizer-fill" />
                                      </a>
                                      <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuLink3"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-eye-fill me-2 align-middle text-muted" />
                                            View
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                            Download
                                          </a>
                                        </li>
                                        <li className="dropdown-divider" />
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="avatar-sm">
                                        <div className="avatar-title bg-secondary-subtle text-secondary rounded fs-20 material-shadow">
                                          <i className="ri-video-line" />
                                        </div>
                                      </div>
                                      <div className="ms-3 flex-grow-1">
                                        <h6 className="fs-15 mb-0">
                                          <a href="javascript:void(0);">
                                            Tour-video.mp4
                                          </a>
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>MP4 File</td>
                                  <td>14.62 MB</td>
                                  <td>19 Nov 2021</td>
                                  <td>
                                    <div className="dropdown">
                                      <a
                                        href="javascript:void(0);"
                                        className="btn btn-light btn-icon"
                                        id="dropdownMenuLink4"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                      >
                                        <i className="ri-equalizer-fill" />
                                      </a>
                                      <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuLink4"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-eye-fill me-2 align-middle text-muted" />
                                            View
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                            Download
                                          </a>
                                        </li>
                                        <li className="dropdown-divider" />
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="avatar-sm">
                                        <div className="avatar-title bg-success-subtle text-success rounded fs-20 material-shadow">
                                          <i className="ri-file-excel-fill" />
                                        </div>
                                      </div>
                                      <div className="ms-3 flex-grow-1">
                                        <h6 className="fs-15 mb-0">
                                          <a href="javascript:void(0);">
                                            Account-statement.xsl
                                          </a>
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>XSL File</td>
                                  <td>2.38 KB</td>
                                  <td>14 Nov 2021</td>
                                  <td>
                                    <div className="dropdown">
                                      <a
                                        href="javascript:void(0);"
                                        className="btn btn-light btn-icon"
                                        id="dropdownMenuLink5"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                      >
                                        <i className="ri-equalizer-fill" />
                                      </a>
                                      <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuLink5"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-eye-fill me-2 align-middle text-muted" />
                                            View
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                            Download
                                          </a>
                                        </li>
                                        <li className="dropdown-divider" />
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="avatar-sm">
                                        <div className="avatar-title bg-info-subtle text-info rounded fs-20 material-shadow">
                                          <i className="ri-folder-line" />
                                        </div>
                                      </div>
                                      <div className="ms-3 flex-grow-1">
                                        <h6 className="fs-15 mb-0">
                                          <a href="javascript:void(0);">
                                            Project Screenshots Collection
                                          </a>
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>Floder File</td>
                                  <td>87.24 MB</td>
                                  <td>08 Nov 2021</td>
                                  <td>
                                    <div className="dropdown">
                                      <a
                                        href="javascript:void(0);"
                                        className="btn btn-light btn-icon"
                                        id="dropdownMenuLink6"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                      >
                                        <i className="ri-equalizer-fill" />
                                      </a>
                                      <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuLink6"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-eye-fill me-2 align-middle" />
                                            View
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-download-2-fill me-2 align-middle" />
                                            Download
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-delete-bin-5-line me-2 align-middle" />
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="avatar-sm">
                                        <div className="avatar-title bg-danger-subtle text-danger rounded fs-20 material-shadow">
                                          <i className="ri-image-2-fill" />
                                        </div>
                                      </div>
                                      <div className="ms-3 flex-grow-1">
                                        <h6 className="fs-15 mb-0">
                                          <a href="javascript:void(0);">
                                            Velzon-logo.png
                                          </a>
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>PNG File</td>
                                  <td>879 KB</td>
                                  <td>02 Nov 2021</td>
                                  <td>
                                    <div className="dropdown">
                                      <a
                                        href="javascript:void(0);"
                                        className="btn btn-light btn-icon"
                                        id="dropdownMenuLink7"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                      >
                                        <i className="ri-equalizer-fill" />
                                      </a>
                                      <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuLink7"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-eye-fill me-2 align-middle" />
                                            View
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-download-2-fill me-2 align-middle" />
                                            Download
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ri-delete-bin-5-line me-2 align-middle" />
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="text-center mt-3">
                            <a
                              href="javascript:void(0);"
                              className="text-success"
                            >
                              <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" />{" "}
                              Load more{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*end tab-pane*/}
              </div>
              {/*end tab-content*/}
            </div>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>


    </>
  )
}

export default CourseManagement