import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import DeleteModal from '../../components/modals/DeleteModal'
import DefaultToast from '../../components/toasts/DefaultToast'
import DefaultModal from '../../components/modals/DefaultModal'
import { Avatar, TextField } from '@mui/material'
import dateFormat from 'dateformat'
import axios from 'axios'
import ENDPOINT from '../../context/EndPoint'
import SecurityHeaders from '../../context/SecurityHeaders'
import { Close } from '@material-ui/icons'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

const AssignmentSubmissionItemMobile = ({ item, nValue, dispatch,student }) => {
    const { user } = useContext(AuthContext)
  const [evaluate, setevaluate] = useState(false)
  const [toast, settoast] = useState(false)
  const [deletemodal, setdeletemodal] = useState(false)
  const [alert, setalert] = useState(false)
  const [toastdata, settoastdata] = useState(null)
  const [corrections, setcorrections] = useState(item.corrections)
  const [earnedmarks, setearnedmarks] = useState(item.marks_given)
  const [status, setstatus] = useState(item.status)
  const CloseEvaluation = () => {
    setevaluate(false)
  }

  //delete task code

  const deleteSubmission = async () => {

    const formData = new FormData();
    formData.append('session', user.SessionId);
    formData.append('id', item.id);

    const res = await axios.post(ENDPOINT + "admin-delete-assignment-submission.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {

      settoastdata(res.data)
      settoast(true)
      setdeletemodal(false)
    }

    if (res.data.status === "success") {
      dispatch({type:'FUNCTION_RELOAD',payload:'assignment'})
    }
    else {

    }
  }




  const SaveChanges = async (e) => {

    try {
      e.preventDefault()
      const data = new FormData()
      data.append("id", item.id)
      data.append("session", user.SessionId)
      data.append("earned_marks", earnedmarks)
      data.append("status", status)
      data.append("corrections", corrections)
      data.append('batch_id', item.batch_id);
      data.append('course_url', item.course_url);
      if (nValue) {
        data.append('notification', nValue);

      }

      const res = await axios.post(ENDPOINT + "admin-update-assignment-submission.php", data, SecurityHeaders)
      if (res) {

        settoastdata(res.data)
        settoast(true)
        setevaluate(false)


        if (res.data.status === "success") {
          //  // console.log("reload",Reload())
          dispatch({type:'FUNCTION_RELOAD',payload:'project-submissions'})

        } else {



        }

      }
    }
    catch (error) {
       // console.log("update error", error)
    }
  }

  return (
    <>
    <DeleteModal show={deletemodal} HandleClose={() => setdeletemodal(false)} HandleDelete={deleteSubmission} />




<DefaultToast toastdata={toastdata} show={toast} ToastHandleClose={() => settoast(false)} />












<DefaultModal
  className="col-lg-5 col-sm-12 col-12"
  HandleClose={() => setevaluate(false)}

  show={evaluate}
  content={<>
    <div className='p-3 '>
      <div className="modal-header">
        <h4>Evaluate Assignment</h4>
        <Close onClick={() => setevaluate(false)} />
      </div>
      <hr></hr>
      <form onSubmit={SaveChanges}>


        <div className="d-flex">
          <TextField value={earnedmarks} onChange={(e) => setearnedmarks(e.target.value)} placeholder='Enter Marks' className='col form-control mb-3' />

          <select className='form-control col' value={status} onChange={(e) => setstatus(e.target.value)}>
            <option value="submitted">Mark Submitted</option>

            <option value="rejected">Reject</option>


            <option value="completed">Mark Complete</option>
          </select>
        </div>
        <textarea value={corrections} onChange={(e) => setcorrections(e.target.value)} placeholder='Enter corrections' className='form-control '></textarea>


        <button type='submit' className='mt-3 w-100 btn btn-primary'>Save Changes</button>
      </form>
    </div>


  </>}
></DefaultModal>
            <div className="col-12" key={item.id}>
          <div className="card p-3 mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className='d-flex p-0'>
                <Avatar src={item.profile_pic} className="me-2" />
                <div className="d-flex flex-column justify-content-center">
                  <h5 className='m-0 fs-sm-12'>{item.first_name + " " + item.last_name}</h5>
                  <p className="mb-0 fs-sm-10"> {item.course_url}</p>
                </div>
              </div>
              <span className={`badge ${item.status==='pending'?'bg-warning-subtle text-warning':item.status==="submitted"?'bg-success-subtle text-success':'bg-danger-subtle text-danger'}`}>
                {item.status}
              </span>
            </div>
            <div className="d-flex">
                <div>
                    
                    <p className="mb-1 fs-sm-12"><strong>Title:</strong> {item.title}</p>
                </div>
                <div className="text-end">
                    <button className="btn btn-link text-muted p-0" data-bs-toggle="collapse" data-bs-target={`#details${item.id}`} aria-expanded="false" aria-controls={`details${item.id}`}>
                        <i className="ri-arrow-down-s-fill"></i> More
                    </button>
                </div>
            </div>

           

            <div className="collapse" id={`details${item.id}`}>
              
              <p className="mb-1"><strong>Submission Time:</strong> {dateFormat(item.submission_time, "dS mmm hh:mm tt")}</p>
            <p className="mb-1"><strong>Deadline:</strong> {dateFormat(item.deadline, "dS mmm hh:mm tt")}</p>
            <p className='mb-1'><strong><FileDownloadRoundedIcon/><a href={item.solution_link} target="_blank" rel="noopener noreferrer">Download Link</a></strong> </p>
            <div className="navbar">
            <button className="btn btn-success" onClick={() => setevaluate(true)}>Evaluate</button>
            <button className="btn btn-danger" onClick={() => setdeletemodal(true)}>Delete</button>
            </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default AssignmentSubmissionItemMobile