import React from 'react'
import DemoData from '../../data/DemoData'
import CandidateItem from './CandidateItem'
import Dropdown from 'react-bootstrap/Dropdown';

const CandidatesList = () => {
  return (
<>

        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">List View</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Candidate Lists</a>
                    </li>
                    <li className="breadcrumb-item active">List View</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row g-4 mb-4">
            <div className="col-sm-auto">
              <div>
                <a href="#!" className="btn btn-primary">
                  <i className="ri-add-line align-bottom me-1" /> Add Candidate
                </a>
              </div>
            </div>
            <div className="col-sm">
              <div className="d-md-flex justify-content-sm-end gap-2">
                <div className="search-box ms-md-2 flex-shrink-0 mb-3 mb-md-0">
                  <input
                    type="text"
                    className="form-control"
                    id="searchJob"
                    autoComplete="off"
                    placeholder="Search for candidate name or designation..."
                  />
                  <i className="ri-search-line search-icon" />
                </div>
                <div
                  className="choices"
                  data-type="select-one"
                  tabIndex={0}
                  role="listbox"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <Dropdown>
      <Dropdown.Toggle variant="transparent" id="dropdown-basic">
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                  <div
                    className="choices__list choices__list--dropdown"
                    aria-expanded="false"
                  >
                    <div className="choices__list" role="listbox">
                      <div
                        id="choices--02ky-item-choice-1"
                        className="choices__item choices__item--choice choices__item--selectable is-highlighted"
                        role="option"
                        data-choice=""
                        data-id={1}
                        data-value="All"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                        aria-selected="true"
                      >
                        All
                      </div>
                      <div
                        id="choices--02ky-item-choice-2"
                        className="choices__item choices__item--choice choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id={2}
                        data-value="Last 7 Days"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        Last 7 Days
                      </div>
                      <div
                        id="choices--02ky-item-choice-3"
                        className="choices__item choices__item--choice choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id={3}
                        data-value="Last 30 Days"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        Last 30 Days
                      </div>
                      <div
                        id="choices--02ky-item-choice-4"
                        className="choices__item choices__item--choice choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id={4}
                        data-value="Last Year"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        Last Year
                      </div>
                      <div
                        id="choices--02ky-item-choice-5"
                        className="choices__item choices__item--choice choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id={5}
                        data-value="This Month"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        This Month
                      </div>
                      <div
                        id="choices--02ky-item-choice-6"
                        className="choices__item choices__item--choice choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id={6}
                        data-value="Today"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        Today
                      </div>
                      <div
                        id="choices--02ky-item-choice-7"
                        className="choices__item choices__item--choice is-selected choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id={7}
                        data-value="Yesterday"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        Yesterday
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-2 mb-2" id="candidate-list">
         




{DemoData.map(()=>(
  <>
  
  <div className="col-md-6 col-lg-12">
<CandidateItem/>
    </div>
  
  </>
))}









          </div>
          {/* end row */}
          <div
            className="row g-0 justify-content-end mb-4"
            id="pagination-element"
          >
            {/* end col */}
            <div className="col-sm-6">
              <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                <div className="page-item disabled">
                  <a
                    href="javascript:void(0);"
                    className="page-link"
                    id="page-prev"
                  >
                    Previous
                  </a>
                </div>
                <span id="page-num" className="pagination">
                  <div className="page-item active">
                    <a
                      className="page-link clickPageNumber"
                      href="javascript:void(0);"
                    >
                      1
                    </a>
                  </div>
                  <div className="page-item">
                    <a
                      className="page-link clickPageNumber"
                      href="javascript:void(0);"
                    >
                      2
                    </a>
                  </div>
                  <div className="page-item">
                    <a
                      className="page-link clickPageNumber"
                      href="javascript:void(0);"
                    >
                      3
                    </a>
                  </div>
                </span>
                <div className="page-item">
                  <a
                    href="javascript:void(0);"
                    className="page-link"
                    id="page-next"
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
      

</>  )
}

export default CandidatesList