const initialState = {
  profile: null,
  userName: null,
};

const whatsappChatProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATEPROFILE":
      return { ...state, profile: action.payload };
    case "UPDATENAME":
      return { ...state, userName: action.payload };
    default:
      return state;
  }
};

export default  whatsappChatProfileReducer