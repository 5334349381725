import React, { useContext, useEffect, useState } from 'react'
import CourseBatchesList from './CourseBatchesList'
import GetActiveBatches from '../../actions/course/GetActiveBatches'
import { AuthContext } from '../../context/AuthContext'
import { useSelector } from 'react-redux'
import Localbase from 'localbase';

const ActiveBatchesList = () => {

   
    const value = useSelector((state) => state.modalHandler);
    const { user } = useContext(AuthContext);
    const [batches, setBatches] = useState(null);
    const [total, setTotal] = useState(null);
    const db = new Localbase("db");
    
    const GetBatches = async () => {
        try {
            const res = await GetActiveBatches({
                payload: {
                    session: user.SessionId,
                    status: "active"
                }
            });
    
            if (res && res.status === "success") {
                setBatches(res.data);
                setTotal(res);
                // Save to Localbase
                await db.collection("batches").doc("active_batches").set({
                    batches: res.data,
                    total: res.total_batches
                });
            }
        } catch (error) {
            console.error("Error fetching batches:", error);
        }
    };
    
    useEffect(() => {
        // Fetch batches from Localbase first
        db.collection("batches").doc("active_batches").get().then(data => {
            if (data) {
                setBatches(data.batches);
                setTotal({ total_batches: data.total });
            }
        });
    
        // Then call the API to fetch updated data
        GetBatches();
    }, []);
    
    useEffect(() => {
        if (value.functionName === 'batches') {
            setBatches(null);
            GetBatches();
        }
    }, [value]);
    
    
  return (
<>
{batches?
<>


<CourseBatchesList addbatch={false} batches={batches}/>
</>:
<>


</>}
</>
  )
}

export default ActiveBatchesList