import React, { useContext, useEffect, useState } from 'react'
import DemoData from '../../data/DemoData'
import RegistrationItem from './RegistrationItem'
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../context/EndPoint';
import DefaultModal from '../../components/modals/DefaultModal';
import EditWorkshopRegistration from './EditWorkshopRegistration';

const HackingWorkshopRegistrations = () => {
 
 
  const {user} = useContext(AuthContext);

   
  const [toast,setToast]= useState(false)
  const [message,setMessage] = useState("")
 
  
  // Sample array of user IDs
  const users = [];
  
   const [registrations,setRegistrations] = useState(null)
const [totalregistrations,settotalregistrations] = useState(null)
const [today,settoday] = useState(null)
const [yesterday,setyesterday] = useState(null)
   const getRegistrations = async ()=>{
       const URL = `${ENDPOINT}admin-get-hacking-workshop.php?session=${user.SessionId}`;
       fetch(URL)
       .then(r=>r.json())
       .then(result=>{
      
        
               setRegistrations(result.data);
               setInititalData(result.data)
               setResponse(result.data)
               settoday(result.today)
               setyesterday(result.yesterday)
               settotalregistrations(result.total)
               
      
  // Sample array of user IDs
  result.data.map((item)=>(
   users.push(item.id)
   ))
        // console.log(users)    
       })

   }

   useEffect(() => {
 

       getRegistrations()
    
   }, [])

   const [sendMessage, setSendMessage] = useState('');
 
 // Load the saved message from local storage when the component mounts

 // Update the message state when the input field value changes
 const handleChange = (e) => {
   setSendMessage(e.target.value);
 };
 
 // Save the message to local storage
 const saveMessageToLocalStorage = () => {
   localStorage.setItem('wa_message', sendMessage);
 };

 const [initialData,setInititalData] = useState(null)
 
 const [response, setResponse] = useState(initialData);
 const [searchTerm, setSearchTerm] = useState('');

 const handleSearch = (e) => {
   const term = e.target.value.toLowerCase();

   const filteredData = initialData.filter((item) => {
     return (
       item.email.toLowerCase().includes(term) ||      
       item.name.toLowerCase().includes(term)
     );
   });

   setResponse(filteredData);
   setSearchTerm(term);
 };

 const [selecteditems,setselecteditems] = useState([])
 useEffect(()=>{
    // console.log(selecteditems)
 },[selecteditems])
  const [visibility,setvisibility] = useState(false)

  const [selectedData, setSelectedData] = useState([]);

  // Function to handle user click
  const handleUserClick = (id) => {
    // Check if the user ID is already in the selectedData array
    const isSelected = selectedData.includes(id);

    // If selected, remove from the array; otherwise, add to the array
    setSelectedData((prevSelectedData) => (
      isSelected
        ? prevSelectedData.filter(uid => uid !== id)
        : [...prevSelectedData, id]
    ));

     // console.log(selectedData)
  };

  const [edit,setedit] = useState(false)

  const CloseEdit = ()=>{

setedit(false)

  }

  const [selectedusers,setselectedusers] = useState([])


  const SelectUser = ({id})=>{

if(selectedusers.includes(id)){

  var index = selectedusers.indexOf(id)
  selectedusers.splice(index,1)
}else{
  selectedusers.push(id)

}
 // console.log(selectedusers)

  }



  const [bulkactions,setbulkactions] = useState(false)


  useEffect(()=>{

    if(selectedusers&&selectedusers.length>1){
      setbulkactions(true)
    }else{
      setbulkactions(false)
    }
  },[selectedusers,selectedData])
  return (
   <>










   <div className="container-fluid">
          <div className="card">
               {/*end row*/}
               <div className="row">
              
              <div className="col-lg-3 col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-arrow-down-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
Total </p>
                          <h4 className=" mb-0">
                            
                            <span
                              className="counter-value"
                              data-target="14799.44"
                            >
                             {totalregistrations}
                            </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="ri-arrow-down-s-fill align-middle me-1" />
                           76 %<span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}



              



                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-arrow-down-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
Today                     </p>
                          <h4 className=" mb-0">
                            
                            <span
                              className="counter-value"
                              data-target="14799.44"
                            >
                           {today}
                            </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="ri-arrow-down-s-fill align-middle me-1" />
                          12 %<span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}



              

                 {/* end col */}
                 <div className="col-lg-3 col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-arrow-down-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
Yesterday                  </p>
                          <h4 className=" mb-0">
                            
                            <span
                              className="counter-value"
                              data-target="14799.44"
                            >
                            {yesterday}
                            </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="ri-arrow-down-s-fill align-middle me-1" />
                           <span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}



    {/* end col */}
    <div className="col-lg-3 col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-arrow-down-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
Avg Resolution Time                         </p>
                          <h4 className=" mb-0">
                            
                            <span
                              className="counter-value"
                              data-target="14799.44"
                            >
                           
                           871326
                              </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="ri-arrow-down-s-fill align-middle me-1" />
                           %<span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>



















































































































































































































































                
                {/* end col */}




              </div>
              {/* end row*/}

          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="orderList">
                <div className="card-header border-0">
                  <div className="row align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">Ethical Hacking Workshop Registrations</h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">


                      {bulkactions?
                      <>
                      
                      <button
                          type="button"
                          className="btn btn-success add-btn"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target="#showModal"
                        >
                          <i className="ri-add-line align-bottom me-1" /> Create
                          Order
                        </button>
                      </>:
                      <>
                      
                      
                      </>}
                      

                      <div className="d-flex me-2">
                      <form>
                
                      <div className="">
                        <div className="search-box">
                          <input
                          onChange={handleSearch}
                            type="text"
                            className="form-control search"
                            placeholder="Search for username, email or phone number..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                
                   
                  
                    {/*end row*/}
                  </form>

                  <button type="button" className="btn btn-info">
                          <i className="ri-file-download-line align-bottom me-1" />{" "}
                          Import
                        </button>
                      </div>
                      
                        <button
                          className="btn btn-soft-danger"
                          id="remove-actions"
                          onclick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
               
                <div className="card-body pt-2">
                  <div>
              

{response?
<>
{response.map((item,index)=>(
<>





<RegistrationItem  SelectUser={()=>SelectUser({id:item.id})} item={item}/>
</>
))}


</>:
<>


</>}
                    <div className="table-responsive table-card mb-1">
                      <table
                        className="table table-nowrap align-middle"
                        id="orderTable"
                      >
                        <thead className="text-muted table-light">
                          <tr className="text-uppercase">
                            <th scope="col" style={{ width: 25 }}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                  defaultValue="option"
                                />
                              </div>
                            </th>
                      
                            <th className="sort" data-sort="customer_name">
                              User
                            </th>
                            <th className="sort" data-sort="product_name">
                              Phone
                            </th>
                            <th className="sort" data-sort="date">
                         <span className='d-none d-md-block'>Email</span>
<span className='d-block d-md-none'>Actions</span>
                            </th>
                            <th className="sort" data-sort="amount">
                              Registration Date
                            </th>
                            <th className="sort" data-sort="payment">
                           Status
                            </th>
                            <th className="sort" data-sort="status">
                            Quick Actions
                            </th>
                            <th className="sort" data-sort="city">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                        





                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#405189,secondary:#0ab39c"
                            style={{ width: 75, height: 75 }}
                          />
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <a
                          className="page-item pagination-prev disabled"
                          href="#"
                        >
                          Previous
                        </a>
                        <ul className="pagination listjs-pagination mb-0" />
                        <a className="page-item pagination-next" href="#">
                          Next
                        </a>
                      </div>
                    </div>
                  </div>
              
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>

   </>
  )
}

export default HackingWorkshopRegistrations