import React, { useContext, useState } from 'react'

import { useParams } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import DefaultModal from '../../components/modals/DefaultModal'
import { Close } from '@material-ui/icons'
import BatchReportsList from './BatchReportsList'
import GetBatchReports from '../../actions/reports/GetBatchReports'
import ENDPOINT from '../../context/EndPoint'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import DefaultToast from '../../components/toasts/DefaultToast'

const BatchReports = ({ reports }) => {
  const dispatch = useDispatch();
  const params = useParams()
  const course_url = params.course_url
  const batch_id = params.batch_id
  const { user } = useContext(AuthContext)

  const [reportmodal, setreportmodal] = useState(false)
  const [toast,setToast] = useState({
    show:false,
    message:''
  })

  const SaveBatchReport = async (e) => {
    e.preventDefault()

    const formData = new FormData();

    formData.append('session', user.SessionId);
    formData.append('batch_id', batch_id);

    formData.append('report', report);

    formData.append('title', reporttitle);

    formData.append('course_url', course_url);

    try {
      const res = await axios.post(ENDPOINT + "add-batch-report.php", formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      if (res.data.status === 'success') {
        setreportmodal(false)
setToast({...toast,message:'Report Added',show:true})
        dispatch({ type: 'FUNCTION_RELOAD', payload: 'get-reports' })
      }
    }
    catch (error) {


    }








  }

  const [report, setreport] = useState(null)

  const [reporttitle, setreporttitle] = useState(null)

  return (
    <>
      <DefaultToast toastdata={toast.message} show={toast.show} ToastHandleClose={()=>setToast({...toast,show:false})} />

      <DefaultModal show={reportmodal} content={<>
        <div className="p-3">
          <div className="modal-header">
            <h4>Batch Report</h4>
            <Close onClick={() => setreportmodal(false)} />
          </div>
          <form onSubmit={SaveBatchReport}>
            <input className='my-2 form-control' required value={reporttitle} onChange={(e) => setreporttitle(e.target.value)} placeholder='Enter Title'></input>
            <textarea className='form-control' value={report} onChange={(e) => setreport(e.target.value)} required placeholder='Enter Report' />
            <button className='btn btn-primary mt-3 w-100'>Submit Report</button>

          </form>

        </div>

      </>}></DefaultModal>
      {/* end card */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0  me-2">
                Batch Report
              </h4>
              <div className="flex-shrink-0 ms-auto">
                <button className='btn btn-danger' onClick={() => setreportmodal(true)}>Add Report</button>

              </div>
            </div>
            {reports ?
              <>
                <BatchReportsList reports={reports} />
              </> :
              <>
              </>}
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
        {/* end col */}
      </div>
      {/* end row */}
    </>
  )
}

export default BatchReports