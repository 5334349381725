import axios from 'axios';

import ENDPOINT from '../../context/EndPoint';

const DeletePaymentHistory = async ({payload}) => {

          const formData = new FormData();

           formData.append('session',payload.session);
        
           formData.append('purchase_id',payload.purchase_id);
           

           

           try{
               const res = await axios.post(ENDPOINT+"delete-payment-history.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res.data){
                    return res.data
             
                }
           }
           catch(error){

return error
           }

     




}

export default DeletePaymentHistory