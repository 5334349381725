import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CircularProgress, Snackbar } from "@material-ui/core";
import ENDPOINT from "../../context/EndPoint";
import { useParams } from "react-router-dom";
import SecurityHeaders from "../../context/SecurityHeaders";
import axios from "axios";
import { connect } from "react-redux";
import BasicToast from "../../components/toasts/BasicToast";
const AddCourseAssignment = ({ id, modalClose, Reload, nValue, dispatch }) => {
  //  // console.log(nValue);
  const params = useParams();
  const courseUrl = params.course_url;
  const batchId = params.batch_id;
  const { user } = useContext(AuthContext);

  const [ form,setForm] = useState({
    title:'',
    description:'',
    reference:'',
    deadline:'',
    marks:''

  })

   const handelForm = (e)=>{
    setForm((prev)=>(
      {
        ...prev,
        [e.target.name]:e.target.value
      }
    ))
   }
 
   const [toast, settoast] = useState(false)
   const [toastdata, setoastdata] = useState({ title: null, message: null })

  const [addstatus, setaddstatus] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setaddstatus(true);

      const data = new FormData();
      // if (nValue) {
      //   data.append("notification", nValue);

      // }
      data.append("title", form.title);
      data.append("description", form.description);
      data.append("marks", form.marks);
      data.append("deadline", form.deadline);
      data.append("reference", form.reference);
      data.append("session", user.SessionId);
      data.append("course", courseUrl);
      data.append("batch_id", batchId);

      const result = await axios.post(
        ENDPOINT + "admin-add-course-assignment.php",
        data,
        SecurityHeaders
      );

      if (result) {
         // console.log(result.data);

        if (result.data[0].status === "success") {

          setaddstatus(false);
          setsnackbarMessage("Changes Saved Successfully");
          setsnackbar(true);
          modalClose()
          setoastdata({title:'Added Successfully',message:`Assignment ${form.title} `})
          settoast(true)
          
         dispatch({type:'FUNCTION_RELOAD',payload:'assignment'})
        } else {
          setaddstatus(false);
          setoastdata({title:'Something went wrong'})

          settoast(true)

          setsnackbarMessage("Something went wrong");
          setsnackbar(true);
        }
      }

    }
    catch (error) {
       // console.log("add assignment", error)
    }
  };

  const options = ["Option 1", "Option 2"];
  useEffect(() => {
    let timeOut;
    if (toast) {
        timeOut = setTimeout(() => {
            settoast(false);
        }, 2500);
    }

   
    return () => clearTimeout(timeOut);
}, [toast]);

  return (
    <div>
      <BasicToast visibility={toast} item={toastdata} />
      {/*account form*/}

      <>
        <div className="flex-wrap container">
          <div className="">
            <form>
              <div class="form-row">
                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Assignment Title</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputEmail4"
                    name="title"
                   
                    onChange={handelForm}
                    maxLength={200}
                  />
                </div>

                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Description</label>
                  <textarea
                    type="text"
                    class="form-control w-100"
                    id="inputEmail4"
                    name="description"
                    onChange={handelForm}
                    maxLength={1000}
                  />
                </div>

                <div class="form-group col-12">
                  <label for="inputEmail4">Reference</label>
                  <input
                    type="text"
                    class="form-control "
                    id="inputEmail4"
                    name="reference"
                    onChange={handelForm}
                    maxLength={500}
                  />
                </div>
                <div class="form-group col-lg-6">
                  <label for="inputEmail4">Deadline</label>
                  <input
                    type="datetime-local"
                    class="form-control"
                    id="inputEmail4"
                   name="deadline"
                    onChange={handelForm}
                    maxLength={30}
                  />
                </div>

                <div className="col-lg-12">
                  <p>Notify Students</p>
                  <input type="checkbox" checked></input>
                </div>

                <Snackbar
                  open={snackbar}
                  autoHideDuration={6000}
                  onClose={() => setsnackbar(false)}
                  message={snackbarMessage}
                />

                <div className="form-row ">
                  <div class="form-group col-lg-6">
                    <label for="inputPassword4">Max Marks</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputPassword4"
                    name="marks"
                      onChange={handelForm}
                      maxLength={30}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group"></div>
              <button
                type="submit"
                class="btn mt-3 btn-primary w-100"
                onClick={onSubmit}
              >
                {addstatus ? (
                  <CircularProgress className="text-white" size={20} />
                ) : (
                  ""
                )}
                Save Changes
              </button>
            </form>
          </div>
        </div>
      </>

      {/*account form*/}
    </div>
  );
};

const Notification = (prop) => ({
  nValue: prop.sendNotification.notification
})

export default connect(Notification)(AddCourseAssignment);
