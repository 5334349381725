import axios from 'axios';
import React from 'react'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';

const AdminUpdateDoubtRequest = async({payload}) => {

   
        const data = new FormData();
        if(payload.notification){
         data.append('notification_send',payload.notification)
        }
        data.append("session", payload.session);
        data.append("status",payload.status);
        data.append("id", payload.id);
        const res = await axios.post(
          `${ENDPOINT}admin-update-doubt-request.php`,
          data,
          SecurityHeaders
        );
    
     if(res){

        return res.data
     }
      
    
}

export default AdminUpdateDoubtRequest