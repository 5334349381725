import React from 'react'
import AssignmentSubmissionItem from '../assignments/AssignmentSubmissionItem'

const StudentAssignmentsSubmissions = ({ tasks }) => {


  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="table table-borderless align-middle mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Course</th>
                  <th scope="col">Title</th>
                  <th scope="col">Size</th>
                  <th scope="col">Submission Time</th>
                  <th scope="col">Deadline</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>

                {tasks ?
                  <>
                    {tasks.map((item) => (
                      <>
                        <AssignmentSubmissionItem item={item} />

                      </>
                    ))}

                  </> :
                  <>

                  </>}

              </tbody>
            </table>
          </div>
          <div className="text-center mt-3">
            <a
              href="javascript:void(0);"
              className="text-success"
            >
              <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" />{" "}
              Load more{" "}
            </a>
          </div>
        </div>
      </div>
    </>)
}

export default StudentAssignmentsSubmissions