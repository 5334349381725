import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { Avatar } from '@material-ui/core'
import Rating from '@mui/material/Rating';
// import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'


import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Skeleton from "@mui/material/Skeleton";
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../context/EndPoint'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import SecurityHeaders from '../../context/SecurityHeaders'

const DailyClassFeedbacks = () => {

const params = useParams();

  const { user } = useContext(AuthContext)
  const userdata = user;
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [internships, setinternships] = useState([]);



  const getSupportLogs = async () => {
    if (userdata !== null) {
      try {
        const formData = new FormData();
        formData.append('session', user.SessionId);
        if(params.class_id){
          formData.append('class_id',params.class_id);
        }
  
        const response = await axios.post(`${ENDPOINT}class-feedback.php`, formData,SecurityHeaders);
  
        if (response.data) {
          setinternships(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      // Handle case when userdata is null, if needed
    }
  };
  

  useEffect(() => {
    getSupportLogs();
  }, [])

  const [value, setValue] = React.useState(internships[0]);
  const [inputValue, setInputValue] = React.useState('');
    const [category, setcategory] = useState("all")
  const [query, setquery] = useState(null)


  return (
    <>
      <div className=" flex-wrap  ">
        <div className="container-fluid  ">
          <div className="flex  align-items-center space-between mb-4">
            <div className="flex col-lg-9">
              <FormControl >
                <InputLabel id="demo-simple-select-label">Category</InputLabel>

                <Select

                  value={category}
                  label="Category"
                  onChange={(e) => setcategory(e.target.value)}
                >
                  <MenuItem value={"all"}>All </MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"inactive"}>De-Activated</MenuItem>

                </Select>
              </FormControl>



              <Paper
                onSubmit={(e) => { e.preventDefault(); getSupportLogs() }}
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
              >

                <InputBase

                  sx={{ ml: 1, flex: 1 }}
                  onChange={(e) => setquery(e.target.value)}
                  placeholder="Search user id"
                  inputProps={{ 'aria-label': 'Search Batch ID ' }}
                />
                <IconButton onClick={() => getSupportLogs()} sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>

              </Paper>

            </div>


            <h4>{internships ? internships.length : 0} Class Reviews</h4>


          </div>



          <hr />

        </div>
        <div className="card">
          <div className="row">
            <div className="table-responsive">
              <table className="table table-borderless mb-0">

                <thead className="table-light">
                  <tr>
                    <th scope="col">Feedbacks</th>
                    <th scope="col">Rating</th>
                    <th scope="col">Date</th>

                  </tr>
                </thead>

                <>
                  <tbody className="overflow">
                    {internships ? (
                      internships.map((internship, index) => (
                        <tr key={index}>
                          <td className="col-lg-8">

                            <div className="ms-2 flex-grow-1">
                              <p>{internship.review}</p>
                             
                            </div>
                          </td>
                          <td>
                          <div className="d-flex align-items-center">
                                <Avatar className="me-10" src={internship.profile_pic}    sx={{ width: 10, height: 15 }} />
                                <div className='ms-2'>
                                  <h6 className='mb-0 pb-0'>{internship.first_name + "( " + internship.phone_number + " )"}</h6>
                                  <Rating className='' name="read-only" value={internship.rating} readOnly size="small" />


                                </div>
                              </div> 
                              </td>
                          <td>{internship.date_time}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          <Skeleton variant="text" />
                          <Skeleton variant="text" />
                          <Skeleton variant="text" />
                        </td>
                      </tr>
                    )}
                  </tbody>

                </>
              </table>
            </div>


          </div>
        </div>



      </div>




    </>
  )
}

export default DailyClassFeedbacks