import React, { useState } from 'react'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import DeleteModal from '../../components/modals/DeleteModal';
import axios from 'axios';
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';

const TeamMemberItem = ({item,Refresh}) => {

    const removeteam = async({payload}) =>{
        const data = new FormData();
        data.append('id',item.id)
        var resp = await axios.post(ENDPOINT + 'remove-team-member.php' , data, SecurityHeaders)
        if (resp.data.status === "success") {
            setdeletemodal(false)
            Refresh()
          }
      }
  

    const [deletemodal, setdeletemodal] = useState(false)
  return (
    <>
        <DeleteModal show={deletemodal} HandleDelete={() => removeteam({ payload: { id: item.id } })} HandleClose={() => setdeletemodal(false)}/>
    <div className="col-xxl-3 col-sm-6 project-card">
                    <div className="card card-height-100">
                        <div className="team-cover">
                            <p className="left-10 position-absolute top-10 text-light">
                                {item.status}
                            </p>
                        </div>
                        <div className="team-avatar-container position-relative d-flex justify-content-center">
                            <Avatar
                                sx={{ height: 90, width: 90 }}
                                src={item.profile_pic}
                                className="team-avatar"
                            />
                        </div>
                        <div className="card-body">
                            <div className="text-center">
                                <h4>{item.first_name + " " + item.last_name}</h4>
                                <p>{item.designation ? item.designation : "NA"}</p>
                            </div>
                            <div className="d-flex flex-column h-100">
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                    <p className="text-muted mb-1">
                                            category: {item.category ? item.category : "NA"}
                                        </p>
                                        <p className="text-muted mb-1">
                                            Email: {item.email ? item.email : "NA"}
                                        </p>
                                        <p className="text-muted mb-1">
                                            phone: {item.phone ? item.phone : "NA"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-transparent border-top-dashed py-2">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="flex-shrink-0">
                                    <div className="text-muted">
                                        <i className="ri-edit-fill me-1 align-bottom" /> Edit
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <Link
                                        to='#' onClick={()=>{ setdeletemodal(true)}}
                                        className="text-muted"
                                    >
                                        <i className="ri-delete-bin-fill me-1 align-bottom" /> Delete
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

        </div>
    </>
  )
}

export default TeamMemberItem