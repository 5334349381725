import { Delete, Refresh } from '@material-ui/icons'
import { Avatar } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import ENDPOINT from '../../context/EndPoint'
import SecurityHeaders from '../../context/SecurityHeaders'
import DeleteModal from '../../components/modals/DeleteModal'

const PlacementStudent = ({ student,RefreshPla }) => {
    const[deletemodal,setdeletemodal] = useState(false)
    const DeleteStudentinfo= async()=>{

        const data= new FormData()
        data.append("id",student.id)

        const res = await axios.post(ENDPOINT+"delete-placed-student-data.php",data,SecurityHeaders)
        console.log(res)
        if(res.data.status==='success')
        {
            setdeletemodal(false)
            RefreshPla()
        }
        
    }

    return (
        <>
        <DeleteModal show={deletemodal} HandleClose={()=>setdeletemodal(false)} HandleDelete={DeleteStudentinfo}/>
            <div className='col-lg-4 p-2 col-12 col-md-6'>

<div className="shadow p-3">

    <div className="d-flex click" >
        <Avatar src={student.image} />
        <div className='text-cener ms-2 w-100'>
            <div className='d-flex justify-content-between'>
                <h5>{student.full_name}</h5>
                
                <Delete  className='text-info' onClick={()=>{
                    setdeletemodal(true)
                }} />
               
            </div>
            <p className="mr-4 mb-0">{student.role} </p>
            <p className="mr-4 mb-0">{student.package}  LPA </p>
        </div>

    </div>



</div>
</div>
        </>
    )
}

export default PlacementStudent