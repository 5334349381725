import React from 'react'

const StatusChip = ({item}) => {
  return (
    <span className={`badge ${item.status==='initiated'?'bg-warning-subtle text-warning':item.status==="closed"?'bg-success-subtle text-success':item.status==="pending"?'bg-danger-subtle text-danger':'bg-danger-subtle text-danger'}`}>
    {item.status}
  </span>
  )
}

export default StatusChip