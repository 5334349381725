import React from 'react'

const ProductsPage = () => {
  return (
   <>
   
   <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Products</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Ecommerce</a>
                    </li>
                    <li className="breadcrumb-item active">Products</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex mb-3">
                    <div className="flex-grow-1">
                      <h5 className="fs-16">Filters</h5>
                    </div>
                    <div className="flex-shrink-0">
                      <a
                        href="#"
                        className="text-decoration-underline"
                        id="clearall"
                      >
                        Clear All
                      </a>
                    </div>
                  </div>
                  <div className="filter-choices-input">
                    <div
                      className="choices"
                      data-type="text"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="choices__inner">
                        <input
                          className="form-control choices__input"
                          data-choices=""
                          data-choices-removeitem=""
                          type="text"
                          id="filter-choices-input"
                          defaultValue="T-Shirts"
                          hidden=""
                          tabIndex={-1}
                          data-choice="active"
                        />
                        <div className="choices__list choices__list--multiple">
                          <div
                            className="choices__item choices__item--selectable"
                            data-item=""
                            data-id={1}
                            data-value="T-Shirts"
                            data-custom-properties="[object Object]"
                            aria-selected="true"
                            data-deletable=""
                          >
                            T-Shirts
                            <button
                              type="button"
                              className="choices__button"
                              aria-label="Remove item: 'T-Shirts'"
                              data-button=""
                            >
                              Remove item
                            </button>
                          </div>
                        </div>
                        <input
                          type="search"
                          name="search_terms"
                          className="choices__input choices__input--cloned"
                          autoComplete="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          role="textbox"
                          aria-autocomplete="list"
                          aria-label="null"
                        />
                      </div>
                      <div
                        className="choices__list choices__list--dropdown"
                        aria-expanded="false"
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion accordion-flush filter-accordion">
                  <div className="card-body border-bottom">
                    <div>
                      <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
                        Products
                      </p>
                      <ul className="list-unstyled mb-0 filter-list">
                        <li>
                          <a
                            href="#"
                            className="d-flex py-1 align-items-center"
                          >
                            <div className="flex-grow-1">
                              <h5 className="fs-13 mb-0 listname">Grocery</h5>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="d-flex py-1 align-items-center"
                          >
                            <div className="flex-grow-1">
                              <h5 className="fs-13 mb-0 listname">Fashion</h5>
                            </div>
                            <div className="flex-shrink-0 ms-2">
                              <span className="badge bg-light text-muted">
                                5
                              </span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="d-flex py-1 align-items-center"
                          >
                            <div className="flex-grow-1">
                              <h5 className="fs-13 mb-0 listname">Watches</h5>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="d-flex py-1 align-items-center"
                          >
                            <div className="flex-grow-1">
                              <h5 className="fs-13 mb-0 listname">
                                Electronics
                              </h5>
                            </div>
                            <div className="flex-shrink-0 ms-2">
                              <span className="badge bg-light text-muted">
                                5
                              </span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="d-flex py-1 align-items-center"
                          >
                            <div className="flex-grow-1">
                              <h5 className="fs-13 mb-0 listname">Furniture</h5>
                            </div>
                            <div className="flex-shrink-0 ms-2">
                              <span className="badge bg-light text-muted">
                                6
                              </span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="d-flex py-1 align-items-center"
                          >
                            <div className="flex-grow-1">
                              <h5 className="fs-13 mb-0 listname">
                                Automotive Accessories
                              </h5>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="d-flex py-1 align-items-center"
                          >
                            <div className="flex-grow-1">
                              <h5 className="fs-13 mb-0 listname">
                                Appliances
                              </h5>
                            </div>
                            <div className="flex-shrink-0 ms-2">
                              <span className="badge bg-light text-muted">
                                7
                              </span>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="d-flex py-1 align-items-center"
                          >
                            <div className="flex-grow-1">
                              <h5 className="fs-13 mb-0 listname">Kids</h5>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body border-bottom">
                    <p className="text-muted text-uppercase fs-12 fw-medium mb-4">
                      Price
                    </p>
                    <div
                      id="product-price-range"
                      data-slider-color="primary"
                      className="noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr"
                    >
                      <div className="noUi-base">
                        <div className="noUi-connects">
                          <div
                            className="noUi-connect noUi-draggable"
                            style={{
                              transform: "translate(0%, 0px) scale(1, 1)"
                            }}
                          />
                        </div>
                        <div
                          className="noUi-origin"
                          style={{
                            transform: "translate(-100%, 0px)",
                            zIndex: 5
                          }}
                        >
                          <div
                            className="noUi-handle noUi-handle-lower"
                            data-handle={0}
                            tabIndex={0}
                            role="slider"
                            aria-orientation="horizontal"
                            aria-valuemin={0.0}
                            aria-valuemax={1980.0}
                            aria-valuenow={0.0}
                            aria-valuetext="$ 0"
                          >
                            <div className="noUi-touch-area" />
                          </div>
                        </div>
                        <div
                          className="noUi-origin"
                          style={{ transform: "translate(0%, 0px)", zIndex: 4 }}
                        >
                          <div
                            className="noUi-handle noUi-handle-upper"
                            data-handle={1}
                            tabIndex={0}
                            role="slider"
                            aria-orientation="horizontal"
                            aria-valuemin={20.0}
                            aria-valuemax={2000.0}
                            aria-valuenow={2000.0}
                            aria-valuetext="$ 2000"
                          >
                            <div className="noUi-touch-area" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formCost d-flex gap-2 align-items-center mt-3">
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        id="minCost"
                        defaultValue={0}
                      />{" "}
                      <span className="fw-semibold text-muted">to</span>{" "}
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        id="maxCost"
                        defaultValue={1000}
                      />
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingBrands">
                      <button
                        className="accordion-button bg-transparent shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseBrands"
                        aria-expanded="true"
                        aria-controls="flush-collapseBrands"
                      >
                        <span className="text-muted text-uppercase fs-12 fw-medium">
                          Brands
                        </span>{" "}
                        <span
                          className="badge bg-success rounded-pill align-middle ms-1 filter-badge"
                          style={{ display: "none" }}
                        >
                          0
                        </span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseBrands"
                      className="accordion-collapse collapse show"
                      aria-labelledby="flush-headingBrands"
                    >
                      <div className="accordion-body text-body pt-0">
                        <div className="search-box search-box-sm">
                          <input
                            type="text"
                            className="form-control bg-light border-0"
                            id="searchBrandsList"
                            placeholder="Search Brands..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                        <div className="d-flex flex-column gap-2 mt-3 filter-check">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="Boat"
                              id="productBrandRadio5"
                              defaultChecked=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio5"
                            >
                              Boat
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="OnePlus"
                              id="productBrandRadio4"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio4"
                            >
                              OnePlus
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="Realme"
                              id="productBrandRadio3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio3"
                            >
                              Realme
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="Sony"
                              id="productBrandRadio2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio2"
                            >
                              Sony
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="JBL"
                              id="productBrandRadio1"
                              defaultChecked=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio1"
                            >
                              JBL
                            </label>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none text-uppercase fw-medium p-0"
                            >
                              1,235 More
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end accordion-item */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingDiscount">
                      <button
                        className="accordion-button bg-transparent shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseDiscount"
                        aria-expanded="true"
                        aria-controls="flush-collapseDiscount"
                      >
                        <span className="text-muted text-uppercase fs-12 fw-medium">
                          Discount
                        </span>{" "}
                        <span
                          className="badge bg-success rounded-pill align-middle ms-1 filter-badge"
                          style={{ display: "none" }}
                        >
                          0
                        </span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseDiscount"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingDiscount"
                    >
                      <div className="accordion-body text-body pt-1">
                        <div className="d-flex flex-column gap-2 filter-check">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="50% or more"
                              id="productdiscountRadio6"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio6"
                            >
                              50% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="40% or more"
                              id="productdiscountRadio5"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio5"
                            >
                              40% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="30% or more"
                              id="productdiscountRadio4"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio4"
                            >
                              30% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="20% or more"
                              id="productdiscountRadio3"
                              defaultChecked=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio3"
                            >
                              20% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="10% or more"
                              id="productdiscountRadio2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio2"
                            >
                              10% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="Less than 10%"
                              id="productdiscountRadio1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio1"
                            >
                              Less than 10%
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end accordion-item */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingRating">
                      <button
                        className="accordion-button bg-transparent shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseRating"
                        aria-expanded="false"
                        aria-controls="flush-collapseRating"
                      >
                        <span className="text-muted text-uppercase fs-12 fw-medium">
                          Rating
                        </span>{" "}
                        <span
                          className="badge bg-success rounded-pill align-middle ms-1 filter-badge"
                          style={{ display: "none" }}
                        >
                          0
                        </span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseRating"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingRating"
                    >
                      <div className="accordion-body text-body">
                        <div className="d-flex flex-column gap-2 filter-check">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="4 & Above Star"
                              id="productratingRadio4"
                              defaultChecked=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productratingRadio4"
                            >
                              <span className="text-muted">
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star" />
                              </span>{" "}
                              4 &amp; Above
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="3 & Above Star"
                              id="productratingRadio3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productratingRadio3"
                            >
                              <span className="text-muted">
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star" />
                                <i className="mdi mdi-star" />
                              </span>{" "}
                              3 &amp; Above
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="2 & Above Star"
                              id="productratingRadio2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productratingRadio2"
                            >
                              <span className="text-muted">
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star" />
                                <i className="mdi mdi-star" />
                                <i className="mdi mdi-star" />
                              </span>{" "}
                              2 &amp; Above
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="1 Star"
                              id="productratingRadio1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productratingRadio1"
                            >
                              <span className="text-muted">
                                <i className="mdi mdi-star text-warning" />
                                <i className="mdi mdi-star" />
                                <i className="mdi mdi-star" />
                                <i className="mdi mdi-star" />
                                <i className="mdi mdi-star" />
                              </span>{" "}
                              1
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end accordion-item */}
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
            <div className="col-xl-9 col-lg-8">
              <div>
                <div className="card">
                  <div className="card-header border-0">
                    <div className="row g-4">
                      <div className="col-sm-auto">
                        <div>
                          <a
                            href="apps-ecommerce-add-product.html"
                            className="btn btn-success"
                            id="addproduct-btn"
                          >
                            <i className="ri-add-line align-bottom me-1" /> Add
                            Product
                          </a>
                        </div>
                      </div>
                      <div className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control"
                              id="searchProductList"
                              placeholder="Search Products..."
                            />
                            <i className="ri-search-line search-icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <ul
                          className="nav nav-tabs-custom card-header-tabs border-bottom-0"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link active fw-semibold"
                              data-bs-toggle="tab"
                              href="#productnav-all"
                              role="tab"
                              aria-selected="true"
                            >
                              All{" "}
                              <span className="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">
                                12
                              </span>
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link fw-semibold"
                              data-bs-toggle="tab"
                              href="#productnav-published"
                              role="tab"
                              aria-selected="false"
                              tabIndex={-1}
                            >
                              Published{" "}
                              <span className="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">
                                5
                              </span>
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link fw-semibold"
                              data-bs-toggle="tab"
                              href="#productnav-draft"
                              role="tab"
                              aria-selected="false"
                              tabIndex={-1}
                            >
                              Draft
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-auto">
                        <div id="selection-element">
                          <div className="my-n1 d-flex align-items-center text-muted">
                            Select{" "}
                            <div
                              id="select-content"
                              className="text-body fw-semibold px-1"
                            />{" "}
                            Result{" "}
                            <button
                              type="button"
                              className="btn btn-link link-danger p-0 ms-3 shadow-none"
                              data-bs-toggle="modal"
                              data-bs-target="#removeItemModal"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end card header */}
                  <div className="card-body">
                    <div className="tab-content text-muted">
                      <div
                        className="tab-pane active"
                        id="productnav-all"
                        role="tabpanel"
                      >
                        <div
                          id="table-product-list-all"
                          className="table-card gridjs-border-none"
                        >
                          <div
                            role="complementary"
                            className="gridjs gridjs-container"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="gridjs-wrapper"
                              style={{ height: "auto" }}
                            >
                              <table
                                role="grid"
                                className="gridjs-table"
                                style={{ height: "auto" }}
                              >
                                <thead className="gridjs-thead">
                                  <tr className="gridjs-tr">
                                    <th
                                      data-column-id="#"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 40 }}
                                    >
                                      <div className="gridjs-th-content">#</div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="product"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 360 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Product
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="stock"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 94 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Stock
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="price"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 101 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Price
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="orders"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 84 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Orders
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="rating"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 105 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Rating
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="published"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 220 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Published
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="action"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 80 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Action
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="gridjs-tbody">
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={1}
                                            id="checkbox-1"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-1"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-1.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Half Sleeve Round Neck T-Shirts
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Fashion
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      12
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$215.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      48
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.2
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        12 Oct, 2021
                                        <small className="text-muted ms-1">
                                          10:05 AM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={1}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={1}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={2}
                                            id="checkbox-2"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-2"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-2.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Urban Ladder Pashe Chair
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Furniture
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      06
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$160.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      30
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.3
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        06 Jan, 2021
                                        <small className="text-muted ms-1">
                                          01:31 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={2}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={2}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={3}
                                            id="checkbox-3"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-3"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-3.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                350 ml Glass Grocery Container
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Grocery
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      10
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$125.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      48
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.5
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        26 Mar, 2021
                                        <small className="text-muted ms-1">
                                          11:40 AM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={3}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={3}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={4}
                                            id="checkbox-4"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-4"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-4.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Fabric Dual Tone Living Room
                                                Chair
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Furniture
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      15
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$340.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      40
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.2
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        19 Apr, 2021
                                        <small className="text-muted ms-1">
                                          02:51 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={4}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={4}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={5}
                                            id="checkbox-5"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-5"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-5.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Crux Motorsports Helmet
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Automotive Accessories
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      08
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$175.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      55
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.4
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        30 Mar, 2021
                                        <small className="text-muted ms-1">
                                          09:42 AM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={5}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={5}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={6}
                                            id="checkbox-6"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-6"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-6.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Half Sleeve T-Shirts (Blue)
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Fashion
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      15
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$225.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      48
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.2
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        12 Oct, 2021
                                        <small className="text-muted ms-1">
                                          04:55 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={6}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={6}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={7}
                                            id="checkbox-7"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-7"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-7.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Noise Evolve Smartwatch
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Watches
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      12
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$105.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      45
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.3
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        15 May, 2021
                                        <small className="text-muted ms-1">
                                          03:40 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={7}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={7}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={8}
                                            id="checkbox-8"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-8"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-8.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Sweatshirt for Men (Pink)
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Fashion
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      20
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$120.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      48
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.2
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        21 Jun, 2021
                                        <small className="text-muted ms-1">
                                          12:18 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul
                                            className="dropdown-menu dropdown-menu-end"
                                            style={{}}
                                          >
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={8}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={8}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={9}
                                            id="checkbox-9"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-9"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-9.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Reusable Ecological Coffee Cup
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Grocery
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      14
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$325.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      55
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.3
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        15 Jan, 2021
                                        <small className="text-muted ms-1">
                                          10:29 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={9}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={9}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue={10}
                                            id="checkbox-10"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-10"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-10.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Travel Carrying Pouch Bag
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Kids
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      20
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$180.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      60
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.3
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        15 Jun, 2021
                                        <small className="text-muted ms-1">
                                          03:51 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item edit-list"
                                                data-edit-id={10}
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id={10}
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="gridjs-footer">
                              <div className="gridjs-pagination">
                                <div
                                  role="status"
                                  aria-live="polite"
                                  className="gridjs-summary"
                                  title="Page 1 of 2"
                                >
                                  Showing <b>1</b> to <b>10</b> of <b>12</b>{" "}
                                  results
                                </div>
                                <div className="gridjs-pages">
                                  <button
                                    tabIndex={0}
                                    role="button"
                                    disabled=""
                                    title="Previous"
                                    aria-label="Previous"
                                    className=""
                                  >
                                    Previous
                                  </button>
                                  <button
                                    tabIndex={0}
                                    role="button"
                                    className="gridjs-currentPage"
                                    title="Page 1"
                                    aria-label="Page 1"
                                  >
                                    1
                                  </button>
                                  <button
                                    tabIndex={0}
                                    role="button"
                                    className=""
                                    title="Page 2"
                                    aria-label="Page 2"
                                  >
                                    2
                                  </button>
                                  <button
                                    tabIndex={0}
                                    role="button"
                                    title="Next"
                                    aria-label="Next"
                                    className=""
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div id="gridjs-temp" className="gridjs-temp" />
                          </div>
                        </div>
                      </div>
                      {/* end tab pane */}
                      <div
                        className="tab-pane"
                        id="productnav-published"
                        role="tabpanel"
                      >
                        <div
                          id="table-product-list-published"
                          className="table-card gridjs-border-none"
                        >
                          <div
                            role="complementary"
                            className="gridjs gridjs-container"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="gridjs-wrapper"
                              style={{ height: "auto" }}
                            >
                              <table
                                role="grid"
                                className="gridjs-table"
                                style={{ height: "auto" }}
                              >
                                <thead className="gridjs-thead">
                                  <tr className="gridjs-tr">
                                    <th
                                      data-column-id="#"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 40 }}
                                    >
                                      <div className="gridjs-th-content">#</div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="product"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 360 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Product
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="stock"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 94 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Stock
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="price"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 101 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Price
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="orders"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 84 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Orders
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="rating"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 105 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Rating
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="published"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 220 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Published
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                    <th
                                      data-column-id="action"
                                      className="gridjs-th gridjs-th-sort text-muted"
                                      tabIndex={0}
                                      style={{ width: 80 }}
                                    >
                                      <div className="gridjs-th-content">
                                        Action
                                      </div>
                                      <button
                                        tabIndex={-1}
                                        aria-label="Sort column ascending"
                                        title="Sort column ascending"
                                        className="gridjs-sort gridjs-sort-neutral"
                                      />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="gridjs-tbody">
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue="undefined"
                                            id="checkboxpublished-undefined"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-undefined"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-2.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Urban Ladder Pashe Chair
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Furniture
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      06
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$160.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      30
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.3
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        06 Jan, 2021
                                        <small className="text-muted ms-1">
                                          01:31 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id="undefined"
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue="undefined"
                                            id="checkboxpublished-undefined"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-undefined"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-6.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Half Sleeve T-Shirts (Blue)
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Fashion
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      15
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$125.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      48
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.2
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        12 Oct, 2021
                                        <small className="text-muted ms-1">
                                          04:55 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id="undefined"
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue="undefined"
                                            id="checkboxpublished-undefined"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-undefined"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-4.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Fabric Dual Tone Living Room
                                                Chair
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Furniture
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      15
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$140.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      40
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.2
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        19 Apr, 2021
                                        <small className="text-muted ms-1">
                                          02:51 PM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id="undefined"
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue="undefined"
                                            id="checkboxpublished-undefined"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-undefined"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-4.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                350 ml Glass Grocery Container
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Grocery
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      10
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$125.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      48
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.5
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        26 Mar, 2021
                                        <small className="text-muted ms-1">
                                          11:40 AM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id="undefined"
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="gridjs-tr">
                                    <td
                                      data-column-id="#"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="form-check checkbox-product-list">
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue="undefined"
                                            id="checkboxpublished-undefined"
                                          />{" "}
                                          <label
                                            className="form-check-label"
                                            htmlFor="checkbox-undefined"
                                          />{" "}
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="product"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded p-1">
                                              <img
                                                src="assets/images/products/img-5.png"
                                                alt=""
                                                className="img-fluid d-block"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <a
                                                href="apps-ecommerce-product-details.html"
                                                className="text-body"
                                              >
                                                Crux Motorsports Helmet
                                              </a>
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Category :{" "}
                                              <span className="fw-medium">
                                                Automotive Accessories
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="stock"
                                      className="gridjs-td"
                                    >
                                      08
                                    </td>
                                    <td
                                      data-column-id="price"
                                      className="gridjs-td"
                                    >
                                      <span>$135.00</span>
                                    </td>
                                    <td
                                      data-column-id="orders"
                                      className="gridjs-td"
                                    >
                                      55
                                    </td>
                                    <td
                                      data-column-id="rating"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <span className="badge bg-light text-body fs-12 fw-medium">
                                          <i className="mdi mdi-star text-warning me-1" />
                                          4.4
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="published"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        30 Mar, 2021
                                        <small className="text-muted ms-1">
                                          09:42 AM
                                        </small>
                                      </span>
                                    </td>
                                    <td
                                      data-column-id="action"
                                      className="gridjs-td"
                                    >
                                      <span>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="ri-more-fill" />
                                          </button>
                                          <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-product-details.html"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                                                View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="apps-ecommerce-add-product.html"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted" />{" "}
                                                Edit
                                              </a>
                                            </li>
                                            <li className="dropdown-divider" />
                                            <li>
                                              <a
                                                className="dropdown-item remove-list"
                                                href="#"
                                                data-id="undefined"
                                                data-bs-toggle="modal"
                                                data-bs-target="#removeItemModal"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{" "}
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="gridjs-footer">
                              <div className="gridjs-pagination">
                                <div
                                  role="status"
                                  aria-live="polite"
                                  className="gridjs-summary"
                                  title="Page 1 of 1"
                                >
                                  Showing <b>1</b> to <b>5</b> of <b>5</b>{" "}
                                  results
                                </div>
                                <div className="gridjs-pages">
                                  <button
                                    tabIndex={0}
                                    role="button"
                                    disabled=""
                                    title="Previous"
                                    aria-label="Previous"
                                    className=""
                                  >
                                    Previous
                                  </button>
                                  <button
                                    tabIndex={0}
                                    role="button"
                                    className="gridjs-currentPage"
                                    title="Page 1"
                                    aria-label="Page 1"
                                  >
                                    1
                                  </button>
                                  <button
                                    tabIndex={0}
                                    role="button"
                                    disabled=""
                                    title="Next"
                                    aria-label="Next"
                                    className=""
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div id="gridjs-temp" className="gridjs-temp" />
                          </div>
                        </div>
                      </div>
                      {/* end tab pane */}
                      <div
                        className="tab-pane"
                        id="productnav-draft"
                        role="tabpanel"
                      >
                        <div className="py-4 text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#405189,secondary:#0ab39c"
                            style={{ width: 72, height: 72 }}
                          ></lord-icon>
                          <h5 className="mt-4">Sorry! No Result Found</h5>
                        </div>
                      </div>
                      {/* end tab pane */}
                    </div>
                    {/* end tab content */}
                  </div>
                  {/* end card body */}
                </div>
                {/* end card */}
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>

   </>
  )
}

export default ProductsPage