import React, { useState } from 'react';
import {
  Card, CardContent, Typography, IconButton, Menu, MenuItem, Button, Grid
} from '@mui/material';
import { MoreVert, Edit, Delete } from '@mui/icons-material';
import styled from 'styled-components';

const Test2 = ({ item }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledTest2>
      <Card className="batch-card">
        <CardContent className="card-content">
          <div className="header">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Detail label="Batch Name:" value="8pm CEH Batch" />
                <Detail label="Batch Id:" value="20240525" />
                <Detail label="Course URL:" value="Ethical-hacking-specilization" />
              </Grid>
              <Grid item xs={6}>
                <Detail label="Date:" value="18-07-2024" />
                <Detail label="Start Time:" value="12:26am" />
                <Detail label="End Time:" value="12:30am" />
              </Grid>
            </Grid>
            <IconButton
              aria-controls={open ? 'options-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="options-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'options-button',
              }}
            >
              <MenuItem onClick={handleClose}>
                <Edit /> Edit
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Delete /> Delete
              </MenuItem>
            </Menu>
          </div>
          <Button variant="contained" color="primary" className="action-button">
            Click
          </Button>
        </CardContent>
      </Card>
    </StyledTest2>
  );
};

const Detail = ({ label, value }) => (
  <Typography variant="body2" className="detail">
    <strong>{label}</strong> {value}
  </Typography>
);

const StyledTest2 = styled.div`
  .batch-card {
    max-width: 400px;
    margin: 10px auto;
  }

  .card-content {
    padding: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .detail {
    margin-bottom: 5px;
  }

  .action-button {
    margin-top: 10px;
    width: 100%;
  }
`;

export default Test2;
