import axios from 'axios';
import React, { useState } from 'react'
import ENDPOINT from '../../context/EndPoint';
import { CodeOffSharp } from '@mui/icons-material';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import SecurityHeaders from '../../context/SecurityHeaders';
import { Avatar } from '@mui/material';

const AddStudent = () => {

    //vedio_id
    //usrer-id
    //full_name
    //package
    //role
    //company
    //type

    const [imagePreview, setImagePreview] = useState(null);

    const [formData, setFormData] = useState({
        full_name: '',
        package: '',
        role: '',
        company: '',
        image: null
    }
    )
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                image: file
            });
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            const response = await axios.post(ENDPOINT + "add-student-placement-data.php", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
           
            if(response.data.status==='success')
            {
                console.log('Student placement details uploaded successfully:', response);
                window.location.replace('/placement_students')
            }

        }
        catch (error) {
            console.error('Error uploading placement details:', error);
        }
    };



    return (

        <Container className="mt-3 ">
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <div className="p-4 shadow rounded bg-light">
                        <h3 className="text-center mb-4">Upload Placed Student Details</h3>
                        <Form onSubmit={handleSubmit}>

                        {imagePreview && (
                                <div className="mb-2">
                                    <p>Image Preview:</p>
                                    <Avatar 
                                     
                                        src={imagePreview}
                                        alt="Preview"
                                        style={{
                                            width: '150px',
                                            height: '150px',
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            )}

                            <Form.Group className="mb-2">
                                <Form.Label>Upload Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </Form.Group>

                           

                            <Form.Group className="mb-2">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="full_name"
                                    value={formData.full_name}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-2">
                                <Form.Label>Package</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="package"
                                    value={formData.package}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-2">
                                <Form.Label>role</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>


                            <Form.Group className="mb-2">
                                <Form.Label>Company</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            
                            <Button type="submit" className="btn ">
                                Upload
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AddStudent