import React from 'react'
import Navbar from '../components/Navbar'

const EmptyTheme = ({children}) => {
  return (
    <div>

<div className="w-100">


    {children}
</div>

    </div>
  )
}

export default EmptyTheme