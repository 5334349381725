import { Avatar } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

const TeamItem = ({ item }) => {
  return (
    <>
      <div className="card card-height-100">
        <div className="team-cover ">
          <p className="left-10 position-absolute top-10 text-light">
            {item.online_status}
          </p>
        </div>
        <div className="team-avatar-container position-relative d-flex justify-content-center">
          <Avatar
            sx={{ height: 90, width: 90 }}
            src={item.profile_pic}
            className="team-avatar"
          />
        </div>
        <div className="card-body">
          <div className="text-center">
            <h4>{item.first_name + " " + item.last_name}</h4>
            <p>{item.role ? item.role : "NA"}</p>
          </div>
          <div className="d-flex flex-column h-100">
            <div className="d-flex">
              <div className="flex-grow-1">
             
                  <p className="text-muted mb-4">
                    Last active :{" "}
                    {dateFormat(item.last_active_time, "dS mmm hh:mm tt")}
                  </p>
                
              </div>
            </div>

            <div className="">
              <div className="d-flex mb-2">
                <div className="flex-grow-1">
                  <div>Tasks</div>
                </div>
                <div className="flex-shrink-0">
                  <div>
                    <i className="ri-list-check align-bottom me-1 text-muted" />{" "}
                    {item.completed_tasks}/{item.total_tasks}
                  </div>
                </div>
              </div>
              <div className="progress progress-sm animated-progress bg-success-subtle">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  aria-valuenow={54}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: "54%" }}
                />
                {/* /.progress-bar */}
              </div>
              {/* /.progress */}
            </div>
          </div>
        </div>
        {/* end card body */}
        <div className="card-footer bg-transparent border-top-dashed py-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="flex-shrink-0">
              <div className="text-muted">
                <i className="ri-calendar-event-fill me-1 align-bottom" /> View
                Tasks
              </div>
            </div>

            <div className="flex-shrink-0">
              <Link
                to={
                  "/team-member/internship/" +
                  item.internship_id +
                  "/" +
                  item.user_id
                }
                className="text-muted"
              >
                <i className="ri-calendar-event-fill me-1 align-bottom" /> View
                Profile
              </Link>
            </div>
          </div>
        </div>
        {/* end card footer */}
      </div>
      {/* end card */}
    </>
  );
};

export default TeamItem;
