import React from 'react'
import AssignmentSubmissionItem from './AssignmentSubmissionItem'
import AssignmentItem from './AssignmentItem'
import DetectDevice from '../../context/DetectDevice';
import dateFormat from 'dateformat'
import { Dropdown } from 'react-bootstrap';
import { Delete, Edit, MoreVert } from '@material-ui/icons';
import Assigmentitemmobile from './Assigmentitemmobile';

const AssignmentsList = ({ tasks, Reload }) => {

  const device = DetectDevice();

  return (
    <>

      {device === "mobile" ? (
        <>
          {tasks ? (
            <>
              {tasks.map((item) => (
                <Assigmentitemmobile key={item.id} item={item} Reload={Reload} />
              ))}
            </>
          ) : (
            <>
              <div className="loading">
                <p>Loading tasks, please wait...</p>
              </div>
            </>
          )}
        </>
      ) : (

        <>


          <div className="row min-vh-50">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table table-borderless align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="cqol">Name</th >


                      <th scope="col">Given on</th>

                      <th scope="col">Deadline</th>
                      <th scope="col">unlock</th>

                      <th scope="col">Action</th>
                    </tr >
                  </thead >
                  <tbody>

                    {tasks ?
                      <>
                        {tasks.map((item) => (
                          <>
                            <AssignmentItem item={item} Reload={Reload} />

                          </>
                        ))}

                      </> :
                      <>

                      </>}

                  </tbody>
                </table >
              </div >
              <div className="text-center mt-3">
                <a
                  href="javascript:void(0);"
                  className="text-success"
                >
                  <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" />{" "}
                  Load more{" "}
                </a>
              </div>
            </div >
          </div >
        </>)}


    </>

  )
}

export default AssignmentsList