import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import {  Modal } from 'react-bootstrap'

import {Container,Row,Col,Table} from 'react-bootstrap';

import { GoKebabVertical } from "react-icons/go";
import { BsFillFileBarGraphFill } from "react-icons/bs";


import ENDPOINT from '../../context/EndPoint'
import Checkbox from '@mui/material/Checkbox';

import {  UploadFile } from '@mui/icons-material'
import UploadVideo from './UploadVideo'
import { Link } from 'react-router-dom'

import SecurityHeaders from '../../context/SecurityHeaders'
import { Delete } from '@material-ui/icons'
const InternalVideoManagement = ({ page,role}) => {

const {user} = useContext(AuthContext)


const [edit,setedit] = useState(false);


const tasktype= useRef()


useEffect(() => {
    window.scrollTo(0, 0)
    getVideos()
  }, [])


    const [videos,setvideos] = useState(null);













const getVideos = async (status)=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);



const res = await axios.post(ENDPOINT+"admin-get-videos.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
 //  // console.log("videos "+JSON.stringify(res))
 if(res.data.status==="success"){
  setvideos(res.data.videos)
 }
 else{

 }
}
}



//delete task code


const deleteVideo = async ({id})=>{

const formData = new FormData();
formData.append('session',user.SessionId);
formData.append('video_id',id);

const res = await axios.post(ENDPOINT+"admin-delete-video.php",formData,SecurityHeaders);


if(res.data.status==="success"){
 
}
else{
  
}
}

const [uploadvideo,setuploadvideo] = useState(false)



    return (
   <>
   
   
<Modal show={uploadvideo} 
size="lg"
animation={false}
onHide={()=>setuploadvideo(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Video</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <UploadVideo/>

        </Modal.Body>
      
      </Modal>


<div className="container-fluid pt-2">
   {/* end page title */}
   <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-3 col-sm-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search for order ID, customer, order status or something..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-6">
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            data-provider="flatpickr"
                            data-date-format="d M, Y"
                            data-range-date="true"
                            id="demo-datepicker"
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idStatus"
                          >
                            <option value="">Status</option>
                            <option value="all" selected="">
                              All
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="Inprogress">Inprogress</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Pickups">Pickups</option>
                            <option value="Returns">Returns</option>
                            <option value="Delivered">Delivered</option>
                          </select>
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idPayment"
                          >
                            <option value="">Select Payment</option>
                            <option value="all" selected="">
                              All
                            </option>
                            <option value="Mastercard">Mastercard</option>
                            <option value="Paypal">Paypal</option>
                            <option value="Visa">Visa</option>
                            <option value="COD">COD</option>
                          </select>
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-3 col-sm-4">
                        <div className='d-flex'>
                          <button
                            type="button"
                            className="btn me-2 btn-primary "
                            onclick="SearchData();"
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>

                          <Link to="/videos/upload" className='btn btn-danger w-fit h-fit' >Add new Task</Link>
                         
                        </div>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </form>
                </div>
<div className="flex">




</div>

<div className="container-fluid">

           
         
         

            <Table bordered>
    <thead>
    <tr>
      <th><Checkbox/></th>
      <th>Video</th>
    
      <th>Privacy</th>
      <th>Published</th>
      <th>Date</th>
      <th>Views</th>
      <th>Comments</th>
      <th>Like(vs.dislikes)</th>
    </tr>
  </thead>
  <tbody>
   

 
{videos?
<>
{videos.map((video)=>(
<>



<tr>

      <td className="col-lg-2">
         <div className="d-flex ">
           
         <img className='w-100' src={video.cover_image} ></img>
           
             
         </div>
      </td>
      <td>
      <Col className="v-data w-100">
             <div className="title w-100">
                 <h6 className="mt-2">{video.title}</h6>
                 <p className="truncate truncate-4 extra-small">{video.description}</p>
             </div>
             <div className="hide space-evenly w-100 mt-2">
                <Link to={"/videos/edit/"+video.video_id}> EDIT</Link>
                <Delete onClick={()=>deleteVideo({id:video.video_id})} className="me-3 icon click"/>
              
             </div>
             </Col>
      </td>
     
      <td>None</td>
      <td><p className="m-0 extra-small">{video.upload_date}</p> </td>
      <td>2</td>
      <td>0</td>
      <td>-</td>
    </tr>




</>


))}
</>:
<>
no videos
</>}

</tbody>
</Table>




        </div>


</div>



  

   



   


   </>
    )
}

export default InternalVideoManagement

