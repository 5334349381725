import React, { useContext, useEffect, useState } from 'react'
import dateFormat from 'dateformat'
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../context/EndPoint'
import axios from 'axios'
import { Avatar } from '@mui/material'
import DefaultModal from '../../components/modals/DefaultModal'
import AddPayment from './AddPayment'
import { Close, Edit } from '@material-ui/icons'
import { Dropdown } from "react-bootstrap";
import { Delete, MoreVert } from '@material-ui/icons';
import SecurityHeaders from '../../context/SecurityHeaders'
import { CircularProgress, Snackbar } from '@material-ui/core';
import DeleteModal from '../../components/modals/DeleteModal'
import UpdatePayment from './UpdatePayment'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useActionsContext } from '../../context/ActionsContext'
import DetectDevice from '../../context/DetectDevice'
import { CalendarEvent } from 'react-bootstrap-icons'
import { BsClock } from 'react-icons/bs'
import Localbase from 'localbase'
const PaymentsList = ({ options }) => {
  const db = new Localbase('db')
  const dispatch = useDispatch()
  const device = DetectDevice()
  const storeValue = useSelector((state) => state.modalHandler)

  const [payments, setpayments] = useState(null)
  const { user } = useContext(AuthContext)
  const getPayments = async (status) => {

    const formData = new FormData();

   if(options?.limit){
    formData.append('limit',options?.limit);
   }
   if(options?.status){
    formData.append('option',options?.status);
   }

    formData.append('session', user.SessionId);

    const res = await axios.post(ENDPOINT + "get-fee-payments.php", formData,SecurityHeaders);
    if (res) {
      if (res.data.status === "success") {
        setInitialData(res.data.data)
        setResponse(res.data.data)
        setpayments(res.data)
        db.collection('paths').add({initial_data:res.data.data},'initial_data')
        db.collection('paths').add({initial_data_payments:res.data},'initial_data_payments')
      }
      else {

      }
    }
     // console.log(payments ? payments.payment_success : null)
  }
  useEffect(() => {
    getPayments()
  }, [])
useEffect(()=>{
  db.collection('paths').doc('initial_data').get().then((data)=>{
    if(data){
      setInitialData(data.initial_data)
    setResponse(data.initial_data)
    }
  })
  db.collection('paths').doc('initial_data_payments').get().then((data)=>{
    if(data){
      setpayments(data.initial_data_payments)
    }
  })
},[])
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [deleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState(null)

  const deleteFee = async () => {
    try {
      const data = new FormData();
      data.append('id', id);
      data.append('session', user.SessionId);

      const res = await axios.post(ENDPOINT + "fee-payment-delete.php", data, SecurityHeaders);
      if (res) {
        if (res.data.status === "success") {
          setSnackbarMessage(res.data.message)
          setSnackbar(true)
          getPayments()

          setDeleteModal(false)

        }
        else {
          setSnackbarMessage(res.data.message)
          setSnackbar(true)
        }
      }
    }
    catch (err) {
      setSnackbarMessage("Network Error")
      setSnackbar(true)

    }
  }

  const [addPayment, setAddPayment] = useState(false)

  const [searchTerm, setSearchTerm] = useState("");

  const [response, setResponse] = useState(null);
  const [initialData, setInitialData] = useState('')

  // Function to handle changes in the search input
  const {searchquery} = useActionsContext() 
  useEffect(()=>{
    handleSearch({term:searchquery})
  },[searchquery])
  const handleSearch = ({term}) => {
    if(!term)
    {
      setResponse(initialData)
      setSearchTerm("")
      return
    }
    if(term && initialData)
    {
      const filteredData = initialData.filter((item) => {
        return (
          (item.first_name && item.first_name.toLowerCase().includes(term)) ||
          (item.last_name && item.last_name.toLowerCase().includes(term)) ||
          (item.payment_method && item.payment_method.toLowerCase().includes(term)) ||
          (item.payment_option && item.payment_option.toLowerCase().includes(term)) ||
          (item.mode && item.mode.toLowerCase().includes(term)) ||
          (item.purchase_status && item.purchase_status.toLowerCase().includes(term))
        );
      });
  
      setResponse(filteredData);
      setSearchTerm(term)
    }

    // Filter the data based on search term


  };
  const [edit, setEdit] = useState(false)
  const [payment, setPayment] = useState(null);
  useEffect(() => {
    if (storeValue.functionName === 'fee-payments') {
      getPayments()
    }
  }, [storeValue]);

  return (
    <>
      <DeleteModal
        HandleClose={() => setDeleteModal(false)}
        HandleDelete={deleteFee}
        show={deleteModal}
      />
      <Snackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar(false)}
        message={snackbarMessage}
      />
      <DefaultModal content={<>

        <div className="p-3 rounded">
          <div className="d-flex modal-header">
            <h3>Update Payment Details</h3>
            <Close onClick={() => setEdit(false)} />
          </div>
          <hr></hr>
          <UpdatePayment item={payment} HandleClose={() => setEdit(false)} />
        </div>

      </>} show={edit} HandleClose={() => setEdit(false)} />

      <DefaultModal content={<>

        <div className="p-3 rounded">
          <div className="d-flex modal-header">
            <h3>Add Payment Details</h3>
            <Close onClick={() => setAddPayment(false)} />
          </div>
          <hr></hr>
          <AddPayment HandleClose={() => setAddPayment(false)} />
        </div>

      </>} show={addPayment} HandleClose={() => setAddPayment(false)} />





      <div className="container-fluid">

        {options?.search === false ? <></> : <>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                        <i className="ri-arrow-down-circle-fill align-middle" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                        payment-success{" "}
                      </p>
                      <h4 className=" mb-0">

                        <span
                          className="counter-value"
                          data-target="14799.44"
                        >
                          {payments ? payments.payment_success.payment_success : null}
                        </span>
                      </h4>
                    </div>
                    <div className="flex-shrink-0 align-self-end d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      <span className="badge bg-danger-subtle text-danger">
                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                        4.80 %<span> </span>
                      </span>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}

            {/* end col */}
            <div className="col-lg-3 col-md-3 col-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                        <i className="ri-arrow-down-circle-fill align-middle" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                        payment-pending{" "}
                      </p>
                      <h4 className=" mb-0">

                        <span
                          className="counter-value"
                          data-target="14799.44"
                        >
                          {payments ? payments.payment_pending.payment_pending : null}
                        </span>
                      </h4>
                    </div>
                    <div className="flex-shrink-0 align-self-end d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      <span className="badge bg-danger-subtle text-danger">
                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                        4.80 %<span> </span>
                      </span>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}

            {/* end col */}
            <div className="col-lg-3 col-md-3 col-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                        <i className="ri-arrow-down-circle-fill align-middle" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                        payment-paused {" "}
                      </p>
                      <h4 className=" mb-0">

                        <span
                          className="counter-value"
                          data-target="14799.44"
                        >
                          {payments ? payments.payment_paused.payment_paused : null}
                        </span>
                      </h4>
                    </div>
                    <div className="flex-shrink-0 align-self-end d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      <span className="badge bg-danger-subtle text-danger">
                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                        4.80 %<span> </span>
                      </span>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}

            {/* end col */}
            <div className="col-lg-3 col-md-3 col-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                        <i className="ri-arrow-down-circle-fill align-middle" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                        specialization students{" "}
                      </p>
                      <h4 className=" mb-0">

                        <span
                          className="counter-value"
                          data-target="14799.44"
                        >
                          { }
                        </span>
                      </h4>
                    </div>
                    <div className="flex-shrink-0 align-self-end d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      <span className="badge bg-danger-subtle text-danger">
                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                        4.80 %<span> </span>
                      </span>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div></>}


        <div className="row">
          <div className="col-lg-12">
            <div className="card" id="orderList">
              {options?.search === false ? <></> : <> <div className="card-header border-0">
                <div className="row align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Fee Payment History</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-success add-btn"
                        onClick={() => setAddPayment(true)}
                      >
                        <i className="ri-add-line align-bottom me-1" />Add Payment
                      </button>
                      <button type="button" className="btn btn-info">
                        <i className="ri-file-download-line align-bottom me-1" />{" "}
                        Export
                      </button>
                      <button
                        className="btn btn-soft-danger"
                        id="remove-actions"
                        onclick="deleteMultiple()"
                      >
                        <i className="ri-delete-bin-2-line" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
                {/* <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                    <div className="row g-3">
                      <div className="col-xxl-5 col-sm-6">
                        <div className="search-box">
                          <input
                            type="text"
                            onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                            className="form-control search"
                            placeholder="Search Name"
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      <div className="col-xxl-2 col-sm-6">
                        <div>
                          <input
                            type="date"
                            onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}

                            className="form-control"
                            data-provider="flatpickr"
                            data-date-format="d M, Y"
                            data-range-date="true"
                            id="demo-datepicker"
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}

                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idStatus"
                          >
                            <option value="">Status</option>

                            <option value="live">Live</option>
                            <option value="offline">Offline</option>
                            <option value="specialization">Specialization</option>
                            <option value="selfpaced">Selfpaced</option>

                          </select>
                        </div>
                      </div>
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idPayment"
                          >
                            <option value="">Select Payment</option>
                            <option value="all" selected="">
                              All
                            </option>
                            <option value="Mastercard">Mastercard</option>
                            <option value="Paypal">Paypal</option>
                            <option value="Visa">Visa</option>
                            <option value="COD">COD</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xxl-1 col-sm-4">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onclick="SearchData();"
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div> */}
                </>}

        {device==="mobile"?
        <>
            
            {payments?<>{response.map((item)=>(
             <>
              
              <div className='col-12 mb-1' key={item.id}>
                <div className='card mb-1'>
                  <div className='d-flex justify-content-between align-items-start m-2'>
                      <div className='d-flex '>
                      <Avatar src={item.profile_pic}/>
                      <div>
                      <h5 className='ms-2 mb-0'>{item.first_name+" "+item.last_name}</h5>
                      <p className='ms-2 mb-0'>{item.course_url}</p>
                      </div>
                      </div>
                      <div className='me-1'>
                        <h6 className='mb-0'>₹{item.paid_amount}</h6>
                        <span className={`badge ${item.purchase_status==='success'?'bg-success-subtle text-success':'bg-warning-subtle text-warning'}`}>
                {item.purchase_status}
              </span>
                      </div>

                  </div>
                 <div className='d-flex justify-content-between mx-2 mb-2'>
                 <div className='d-flex align-items-end'>
                 <BsClock className='mx-2 mb-1'/>
                  <p className='m-0'>{dateFormat(item.purchase_datetime,'dS mmm hh:mm tt')}</p></div>
                  <button
                className="btn btn-link text-muted p-1 m-0"
                data-bs-toggle="collapse"
                data-bs-target={`#details${item.id}`}
                aria-expanded="false"
                aria-controls={`details${item.id}`}
              >
                <i className="ri-arrow-down-s-fill"></i> More
              </button>
                 </div>
                </div>
              </div>
             
              <div className="collapse m-2" id={`details${item.id}`}>
              <div className='d-flex justify-content-between'>
              <p className="mb-1"><strong>Price :</strong> {item.purchase_price}</p>
              <p className="mb-1"><strong>Pending Amount : </strong> {item.pending_amount}</p>
              </div>
              <div className='d-flex justify-content-between'>
              <p className="mb-1"><strong>Payment method:</strong> {item.payment_method?item.payment_method:"N/A"}</p>
              <p className="mb-1"><strong>Payment mode:</strong> {item.payment_option}</p>
              </div>
              <div className='d-flex justify-content-between'>
              <p className="mb-1"><strong>Phone number:</strong> {item.phone_number}</p>
              <p className="mb-1"><strong>ID:</strong> {item.id}</p>
              </div>
             
            </div>
             
             
             </>
              
              
              
              
              
              ))}</>:<></>}

        </>
        :
        <>
        
        <div className="card-body pt-2">
                <div>

                  <div className="table-responsive table-card mb-1">
                    <table
                      className="table table-nowrap align-middle"
                      id="orderTable"
                    >
                      <thead className="text-muted table-light">
                        <tr className="text-uppercase">
                          <th scope="col" style={{ width: 25 }}>
                            {/* <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="checkAll"
                                defaultValue="option"
                              />
                            </div> */}
                          </th>
                          {options?.id === false ? <></> : <th className="" data-sort="id">
                            Invoice ID
                          </th>}
                          <th className="" data-sort="customer_name">
                            User
                          </th>
                          <th className="" data-sort="product_name">
                            Course
                          </th>
                          <th className="" data-sort="date">
                            Mode
                          </th>
                          <th className="" data-sort="amount">
                            Paid
                          </th>
                          <th className="" data-sort="status">
                            Pending
                          </th>
                        {options?.method === false?<></>:
                          <th className="" data-sort="payment">
                          Method
                        </th>}
                       {options?.option === false?<></>:   <th className="" data-sort="payment">
                            Option
                          </th>}
                          <th className="" data-sort="payment">
                            Date
                          </th>
                          {options?.actions === false ? <></> :

                            <th className="" data-sort="city">
                              Action
                            </th>}
                        </tr>
                      </thead>
                      <tbody className="list form-check-all">
                        {payments ?
                          <>

                            {response.map((item) => (
                              <>
                                <tr>
                                  <th scope="row">
                                    {/* <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="checkAll"
                                        defaultValue="option1"
                                      />
                                    </div> */}
                                  </th>
                                  {options?.id === false ? <></> : <td className="id">
                                    <Link
                                      onClick={() => dispatch({ type: 'INSERT_INVOICE', payload: item })}
                                      to={"/invoice/" + item.id}
                                      className="fw-medium link-primary"
                                    >
                                      {item.id}
                                    </Link>
                                  </td>}
                                  <td className="customer_name">

                                    <div className="d-flex align-items-center">
                                      <Avatar className='me-2' src={item.profile_pic} />
                                      <div>
                                        <h6 className='m-0 p-'>{item.first_name + " " + item.last_name}</h6>
                                        <p className='m-0 p-0'>{item.phone_number}</p>
                                      </div>
                                    </div>

                                  </td>
                                  <td className="product_name"><>
                                    <p className='mb-0 pb-0'>{item.course_url}</p>
                                    <p>
                                      st-Id :
                                      {item.student_id}

                                    </p>
                                  </></td>
                                  <td >
                                    {item.mode}
                                  </td>
                                  <td className="amount">₹ {item.paid_amount}</td>
                                  <td className="status">

                                    ₹{item.pending_amount}

                                  </td>
                                  {options?.method === false ? <></> :
                                    <td className="payment">{item.payment_method}</td>
                                  }
                                   {options?.option === false?<></>:
                                  <td className="payment">{item.payment_option}</td>}
                                  <td className="payment">{item.purchase_datetime}</td>


                                  {options?.actions === false ? <></> :
                                    <td>
                                      <Dropdown className="options-dropdown">
                                        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
                                          <MoreVert className="text-dark" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>

                                          <Dropdown.Item href="#" onClick={() => { setPayment(item); setEdit(true) }}> <Edit /> Edit</Dropdown.Item>

                                          <Dropdown.Item href="#" onClick={() => { setId(item.id); setDeleteModal(true) }}><Delete /> Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>}
                                </tr>

                              </>
                            ))}
                          </> :
                          <>

                          </>}
                      </tbody>
                    </table>
                    <div className="noresult" style={{ display: "none" }}>
                      <div className="text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/msoeawqm.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#0ab39c"
                          style={{ width: 75, height: 75 }}
                        />
                        <h5 className="mt-2">Sorry! No Result Found</h5>
                        <p className="text-muted">
                          We've searched more than 150+ Orders We did not find
                          any orders for you search.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
                <div
                  className="modal fade"
                  id="showModal"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">

                  </div>
                </div>
                {/* Modal */}
                <div
                  className="modal fade flip"
                  id="deleteOrder"
                  tabIndex={-1}
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body p-5 text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/gsqxdxog.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#f06548"
                          style={{ width: 90, height: 90 }}
                        />
                        <div className="mt-4 text-center">
                          <h4>You are about to delete a order ?</h4>
                          <p className="text-muted fs-15 mb-4">
                            Deleting your order will remove all of your
                            information from our database.
                          </p>
                          <div className="hstack gap-2 justify-content-center remove">
                            <button
                              className="btn btn-link link-success fw-medium text-decoration-none"
                              id="deleteRecord-close"
                              data-bs-dismiss="modal"
                            >
                              <i className="ri-close-line me-1 align-middle" />{" "}
                              Close
                            </button>
                            <button
                              className="btn btn-danger"
                              id="delete-record"
                            >
                              Yes, Delete It
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*end modal */}
              </div>
        
        
        </>}
             
            </div>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>

    </>
  )
}

export default PaymentsList