import { Avatar } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import dateFormat from "dateformat";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { AuthContext } from "../../context/AuthContext";
import ENDPOINT from "../../context/EndPoint";
import SecurityHeaders from "../../context/SecurityHeaders";

const WhatsAppNotifications = () => {
  const { user } = useContext(AuthContext);
  const params = useParams();
  const phone_number = 9490174876;
  
  const [chats, setChats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState("hi");
  const [template, setTemplate] = useState("added_assignment");
  const [student, setStudents] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState({ phone: [] });
  const [script, setScript] = useState(null);
  const [automatic, setAutomatic] = useState(false);
  const [meeting, setMeeting] = useState(false);
  const [courseUrl, setCourseUrl] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [all, setAll] = useState(false);
  const [students, setStudentsFlag] = useState(false);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState(null);
  const [category, setCategory] = useState("students");
  const [one, setOne] = useState(null);
  const [two, setTwo] = useState(null);
  const [three, setThree] = useState(null);
  const [four, setFour] = useState(null);
  const [five, setFive] = useState(null);
  const [six, setSix] = useState(null);
  const [seven, setSeven] = useState(null);
  const [payload, setPayload] = useState({
    image: image,
    one: one,
    two: two,
    three: three,
    four: four,
    five: five,
    six: six,
    seven: seven,
  });

  const { transcript, listening } = useSpeechRecognition();

  const GetChats = async () => {
    setLoading(true);
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("phone", phone_number);
    try {
      const res = await axios.post(ENDPOINT + "whatsapp-chats.php", data, SecurityHeaders);
      setLoading(false);
      if (res.data.status === "success") {
        setChats(res.data.chat_heads);
        setMessages(res.data.messages);
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetChats();
  }, [phone_number]);

  useEffect(() => {
    setPayload({
      image: image,
      one: one,
      two: two,
      three: three,
      four: four,
      five: five,
      six: six,
      seven: seven,
    });
  }, [template, image, one, two, three, four, five, six, seven]);
  const getStudents = async () => {
    try {
      const data = new FormData();
      data.append('session', user.SessionId);
      const res = await axios.post(`${ENDPOINT}get-campaign-contact.php`, data, SecurityHeaders);
      if (res.data.status === 'success') {
        setStudents(res.data.data);
      }
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };
  useEffect(() => {
   
    getStudents();
  }, [user]);

  useEffect(() => {
    if (transcript) {
      setScript(transcript);
      setMessage(transcript.replace("send", ""));
      if (automatic && !listening) {
        SendMessage({ payload, phone: null });
        SpeechRecognition.startListening();
      }
    }
  }, [transcript, listening, automatic]);

  const handlePhoneNumbers = (contact) => {
    setPhoneNumbers((prevSelectedContacts) => {
      if (prevSelectedContacts.phone.includes(contact)) {
        return { phone: prevSelectedContacts.phone.filter(c => c !== contact) };
      } else {
        return { phone: [...prevSelectedContacts.phone, contact] };
      }
    });
  };

  const SendMessage = async ({ payload, phone }) => {
    if (phone){
       // console.log(phone);
    };

    const formData = new FormData();

    formData.append('id',phone);
    formData.append('send','send');
    formData.append("session", user.SessionId);
    try {
       const response =  await axios.post(ENDPOINT + "campaign-send-message-update.php", formData, SecurityHeaders);
        if(response){
          if(response.data.status === 'success'){
            getStudents();
            setPhoneNumbers('')
          }
        }
      } catch (error) {
        console.error('Error sending message:', error);
       }

    // const data = new FormData();
    // data.append("phone", phone);
    // data.append("session", user.SessionId);
    // Object.keys(payload).forEach(key => data.append(key, payload[key] || ''));
    // try {
    //   await axios.post(ENDPOINT + "whatsapp-api.php", data, SecurityHeaders);
    //   setMessage("");
    // } catch (error) {
    //   console.error('Error sending message:', error);
    // }
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="col-5">
          <div className="col-lg-12 shadow p-2 border br-4">
            <img className="w-75 mx-auto" src={image} alt="Notification"></img>
            <div className="p-2">
              <h4>Batch: {batchId}</h4>
              {template === "added_assignment" && (
                <>
                  <p>
                    Hey {one}, a new assignment has been added for your course {two}. Visit Skills Uprise course dashboard to access your assignments.
                    Title: {three}
                    Deadline: {four},
                    <br></br>
                    {five}
                    All the best!
                  </p>
                  <p>Image: {image}</p>
                  <p>1: {one}</p>
                  <p>2: {two}</p>
                  <p>3: {three}</p>
                  <p>4: {four}</p>
                  <p>5: {five}</p>
                </>
              )}
              {template === "assignment_evaluated" && (
                <>
                  <p>
                    Hey {one}, your assignment has been evaluated for your course {two}. You have scored {three} marks.
                    Visit Skills Uprise course dashboard to check your corrections and remarks.
                  </p>
                  <p>Image: {image}</p>
                  <p>1: {one}</p>
                  <p>2: {two}</p>
                  <p>3: {three}</p>
                </>
              )}
              {template === "class_commencement_notification" && (
                <>
                  <p>
                    Dear {one}, your trainer is waiting for you at your classroom. Kindly join the class and bring light to the classroom with your presence. Please login into your website dashboard to join the class by marking your attendance. Join Skills Uprise & Keep Rising Up! Sofy | Skills Uprise AI Bot
                  </p>
                </>
              )}
              {template === "class_early_reminder" && (
                <>
                  <p>
                    Class Starts Soon! Dear {one}, this is a reminder for your {two} class that has been scheduled at {three}. Join the class and learn the best lessons. Visit Skills Uprise course dashboard to mark class details.
                  </p>
                </>
              )}
              {template === "otp_verification" && (
                <>
                  <p>
                    Dear {one}, Your 6 Digit Verification Code is {two}. Verify your account and start building a successful career with us. In case, you have any issues verifying your account, feel free to contact us via Email: contact@skillsuprise.com or via Phone: +919490174876. Happy Uprising! Team Skills Uprise
                  </p>
                </>
              )}
              {template === "pending_course_registration" && (
                <>
                  <p>
                    Hey {one}, Here's a welcome bonus for you! Save ₹ {two} by using this Coupon Code: {three}. Hurry up, the seats are filling quick. Complete your course registration by paying the registration fee and start a successful career journey. Join 5000+ students who have already joined our trainings. Offer ends soon!
                  </p>
                </>
              )}
              {template === "recording_added" && (
                <>
                  <p>
                    Class Recording Available! Hey {one}, today's class recording is now available in your course dashboard. Class Title: {two}. Schedule: {three}.
                  </p>
                </>
              )}
              {template === "password_reset_otp" && (
                <>
                  <p>
                    Dear {one}, Your 6 digit verification code is {two}. This OTP is valid only for 10 minutes. Reset your password now to login into your account. In case you have problems resetting your password or logging into your account, feel free to reach us at contact@skillsuprise.com.
                  </p>
                </>
              )}
              {template === "assignment_submission_notification" && (
                <>
                  <p>
                    Hey {one}, You have {two} pending assignments. Submit your assignment before the deadline to unlock certificates, badges, and points.
                  </p>
                </>
              )}
              {template === "coupon_code" && (
                <>
                  <p>Hey {one}, a new coupon code was added.</p>
                </>
              )}
              {template === "added_project" && (
                <>
                  <p>
                    Hey {one}, a new task has been added for you. Visit your account dashboard for more details.
                    Title: {two}
                    Description: {three}
                    Deadline: {four}
                  </p>
                </>
              )}
              {template === "campaign" && (
                <>
                  <p>
                    Hey Budding Ethical Hacker 🕵️‍♂️🔐
                    Just a friendly reminder that our Ethical Hacking & Cyber Security Demo class is happening TOMORROW at 11 am* 🕕💻. Don't miss out on this opportunity to kickstart your journey of ethical hacking! 🚀✨. Join the demo using the Zoom Meetings App.
                    Meeting Link: https://us06web.zoom.us/j/9490174876?pwd=Ymo4aDgrWjh2bDhiQ1Y4WFdxQTh2QT09
                    Meeting ID: 9490174876
                    Password: manojkumarS
                    See you there! 👩‍💻👨‍💻
                  </p>
                </>
              )}
              {template === "doubt_support_update" && (
                <>
                  <p>
                    Hey {one}, Your support request has been updated by {two}. Visit Skills Uprise course dashboard to know more. Current Support Status: {three}.
                  </p>
                </>
              )}
              {template === "course_termination" && (
                <>
                  <p>
                    Dear {one}, Your student account has been marked for termination. Please contact your trainer and resolve any issues of your training to get the suspension of your training program canceled.
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-12 mt-3">
            <select
              className="form-control"
              onChange={(e) => setTemplate(e.target.value)}
            >
              <option>Message category</option>
              <option value="general_message">General message</option>
              <option value="campaign">Campaign</option>
              <option value="class_details">Class Meeting details</option>
              <option value="events">Events</option>
              <option value="otp_verification">OTP Verification</option>
              <option value="password_reset_otp">Password reset</option>
              <option value="added_assignment">Assignment Added</option>
              <option value="assignment_evaluated">Assignment Evaluated</option>
              <option value="added_schedule">Add class schedule</option>
              <option value="added_project">Added project</option>
              <option value="user_engagement">User engagement</option>
              <option value="pending_course_registration">Pending course registration</option>
              <option value="coupon_code">Coupon code</option>
              <option value="pending_payment">Pending payment</option>
              <option value="placement_training">Placement trainings</option>
              <option value="doubt_support_update">Doubt support updates</option>
              <option value="course_termination">Course termination</option>
            </select>
            <div className="d-flex mt-4">
              <select
                onChange={(e) => setCategory(e.target.value)}
                className="form-control"
              >
                <option value="all">All Users</option>
                <option value="students">All Students</option>
                <option value="pending_payment">Website development from scratch</option>
              </select>
              <select
                className="form-control"
                onChange={(e) => setCourseUrl(e.target.value)}
              >
                <option>Select Course</option>
                <option value="website-development">Full stack</option>
                <option value="fullstack-specialization">Full stack specialization- MERN</option>
                <option value="mean-stack-specialization">Full stack specialization - MEAN</option>
                <option value="ethical-hacking-specialization">Ethical Hacking Specialization</option>
                <option value="data-science-specialization">Data science specialization</option>
                <option value="cloud-computing">Cloud computing</option>
                <option value="data-science">Data science</option>
                <option value="python-programming">Python programming</option>
                <option value="ui-ux-development">UI / UX Development</option>
                <option value="corejava">Java Programming</option>
                <option value="reactjs">ReactJs</option>
                <option value="cpp">C programming</option>
                <option value="software-testing">Software testing</option>
                <option value="internet-of-things-iot">Internet of Things</option>
                <option value="machine-learning">Machine Learning</option>
              </select>
              <select className="form-control">
                <option>All Batches</option>
                <option>Specific Batch</option>
              </select>
            </div>

            {template === 'course_termination' && (
              <div className="mt-2">
                <input
                  className="form-control"
                  onChange={(e) => setOne(e.target.value)}
                  placeholder="One"
                ></input>
              </div>
            )}
            {template === 'doubt_support_update' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    placeholder="Image URL"
                    onChange={(e) => setImage(e.target.value)}
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setTwo(e.target.value)}
                    placeholder="Two"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setThree(e.target.value)}
                    placeholder="Three"
                  ></input>
                </div>
              </>
            )}
            {template === 'coupon_code' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    placeholder="Image URL"
                    onChange={(e) => setImage(e.target.value)}
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
              </>
            )}
            {template === 'pending_course_registration' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    placeholder="Image URL"
                    onChange={(e) => setImage(e.target.value)}
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setTwo(e.target.value)}
                    placeholder="Two"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setThree(e.target.value)}
                    placeholder="Three"
                  ></input>
                </div>
              </>
            )}
            {template === 'campaign' && (
              <div className="mt-2">
                <input
                  className="form-control"
                  placeholder="Image URL"
                  onChange={(e) => setImage(e.target.value)}
                ></input>
              </div>
            )}
            {template === 'general_message' && (
              <div className="mt-2">
                <input
                  className="form-control"
                  onChange={(e) => setBatchId(e.target.value)}
                  placeholder="Batch ID"
                ></input>
              </div>
            )}
            {template === 'class_details' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    placeholder="Image URL"
                    onChange={(e) => setImage(e.target.value)}
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setTwo(e.target.value)}
                    placeholder="Two"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setThree(e.target.value)}
                    placeholder="Three"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setFour(e.target.value)}
                    placeholder="Four"
                  ></input>
                </div>
              </>
            )}
            {template === 'events' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setBatchId(e.target.value)}
                    placeholder="Batch ID"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    placeholder="Image URL"
                    onChange={(e) => setImage(e.target.value)}
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setTwo(e.target.value)}
                    placeholder="Two"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setThree(e.target.value)}
                    placeholder="Three"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setFour(e.target.value)}
                    placeholder="Four"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setFive(e.target.value)}
                    placeholder="Five"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setSix(e.target.value)}
                    placeholder="Six"
                  ></input>
                </div>
              </>
            )}
            {template === 'otp_verification' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setTwo(e.target.value)}
                    placeholder="Two"
                  ></input>
                </div>
              </>
            )}
            {template === 'password_reset_otp' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setTwo(e.target.value)}
                    placeholder="Two"
                  ></input>
                </div>
              </>
            )}
            {template === 'added_assignment' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    placeholder="Image URL"
                    onChange={(e) => setImage(e.target.value)}
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setTwo(e.target.value)}
                    placeholder="Two"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setThree(e.target.value)}
                    placeholder="Three"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setFour(e.target.value)}
                    placeholder="Four"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setFive(e.target.value)}
                    placeholder="Five"
                  ></input>
                </div>
              </>
            )}
            {template === 'assignment_evaluated' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    placeholder="Image URL"
                    onChange={(e) => setImage(e.target.value)}
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setTwo(e.target.value)}
                    placeholder="Two"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setThree(e.target.value)}
                    placeholder="Three"
                  ></input>
                </div>
              </>
            )}
            {template === 'added_project' && (
              <>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setOne(e.target.value)}
                    placeholder="One"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setTwo(e.target.value)}
                    placeholder="Two"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setThree(e.target.value)}
                    placeholder="Three"
                  ></input>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    onChange={(e) => setFour(e.target.value)}
                    placeholder="Four"
                  ></input>
                </div>
              </>
            )}
            <button
              className="btn btn-primary w-100 mt-3"
              onClick={() => {
                if (phoneNumbers.phone.length > 0) {
                  phoneNumbers.phone.forEach((item) => {
                    SendMessage({
                      payload: {
                        one: "Manoj, I",
                        two: "Manoj, I.",
                        three: "Manoj, I.",
                        four: "Manoj, I.",
                        template: "meeting_details",
                      },
                      phone: item
                    });
                  });
                } else {
                  SendMessage({
                    payload: {
                      one: "Manoj, I",
                      two: "Manoj, I.",
                      three: "Manoj, I.",
                      four: "Manoj, I.",
                      template: "meeting_details",
                    },
                    phone: 9490174876 
                  });
                }
              }}
            >
              Send Message
            </button>
          </div>
        </div>
        <div className="col-lg-5 bg-white rounded shadow p-3 overflow-scroll vh-100">
          <h4>Students</h4>
          {student.length > 0 ? student.map((chat, index) => (
            <div key={index} className="d-flex align-items-center bg-white rounded shadow mt-3 p-3 justify-content-between">
              <div className="d-flex align-items-center">
                <Avatar sx={{ width: 30, height: 30 }} />
                <p className="ms-2 mb-0 pb-0">{chat.phone_number}</p>
              </div>
              {chat.message_send === 'send' ? (
                <h5 className="text-success">Send</h5>
              ) : (
                <div className="form-check form-check-inline">
                  <input
                    type='checkbox'
                    onChange={(e) => {
                      handlePhoneNumbers(chat.phone_number);
                      e.preventDefault();
                    }}
                  />
                </div>
              )}
            </div>
          )) : (
            <h3>No students</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppNotifications;
