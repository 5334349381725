import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import defaultdp from "../../assets/user.png";

import { useState } from "react";
import { Modal } from "react-bootstrap";

import { useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import dateFormat from "dateformat";
import coinsicon from "../../assets/money.svg";
import sessionicon from "../../assets/sessions.svg";
import influencedicon from "../../assets/influenced.svg";
import cairefericon from "../../assets/caireferral.svg";
import workshopicon from "../../assets/workshopicon.svg";
import tutorialicon from "../../assets/tutorialsicon.svg";
import Lock from "@mui/icons-material/Lock";

import Tooltip from "@mui/material/Tooltip";
import { Avatar } from "@mui/material";
import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import SecurityHeaders from "../../context/SecurityHeaders";
import styled from "styled-components";
const CampusAmbassador = () => {

  const params = useParams()

  const user_id = params.user_id

  const [cai, setCia] = useState(null);
  const [referrals, setReferrals] = useState([]);


  const GetCia = async () => {

    const data = new FormData();
    data.append('user_id', user_id);

    const res = await axios.post(ENDPOINT + "get-campus-ambassador.php", data, SecurityHeaders);

    if (res.data) {
      setCia(res.data.data[0])

      setReferrals(res.data.referrals_list);
    }
  }







  const [show, setshow] = useState(false);

  const [milestonestatus, setmilestonestatus] = useState(false);




  const [report, setReport] = useState("");
  const [batchId, setBatchId] = useState(666);
  const [reports, setReports] = useState("");



  const getReports = async () => {
    const data = new FormData();
    const user = JSON.parse(localStorage.getItem("user"));
    data.append("user_id", user_id);

    const res = await axios.post(
      ENDPOINT + "get-ambassador-reports.php",
      data,
      SecurityHeaders
    );
    if (res && res.data && res.data.status == "success") {
      setReports(res.data.data);

    }
  };
  useEffect(() => {
    getReports();
    GetCia();
  }, []);

  const [uploading, setuploading] = useState(false)

  return (
    <>
      {
        cai ?
          <>
            {/* <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Internship Application</Modal.Title>
      </Modal.Header>

      <div className="p-3">
        <ApplyCai />
      </div>
    </Modal> */}


            <ApplyInternshipStyled>
              <div className=" bg-dark py-5">
                <div className="wallet-crumb container flex-wrap d-flex   justify-content-between">
                  <div className="col-lg-6 text-light">
                    <h2 className="ambassador-title d-none">
                      Welcome Campus Ambassador !
                    </h2>
                    <div className="flex mt-3 mb-5 align-items-center">
                      <Avatar
                        src={cai.profile_pic ? cai.profile_pic : null}
                        className="user-dp"
                      />

                      <div className="ms-3">
                        <h4 className="text-light">{cai.first_name + " " + cai.last_name}</h4>
                        <h6 className="text-light">CAI-ID : {cai.id}</h6>
                        <h6 className="text-light"> {cai.phone}</h6>


                      </div>
                    </div>

                    <hr />
                    <div className="mb-50">
                      <h4 className="text-light">Total Earnings</h4>
                      <h2 className="mb-1 text-light">₹ {cai.earnings ? cai.earnings : "0"}</h2>

                      <div className="flex mt-3">
                        <span className="border-left-red border-right-red p-2 bg-danger mr-3 mt-0">
                          ₹ {cai.balance ? cai.balance : "0"} Balance
                        </span>
                        <span className="border-left-red border-right-red p-2 bg-dark mt-0">
                          SkillsUprise{cai.id}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5 col-sm-10 d-flex   flex-wrap justify-content-evenly  p-0 fit-content mt-5 mt-md-4 mt-lg-0 mt-sm-4">
                    <div className="shadow bg-light p-3 rounded fit-content col-lg-5 p-10 col-sm-5 col-5 mb-20 m-2">
                      <h6 className="sm-14 text-truncate">Estimated Earnings</h6>

                      <div className="d-flex justify-content-between">
                        <h3 className="sm-16">
                          ₹ {cai.earnings ? cai.est_earnings : "0"}
                        </h3>

                        <img className="stat-icon" src={coinsicon} alt="" />
                      </div>
                    </div>

                    <div className="shadow bg-light p-3 rounded fit-content col-lg-5 p-10 col-sm-5 col-5 mb-20 m-2">
                      <h6 className="sm-14 text-truncate">Sessions Taken</h6>

                      <div className="d-flex justify-content-between">
                        <h3 className="sm-16">{cai.sessions ? cai.sessions : "0"}</h3>

                        <img className="stat-icon" src={sessionicon} alt="" />
                      </div>
                    </div>

                    <div className="shadow bg-light rounded p-3 fit-content col-lg-5 col-sm-5 col-5  p-10 mb-20 m-2">
                      <h6 className="sm-14 text-truncate">Influenced</h6>

                      <div className="d-flex justify-content-between">
                        <h3 className="sm-16">
                          {cai.influenced ? cai.influenced : "0"}
                        </h3>

                        <img className="stat-icon" src={influencedicon} alt="" />
                      </div>
                    </div>

                    <div className="shadow bg-light rounded p-3 fit-content col-lg-5 col-sm-5 col-5 p-10 mb-20 m-2">
                      <h6 className="text-truncate sm-14">Ambassador Referrals</h6>

                      <div className="d-flex justify-content-between">
                        <h3 className="sm-16">{cai ? cai.num_cai_referrals : "0"}</h3>

                        <img className="stat-icon" src={cairefericon} alt="" />
                      </div>
                    </div>

                    <div className="shadow bg-light rounded p-3 fit-content col-lg-5 col-sm-5 col-5 p-10 mb-20 m-2">
                      <h6 className="sm-14 text-truncate">Workshop Referrals</h6>

                      <div className="d-flex justify-content-between">
                        <h3 className="sm-16">
                          {cai ? cai.workshop_referrals_count : "0"}
                        </h3>

                        <img className="stat-icon" src={workshopicon} alt="" />
                      </div>
                    </div>

                    <div className="shadow bg-light rounded p-3 fit-content col-lg-5 col-sm-5 col-5 p-10 mb-20 m-2">
                      <h6 className="sm-14 text-truncate">Course Referrals</h6>

                      <div className=" d-flex justify-content-between">
                        <h3 className="sm-16">
                          {cai ? cai.course_referrals_count : "0"}
                        </h3>

                        <img className="stat-icon" src={tutorialicon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </ApplyInternshipStyled>


            <div className="">
              <div className=" d-flex flex-wrap justify-content-center justify-content-lg-between justify-content-md-between justify-content-sm-center mt-5 container ">
                <div className=" col-lg-6 p-2 col-md-12 col-sm-12 col-12">


                  <div className="col-12  mt-0">
                    <div className="border rounded mb-5  p-3">
                      <div className="d-flex justify-content-between">
                        <h4>Reports</h4>

                      </div>

                      <hr />
                      {reports ? <>{

                        reports.map((item) => (
                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <h6 className="mb-0">{dateFormat(item.submission_time, "dS mmm yyyy")}</h6>
                            <p

                              className={item.status == "approved" ? "text-success " : "text-danger "}
                            >{item.status}</p>
                            <a href={item.report_url} download={"report" + Date.now()} target='_blank' className="mb-0 ">
                              <DownloadIcon />
                            </a>
                          </div>

                        ))

                      }</> : <></>}


                    </div>
                  </div>
                </div>

                <div className=" col-lg-6 p-2 col-md-12 col-sm-12 col-12">
                <div className=" col-12 p-3 border rounded ">
                  <div className="d-flex justify-content-between">
                    <h4>Referrals</h4>

                  </div>

                  <hr />

                  {
                    referrals ? referrals.map((item) => (
                      <div className=" ms-4 d-flex align-items-center ">
                        <Avatar src={item.profile_pic ? item.profile_pic : null} />
                        <div className="ms-3">
                          <h6 className="pb-0 mb-0">{item.first_name+" " +item.last_name}</h6>
                          <p className="pb-0 mb-0">{item.phone_number}</p>
                        </div>

                      </div>
                    )) : null
                  }

                </div>
                </div>

                
              </div>


            </div>

            {/*leaderboard section*/}

            {/*leaderboard section*/}
          </> : null
      }</>
  );
};

export default CampusAmbassador;
const ApplyInternshipStyled = styled.div`
  input,
  textarea {
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-bottom: 10px;
    
  }
  .user-dp{
    height: 100px;
     min-width: 100px; 
     min-height: 100px; 
    width: 100px;
    border-radius: 55px;
    box-shadow: 0 0 3px grey;
  }
  .stat-icon {
    height: 40px;
}
`;
