import React, { useContext, useState } from 'react';
import { NavItem } from 'react-bootstrap';
import DefaultAlertModal from '../../components/modals/DeafaultAlertModal';
import DefaultModal from '../../components/modals/DefaultModal';
import { TextField } from '@mui/material';
import { Close } from '@material-ui/icons';
import DeleteProject from '../../actions/course/DeleteProject';
import { AuthContext } from '../../context/AuthContext';
import DeleteModal from '../../components/modals/DeleteModal';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import { CircularProgress, Snackbar } from "@material-ui/core";
import { connect } from 'react-redux';

const ProjectItem = ({ item, value, dispatch }) => {
  const { user } = useContext(AuthContext);

  const Delete = async () => {
    const res = await DeleteProject({
      payload: {
        session:user.SessionId,
        id: item.id
      }
    });

    if (res) {
      if (res.status === "success") {
        dispatch({ type: 'FUNCTION_RELOAD', payload: 'projects' });
        setDeleteModal(false)
      }
    }
  };

  const [description, setDescription] = useState(item.description);
  const [title, setTitle] = useState(item.title);
  const [image, setImage] = useState(item.image);

  const SaveChanges = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("image", image);
    data.append("id", item.id);
    data.append("session", user.SessionId);
    const res = await axios.post(ENDPOINT + "edit-project.php", data, SecurityHeaders);

    if (res) {
      if (res.data.status === "success") {
        closeModal();
        setSnackbar(true);
        setSnackbarMessage("Updated Successfully");
        dispatch({ type: 'FUNCTION_RELOAD', payload: 'projects' });
      } else {
        setSnackbarMessage("Something Went Wrong");
        setSnackbar(true);
      }
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const closeModal = () => {
    setEdit(false);
  };
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  return (
    <>
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}
        message={snackbarMessage}
      />
      <DeleteModal show={deleteModal} HandleClose={() => setDeleteModal(false)} HandleDelete={Delete} />
      <DefaultModal show={edit} HandleClose={closeModal}
        content={
          <>
            <div className="p-3">
              <div className="modal-header">
                <h4>Edit Project</h4>
                <Close onClick={() => setEdit(false)} />
              </div>
              <hr />
              <form onSubmit={SaveChanges}>
                <TextField label="Title" value={title} onChange={(e) => setTitle(e.target.value)} className='form-control' variant="outlined" />
                <TextField value={description} onChange={(e) => setDescription(e.target.value)} label="Description" className='form-control mt-3' variant="outlined" />
                <TextField label="Image" value={image} onChange={(e) => setImage(e.target.value)} className='mb-3' variant="standard" />
                <button className='btn btn-primary w-100'>Save Changes</button>
              </form>
            </div>
          </>
        }
      ></DefaultModal>
      <div className='w-100 card'>
        <img src={item.image} className='w-100' alt="Project" />
        <div className="p-3">
          <h5>{item.title}</h5>
          <p>{item.description}</p>
          <hr />
          <div className="d-flex justify-content-between">
            <button onClick={() => setDeleteModal(true)} className='btn btn-outline-primary'>Delete</button>
            <button onClick={() => setEdit(true)} className='btn btn-primary'>Edit</button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({

  value: state.modalHandler
});

export default connect(mapStateToProps)(ProjectItem);
