import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../context/EndPoint';
import axios from 'axios';
import securityHeaders from '../../context/SecurityHeaders';
import { useDispatch } from 'react-redux';

const AddPayment = ({HandleClose}) => {
  const dispatch = useDispatch()
  const { user } = useContext(AuthContext);
  const [coupon, setCoupon] = useState(false);

  const [payment, setPayment] = useState({
    paidAmount: '',
    pendingAmount: '',
    studentId: '',
    purchaseDate: '',
    paymentOption: '',
    paymentMethod: ''
  });

  const handlePaymentChange = (e) => {
    setPayment({
      ...payment,
      [e.target.name]: e.target.value
    });
  };

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const addPayment = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('session', user.SessionId);
    formData.append('student_id', payment.studentId);
    formData.append('paid_amount', payment.paidAmount);
    formData.append('pending_amount', payment.pendingAmount);
    formData.append('payment_method', payment.paymentMethod);
    formData.append('purchase_datetime', payment.purchaseDate);
    formData.append('payment_option', payment.paymentOption);
    formData.append('coupon_applied', coupon ? 'applied' : 'not');

    try {
      const res = await axios.post(ENDPOINT + "add-fee-payment.php", formData, securityHeaders);

      if (res.data.status === "success") {
        setSnackbarMessage("Payment Added");
        setSnackbar(true);
        HandleClose()
        dispatch({type:'FUNCTION_RELOAD',payload:'fee-payments'})
   
      } else {
        setSnackbarMessage("Something went wrong!");
        setSnackbar(true);
      }
    } catch (error) {
      console.error("Error adding payment:", error);
      setSnackbarMessage("Error adding payment. Please try again.");
      setSnackbar(true);
    }

    setLoading(false);
  };

  return (
    <div className="container">
      <form onSubmit={addPayment}>
        <div className="d-flex flex-wrap">
          <div className="form-group col-lg-6 col-12 p-1">
            <label>Student ID</label>
            <input type="text" name='studentId' className="form-control" value={payment.studentId} onChange={handlePaymentChange} />
          </div>

          <div className="form-group col-lg-6 col-12 p-1">
            <label>Paid Amount</label>
            <input type="text" className="form-control" value={payment.paidAmount} name='paidAmount' onChange={handlePaymentChange} />
          </div>

          <div className="form-group col-lg-6 col-12 p-1">
            <label>Pending Amount</label>
            <input type="text" className="form-control" value={payment.pendingAmount} name='pendingAmount' onChange={handlePaymentChange} />
          </div>

          <div className="form-group col-lg-6 col-12 p-1">
            <label>Payment Method</label>
            <select className="form-control" value={payment.paymentMethod} name='paymentMethod' onChange={handlePaymentChange}>
              <option value='phonePe'>PhonePe</option>
              <option value='GPay'>Google Pay</option>
              <option value='cash'>Cash</option>
            </select>
          </div>

          <div className="form-group col-lg-6 col-12 p-1">
            <label>Payment Option</label>
            <select className="form-control" value={payment.paymentOption} name='paymentOption' onChange={handlePaymentChange}>
              <option value=''>Full Payment</option>
              <option value='EMI'>EMI</option>
            </select>
          </div>

          <div className="form-group col-lg-6 col-12 p-1">
            <label>Purchase Datetime</label>
            <input type="datetime-local" className="form-control" name='purchaseDate' value={payment.purchaseDate} onChange={handlePaymentChange} />
          </div>

          <div className="form-group col-lg-6 col-12 p-1 mt-2 d-flex align-items-center">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={coupon} onChange={() => setCoupon(!coupon)} />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Coupon Applied
              </label>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary w-100 mt-3" disabled={loading}>
          {loading ? <CircularProgress size={20} /> : "Add Payment"}
        </button>
      </form>

      <Snackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

export default AddPayment;
