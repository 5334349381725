import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import dateFormat from 'dateformat';
import { AuthContext } from '../../context/AuthContext';
import DetectDevice from '../../context/DetectDevice';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DefaultToast from '../../components/toasts/DefaultToast';

const CreateCertificate = ({ permission, userdata, account }) => {
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'firstName', headerName: 'First name', width: 130 },
        { field: 'lastName', headerName: 'Last name', width: 130 },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            width: 90,
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    const [xdirection, setxdirection] = useState(0);
    const [idcode, setidcode] = useState(null);
    const [title, settitle] = useState(null);
    const [certificatetype, setcertificatetype] = useState("training");
    const [type, settype] = useState("internship");
    const [gender, setgender] = useState("male");
    const [startdate, setstartdate] = useState(null);
    const [enddate, setenddate] = useState(null);
    const [issueddate, setissueddate] = useState(null);
    const [department, setdepartment] = useState(null);
    const [certificate, setcertificate] = useState(null);
    const [id, setid] = useState(null);
    const [name, setname] = useState(null);
    const [certificates, setcertificates] = useState(null);
    const [certificateid, setcertificateid] = useState(null);
    const [category, setcategory] = useState("workshop");
    const [loadingCertificate,setLoading] = useState(false)
    const [savingCertificate,setSaving] = useState(false)
    const { user } = useContext(AuthContext);
    const device = DetectDevice();
    const [toast, settoast] = useState(false);

    const[toastdata,settoastdata] = useState(null)

    const GetCertificate = async ({ payload }) => {
        
        const data = new FormData();
        if(payload.saved===false)
        {
            setLoading(true)
        }
        if(payload.save)
        {
            setSaving(true)
        }
        data.append("start_date", dateFormat(startdate, "dS mmm yy"));
        data.append("end_date", dateFormat(enddate, "dS mmm yy"));
        data.append("internship_title", title);
        data.append("name", name);
        data.append("id", id);
        data.append('gender', gender);
        data.append("department", department);
        data.append("role", "Internship");
        data.append("certificate_code", certificate);
        data.append("category", type);

        if (payload.save) {
            data.append("save", true);
        }

        data.append("issued_date", dateFormat(issueddate, "dS mmm yyyy"));

        const res = await axios.post(ENDPOINT + "certificate-template.php", data, SecurityHeaders);
        if (res) {
            if(payload.saved===false)
                {
                    setLoading(false)
                }
            console.log(res)
            if (payload.save) {
                settoast(true);
                setSaving(false)
                if(res.data.status==="success")
                {
                    settoastdata({
                        title: 'Success',
                        message: "Certificate Saved Successfully"
                    });
                }
                else{
                    settoastdata({
                        title: 'Error',
                        message: res.data.message
                    });
                }
            } else {
                setcertificate(res.data);
                const certificateElement = document.getElementById("certificate");
                certificateElement.innerHTML = res.data;
            }
        }
    };

    const GetCertificates = async () => {
        const data = new FormData();
        data.append("session", user.SessionId);
        const res = await axios.post(ENDPOINT + "admin-get-certificates.php", data, SecurityHeaders);

        if (res && res.data.status === "success") {
            setcertificates(res.data.certificates);
        }
    };

    useEffect(() => {
        GetCertificates();
    }, []);

    return (
        <>
            <DefaultToast show={toast} toastdata={toastdata} ToastHandleClose={()=>{settoast(false)}}/>
            <div>
                <div className="container pt-4">
                    <div className="d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-between">
                        <div className="col-lg-6 border br-5 p-3 br-4">
                            <h4 className="mb-4">Certificate Preview</h4>
                            <span id="certificate" className={device === 'mobile' ? "min-height w-100" : "w-100"}></span>
                            <div className="my-3">
                                <input value={certificate} className="form-control" readOnly></input>
                            </div>
                        </div>

                        <div className="col-lg-5">
                            <h4>Certificate Info</h4>
                            <select value={type} onChange={(e) => settype(e.target.value)}>
                                <option value="internship">Internship Certificate</option>
                                <option value="training">Training Certificate</option>
                                <option value="workshop">Workshop Certificate</option>
                                <option value="appreciation">Appreciation Certificate</option>
                                <option value="best-team">Best Team</option>
                                <option value="best-student">Best student</option>
                            </select>

                            <div className="d-flex mt-4">
                                <input type="text" className="form-control" value={name} onChange={(e) => setname(e.target.value)} placeholder="full name"></input>
                            </div>
                            <div className="d-flex mt-2">
                                <input type="text" className="form-control" value={certificateid} onChange={(e) => setid(e.target.value)} placeholder="Certificate ID"></input>
                            </div>

                            <div className="d-flex mt-1">
                                <select value={gender} className='form-control my-1' onChange={(e) => setgender(e.target.value)}>
                                    <option>Select Gender</option>
                                    <option value="him">Male</option>
                                    <option value="her">Female</option>
                                </select>
                            </div>

                            {type === "internship" && (
                                <>
                                    <input className="form-control" placeholder="Internship title" value={title} onChange={(e) => settitle(e.target.value)} type="text"></input>
                                    <div className="d-flex mt-2">
                                        <div>
                                            <h6>Start Date</h6>
                                            <input className="form-control" value={startdate} onChange={(e) => setstartdate(e.target.value)} type="date"></input>
                                        </div>
                                        <div>
                                            <h6>End Date</h6>
                                            <input className="form-control" value={enddate} onChange={(e) => setenddate(e.target.value)} type="date"></input>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="mt-3">
                                <div>
                                    <h6>Issued Date</h6>
                                    <input className="form-control" value={issueddate} onChange={(e) => setissueddate(e.target.value)} type="date"></input>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary mt-3" onClick={() => GetCertificate({ payload: { saved: false } })}>{loadingCertificate?"Loading.........":"Load Certificate"}</button>
                                <button className="btn btn-primary mt-3" onClick={() => GetCertificate({ payload: { save: true } })}>{savingCertificate?"saving.....":"Save Certificate"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateCertificate;
