import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Chip } from '@material-ui/core'
import { Stack } from '@mui/material'
import { Dropdown } from 'react-bootstrap'
import { MoreVert } from '@material-ui/icons'
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../context/EndPoint'
import axios from 'axios'
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';


const InternshipItem = ({internship, userdata}) => {


    const {user}= useContext(AuthContext)
    const UpdateInternship = async ({status})=>{
        

        const formData = new FormData();
        
        
        //formData.append('whyus',document.getElementById('whyus').value)
        
        formData.append('session',user.SessionId);
        
        formData.append('internship_id',internship.internship_id);
        
        formData.append('status',status);
        

        
        const res = await axios.post(ENDPOINT+"admin-internship-actions.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
          if(res.data.status==="success"){

              
         }else{
         
         }
      
        }
        }
 const [submit,setsubmit] = useState(false)
    return (
        <InternshipItemStyled>



{/*INTERNSHIP ITEM START*/}
<div className="internship-item bg-white col-lg-12 col-md-12 col-sm-12">
<div className="d-flex justify-content-between w-100">
<h1 className="internship-title  sm-16">{internship.title}</h1>


<Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white text-dark options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu >
  <Dropdown.Item onClick={()=>UpdateInternship({internship_id:internship.id,status:"active"})} ><PublishIcon/>Publish</Dropdown.Item>
 
  <Dropdown.Item onClick={()=>UpdateInternship({internship_id:internship.id,status:"inactive"})} ><NoAccountsIcon className='fs-4'/>Deactivate</Dropdown.Item>
  
  <Dropdown.Item onClick={()=>UpdateInternship({internship_id:internship.id,status:"rejected"})} ><ThumbDownIcon className='fs-4'/>Reject </Dropdown.Item>
  

  <Dropdown.Item><DeleteForeverIcon/>Delete</Dropdown.Item>

    </Dropdown.Menu>
</Dropdown>
</div>
<Stack direction="row" spacing={1}>


{internship.ppo==="1"||1?<Chip label="Pre-placement"   className="internship-chip"  size="small" />
:null}

{internship.part_time==="1"||1?<Chip label="Part-time"   className="internship-chip"  size="small" />
:null}
{internship.remote==="1"||1?<Chip label="Online"   className="internship-chip"  size="small" />
:<Chip label="In-office"   className="internship-chip"  size="small" />
}
</Stack>
<div className="flex">

  

    <div className="short-info col-6 col-lg-3 col-md-4 col-xl-4">
   

    {internship.status?
    
    <div className="flex">
   <h6 className="mr-2">Status :  </h6>
   <p>{internship.status}</p>
  

   </div>
    
    :""}
   
     </div>

</div>
<hr className="mt-0 mb-0"/>
<div className="short-info">

<div className="internship-info sm-d-none">
<h6>Location</h6>
<p>{internship.mode==="online"?"Work from home":"In Office"}</p>
</div>


<div className="internship-info">
<h6>Stipend</h6>
<p> {internship.stipend==="0"?<p>Unpaid</p>:"₹ " +internship.stipend + "/month"}</p>
</div>



<div className="internship-info">
<h6>Duration</h6>
<p>{internship.duration>1?internship.duration+" months":internship.duration+" month"}

</p>
</div>

<div className="internship-info">
<h6>Apply by</h6>
<p>{internship.apply_by}</p>
</div>

</div>
<div className="bottom-flex">
<div className="flex">
<h6 className="mr-2">Openings : </h6>
<p>{internship.openings}</p>
</div>

<Link className="sm-14" to={"/internship/"+internship.id}>View Details</Link>



</div>

</div>
{/*INTERNSHIPS ITEM END*/}







        </InternshipItemStyled>
    )
}

export default InternshipItem

export const InternshipItemStyled = styled.div`

.internship-item{
    
    box-shadow:0px 0px 2px #d2cbcb;
    padding: 15px;
    margin-bottom: 30px;
}

.short-info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0px;
}
.internship-title{

    font-size:20px;
}
.internship-tags{
    display: flex;
   
}
.bottom-flex{
    display: flex;
    justify-content: space-between;
    text-decoration: none;
}
a:hover{
    text-decoration:none;
}

`
