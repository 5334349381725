import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const ExpenseRow = ({item}) => {
    const [expand,setexpand] = useState(false)
  return (
<>
<TableRow onClick={()=>setexpand(!expand)}
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
               <TableCell align="">{item.title}</TableCell>
             
              <TableCell align="">{item.category}</TableCell>
              <TableCell align="">{item.amount}</TableCell>
              <TableCell align="">{item.status}</TableCell>
            </TableRow>
            {expand?<>
            
            <div className="p-3">

                <p>{item.description}</p>
            </div>
            </>:null}

</>  
  )
}

export default ExpenseRow