import { Avatar } from '@mui/material'
import React, { useContext, useState } from 'react'
import DeleteModal from '../../components/modals/DeleteModal'
import { MoreVert } from '@material-ui/icons'
import { Dropdown } from 'react-bootstrap'
import DeleteUser from '../../actions/user/DeleteUser'
import { AuthContext } from '../../context/AuthContext'
import SendOTP from '../../actions/user/SendOTP'
import ChangeAccountStatus from '../../actions/user/ChangeAccountStatus'
import DefaultModal from '../../components/modals/DefaultModal'
import UserActivity from './UserActivity'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';


const UserItem = ({item,permission}) => {
const {user} = useContext(AuthContext)
    const [deleteuser,setdeleteuser] = useState(false)
    const [accountstatus,setaccountstatus] = useState("pending")
    const CloseDelete =()=>{

        setdeleteuser(false)
    }

    const HandleDelete = ()=>{
setdeleteuser(false)
    }

    const Delete = async ()=>{


        const res = await DeleteUser({
            payload:{
                SessionId:user.SessionId,
                user_id:item.user_id
            }
        })
    }


    const ResendOTP = async ()=>{


        const res = await SendOTP({  payload:{
            SessionId:user.SessionId,
            user_email:item.user_email
        }})
    }

    const ChangeAccountActiveStatus = async({status})=>{

        const res = await ChangeAccountStatus({
            payload:{
                SessionId:user.SessionId,
                user_id:item.user_id,
                status:status
            }
        })
    }

    const CloseUserActivity = ()=>{
        setactivity(false)
    }
    const [activity,setactivity] = useState(false)
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 800,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
  return (
<>

<Modal
  open={activity}
  onClose={CloseUserActivity}
>
  <Box sx={style}>
  <UserActivity userdata={item} HandleClose={CloseUserActivity}/>
  </Box>
</Modal>

<DeleteModal show={deleteuser} HandleClose={CloseDelete} HandleDelete={HandleDelete}/>
<tr>
                            <th scope="row">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="chk_child"
                                  defaultValue="option1"
                                />
                              </div>
                            </th>
                            <td className="id" style={{ display: "none" }}>
                              <a
                                href="javascript:void(0);"
                                className="fw-medium link-primary"
                              >
                     
                              </a>
                            </td>
                            <td className="customer_name d-flex align-items-center">
                            <Avatar src={item.profile_pic?item.profile_pic:null}/>
                              <div className='ms-2'>
                              <h6 className='mb-0 pb-0'>{item.first_name+" "+item.last_name}</h6>
                                <p className='mb-0'>user id :{item.user_id}</p>
                              </div>
                                </td>
                            <td className="email">{item.email}</td>
                            <td className="phone">{item.phone_number}</td>
                            <td className="date">{item.create_datetime}</td>
                            <td className="status">
                              <span className="badge bg-success-subtle text-success text-uppercase">
                                {item.status}
                              </span>
                            </td>
                            <td>
                              <ul className="list-inline hstack gap-2 mb-0">
                                <li
                                  className="list-inline-item edit"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  data-bs-placement="top"
                                  title="Edit"
                                >
                                  <a
                                    href="#showModal"
                                    data-bs-toggle="modal"
                                    className="text-primary d-inline-block edit-item-btn"
                                  >
                                    <i className="ri-pencil-fill fs-16" />
                                  </a>
                                </li>
                                <li
                                  className="list-inline-item"
                                onClick={()=>setdeleteuser(true)}
                                  title="Remove"
                                >
                                  <a
                                    className="text-danger d-inline-block remove-item-btn"
                                
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16" />
                                  </a>
                                </li>
                              </ul>
                            </td>

                            <td>

                            <Dropdown className="options-dropdown">
        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
          <MoreVert className="text-dark" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {item.status === "pending" ||
          item.status === "inactive" ? (
            <>
              <Dropdown.Item
                onClick={() =>
                  ChangeAccountActiveStatus({status:"active"})
                }
              >
                Activate Account
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  ResendOTP()
                }
              >
                Send Verification Code
              </Dropdown.Item>
            </>
          ) : (
            <>
              {item.status === "active" ? (
                <>
                  <Dropdown.Item className='d-flex'
                    onClick={() =>
                        ChangeAccountActiveStatus({status:"pending"})
                      }
                  >
                    <PendingActionsIcon className='fs-5 text-primary me-2'/>
                   <p> Mark Pending Verification</p>
                  </Dropdown.Item>

                  <Dropdown.Item className='d-flex'
                  onClick={() =>
                    ChangeAccountActiveStatus({status:"inactive"})
                
                  }
                  >
                    <PersonOffIcon className='text-danger me-2 fs-5'/>
                    <p>Deactivate Account</p>
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <Dropdown.Item className='d-flex'
            onClick={() =>setactivity(true)}
          >
            <PersonIcon className='fs-5 me-2' />
          <p> Show User Activity</p>
          </Dropdown.Item>
          {parseInt(permission.delete_user) === 1 ? (
            <>
              <Dropdown.Item
                onClick={()=>setdeleteuser(true)}
              >
                <PersonRemoveIcon className='fs-5 me-2' />
                Delete Users
              </Dropdown.Item>
            </>
          ) : (
            <></>
          )}
        </Dropdown.Menu>
      </Dropdown>
                            </td>
                          </tr>
</>
  )
}

export default UserItem