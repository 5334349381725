import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

const CourseItem = ({item}) => {
  const dispatch = useDispatch()
  return (
    <>
       <div className="card course-item card-height-100">
                <div className="card-body">
                    <img   src={item.course_cover_url} className='w-100 courseimage'></img>
                  <div className="d-flex flex-column h-100">
                   
                    <div className="d-flex mb-2 mt-2">
                     
                      <div className="flex-grow-1">
                        <h4 className="mb-1 fs-15">
                          <a
                            href="apps-projects-overview.html"
                            className="text-body"
                          >
                            {item.course_title}
                          </a>
                        </h4>
                      
                      </div>
                    </div>
                    <div className="-auto">
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                          <div>Seats Left</div>
                        </div>
                        <div className="flex-shrink-0">
                          <div>
                            <i className="ri-list-check align-bottom me-1 text-muted" />{" "}
                            18/50
                          </div>
                        </div>
                      </div>
                      <div className="progress progress-sm animated-progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          aria-valuenow={34}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "34%" }}
                        />
                        {/* /.progress-bar */}
                      </div>
                      {/* /.progress */}
                    </div>
                  </div>
                </div>
                {/* end card body */}

                 <div className='d-flex  px-3 text-muted'>
                            {item.mode}
                          </div>
                <div className="card-footer bg-transparent border-top-dashed py-2">
              
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <div className="avatar-group">
                        <a
                          href="javascript: void(0);"
                          className="avatar-group-item shadow"
                          data-bs-toggle="tooltip"
                          data-bs-trigger="hover"
                          data-bs-placement="top"
                          aria-label="Darline Williams"
                          data-bs-original-title="Darline Williams"
                        >
                         
                          <div className="avatar-xxs">
                            <img
                              src="assets/images/users/avatar-2.jpg"
                              alt=""
                              className="rounded-circle img-fluid"
                            />
                          </div>
                        </a>
                        <a
                          href="javascript: void(0);"
                          className="avatar-group-item shadow"
                          data-bs-toggle="tooltip"
                          data-bs-trigger="hover"
                          data-bs-placement="top"
                          data-bs-original-title="Add Members"
                        >
                          <div className="avatar-xxs">
                            <div className="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                              +
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <Link onClick={()=>{dispatch({type:'ADD_COURSE_DETAILS',payload:{
                        image:item.course_cover_url,
                        name:item.course_title,
                        mode:item.mode
                      }})}} to={"/course-management/"+item.course_url+"/"+item.course_id} className="text-muted">
                        <i className="ri-calendar-event-fill me-1 align-bottom" />{" "}
                      Manage Course
                      </Link>
                    </div>
                  </div>
                </div>
                {/* end card footer */}
              </div>
    
    </>
  )
}

export default CourseItem