import React, { useEffect, useState } from 'react'
import DemoData from '../../data/DemoData'
import CourseItem from './CourseItem'
import { Link, useParams } from 'react-router-dom';
import ENDPOINT from '../../context/EndPoint';
import Localbase from 'localbase';
import axios from 'axios';
import { useActionsContext } from '../../context/ActionsContext';

const CoursesList = () => {

  let db = new Localbase("db")
  
  const params = useParams();
  const [mode, setmode] = useState("all");

  const [show, setShow] = useState(false);
  const [currentbatch, setcurrentbatch] = useState(null);
  const [courseurl, setcourseurl] = useState(null);
  const handleClose = () => setShow(false);
  const addAssignment = (course_url, batch_id) => {
    setcourseurl(course_url);
    setcurrentbatch(batch_id);
    setShow(true);
  };
  const [studentslist, setstudentslist] = useState(false);

  const [courses, setcourses] = useState(null);

  useEffect(() => {
    getcourses({ cat: "all" });
    // getofflinedata().then().catch(()=>{
    //   getcourses({ cat: "all" })
    // })
  }, [mode]);


  const getcourses = async({cat,query}) =>{
    const data=new FormData()
    data.append("mode",cat)
    const res= await axios.post("https://internalapi.skillsuprise.com/admin-get-courses.php",data,{headers:{'content-type': 'multipart/form-data'}})
    if(res.status===200){
        setcourses(res.data)
        setInitialData(res.data)
        setResponse(res.data)
        db.collection("paths").add({all_courses:res.data},"all_courses")
    }
  }

  const getofflinedata=()=>{
      db.collection("paths").doc("all_courses").get().then((data)=>{
        setcourses(data.all_courses)
        setInitialData(data.all_courses)
        setResponse(data.all_courses)
      }).catch(()=>{
        getcourses({cat:'all'})
      })
  }
  useEffect(()=>{
    getofflinedata()
  },[])

//   const getcourses = async ({ cat, query }) => {
// try{
//   var LINK = null;
//   if (cat) {
//     LINK = `${ENDPOINT}admin-get-courses.php?mode=${cat}`;
//   } else {
//     LINK = `${ENDPOINT}admin-get-courses.php?mode=all`;
//   }
//   fetch(LINK)
//     .then((r) => r.json())
//     .then((result) => {
//       setcourses(result)
//       setInitialData(result);
//       setResponse(result);
//       db.collection("paths").add({courses:result},"courses")
//     });
// }
// catch(err){

// }
//   };

//   const Getofflinedata = () => {
//       db.collection("paths").doc("courses").get().then((data)=>{
//       setcourses(data.courses)
//       setInitialData(data.courses)
//       setResponse(data.courses)
//     }).catch(()=>{
//       getcourses({ cat: "all" })
//     })
//   }
//   useEffect(()=>{
//     Getofflinedata()
//   },[])

  const values = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"];
  const [fullscreen, setFullscreen] = useState(true);
  const [currentcourse, setcurrentcourse] = useState(null);
  const displayStudents = (course) => {
    setcurrentcourse(course);
    setstudentslist(true);
  };

  const [editoverview, seteditoverview] = useState(false);

  const [courseid, setcourseid] = useState(null);

  const editCourseOverview = ({ courseurl, course_batch, course_id }) => {
    setcurrentbatch(course_batch);
    setcourseurl(courseurl);
    setcourseid(course_id);
    seteditoverview(true);
  };

  const [query, setquery] = useState(null);
  const [category, setcategory] = useState("all");

  const [initialData, setInitialData] = useState("");

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");
  const {searchquery} = useActionsContext()
  useEffect(()=>{
    handleSearch({term:searchquery})
  },[searchquery])
  const handleSearch = ({term}) => {
    if(!term)
    {
      setResponse(initialData);
      setSearchTerm("")
      return
    }
   if(term)
   {
    const filteredData = initialData.filter((item) => {
      return (
        item.course_id.toLowerCase().includes(term) ||
        item.course_title.toLowerCase().includes(term) ||
        item.mode.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
   }


  };

  return (
   <>
   
   <div className="container-fluid">
          <div className="row g-4 mb-3 d-flex justify-content-end">

              {/* Previous search filters */}
            {/* <div className="col-sm-auto">
              <div>
                <Link to="/add-course" className="btn btn-success">
                  <i className="ri-add-line align-bottom me-1" /> Add New Course
                </Link>
              </div>
            </div> */}
            {/* <div className="col-sm d-none d-sm-block d-md-block d-lg-block d-xl-block">
              <div className="d-flex justify-content-sm align-items-center gap-3">
                <div className="search-box ms-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}/>
                  <i className="ri-search-line search-icon" />
                </div>
                  <div className='col-1 '>
                    <select
                      className="form-control w-md choices__input p-2 "
                      data-choices=""
                      data-choices-search-false=""
                      hidden=""
                      tabIndex={-1}
                      data-choice="active"
                      onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}>
                       <option value="live" data-custom-properties="[object Object]">Course Mode</option>
                      <option value="live" data-custom-properties="[object Object]" >Live</option>
                      <option value="offline" data-custom-properties="[object Object]">Offline</option>
                      <option
                        value="specialization"
                        data-custom-properties="[object Object]"
                      >
                      Specialization
                      </option> 
                      <option
                        value="selfpaced"
                        data-custom-properties="[object Object]"
                      >
                     selfpaced
                      </option>
                      <option
                        value="free"
                        data-custom-properties="[object Object]"
                      >
                     Free
                      </option>
                    </select>
                  </div>
              </div>
            </div> */}
            <div className="col-sm-auto">
              <div>
                <Link to="/add-course" className="btn btn-success">
                  <i className="ri-add-line align-bottom me-1" /> Add New Course
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
  


{response?
<>
{response.map((item)=>(

<>
<div className="col-xxl-4 col-sm-6 project-card">
       <CourseItem item={item}/>
       {/* end card */}
     </div>
</>
))}

</>:
<>


</>}
          </div>
          {/* end row */}
      
        </div>

   </>
  )
}

export default CoursesList