import { Avatar } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

import dateFormat from "dateformat";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { AuthContext } from "../../context/AuthContext";
import ENDPOINT from "../../context/EndPoint";
import SecurityHeaders from "../../context/SecurityHeaders";
import { ChevronDown } from "react-bootstrap-icons";
import { connect } from "react-redux";
import demoProfile from '../../assets/images/users/avatar-2.jpg'
const WhatsAppSupport = ({value,dispatch}) => {
  const { user } = useContext(AuthContext);

  const params = useParams();
  const phone_number = params.phone_number;
  const GetChats = async () => {
    setloading(true);
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("phone", phone_number);
    const res = await axios.post(
      ENDPOINT + "whatsapp-chats.php",
      data,
      SecurityHeaders
    );

    if (res) {
      setloading(false);
      if (res.data.status === "success") {
        setchats(res.data.chat_heads);
        setmessages(res.data.messages);
      }
      //  // console.log(JSON.stringify(res.data));
    }
  };
  useEffect(() => {
    GetChats();
  }, [phone_number]);

  /*
{"messages":[{"from":"919490174876","id":"ABEGkZSQF0h2AhA9J7p8OWjSi0ccbx8djb49","image":{"id":"b6952a89-f771-4c1c-8d23-e286fa29baf3","mime_type":"image/jpeg","sha256":"2e9b11a3915c3a199c93e5aaba25af74409c58160de95da589508bdad69c553d"},"timestamp":"1663580433","type":"image"}]}

*/
  const [chats, setchats] = useState(null);
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState(null);
  const [messages, setmessages] = useState(null);
  const SendMessage = async () => {
    if (message) {
      const data = new FormData();
      data.append("phone", phone_number);
      data.append("session", user.SessionId);
      data.append("message", message);
      const res = await axios.post(
        "https://awetecks.com/test3.php",
        data,
        SecurityHeaders
      );
      setmessage("");
      GetChats();
      setmessage(null);
      SpeechRecognition.startListening();
      if (res) {
        //  // console.log("send " + JSON.stringify(res));
      }
    } else {
    }
  };

  //ai section

  const [script, setscript] = useState(null);

  const { transcript, listening } = useSpeechRecognition();

  useEffect(() => {
    if (transcript !== null) {
      setscript(transcript);
    }
  }, [transcript]);

  const [automatic, setautomatic] = useState(false);

  useEffect(() => {
    setmessage(message + " " + transcript);

    if (!listening && transcript !== null && message !== null) {
      var input = message.toLowerCase();
      var text = null;

      if (input) {
        text = input.replace(" ", "");
        //setmessage(text.replace("send",""))

        if (text.includes("send")) {
          SendMessage();
        }
      }
    }
    if (automatic) {
      SpeechRecognition.startListening();
    }
  }, [transcript]);

  useEffect(() => {
    SpeechRecognition.startListening();
  }, [window.location.href]);
  const [meeting, setmeeting] = useState(false);

  //ai section


  // get student list start


  const [students,setStudents] = useState(null);
  




  const GetStudents = async () => {

  try{
    const data = new FormData();
    data.append('session',user.SessionId)
    data.append('mode','all')

    const res = await axios.post(ENDPOINT+"admin-get-students.php",data,SecurityHeaders);

  if(res.data){
    if(res.data.status === "success"){
      setStudents(res.data.data)
  }
  }

  }
  catch(error){
     // console.log("get students error",error)
  }}
 

  useEffect(() => {
    GetStudents()
  }, [])

  const UpdateUserDetails = ({name,pic}) => {
    let str = name;
    
    // Check if str is truthy and contains double quotes
    
      let result = str.replace(/"/g, ''); // Replace all double quotes with an empty string
      dispatch({ type: 'UPDATEPROFILE', payload: pic });
      dispatch({ type: 'UPDATENAME', payload: result });
    
  }
  
  //   // console.log(value)
  
  return (
    <>
      <div>
        <div className="d-none">
          <div className="col-lg-5 overflow-y-scroll min-vh-90 vh-90">
            {chats ? (
              <>
                {chats.map((chat, index) => (
                  <>
                    <Link
                      to={"/whatsapp/" + chat.from}
                      className={"d-flex click mt-2 " + chat.from}
                    >
                      <Avatar src={chat.profile_pic} />
                      <div className="ml-2">
                        <h5>{chat.from ? chat.username : null}</h5>
                        <p>{chat.from}</p>
                      </div>
                    </Link>
                    <hr />
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="col-lg-6 vh-75 container-fluid ">
            <div className="whatsapp-messages vh-75 overflow-y-scroll">
              {messages ? (
                <>
                  {messages.map((item) => (
                    <>
                      <div
                        className={
                          parseInt(item.team) === 1
                            ? "conversation self "
                            : "conversation incoming"
                        }
                      >
                        <div className="message">
                          <p>{item.message}</p>

                          <p className="extra-small">
                            {dateFormat(item.datetime, "dS hh:mm:tt")}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="shadow">
              <div className="">
                {automatic ? (
                  <>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        setautomatic(false);
                        SpeechRecognition.startListening();
                      }}
                    >
                      AI Off
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-sm btn-success"
                      onClick={() => {
                        setautomatic(true);
                        SpeechRecognition.startListening();
                      }}
                    >
                      AI On
                    </button>
                  </>
                )}
                <div className="pl-2 pr-2 pb-0">
                  <p className="extra-small text-dark">{script}</p>
                </div>
              </div>

              <hr />
              <div className="d-flex ">
                <textarea
                  value={message}
                  className="form-control wa-input"
                  onChange={(e) => setmessage(e.target.value)}
                ></textarea>
                <button
                  onClick={() => SendMessage()}
                  className="btn btn-secondary"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
          <div className="chat-leftsidebar">
            <div className="px-4 pt-4 mb-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h5 className="mb-4">WhatsApp Chats</h5>
                </div>
                <div className="flex-shrink-0">
                  <div
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="bottom"
                    aria-label="Add Contact"
                    data-bs-original-title="Add Contact"
                  >
                    {/* Button trigger modal */}
                    <button
                      type="button"
                      className="btn btn-soft-success btn-sm shadow-none"
                    >
                      <i className="ri-add-line align-bottom" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="search-box">
                <input
                  type="text"
                  className="form-control bg-light border-light"
                  placeholder="Search here..."
                />
                <i className="ri-search-2-line search-icon" />
              </div>
            </div>{" "}
            {/* .p-4 */}
            <ul
              className="nav nav-tabs nav-tabs-custom nav-success nav-justified"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#chats"
                  role="tab"
                  aria-selected="true"
                >
                  Chats
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#contacts"
                  role="tab"
                  aria-selected="true"
                  tabIndex={-1}
                >
                  Students
                </a>
              </li>
            </ul>
            <div className="tab-content text-muted">
              <div className="tab-pane active" id="chats" role="tabpanel">
                <div
                  className="chat-room-list pt-3 simplebar-scrollable-y"
                  data-simplebar="init"
                >
                  <div
                    className="simplebar-wrapper"
                    style={{ margin: "-16px 0px 0px" }}
                  >
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer" />
                    </div>
                    <div className="simplebar-mask">
                      <div
                        className="simplebar-offset"
                        style={{ right: 0, bottom: 0 }}
                      >
                        <div
                          className="simplebar-content-wrapper"
                          tabIndex={0}
                          role="region"
                          aria-label="scrollable content"
                          style={{
                            height: "auto",
                            overflow: "hidden scroll",
                          }}
                        >
                          <div
                            className="simplebar-content"
                            style={{ padding: "16px 0px 0px" }}
                          >
                            
                            <div className="chat-message-list ">
                              <ul
                                className="list-unstyled chat-list chat-user-list "
                                id="userList"
                              >
                                {chats ? (
                                  <>
                                    {chats.map((chat, index) => (
                                      <>
                                        <li
                                        onClick={()=>UpdateUserDetails({name:chat.from ? chat.username : null,pic:chat.profile_pic})}
                                        key={index}
                                          id="contact-id-1"
                                          data-name="direct-message"
                                          className=" "
                                        >
                                        <Link to={"/whatsapp/"+chat.from} >
                                        
                                        {" "}
                                            <div className="d-flex align-items-center">
                                              {" "}
                                              <div className="flex-shrink-0  align-self-center me-2 ms-0">
                                                {" "}
                                              
                                                  {" "}
                                                  <Avatar src={chat.profile_pic} />
                                                 
                                              
                                              </div>{" "}
                                              <div className="flex-grow-1 overflow-hidden">
                                                {" "}
                                                <p className="text-truncate mb-0">
                                                {chat.from ? chat.username : null}
                                                </p>{" "}
                                                <p className="text-truncate text-secondary mb-0">
                                                {chat.from}
                                                </p>{" "}
                                              </div>{" "}
                                            </div>{" "}</Link>
                                        </li>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ul>
                            </div>
                         
                          
                            {/* End chat-message-list */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="simplebar-placeholder"
                      style={{ width: 300, height: 651 }}
                    />
                  </div>
                  <div
                    className="simplebar-track simplebar-horizontal"
                    style={{ visibility: "hidden" }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{ width: 0, display: "none" }}
                    />
                  </div>
                  <div
                    className="simplebar-track simplebar-vertical"
                    style={{ visibility: "visible" }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{
                        height: 289,
                        transform: "translate3d(0px, 0px, 0px)",
                        display: "block",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="contacts" role="tabpanel">
              <div
                  className="chat-room-list pt-3 simplebar-scrollable-y"
                  data-simplebar="init"
                >
                  <div
                    className="simplebar-wrapper"
                    style={{ margin: "-16px 0px 0px" }}
                  >
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer" />
                    </div>
                    <div className="simplebar-mask">
                      <div
                        className="simplebar-offset"
                        style={{ right: 0, bottom: 0 }}
                      >
                        <div
                          className="simplebar-content-wrapper"
                          tabIndex={0}
                          role="region"
                          aria-label="scrollable content"
                          style={{
                            height: "auto",
                            overflow: "hidden scroll",
                          }}
                        >
                          <div
                            className="simplebar-content"
                            style={{ padding: "16px 0px 0px" }}
                          >
                            
                            <div className="chat-message-list ">
                              <ul
                                className="list-unstyled chat-list chat-user-list "
                                id="userList"
                              >
                                {students ? (
                                  <>
                                    {students.map((chat, index) => (
                                      <>
                                        <li
                                        onClick={()=>UpdateUserDetails({name:chat.first_name+" "+ chat.last_name,pic:chat.profile_pic})}
                                        key={index}
                                          id="contact-id-1"
                                          data-name="direct-message"
                                          className=" "
                                        >
                                        <Link to={"/whatsapp/"+chat.phone_number} >
                                        
                                        {" "}
                                            <div className="d-flex align-items-center">
                                              {" "}
                                              <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                {" "}
                                              
                                                  {" "}
                                                  <Avatar src={chat.profile_pic} />
                                                  <span className="user-status" />{" "}
                                                
                                              </div>{" "}
                                              <div className="flex-grow-1 overflow-hidden">
                                                {" "}
                                                <p className="text-truncate mb-0">
                                                {chat.first_name+" "+ chat.last_name}
                                                </p>{" "}
                                                <p className="text-truncate text-secondary mb-0">
                                                {chat.phone_number}
                                                </p>{" "}
                                              </div>{" "}
                                            </div>{" "}</Link>
                                        </li>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ul>
                            </div>
                         
                          
                            {/* End chat-message-list */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="simplebar-placeholder"
                      style={{ width: 300, height: 651 }}
                    />
                  </div>
                  <div
                    className="simplebar-track simplebar-horizontal"
                    style={{ visibility: "hidden" }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{ width: 0, display: "none" }}
                    />
                  </div>
                  <div
                    className="simplebar-track simplebar-vertical"
                    style={{ visibility: "visible" }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{
                        height: 289,
                        transform: "translate3d(0px, 0px, 0px)",
                        display: "block",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* end tab contact */}
          </div>
          {/* end chat leftsidebar */}
          {/* Start User chat */}
          <div className="user-chat w-100 overflow-hidden">
            <div className="chat-content d-lg-flex">
              {/* start chat conversation section */}
              <div className="w-100 overflow-hidden position-relative">
                {/* conversation user */}
                <div className="position-relative">
                  <div
                    className="position-relative"
                    id="users-chat"
                    style={{ display: "block" }}
                  >
                    <div className="p-3 user-chat-topbar">
                      <div className="row align-items-center">
                        <div className="col-sm-4 col-8">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 d-block d-lg-none me-3">
                              <a
                                href="javascript: void(0);"
                                className="user-chat-remove fs-18 p-1"
                              >
                                <i className="ri-arrow-left-s-line align-bottom" />
                              </a>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                  <img
                                    src={value.profile? value.profile:demoProfile}
                                    className="rounded-circle avatar-xs"
                                    alt=""
                                  />
                                  <span className="user-status" />
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <h5 className="text-truncate mb-0 fs-16">
                                    <a
                                      className="text-reset username"
                                      data-bs-toggle="offcanvas"
                                      href="#userProfileCanvasExample"
                                      aria-controls="userProfileCanvasExample"
                                    >
                                      {value.userName}
                                    </a>
                                  </h5>
                                  <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                    <small>Online</small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 col-4">
                          <ul className="list-inline user-chat-nav text-end mb-0">
                            <li className="list-inline-item m-0">
                              <div className="dropdown">
                                <button
                                  className="btn btn-ghost-secondary btn-icon shadow-none"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-search icon-sm"
                                  >
                                    <circle cx={11} cy={11} r={8} />
                                    <line
                                      x1={21}
                                      y1={21}
                                      x2="16.65"
                                      y2="16.65"
                                    />
                                  </svg>
                                </button>
                                <div className="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                                  <div className="p-2">
                                    <div className="search-box">
                                      <input
                                        type="text"
                                        className="form-control bg-light border-light"
                                        placeholder="Search here..."
                                        onkeyup="searchMessages()"
                                        id="searchMessage"
                                      />
                                      <i className="ri-search-2-line search-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="list-inline-item d-none d-lg-inline-block m-0">
                              <button
                                type="button"
                                className="btn btn-ghost-secondary btn-icon shadow-none"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#userProfileCanvasExample"
                                aria-controls="userProfileCanvasExample"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-info icon-sm"
                                >
                                  <circle cx={12} cy={12} r={10} />
                                  <line x1={12} y1={16} x2={12} y2={12} />
                                  <line x1={12} y1={8} x2="12.01" y2={8} />
                                </svg>
                              </button>
                            </li>
                            <li className="list-inline-item m-0">
                              <div className="dropdown">
                                <button
                                  className="btn btn-ghost-secondary btn-icon"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-more-vertical icon-sm"
                                  >
                                    <circle cx={12} cy={12} r={1} />
                                    <circle cx={12} cy={5} r={1} />
                                    <circle cx={12} cy={19} r={1} />
                                  </svg>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a
                                    className="dropdown-item d-block d-lg-none user-profile-show"
                                    href="#"
                                  >
                                    <i className="ri-user-2-fill align-bottom text-muted me-2" />{" "}
                                    View Profile
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-inbox-archive-line align-bottom text-muted me-2" />{" "}
                                    Archive
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-mic-off-line align-bottom text-muted me-2" />{" "}
                                    Muted
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-delete-bin-5-line align-bottom text-muted me-2" />{" "}
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* end chat user head */}
                    <div
                      className="chat-conversation p-3 p-lg-4 simplebar-scrollable-y"
                      id="chat-conversation"
                      data-simplebar="init"
                    >
                      <div
                        className="simplebar-wrapper"
                        style={{ margin: "-24px" }}
                      >
                        <div className="simplebar-height-auto-observer-wrapper">
                          <div className="simplebar-height-auto-observer" />
                        </div>
                        <div className="simplebar-mask">
                          <div
                            className="simplebar-offset"
                            style={{ right: 0, bottom: 0 }}
                          >
                            <div
                              className="simplebar-content-wrapper"
                              tabIndex={0}
                              role="region"
                              aria-label="scrollable content"
                              style={{
                                height: "100%",
                                overflow: "hidden scroll",
                              }}
                            >
                              <div
                                className="simplebar-content"
                                style={{ padding: 24 }}
                              >
                                <div id="elmLoader" />
                                <ul
                                  className="list-unstyled position-relative chat-conversation-list"
                                  id="users-conversation"
                                >


                                  <div className="position-absolute scroll-down">
<ChevronDown/>
                                  </div>

{messages ? (
                <>
                  {messages.map((item) => (
                    <>



                      {
                          parseInt(item.team) === 1
                            ?  
                             <li ref={item.id} className="chat-list right" id={5}>
                            {" "}
                            <div className="conversation-list">
                              <div className="user-chat-content">
                                <div className="ctext-wrap">
                                  <div
                                    className="ctext-wrap-content"
                                    id={5}
                                  >
                                    <p className="mb-0 ctext-content">
                                    {item.message}
                                    </p>
                                  </div>
                                  <div className="dropdown align-self-start message-box-drop">
                                    {" "}
                                    <a
                                      className="dropdown-toggle"
                                      href="#"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {" "}
                                      <i className="ri-more-2-fill" />{" "}
                                    </a>{" "}
                                    <div className="dropdown-menu">
                                      {" "}
                                      <a
                                        className="dropdown-item reply-message"
                                        href="#"
                                      >
                                        <i className="ri-reply-line me-2 text-muted align-bottom" />
                                        Reply
                                      </a>{" "}
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                      >
                                        <i className="ri-share-line me-2 text-muted align-bottom" />
                                        Forward
                                      </a>{" "}
                                      <a
                                        className="dropdown-item copy-message"
                                        href="#"
                                      >
                                        <i className="ri-file-copy-line me-2 text-muted align-bottom" />
                                        Copy
                                      </a>{" "}
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                      >
                                        <i className="ri-bookmark-line me-2 text-muted align-bottom" />
                                        Bookmark
                                      </a>{" "}
                                      <a
                                        className="dropdown-item delete-item"
                                        href="#"
                                      >
                                        <i className="ri-delete-bin-5-line me-2 text-muted align-bottom" />
                                        Delete
                                      </a>{" "}
                                    </div>{" "}
                                  </div>
                                </div>
                                <div className="conversation-name">
                                  <span className="d-none name">
                                    Frank Thomas
                                  </span>
                                  <small className="text-muted time">
                                  {dateFormat(item.datetime, "dS mm yy hh:mm:tt")}
                                  </small>{" "}
                                  <span className="text-success check-message-icon">
                                    <i className="bx bx-check-double" />
                                  </span>
                                </div>
                              </div>{" "}
                            </div>{" "}
                          </li>
                            :
                             <li ref={item.id} className="chat-list left" id={1}>
                            {" "}
                            <div className="conversation-list">
                              <div className="chat-avatar">
                                <img
                                  src={value.profile}
                                  alt=""
                                />
                              </div>
                              <div className="user-chat-content">
                                <div className="ctext-wrap">
                                  <div
                                    className="ctext-wrap-content"
                                    id={1}
                                  >
                                    <p className="mb-0 ctext-content">
                                    {item.message}
                                    </p>
                                  </div>
                                  <div className="dropdown align-self-start message-box-drop">
                                    {" "}
                                    <a
                                      className="dropdown-toggle"
                                      href="#"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {" "}
                                      <i className="ri-more-2-fill" />{" "}
                                    </a>{" "}
                                    <div className="dropdown-menu">
                                      {" "}
                                      <a
                                        className="dropdown-item reply-message"
                                        href="#"
                                      >
                                        <i className="ri-reply-line me-2 text-muted align-bottom" />
                                        Reply
                                      </a>{" "}
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                      >
                                        <i className="ri-share-line me-2 text-muted align-bottom" />
                                        Forward
                                      </a>{" "}
                                      <a
                                        className="dropdown-item copy-message"
                                        href="#"
                                      >
                                        <i className="ri-file-copy-line me-2 text-muted align-bottom" />
                                        Copy
                                      </a>{" "}
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                      >
                                        <i className="ri-bookmark-line me-2 text-muted align-bottom" />
                                        Bookmark
                                      </a>{" "}
                                      <a
                                        className="dropdown-item delete-item"
                                        href="#"
                                      >
                                        <i className="ri-delete-bin-5-line me-2 text-muted align-bottom" />
                                        Delete
                                      </a>{" "}
                                    </div>{" "}
                                  </div>
                                </div>
                                <div className="conversation-name">
                                  <span className="d-none name">
                                    Lisa Parker
                                  </span>
                                  <small className="text-muted time">
                                  {dateFormat(item.datetime, "dS hh:mm:tt")}
                                  </small>{" "}
                                  <span className="text-success check-message-icon">
                                    <i className="bx bx-check-double" />
                                  </span>
                                </div>
                              </div>{" "}
                            </div>{" "}
                          </li>
                        }
                                  
                                
                               
                                
                                  </>
                                  ))}
                </>
              ) : (
                <></>
              )}
                               
                                </ul>
                                {/* end chat-conversation-list */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="simplebar-placeholder"
                          style={{ width: 519, height: 700 }}
                        />
                      </div>
                      <div
                        className="simplebar-track simplebar-horizontal"
                        style={{ visibility: "hidden" }}
                      >
                        <div
                          className="simplebar-scrollbar"
                          style={{ width: 0, display: "none" }}
                        />
                      </div>
                      <div
                        className="simplebar-track simplebar-vertical"
                        style={{ visibility: "visible" }}
                      >
                        <div
                          className="simplebar-scrollbar"
                          style={{
                            height: 265,
                            display: "block",
                            transform: "translate3d(0px, 0px, 0px)",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show "
                      id="copyClipBoard"
                      role="alert"
                    >
                      Message copied
                    </div>
                  </div>
                  <div
                    className="position-relative"
                    id="channel-chat"
                    style={{ display: "none" }}
                  >
                    <div className="p-3 user-chat-topbar">
                      <div className="row align-items-center">
                        <div className="col-sm-4 col-8">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 d-block d-lg-none me-3">
                              <a
                                href="javascript: void(0);"
                                className="user-chat-remove fs-18 p-1"
                              >
                                <i className="ri-arrow-left-s-line align-bottom" />
                              </a>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                  <img
                                    src="assets/images/users/avatar-2.jpg"
                                    className="rounded-circle avatar-xs"
                                    alt=""
                                  />
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <h5 className="text-truncate mb-0 fs-16">
                                    <a
                                      className="text-reset username"
                                      data-bs-toggle="offcanvas"
                                      href="#userProfileCanvasExample"
                                      aria-controls="userProfileCanvasExample"
                                    >
                                      Lisa Parker
                                    </a>
                                  </h5>
                                  <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                    <small>24 Members</small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 col-4">
                          <ul className="list-inline user-chat-nav text-end mb-0">
                            <li className="list-inline-item m-0">
                              <div className="dropdown">
                                <button
                                  className="btn btn-ghost-secondary btn-icon"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-search icon-sm"
                                  >
                                    <circle cx={11} cy={11} r={8} />
                                    <line
                                      x1={21}
                                      y1={21}
                                      x2="16.65"
                                      y2="16.65"
                                    />
                                  </svg>
                                </button>
                                <div className="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                                  <div className="p-2">
                                    <div className="search-box">
                                      <input
                                        type="text"
                                        className="form-control bg-light border-light"
                                        placeholder="Search here..."
                                        onkeyup="searchMessages()"
                                        id="searchMessage"
                                      />
                                      <i className="ri-search-2-line search-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="list-inline-item d-none d-lg-inline-block m-0">
                              <button
                                type="button"
                                className="btn btn-ghost-secondary btn-icon shadow-none"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#userProfileCanvasExample"
                                aria-controls="userProfileCanvasExample"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-info icon-sm"
                                >
                                  <circle cx={12} cy={12} r={10} />
                                  <line x1={12} y1={16} x2={12} y2={12} />
                                  <line x1={12} y1={8} x2="12.01" y2={8} />
                                </svg>
                              </button>
                            </li>
                            <li className="list-inline-item m-0">
                              <div className="dropdown">
                                <button
                                  className="btn btn-ghost-secondary btn-icon shadow-none"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-more-vertical icon-sm"
                                  >
                                    <circle cx={12} cy={12} r={1} />
                                    <circle cx={12} cy={5} r={1} />
                                    <circle cx={12} cy={19} r={1} />
                                  </svg>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a
                                    className="dropdown-item d-block d-lg-none user-profile-show"
                                    href="#"
                                  >
                                    <i className="ri-user-2-fill align-bottom text-muted me-2" />{" "}
                                    View Profile
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-inbox-archive-line align-bottom text-muted me-2" />{" "}
                                    Archive
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-mic-off-line align-bottom text-muted me-2" />{" "}
                                    Muted
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-delete-bin-5-line align-bottom text-muted me-2" />{" "}
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* end chat user head */}
                    <div
                      className="chat-conversation p-3 p-lg-4"
                      id="chat-conversation"
                      data-simplebar="init"
                    >
                      <div
                        className="simplebar-wrapper"
                        style={{ margin: "-24px" }}
                      >
                        <div className="simplebar-height-auto-observer-wrapper">
                          <div className="simplebar-height-auto-observer" />
                        </div>
                        <div className="simplebar-mask">
                          <div
                            className="simplebar-offset"
                            style={{ right: 0, bottom: 0 }}
                          >
                            <div
                              className="simplebar-content-wrapper"
                              tabIndex={0}
                              role="region"
                              aria-label="scrollable content"
                              style={{ height: "auto", overflow: "hidden" }}
                            >
                              <div
                                className="simplebar-content"
                                style={{ padding: 24 }}
                              >
                                <ul
                                  className="list-unstyled chat-conversation-list"
                                  id="channel-conversation"
                                ></ul>
                                {/* end chat-conversation-list */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="simplebar-placeholder"
                          style={{ width: 0, height: 0 }}
                        />
                      </div>
                      <div
                        className="simplebar-track simplebar-horizontal"
                        style={{ visibility: "hidden" }}
                      >
                        <div
                          className="simplebar-scrollbar"
                          style={{ width: 0, display: "none" }}
                        />
                      </div>
                      <div
                        className="simplebar-track simplebar-vertical"
                        style={{ visibility: "hidden" }}
                      >
                        <div
                          className="simplebar-scrollbar"
                          style={{ height: 0, display: "none" }}
                        />
                      </div>
                    </div>
                    <div
                      className="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show "
                      id="copyClipBoardChannel"
                      role="alert"
                    >
                      Message copied
                    </div>
                  </div>
                  {/* end chat-conversation */}
                  <div className="chat-input-section p-3 p-lg-4">
                    <form id="chatinput-form" encType="multipart/form-data">
                      <div className="row g-0 align-items-center">
                        <div className="col-auto">
                          <div className="chat-input-links me-2">
                            <div className="links-list-item">
                              <button
                                type="button"
                                className="btn btn-link text-decoration-none emoji-btn"
                                id="emoji-btn"
                              >
                                <i className="bx bx-smile align-middle" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="chat-input-feedback">
                            Please Enter a Message
                          </div>
                          <input
                            type="text"
                            className="form-control chat-input bg-light border-light"
                            id="chat-input"
                            placeholder="Type your message..."
                            autoComplete="off"
                            value={message?message:''}
                          
                            onChange={(e) => setmessage(e.target.value)}
                          />
                        </div>
                        <div className="col-auto">
                          <div className="chat-input-links ms-2">
                            <div className="links-list-item">
                              <button
                                type="submit"
                                className="btn btn-primary chat-send waves-effect waves-light shadow"
                                onClick={(e) => {SendMessage(); e.preventDefault();}}
                              >
                                <i className="ri-send-plane-2-fill align-bottom" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="replyCard">
                    <div className="card mb-0">
                      <div className="card-body py-3">
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                          <div className="flex-grow-1">
                            <h5 className="conversation-name" />
                            <p className="mb-0" />
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18 shadow-none"
                            >
                              <i className="bx bx-x align-middle" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end chat-wrapper */}
      </div>
    </>
  );
};
const UserDespatch = (prop)=>({

  value :prop.whatsappUser


})

export default connect(UserDespatch) (WhatsAppSupport);
