import React, { useContext, useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Dropdown } from "react-bootstrap";
import { Close, MoreVert } from "@material-ui/icons";
import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import { AuthContext } from "../../context/AuthContext";
import SecurityHeaders from "../../context/SecurityHeaders";
import UploadClassRecordingModal from "../../components/modals/UploadClassRecordingModal";
import { useActionsContext } from "../../context/ActionsContext";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ModalStyle from "../../context/ModalStyle";
import ClassRescheduleModal from "../../components/modals/ClassRescheduleModal";
import DefaultModal from "../../components/modals/DefaultModal";
import SocialShare from "../../components/SocialShare";
import VideoPreviewModal from "../../components/modals/VideoPrevieeModal";
import DeleteModal from "../../components/modals/DeleteModal";
import EditClassTimings from "../../components/modals/EditClassTimings";
import EditClassTopic from "../../components/modals/EditClassTopic";
import NoPermissionModal from "../../components/modals/NoPermissionModal";
import edit from "../classes/images/edit.png";
import sharelink from "../classes/images/share-link.png";
import sharerecord from "../classes/images/share.png";
import previewrecord from "../classes/images/criminal-record.png";
import DeleteIcon from "@mui/icons-material/Delete";

import { connect } from "react-redux";
import DetectDevice from "../../context/DetectDevice";


const ClassScheduleItem = ({ item, Reload, value,dispatch ,options}) => {
  const { ShowStartClassModal,  response, permission } =
    useActionsContext();
  const { user } = useContext(AuthContext);
  const StartClass = async ({payload})=>{
    const data = new FormData()
    if(payload.notification){
      data.append("notification",payload.notification)
    }
    data.append("session",user.SessionId)
    data.append("batch_id",item.batch_id)
    data.append("id",item.id)
    data.append("course_url",item.course_url)
    const res = await axios.post(ENDPOINT+"start-class.php",data,SecurityHeaders)
    if(res){
      setstartclassmodal(false);
      Reload()
    }
  }
  const device = DetectDevice()

  const DeleteClass = async () => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    formData.append("session", user.SessionId);

    formData.append("class_id", item.id);
    formData.append("batch_id", item.batch_id);

    const res = await axios.post(
      ENDPOINT + "admin-delete-class.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      Reload();
      if (res.data.status === "success") {
        Reload();

        setdeletemodal(false);
      } else {
      }
    }
  };
  useEffect(() => {
     // console.log("start class ", response);
    if (response.class_started === true) {
      setstartclassmodal(false);
      Reload()
    }
  }, [response.class_started]);

  const [startclassmodal, setstartclassmodal] = useState(false);
  const [uploadrecording, setuploadrecording] = useState(false);
  const [reschedule, setreschedule] = useState(false);
  const CloseReschedule = () => {
    setreschedule(false);
  };

  const [preview, setpreview] = useState(false);
  const [share, setshare] = useState(false);
  const [sharerecording, setsharerecording] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [edittimings, setedittimings] = useState(false);
  const [edittopic, setedittopic] = useState(false);
  const [nopermission, setnopermission] = useState(false);
   // console.log(options);
  return (
    <>
      <NoPermissionModal
        show={nopermission}
        HandleClose={() => setnopermission(false)}
      />
      <EditClassTopic
        item={item}
        visibility={edittopic}
        handleClose={() => setedittopic(false)}
        Reload={Reload}
      />
      <EditClassTimings
        item={item}
        visibility={edittimings}
        handleClose={() => setedittimings(false)}
      />
      <VideoPreviewModal
        show={preview}
        video={item.recording}
        HandleClose={() => setpreview(false)}
      />
      <DefaultModal
        show={share}
        handleClose={() => setshare(false)}
        content={
          <>
            <div className="p-2">
              <div className="modal-header">
                <h4>Share Meeting Link</h4>
                <Close onClick={() => setshare(false)} />
              </div>
              <hr></hr>
              <SocialShare path={item.meeting_link} />
            </div>
          </>
        }
      ></DefaultModal>

      <DefaultModal
        show={sharerecording}
        handleClose={() => setsharerecording(false)}
        content={
          <>
            <div className="p-2">
              <div className="modal-header">
                <h4>Share Recording Link</h4>
                <Close onClick={() => setsharerecording(false)} />
              </div>
              <hr></hr>
              <SocialShare
                path={
                  "https://skillsuprise.com/recordings/" +
                  item.course_url +
                  "/" +
                  item.batch_id +
                  "/" +
                  item.id
                }
              />
            </div>
          </>
        }
      ></DefaultModal>

      <DeleteModal
        HandleClose={() => setdeletemodal(false)}
        HandleDelete={DeleteClass}
        show={deletemodal}
      />

      <Modal open={startclassmodal} onClose={() => setstartclassmodal(false)}>
        <Box sx={ModalStyle} className="col-lg-3 col-md-4 col-sm-5 col-11">
          <div className="modal-content">
            <div className="modal-body p-2 text-center">
            
              <div className="mt-4 text-center">
                <h4>Start Class ?</h4>
                <p className="text-muted fs-15 mb-4">
                
                </p>
                <div className="hstack gap-2 justify-content-center remove">
                  <button
                    className="btn btn-link link-danger fw-medium text-decoration-none"
                    onClick={() => setstartclassmodal(false)}
                  >
                    <i className="ri-close-line me-1 align-middle" /> Close
                  </button>
                  
                  <button
                    onClick={() =>
                      StartClass({
                        payload: {
                          batch_id: item.batch_id,
                          id: item.id,
                          course_url: item.course_url,
                          notification:value.notification
                        },
                      })
                    }
                    className="btn btn-success"
                    id="delete-record"
                  >
                    Yes, Start Class
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <UploadClassRecordingModal
        item={item}
        visibility={uploadrecording}
        handleClose={() => setuploadrecording(false)}
      />
    {device==="mobile"?
    <>
    
    <div className="border bg-white rounded my-2 p-2">
<div className="d-flex justify-content-between">
<h5 className="fs-sm-14">{item.class_title}</h5>

<Dropdown className="p-0">
            <Dropdown.Toggle className="p-0 text-dark" variant="transparent">
              <MoreVert />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {parseInt(permission.reschedule_class) ? (
                <>
                  <Dropdown.Item onClick={() => setedittimings(true)}>
                  <img src={edit} width={22} className="p-1 m-1"></img>
                    Reschedule
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.edit_class_topic) ? (
                <>
                  <Dropdown.Item onClick={() => setedittopic(true)}>
                    <img src={edit} width={22} className="p-1 m-1"></img>Edit
                    Topic
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.share_class_meeting_link) ? (
                <>
                  <Dropdown.Item onClick={() => setshare(true)}>
                    {" "}
                    <img
                      src={sharelink}
                      width={22}
                      className="p-1 m-1"
                    ></img>{" "}
                    Share Meeting Link
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.share_class_recording) ? (
                <>
                  <Dropdown.Item onClick={() => setsharerecording(true)}>
                    {" "}
                    <img src={sharerecord} width={22} className="p-1 m-1"></img>
                    Share Recording
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.preview_class_recording) ? (
                <>
                  <Dropdown.Item onClick={() => setpreview(true)}>
                    {" "}
                    <img
                      src={previewrecord}
                      width={22}
                      className="p-1 m-1"
                    ></img>
                    Preview Recording
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.delete_class_schedule) ? (
                <>
                  <Dropdown.Item onClick={() => setdeletemodal(true)}>
                    <DeleteIcon className="m-1 p-1 w-22" /> Delete
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}
            </Dropdown.Menu>
          </Dropdown>
</div>
<div className="d-flex ">

<p className="me-3 fs-sm-12">     {dateFormat(item.class_starts, "dS mmm yy")}</p>
<p className="fs-sm-12">     {dateFormat(item.class_starts, "hh:MM tt")} -{" "}
{dateFormat(item.class_ends, "hh:MM tt")}</p>

</div>

<div className="d-flex justify-content-between">

<button className="btn btn-sm btn-outline-primary rounded rounded-pill" onClick={() => setedittimings(true)}>Rechedule</button>
{options?.action === false?<></>:
     
        <>
          {item.class_status === "incomplete" ? (
            <>
              {parseInt(permission.start_class) ? (
                <>
                  <button
                    onClick={() => setstartclassmodal(true)}
                    className="btn btn-sm rounded rounded-pill  btn-success"
                  >
                    Start Class
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => setnopermission(true)}
                    className="btn btn-sm rounded rounded-pill  btn-success"
                  >
                    Start Class
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              {item.class_status === "ongoing" ? (
                <>
                  {parseInt(permission.upload_class_recording) ? (
                    <>
                      <button
                        onClick={() => setuploadrecording(true)}
                        className="btn btn-sm rounded rounded-pill  btn-primary"
                      >
                        Upload Recording
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => setnopermission(true)}
                        className="btn btn-sm rounded rounded-pill  btn-primary"
                      >
                        Upload Recording
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {parseInt(
                    permission.preview_recording ||
                      parseInt(permission.view_class_recording)
                  ) ? (
                    <>
                      <a
                        href={item.recording}
                        target="_blank"
                        className="btn btn-sm rounded rounded-pill btn-outline-primary"
                      >
                        View Recording
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        onClick={() => setnopermission(true)}
                        target="_blank"
                        className="btn  btn-sm rounded rounded-pill  btn-outline-primary"
                      >
                        View Recording
                      </a>
                    </>
                  )}
                </>
              )}
            </>
          )}</>
        }
</div>

   </div>
   
   
    
    </>:
    <>
      <tr className="">
        <ClassRescheduleModal
          visibility={reschedule}
          item={item}
          handleClose={CloseReschedule}
        />{" "}

{options?.title === false?<></>:
        <td>
          <div className="d-flex align-items-center">
            <div className="avatar-sm">
              <img
                src={
                  item.class_cover ? item.class_cover : item.course_cover_url
                }
                className="w-100"
              ></img>
            </div>
          
            <div className="ms-3 ">
              <h6 className="fs-15 mb-0">
                <a href="javascript:void(0)">{item.class_title}</a>
              </h6>
              <p className="text-muted m-0">
                {item.class_description ? item.class_description : "NA"}
              </p>
            </div>
          </div>
        </td>}
        <td>
          <div>
            <h6>{item.course_url}</h6>
            <p>{item.batch_id}</p>
          </div>
        </td>
        <td>{dateFormat(item.class_starts, "dS mmm")}</td>
        <td>
          {dateFormat(item.class_starts, "hh:MM tt")} -{" "}
          {dateFormat(item.class_ends, "hh:MM tt")}
        </td>

        {options?.action === false?<></>:
        <td>
          {item.class_status === "incomplete" ? (
            <>
              {parseInt(permission.start_class) ? (
                <>
                  <button
                    onClick={() => setstartclassmodal(true)}
                    className="btn btn-outline-primary"
                  >
                    Start Class
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => setnopermission(true)}
                    className="btn btn-outline-primary"
                  >
                    Start Class
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              {item.class_status === "ongoing" ? (
                <>
                  {parseInt(permission.upload_class_recording) ? (
                    <>
                      <button
                        onClick={() => setuploadrecording(true)}
                        className="btn btn-outline-primary"
                      >
                        Upload Recording
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => setnopermission(true)}
                        className="btn btn-outline-primary"
                      >
                        Upload Recording
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {parseInt(
                    permission.preview_recording ||
                      parseInt(permission.view_class_recording)
                  ) ? (
                    <>
                      <a
                        href={item.recording}
                        target="_blank"
                        className="btn btn-outline-primary"
                      >
                        View Recording
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        onClick={() => setnopermission(true)}
                        target="_blank"
                        className="btn btn-outline-primary"
                      >
                        View Recording
                      </a>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </td>}
        <td>
          <Dropdown>
            <Dropdown.Toggle>
              <MoreVert />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {parseInt(permission.reschedule_class) ? (
                <>
                  <Dropdown.Item onClick={() => setedittimings(true)}>
                  <img src={edit} width={22} className="p-1 m-1"></img>
                    Reschedule
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.edit_class_topic) ? (
                <>
                  <Dropdown.Item onClick={() => setedittopic(true)}>
                    <img src={edit} width={22} className="p-1 m-1"></img>Edit
                    Topic
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.share_class_meeting_link) ? (
                <>
                  <Dropdown.Item onClick={() => setshare(true)}>
                    {" "}
                    <img
                      src={sharelink}
                      width={22}
                      className="p-1 m-1"
                    ></img>{" "}
                    Share Meeting Link
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.share_class_recording) ? (
                <>
                  <Dropdown.Item onClick={() => setsharerecording(true)}>
                    {" "}
                    <img src={sharerecord} width={22} className="p-1 m-1"></img>
                    Share Recording
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.preview_class_recording) ? (
                <>
                  <Dropdown.Item onClick={() => setpreview(true)}>
                    {" "}
                    <img
                      src={previewrecord}
                      width={22}
                      className="p-1 m-1"
                    ></img>
                    Preview Recording
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.delete_class_schedule) ? (
                <>
                  <Dropdown.Item onClick={() => setdeletemodal(true)}>
                    <DeleteIcon className="m-1 p-1 w-22" /> Delete
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
   
    
    </>}
   
   

   
   
   
   
   
   
   
    </>
  );
};

const Notification = (prop)=>({

  value :prop.sendNotification


})

export default connect (Notification) ( ClassScheduleItem) ;
