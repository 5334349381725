import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';

const DefaultToast = ({ show, toastdata,ToastHandleClose }) => {





  return (
    <>
      <div className="custom-toast-container justify-center justify-content-center right-0">
        <div className="mb-2 col-lg-3 col-md-3 col-sm-4 col-10">
          <Toast show={show} onClose={ToastHandleClose}   delay={3000} autohide className="space-between bg-warning">
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
              <strong className="me-auto">{toastdata && toastdata.title ? toastdata.title : null}</strong>
            </Toast.Header>
            <Toast.Body>{toastdata && toastdata.message ? toastdata.message : null}</Toast.Body>
          </Toast>
        </div>
      </div>
    </>
  );
};

export default DefaultToast;
