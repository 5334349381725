import React, { useEffect } from 'react'
import { useActionsContext } from '../context/ActionsContext'

const Calendar = () => {
  const {searchquery,setsearchquery} = useActionsContext()
  return (
    <>
     <div className="col-xxl-2 col-sm-6 w-45 m-2 ">
                              <div>
                                <input
                                  type="date"
                                  onChange={(e)=>setsearchquery(e.target.value.toLowerCase())}
                                  className="form-control"
                                  data-provider="flatpickr"
                                  data-date-format="d M, Y"
                                  data-range-date="true"
                                  id="demo-datepicker"
                                  placeholder="Select date"
                                />
                              </div>
                            </div>
    </>
  )
}

export default Calendar