import React from "react";

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Avatar } from "@material-ui/core";
import { AuthContext } from "../../context/AuthContext";
import dateFormat from "dateformat";
import { Dropdown, Modal } from "react-bootstrap";
import { MoreVert } from "@material-ui/icons";
import ENDPOINT from "../../context/EndPoint";
import { Link, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AmbassadorListItem from "./AmbssadorListItem";
const CampusAmbassadorsPage = ({ page, course, props }) => {
  const { user } = useContext(AuthContext);
  const userdata = user;

  const params = useParams();
  const category = params.category;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState(null);

  const getinternships = async () => {
    let URL = `${ENDPOINT}admin-get-ambassadors.php?session=${user.SessionId}&mode=all`;
    if (input) {
      URL = URL + `&input=${input}`;
    }

    fetch(URL)
      .then((r) => r.json())
      .then((result) => {
        setinternships(result.data);
        setInititalData(result.data);
        setResponse(result.data);
      });
  };

  const [input, setinput] = useState(null);

  useEffect(() => {
    getinternships();
  }, [category, input]);

  const [mode, setmode] = useState(null);

  const [AddPoints, setAddPoints] = useState(false);

  const showAddPoints = () => {
    setAddPoints(true);
  };

  const closeAddPoints = () => {
    setAddPoints(false);
  };

  const [editstats, seteditstats] = useState(false);
  const [studentid, setstudentid] = useState(null);

  const editStatistics = (studentid) => {
    setstudentid(studentid);

    seteditstats(true);
  };
  const [initialData, setInititalData] = useState(null);

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.email.toLowerCase().includes(term) ||
        item.phone_number.includes(term) ||
        item.first_name.toLowerCase().includes(term) ||
        item.last_name.toLowerCase().includes(term) ||
        item.applied_date.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

   // console.log(internships);
  return (
    <>
      <>
        <>
          <Modal
            className="min-vh-90"
            {...props}
            size="xl"
            centered
            show={editstats}
            onHide={() => seteditstats(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <b className="text-primary">Student Statistics</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body></Modal.Body>
          </Modal>

          <Modal show={AddPoints} onHide={closeAddPoints}>
            <Modal.Header closeButton>
              <Modal.Title>Add Points</Modal.Title>
            </Modal.Header>
          </Modal>

          <div className="card-body border border-dashed border-end-0 border-start-0">
            <form>
              <div className="row g-3">
                <div className="col-xxl-3 col-sm-4">
                  <div className="search-box">
                    <input
                      type="text"
                      onChange={handleSearch}
                      className="form-control search"
                      placeholder="Search for order ID, customer, order status or something..."
                    />
                    <i className="ri-search-line search-icon" />
                  </div>
                </div>
                {/*end col*/}
                <div className="col-xxl-2 col-sm-6">
                  <div>
                    <input
                      type="date"
                      onChange={handleSearch}
                      className="form-control"
                      data-provider="flatpickr"
                      data-date-format="d M, Y"
                      data-range-date="true"
                      id="demo-datepicker"
                      placeholder="Select date"
                    />
                  </div>
                </div>
                {/*end col*/}
                <div className="col-xxl-2 col-sm-4">
                  <div>
                    <select
                      className="form-control"
                      data-choices=""
                      data-choices-search-false=""
                      name="choices-single-default"
                      id="idStatus"
                    >
                      <option value="">Status</option>
                      <option value="all" selected="">
                        All
                      </option>
                      <option value="Pending">Pending</option>
                      <option value="Inprogress">Inprogress</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Pickups">Pickups</option>
                      <option value="Returns">Returns</option>
                      <option value="Delivered">Delivered</option>
                    </select>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-xxl-2 col-sm-4">
                  <div>
                    <select
                      className="form-control"
                      data-choices=""
                      data-choices-search-false=""
                      name="choices-single-default"
                      id="idPayment"
                    >
                      <option value="">Select Payment</option>
                      <option value="all" selected="">
                        All
                      </option>
                      <option value="Mastercard">Mastercard</option>
                      <option value="Paypal">Paypal</option>
                      <option value="Visa">Visa</option>
                      <option value="COD">COD</option>
                    </select>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-xxl-3 col-sm-4">
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn me-2 btn-primary "
                      onclick="SearchData();"
                    >
                      {" "}
                      <i className="ri-equalizer-fill me-1 align-bottom" />
                      Filters
                    </button>

                    <button className="btn btn-danger w-fit h-fit">
                      Add new Task
                    </button>
                  </div>
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </form>
          </div>

          <div className="internships-page flex-wrap mobile-reverse ">
            <div className="internships-list mt-5 sm-p-0  container-fluid">
              {response ? (
                <>
                  <>
                    {response.map((item, index) => (
                      <>
                        <AmbassadorListItem item={item} />
                      </>
                    ))}
                  </>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      </>
    </>
  );
};

export default CampusAmbassadorsPage;
