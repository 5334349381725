import React, { useContext, useEffect, useState } from 'react'
import { useActionsContext } from '../../context/ActionsContext'
import { useParams } from 'react-router-dom'
import dateFormat from 'dateformat'
import TeamMeetingItem from '../meetings/TeamMeetingItem'
import DefaultModal from '../../components/modals/DefaultModal'
import ScheduleTeamMeeting from './ScheduleTeamMeeting'
import ENDPOINT from '../../context/EndPoint'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import { connect } from 'react-redux'
const EmployeeMeetingHistory = ({ item,value ,dispatch}) => {
  const params = useParams()
  const intern_id = params.user_id
  const internship_id = params.internship_id

  const [internmeetings, setinternmeetings] = useState(null)
  const { user } = useContext(AuthContext)
  const fetchInternMeetings = async () => {


    const formData = new FormData();


    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append('session', user.SessionId);

    formData.append('status', "all");
    formData.append("internship_id", item.internship_id)
    formData.append("intern_id", item.user_id)

    const res = await axios.post(ENDPOINT + "intern-meetings.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {
       // console.log(res.data)
      setResponse(res.data.meetings)
      setInitialData(res.data.meetings)
      setinternmeetings(res.data.meetings)
    }
  }

  useEffect(() => {
    fetchInternMeetings()
  }, [])

  const [addmeeting, setaddmeeting] = useState(false)

  const [initialData, setInitialData] = useState(internmeetings)


  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (


        item.title.toLowerCase().includes(term) ||
        item.description.toLowerCase().includes(term) ||
        item.date.includes(term) ||
        item.status.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  useEffect(() => {
if(value.functionName === 'get-intern-meetings'){
  fetchInternMeetings();
}

  }, [value]);


  return (
    <>


      <DefaultModal
        show={addmeeting}
        HandleClose={() => setaddmeeting(false)}
        content={<>
          <ScheduleTeamMeeting handleClose={() => setaddmeeting(false)} item={item} />
        </>}

      />
      <div className="card p-3 grey-body">
        <>


          <div className="modal-header">
            <h4>Meetings</h4>
            <button className='btn btn-primary' onClick={() => setaddmeeting(true)}>Schedule a Meet</button>

          </div>
          <div className="d-flex align-items-center mb-4">

            <div className="card-body border border-dashed border-end-0 border-start-0">
              <form>
                <div className="row g-3">
                  <div className="col-xxl-5 col-sm-6">
                    <div className="search-box">
                      <input
                        onChange={handleSearch}
                        type="text"
                        className="form-control search"
                        placeholder="Search for class title, description, course..."
                      />
                      <i className="ri-search-line search-icon" />
                    </div>
                  </div>
                  {/*end col*/}
                  <div className="col-xxl-2 col-sm-6">
                    <div>
                      <input
                        type="date"
                        onChange={handleSearch}
                        className="form-control"
                        data-provider="flatpickr"
                        data-date-format="d M, Y"
                        data-range-date="true"
                        id="demo-datepicker"
                        placeholder="Select date"
                      />
                    </div>
                  </div>
                  {/*end col*/}
                  <div className="col-xxl-2 col-sm-4">
                    <div>
                      <select
                        onChange={handleSearch}
                        className="form-control"
                        data-choices=""
                        data-choices-search-false=""
                        name="choices-single-default"
                        id="idStatus"
                      >
                        <option value="">Status</option>
                        <option value="all" selected="">
                          All
                        </option>
                        <option value="Pending">Pending</option>
                        <option value="incomplete">Incomplete</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="Pickups">Pickups</option>
                        <option value="Returns">Returns</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xxl-1 col-sm-4">
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary h-fit"

                      >

                        <i className="ri-equalizer-fill me-1 align-bottom" />
                        Filters
                      </button>
                    </div>
                  </div>
                  {/*end col*/}
                </div>
                {/*end row*/}
              </form>
            </div>
          </div>
          <div className="d-flex flex-wrap">

            {response ?
              <>
                {response.map((item, index) => (
                  <>

                    <TeamMeetingItem item={item} index={index} />
                  </>
                ))}

              </> :
              <>


              </>}

          </div>
        </>
      </div>

    </>
  )
}

const reload = (prop)=>({
  value:prop.modalHandler
})

export default connect(reload) (EmployeeMeetingHistory)