import React, { useContext, useEffect, useState } from 'react'
import CustomerSupportPage from '../customer/CustomerSupportPage'
import StudentsSupportPage from '../support/StudentsSupportPage'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';

export const convertMinutesToTime = (minutes) => {
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    const mins = Math.floor(minutes % 60);
    return `${days}d  ${hours}h  ${mins}m`;
};

const CustomerSupportAdmin = () => {

    const { user } = useContext(AuthContext);
    const [statistics, setStatistics] = useState('')
    const [placement, setPlacement] = useState('');

    const GetStatistics = async () => {
        try {
            const data = new FormData();
            data.append('session', user.SessionId);

            const res = await axios.post(ENDPOINT + "hr-dashboard.php", data, SecurityHeaders);
            if (res) {
                if (res.data.status === "success") {
                    setStatistics(res.data)
                }
            }

            const response = await axios.post(ENDPOINT + "placement-request-statistics.php", data, SecurityHeaders);
            if (response) {
                if (response.data.status === "success") {
                    setPlacement(response.data.data)
                }
            }

        }
        catch (err) {
            // console.log(err.message);
        }
    }

    useEffect(() => {
        GetStatistics();
    }, []);




    return (
        <>
            <section className='container-fluid '>
                <div className='row '>
                    <div className="col-lg-4 col-md-12 col-12 p-2 ">


                        <div className='shadow rounded py-3 p-2 bg-secondary-subtle'>
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className=' text-uppercase'><b>Customer Support</b></h5>
                                <Link to="/">View all</Link>
                            </div>
                            <div className='d-flex flex-wrap'>
                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title text-warning bg-warning-subtle rounded-circle fs-3 material-shadow">
                                                        <i className="ri-play-circle-fill align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        Initiated
                                                    </p>
                                                    <h4 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {statistics?.doubts?.total_pending}
                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>


                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title text-success bg-success-subtle rounded-circle fs-3 material-shadow">
                                                        <i className="ri-close-circle-fill align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        Closed
                                                    </p>
                                                    <h4 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {statistics?.doubts?.total_closed}
                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>


                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title text-info bg-info-subtle rounded-circle fs-3 material-shadow">
                                                        <i className="ri-list-unordered align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        Total
                                                    </p>
                                                    <h4 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {statistics?.doubts?.total_doubts}
                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>


                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                                        <i className="ri-time-line align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        avg time
                                                    </p>
                                                    <h6 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {convertMinutesToTime(statistics?.doubts?.average_initiated_time_minutes)}
                                                        </span>
                                                    </h6>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* end card */}
                    </div>

                    <div className="col-lg-4 col-md-12 col-12 p-2">


                        <div className='shadow rounded py-3 p-2 bg-primary-subtle'>
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className=' text-uppercase'><b>Doubt Support</b></h5>
                                <Link to="/">View all</Link>
                            </div>
                            <div className='d-flex flex-wrap'>
                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title text-warning bg-warning-subtle rounded-circle fs-3 material-shadow">
                                                        <i className="ri-play-circle-fill align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        Initiated
                                                    </p>
                                                    <h4 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {statistics?.contacts?.total_pending}
                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>


                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title text-success bg-success-subtle rounded-circle fs-3 material-shadow">
                                                        <i className="ri-close-circle-fill align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        Closed
                                                    </p>
                                                    <h4 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {statistics?.contacts?.total_closed}
                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>


                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title text-info bg-info-subtle rounded-circle fs-3 material-shadow">
                                                        <i className="ri-list-unordered align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        total
                                                    </p>
                                                    <h4 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {statistics?.contacts?.total_contacts}
                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>


                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                                        <i className="ri-time-line align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        avg time
                                                    </p>
                                                    <h6 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {convertMinutesToTime(statistics?.contacts?.average_initiated_time_minutes)}

                                                        </span>
                                                    </h6>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* end card */}
                    </div>

                    <div className="col-lg-4 col-md-12 col-12 p-2">


                        <div className='shadow rounded py-3 p-2 bg-danger-subtle'>
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className=' text-uppercase'><b>Placement Support</b></h5>
                                <Link to="/">View all</Link>
                            </div>
                            <div className='d-flex flex-wrap'>
                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title text-warning bg-warning-subtle rounded-circle fs-3 material-shadow">
                                                        <i className="ri-play-circle-fill align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        initiated
                                                    </p>
                                                    <h4 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {placement?.total_initiated}
                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>


                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title text-success bg-success-subtle rounded-circle fs-3 material-shadow">
                                                        <i className="ri-close-circle-fill align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        Closed
                                                    </p>
                                                    <h4 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {placement?.total_closed}

                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>


                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title text-info bg-info-subtle rounded-circle fs-3 material-shadow">
                                                        <i className="ri-list-unordered align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        total
                                                    </p>
                                                    <h4 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {placement?.total_supports}

                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>


                                <div className='col-lg-6 p-1 p-md-2 col-6'>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                                        <i className="ri-time-line align-middle" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                                        avg time
                                                    </p>
                                                    <h6 className=" mb-0">

                                                        <span
                                                            className="counter-value"
                                                            data-target="14799.44"
                                                        >
                                                            {convertMinutesToTime(placement?.average_initiated_time_minutes)}
                                                        </span>
                                                    </h6>
                                                </div>
                                                {/* <div className="flex-shrink-0 align-self-end">
                                                    <span className="badge bg-danger-subtle text-danger">
                                                        <i className="ri-arrow-down-s-fill align-middle me-1" />
                                                        {55} %<span> </span>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* end card body */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* end card */}
                    </div>
                </div>




                <div className="container-fluid mt-3 d-none d-md-flex row flex-wrap">

                    <div className="col-lg-6 px-0">

                        <CustomerSupportPage options={{ search: false, statistics: false, contact: false, message: true, userinfo: true, limit: 8, view: true }} />

                    </div>

                    <div className="col-lg-6">
                        <StudentsSupportPage options={{ search: false, statistics: false, contact: false, userinfo: true, limit: 8, view: false, viewAll: true, date: false }} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default CustomerSupportAdmin