import React, { useContext, useEffect, useState } from 'react'
import DemoData from '../../data/DemoData'
import CandidateItem from './CandidateItem'
import Dropdown from 'react-bootstrap/Dropdown';
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../context/EndPoint';
import axios from 'axios';

const InternshipApplications = () => {

const {user} = useContext(AuthContext)
  const [internships,setinternships] = useState(null)
  const[applications,setApplications]=useState(null)
  const GetInternships = async (status)=>{
        

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    
      formData.append('status',"all");
    
     
    
    const res = await axios.post(ENDPOINT+"admin-internship-applications.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     
     if(res.data.status==="success"){
      setinternships(res.data.data)
      setApplications(res.data)
 
     }
     else{
    
     }
    }
     // console.log(applications?applications.total_applications:null)
    }


    useEffect(()=>{
GetInternships()
    },[])
  return (
<>

        <div className="container-fluid">
           
        <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Total_Applications{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                 {applications?applications.total_applications.total_application:null}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Today-leaves{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                               
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                             Pending-leaves {" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                              
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                specialization students{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}
                  </div>
       <div className="card">

           {/* end page title */}
           <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-3 col-sm-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search for order ID, customer, order status or something..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-6">
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            data-provider="flatpickr"
                            data-date-format="d M, Y"
                            data-range-date="true"
                            id="demo-datepicker"
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idStatus"
                          >
                            <option value="">Status</option>
                            <option value="all" selected="">
                              All
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="Inprogress">Inprogress</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Pickups">Pickups</option>
                            <option value="Returns">Returns</option>
                            <option value="Delivered">Delivered</option>
                          </select>
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idPayment"
                          >
                            <option value="">Select Payment</option>
                            <option value="all" selected="">
                              All
                            </option>
                            <option value="Mastercard">Mastercard</option>
                            <option value="Paypal">Paypal</option>
                            <option value="Visa">Visa</option>
                            <option value="COD">COD</option>
                          </select>
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-3 col-sm-4">
                        <div className='d-flex'>
                          <button
                            type="button"
                            className="btn me-2 btn-primary "
                            onclick="SearchData();"
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>

                          <button className='btn btn-danger w-fit h-fit' >Add new Task</button>
                         
                        </div>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </form>
                </div>
        </div>  
        
          <div className="row gy-2 mb-2" id="candidate-list">
         




{internships?
<>

{internships.map((item)=>(
  <>
  
  <div className="col-md-6 col-lg-12">
<CandidateItem item={item}/>
    </div>
  
  </>
))}

</>:
<>

</>}








          </div>
          {/* end row */}
          <div
            className="row g-0 justify-content-end mb-4"
            id="pagination-element"
          >
            {/* end col */}
            <div className="col-sm-6">
              <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                <div className="page-item disabled">
                  <a
                    href="javascript:void(0);"
                    className="page-link"
                    id="page-prev"
                  >
                    Previous
                  </a>
                </div>
                <span id="page-num" className="pagination">
                  <div className="page-item active">
                    <a
                      className="page-link clickPageNumber"
                      href="javascript:void(0);"
                    >
                      1
                    </a>
                  </div>
                  <div className="page-item">
                    <a
                      className="page-link clickPageNumber"
                      href="javascript:void(0);"
                    >
                      2
                    </a>
                  </div>
                  <div className="page-item">
                    <a
                      className="page-link clickPageNumber"
                      href="javascript:void(0);"
                    >
                      3
                    </a>
                  </div>
                </span>
                <div className="page-item">
                  <a
                    href="javascript:void(0);"
                    className="page-link"
                    id="page-next"
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
      

</>  )
}

export default InternshipApplications