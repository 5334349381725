import axios from 'axios'
import React from 'react'
import ENDPOINT from '../../context/EndPoint'
import SecurityHeaders from '../../context/SecurityHeaders'

const GetBatchAssignmentSubmissions = async ({payload}) =>{
     // console.log(payload.course_url)
     // console.log(payload.batch_id)
     // console.log(payload.session)

    const  data = new FormData();
    data.append("course_url",payload.course_url);
    data.append("batch_id",payload.batch_id);
    data.append("session",payload.session)

    const res = await axios.post(ENDPOINT+"admin-get-batchwise-assignments.php",data,SecurityHeaders);
    
    if(res){
    
         if(res.data.status ==="success"){
            return res.data
         }
        
    }

}
export default GetBatchAssignmentSubmissions