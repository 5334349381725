import React, { useState } from 'react'
import AssignmentSubmissionItem from './AssignmentSubmissionItem'
import DetectDevice from '../../context/DetectDevice'
import { Avatar } from '@mui/material';
import { Dropdown } from 'react-bootstrap';
import { MoreVert } from '@material-ui/icons';
import dateFormat from 'dateformat'
import { Edit, Delete } from '@mui/icons-material'; 
import AssignmentSubmissionItemMobile from './AssignmentSubmissionItemMobile';

const AssignmentSubmissionsList = ({ tasks}) => {
  const device = DetectDevice();

  const [evaluate, setevaluate] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [toast, settoast] = useState(false);
  const [toastdata, settoastdata] = useState(null)
  return (
    <>

    {/* {
      device==="mobile"?
        <>
              
        </>:
        <>
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="cqol">Name</th>
                        <th scope="col">Course</th>
                        <th scope="col">Title</th>
                        <th scope="col">Size</th>
                        <th scope="col">Submission Time</th>
                        <th scope="col">Deadline</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {tasks ?
                        <>
                          {tasks.map((item) => (
                            <>
                              <AssignmentSubmissionItem item={item}/>

                            </>
                          ))}

                        </> :
                        <>

                        </>}

                    </tbody>
                  </table>
                </div>
                <div className="text-center mt-3">
                  <a
                    href="javascript:void(0);"
                    className="text-success"
                  >
                    <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" />{" "}
                    Load more{" "}
                  </a>
                </div>
              </div>
            </div>
        </>
    } */}




{device === "mobile" ? (
  <div className="row">
    {tasks && tasks.length > 0 ? (
      tasks.map((item) => (
        <AssignmentSubmissionItemMobile item={item}/>
      ))
    ) : (<></>)
    }
  </div>
) : (
  <div className="row">
    <div className="col-lg-12">
      <div className="table-responsive">
        <table className="table table-borderless align-middle mb-0">
          <thead className="table-light">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Course</th>
              <th scope="col">Title</th>
              <th scope="col">Submission Time</th>
              <th scope="col">Deadline</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tasks && tasks.length > 0 ? (
              tasks.map((item) => (
                <>
                    <AssignmentSubmissionItem item={item}/>
                </>
              ))
            ) : (<></>
            )}
          </tbody>
        </table>
      </div>
      <div className="text-center mt-3">
        <a href="javascript:void(0);" className="text-success">
          <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" /> Load more
        </a>
      </div>
    </div>
  </div>
)}



      {/* <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="table table-borderless align-middle mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="cqol">Name</th>
                  <th scope="col">Course</th>
                  <th scope="col">Title</th>
                  <th scope="col">Size</th>
                  <th scope="col">Submission Time</th>
                  <th scope="col">Deadline</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>

                {tasks ?
                  <>
                    {tasks.map((item) => (
                      <>
                        <AssignmentSubmissionItem item={item}/>

                      </>
                    ))}

                  </> :
                  <>

                  </>}

              </tbody>
            </table>
          </div>
          <div className="text-center mt-3">
            <a
              href="javascript:void(0);"
              className="text-success"
            >
              <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" />{" "}
              Load more{" "}
            </a>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default AssignmentSubmissionsList