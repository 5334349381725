import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import { Delete, MoreVert } from '@material-ui/icons';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';

const FreeResources = () => {
    const [title, settitle] = useState(null);
    const [description, setdescription] = useState(null);
    const [type, settype] = useState("link");
    const [url, seturl] = useState(null);
    const [resourceId, setResourceId] = useState(null); // to store the resource id for editing
    const [showModal, setShowModal] = useState(false);
    const { user } = useContext(AuthContext);
    const params = useParams();
    const resource_url = params.resource_url;
    const [resources, setresources] = useState(null);

    const handleClose = () => setShowModal(false);
    const handleShow = (resource) => {
        settitle(resource.title);
        setdescription(resource.description);
        settype(resource.type);
        seturl(resource.url);
        setResourceId(resource.id);
        setShowModal(true);
    };

    const AddResource = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("title", title);
        data.append("description", description);
        data.append("type", type);
        data.append("link", url);
        data.append("resource_url", resource_url);
        data.append("session", user.SessionId);
        const res = await axios.post(ENDPOINT + "add-free-resources.php", data, SecurityHeaders);

        if (res) {
            if (res.data.status === "success") {
                GetResources();
            }
        }
    };

    const GetResources = async () => {
        const data = new FormData();
        data.append("resource_url", resource_url);
        data.append("session", user.SessionId);
        const res = await axios.post(ENDPOINT + "get-free-resources.php", data, SecurityHeaders);

        if (res) {
            if (res.data) {
                if (res.data.status === "success") {
                    setresources(res.data.data);
                }
            }
        }
    };

    const DeleteResource = async ({ resource_id }) => {
        const data = new FormData();
        data.append("resource_id", resource_id);
        data.append("session", user.SessionId);
        const res = await axios.post(ENDPOINT + "delete-free-resources.php", data, SecurityHeaders);

        if (res) {
            if (res.data) {
                if (res.data[0].status === "success") {
                    GetResources();
                }
            }
        }
    };

    const UpdateResource = async () => {
        const data = new FormData();
        data.append("resource_id", resourceId);
        data.append("title", title);
        data.append("description", description);
        data.append("type", type);
        data.append("link", url);
        data.append("session", user.SessionId);
        const res = await axios.post(ENDPOINT + "update-free-resources.php", data, SecurityHeaders);

        if (res) {
            if (res.data[0]) {
                handleClose();
                GetResources();
            }
        }
    };

    useEffect(() => {
        GetResources();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-1">
                <h4>Course Resources</h4>
            </div>
            <div className="mt-2">
                <div className="d-flex flex-wrap justify-content-between ">
                    <div className="col-lg-6">
                        {resources ? (
                            <>
                                {resources.map((resource) => (
                                    <div key={resource.id} className="border p-3 mb-2">
                                        <div className="d-flex justify-content-between">
                                            <h6>{resource.title}</h6>
                                            <div className="d-flex">
                                                <Dropdown className="options-dropdown">
                                                    <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                                                        <MoreVert className="text-dark" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleShow(resource)}>Edit</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => DeleteResource({ resource_id: resource.id })}>
                                                            Delete
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <hr className="mt-1 mb-0" />
                                        <p className="text-secondary extra-small">{resource.description}</p>
                                        <a target="_blank" rel="noopener noreferrer" href={resource.url}>
                                            View {resource.type}
                                        </a>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p>No resources available</p>
                        )}
                    </div>
                    <div className="col-lg-5">
                        <h4>Add Resource</h4>
                        <form onSubmit={(e) => AddResource(e)}>
                            <input
                                required
                                className="form-control mt-3"
                                value={title}
                                onChange={(e) => settitle(e.target.value)}
                                placeholder="title"
                            />
                            <input
                                required
                                className="form-control mt-2"
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                                placeholder="Description"
                            />
                            <select
                                required
                                className="form-control mt-2"
                                value={type}
                                onChange={(e) => settype(e.target.value)}
                            >
                                <option value="pdf">PDF</option>
                                <option value="link">Link</option>
                            </select>
                            <input
                                required
                                className="form-control mt-2"
                                value={url}
                                onChange={(e) => seturl(e.target.value)}
                                placeholder={type === "pdf" ? type + " Link" : "Resource Link"}
                            />
                            <button type="submit" className="btn btn-primary mt-4">
                                Add resource
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            {/* Modal for editing resource */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Resource</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <input
                            required
                            className="form-control mt-3"
                            value={title}
                            onChange={(e) => settitle(e.target.value)}
                            placeholder="title"
                        />
                        <input
                            required
                            className="form-control mt-2"
                            value={description}
                            onChange={(e) => setdescription(e.target.value)}
                            placeholder="Description"
                        />
                        <select
                            required
                            className="form-control mt-2"
                            value={type}
                            onChange={(e) => settype(e.target.value)}
                        >
                            <option value="pdf">PDF</option>
                            <option value="link">Link</option>
                        </select>
                        <input
                            required
                            className="form-control mt-2"
                            value={url}
                            onChange={(e) => seturl(e.target.value)}
                            placeholder={type === "pdf" ? type + " Link" : "Resource Link"}
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={UpdateResource}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FreeResources;
