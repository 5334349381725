const getInitialNotificationState = () => {
    try {
        const storedNotification = localStorage.getItem('notification');
        return storedNotification !== null ? JSON.parse(storedNotification) : true;
    } catch (error) {
        console.error('Error reading notification from localStorage:', error);
        return true; 
    }
};

const initialState = {
    notification: getInitialNotificationState()
}

const notificationSentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SEND':
            const newState = { ...state, notification: action.payload };
            try {
                localStorage.setItem('notification', JSON.stringify(action.payload));
            } catch (error) {
                console.error('Error saving notification to localStorage:', error);
            }
            return newState;
        default:
            return state;
    }
}

export default notificationSentReducer;
