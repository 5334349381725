import axios from "axios"
import { useParams } from "react-router-dom"
import SecurityHeaders from "../context/SecurityHeaders"
import { useEffect, useState } from "react"

const ContactPage = ()=>{

const [username,setusername] = useState(null)
const paramater = useParams()
 // console.log(paramater.username)
 // console.log(paramater.user_id)

const [key,setkey] = useState(null)


const SearchCourses = async()=>{
const data =new FormData()
data.append("category","courses")
data.append("limit",10)
data.append("key",key)

const res = await axios.post("https://skillsuprise.com/basic-search.php",data,SecurityHeaders)
if(res){

    if(res.data.status==="success"){
        setresults(res.data.data)
    }
}
}
const [results,setresults] = useState(null)

useEffect(()=>{
SearchCourses()
},[key,username])

return (
    <>
 <input onChange={(e)=>setkey(e.target.value)}  ></input>


<input onChange={(e)=>setusername(e.target.value)}></input>
 <button onClick={SearchCourses}>Search</button>

 {results?
 <>
 {results.map((item)=>(

    <>
<div className="div">
<h4>{item.course_title}</h4>

</div>
    </>
 ))}
 
 </>:
 <>
 
 
 </>}
    </>
)

}

export default ContactPage