import React from 'react'

const AnalyticsPage = () => {
  return (
   
   <>
   
   <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Analytics</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Dashboards</a>
                    </li>
                    <li className="breadcrumb-item active">Analytics</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-xl-4">
              <div className="card ">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Users by Device
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="dropdown card-header-dropdown">
                      <a
                        className="text-reset dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="text-muted fs-16">
                          <i className="mdi mdi-dots-vertical align-middle" />
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a className="dropdown-item" href="#">
                          Today
                        </a>
                        <a className="dropdown-item" href="#">
                          Last Week
                        </a>
                        <a className="dropdown-item" href="#">
                          Last Month
                        </a>
                        <a className="dropdown-item" href="#">
                          Current Year
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card header */}
                <div className="card-body">
                  <div
                    id="user_device_pie_charts"
                    data-colors='["--vz-primary", "--vz-warning", "--vz-info"]'
                    className="apex-charts"
                    dir="ltr"
                    style={{ minHeight: "201.8px" }}
                  >
                    <div
                      id="apexchartszx11mu42"
                      className="apexcharts-canvas apexchartszx11mu42 apexcharts-theme-light"
                      style={{ width: 434, height: "201.8px" }}
                    >
                     
                      <div className="apexcharts-tooltip apexcharts-theme-dark">
                        <div
                          className="apexcharts-tooltip-series-group"
                          style={{ order: 1 }}
                        >
                          <span
                            className="apexcharts-tooltip-marker"
                            style={{ backgroundColor: "rgb(75, 56, 179)" }}
                          />
                          <div
                            className="apexcharts-tooltip-text"
                            style={{
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontSize: 12
                            }}
                          >
                            <div className="apexcharts-tooltip-y-group">
                              <span className="apexcharts-tooltip-text-y-label" />
                              <span className="apexcharts-tooltip-text-y-value" />
                            </div>
                            <div className="apexcharts-tooltip-goals-group">
                              <span className="apexcharts-tooltip-text-goals-label" />
                              <span className="apexcharts-tooltip-text-goals-value" />
                            </div>
                            <div className="apexcharts-tooltip-z-group">
                              <span className="apexcharts-tooltip-text-z-label" />
                              <span className="apexcharts-tooltip-text-z-value" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="apexcharts-tooltip-series-group"
                          style={{ order: 2 }}
                        >
                          <span
                            className="apexcharts-tooltip-marker"
                            style={{ backgroundColor: "rgb(255, 190, 11)" }}
                          />
                          <div
                            className="apexcharts-tooltip-text"
                            style={{
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontSize: 12
                            }}
                          >
                            <div className="apexcharts-tooltip-y-group">
                              <span className="apexcharts-tooltip-text-y-label" />
                              <span className="apexcharts-tooltip-text-y-value" />
                            </div>
                            <div className="apexcharts-tooltip-goals-group">
                              <span className="apexcharts-tooltip-text-goals-label" />
                              <span className="apexcharts-tooltip-text-goals-value" />
                            </div>
                            <div className="apexcharts-tooltip-z-group">
                              <span className="apexcharts-tooltip-text-z-label" />
                              <span className="apexcharts-tooltip-text-z-value" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="apexcharts-tooltip-series-group"
                          style={{ order: 3 }}
                        >
                          <span
                            className="apexcharts-tooltip-marker"
                            style={{ backgroundColor: "rgb(41, 156, 219)" }}
                          />
                          <div
                            className="apexcharts-tooltip-text"
                            style={{
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontSize: 12
                            }}
                          >
                            <div className="apexcharts-tooltip-y-group">
                              <span className="apexcharts-tooltip-text-y-label" />
                              <span className="apexcharts-tooltip-text-y-value" />
                            </div>
                            <div className="apexcharts-tooltip-goals-group">
                              <span className="apexcharts-tooltip-text-goals-label" />
                              <span className="apexcharts-tooltip-text-goals-value" />
                            </div>
                            <div className="apexcharts-tooltip-z-group">
                              <span className="apexcharts-tooltip-text-z-label" />
                              <span className="apexcharts-tooltip-text-z-value" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table className="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
                      <tbody className="border-0">
                        <tr>
                          <td>
                            <h4 className="text-truncate fs-14 fs-medium mb-0">
                              <i className="ri-stop-fill align-middle fs-18 text-primary me-2" />
                              Desktop Users
                            </h4>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-users me-2 icon-sm"
                              >
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx={9} cy={7} r={4} />
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                              </svg>
                              78.56k
                            </p>
                          </td>
                          <td className="text-end">
                            <p className="text-success fw-medium fs-12 mb-0">
                              <i className="ri-arrow-up-s-fill fs-5 align-middle" />
                              2.08%
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="text-truncate fs-14 fs-medium mb-0">
                              <i className="ri-stop-fill align-middle fs-18 text-warning me-2" />
                              Mobile Users
                            </h4>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-users me-2 icon-sm"
                              >
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx={9} cy={7} r={4} />
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                              </svg>
                              105.02k
                            </p>
                          </td>
                          <td className="text-end">
                            <p className="text-danger fw-medium fs-12 mb-0">
                              <i className="ri-arrow-down-s-fill fs-5 align-middle" />
                              10.52%
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="text-truncate fs-14 fs-medium mb-0">
                              <i className="ri-stop-fill align-middle fs-18 text-info me-2" />
                              Tablet Users
                            </h4>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-users me-2 icon-sm"
                              >
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx={9} cy={7} r={4} />
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                              </svg>
                              42.89k
                            </p>
                          </td>
                          <td className="text-end">
                            <p className="text-danger fw-medium fs-12 mb-0">
                              <i className="ri-arrow-down-s-fill fs-5 align-middle" />
                              7.36%
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
            <div className="col-xl-4 col-md-6">
              <div className="card ">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Top Referrals Pages
                  </h4>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-soft-primary btn-sm shadow-none"
                    >
                      Export Report
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <h6 className="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">
                        Total Referrals Page
                      </h6>
                      <h4 className="fs- mb-0">725,800</h4>
                      <p className="mb-0 mt-2 text-muted">
                        <span className="badge bg-success-subtle text-success mb-0">
                          <i className="ri-arrow-up-line align-middle" /> 15.72
                          %
                        </span>{" "}
                        vs. previous month
                      </p>
                    </div>
                    {/* end col */}
                    <div className="col-6">
                      <div className="text-center">
                        <img
                          src="assets/images/illustrator-1.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    {/* end col */}
                  </div>
                  {/* end row */}
                  <div className="mt-3 pt-2">
                    <div className="progress progress-lg rounded-pill">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: "25%" }}
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{ width: "18%" }}
                        aria-valuenow={18}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: "22%" }}
                        aria-valuenow={22}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{ width: "16%" }}
                        aria-valuenow={16}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{ width: "19%" }}
                        aria-valuenow={19}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                  {/* end */}
                  <div className="mt-3 pt-2">
                    <div className="d-flex mb-2">
                      <div className="flex-grow-1">
                        <p className="text-truncate text-muted fs-14 mb-0">
                          <i className="mdi mdi-circle align-middle text-primary me-2" />
                          www.google.com
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <p className="mb-0">24.58%</p>
                      </div>
                    </div>
                    {/* end */}
                    <div className="d-flex mb-2">
                      <div className="flex-grow-1">
                        <p className="text-truncate text-muted fs-14 mb-0">
                          <i className="mdi mdi-circle align-middle text-info me-2" />
                          www.youtube.com
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <p className="mb-0">17.51%</p>
                      </div>
                    </div>
                    {/* end */}
                    <div className="d-flex mb-2">
                      <div className="flex-grow-1">
                        <p className="text-truncate text-muted fs-14 mb-0">
                          <i className="mdi mdi-circle align-middle text-success me-2" />
                          www.meta.com
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <p className="mb-0">23.05%</p>
                      </div>
                    </div>
                    {/* end */}
                    <div className="d-flex mb-2">
                      <div className="flex-grow-1">
                        <p className="text-truncate text-muted fs-14 mb-0">
                          <i className="mdi mdi-circle align-middle text-warning me-2" />
                          www.medium.com
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <p className="mb-0">12.22%</p>
                      </div>
                    </div>
                    {/* end */}
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-truncate text-muted fs-14 mb-0">
                          <i className="mdi mdi-circle align-middle text-danger me-2" />
                          Other
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <p className="mb-0">17.58%</p>
                      </div>
                    </div>
                    {/* end */}
                  </div>
                  {/* end */}
                  <div className="mt-2 text-center">
                    <a
                      href="javascript:void(0);"
                      className="text-muted text-decoration-underline"
                    >
                      Show All
                    </a>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
            <div className="col-xl-4 col-md-6">
              <div className="card ">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Top Pages</h4>
                  <div className="flex-shrink-0">
                    <div className="dropdown card-header-dropdown">
                      <a
                        className="text-reset dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="text-muted fs-16">
                          <i className="mdi mdi-dots-vertical align-middle" />
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a className="dropdown-item" href="#">
                          Today
                        </a>
                        <a className="dropdown-item" href="#">
                          Last Week
                        </a>
                        <a className="dropdown-item" href="#">
                          Last Month
                        </a>
                        <a className="dropdown-item" href="#">
                          Current Year
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card header */}
                <div className="card-body">
                  <div className="table-responsive table-card">
                    <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                      <thead className="text-muted table-light">
                        <tr>
                          <th scope="col" style={{ width: 62 }}>
                            Active Page
                          </th>
                          <th scope="col">Active</th>
                          <th scope="col">Users</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a href="javascript:void(0);">
                              /themesbrand/skote-25867
                            </a>
                          </td>
                          <td>99</td>
                          <td>25.3%</td>
                        </tr>
                        {/* end */}
                        <tr>
                          <td>
                            <a href="javascript:void(0);">
                              /dashonic/chat-24518
                            </a>
                          </td>
                          <td>86</td>
                          <td>22.7%</td>
                        </tr>
                        {/* end */}
                        <tr>
                          <td>
                            <a href="javascript:void(0);">
                              /skote/timeline-27391
                            </a>
                          </td>
                          <td>64</td>
                          <td>18.7%</td>
                        </tr>
                        {/* end */}
                        <tr>
                          <td>
                            <a href="javascript:void(0);">
                              /themesbrand/minia-26441
                            </a>
                          </td>
                          <td>53</td>
                          <td>14.2%</td>
                        </tr>
                        {/* end */}
                        <tr>
                          <td>
                            <a href="javascript:void(0);">
                              /dashon/dashboard-29873
                            </a>
                          </td>
                          <td>33</td>
                          <td>12.6%</td>
                        </tr>
                        {/* end */}
                        <tr>
                          <td>
                            <a href="javascript:void(0);">/doot/chats-29964</a>
                          </td>
                          <td>20</td>
                          <td>10.9%</td>
                        </tr>
                        {/* end */}
                        <tr>
                          <td>
                            <a href="javascript:void(0);">
                              /minton/pages-29739
                            </a>
                          </td>
                          <td>10</td>
                          <td>07.3%</td>
                        </tr>
                        {/* end */}
                      </tbody>
                      {/* end tbody */}
                    </table>
                    {/* end table */}
                  </div>
                  {/* end */}
                </div>
                {/* end cardbody */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
   
   
   </>
    )
}

export default AnalyticsPage