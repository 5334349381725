import Localbase from "localbase";
import ENDPOINT from "../../context/EndPoint";

const LogoutUser = ({payload})=>{

const db = new Localbase()
db.collection('paths').doc('user').delete()
    fetch(`${ENDPOINT}logout.php?session=${payload.session} `)
    .then(r=>r.json())
    .then(result=>{
          
      db.collection('paths').doc('user').delete()
      
      if(result.status==="loggedout"){
        db.collection('paths').doc('user').delete()

db.collection("paths").doc("notifications").delete()

localStorage.setItem("user", null);
localStorage.setItem("msg", null);
window.location.replace("/");
      }
      else{
       
      }
          

    
                
    
        
    })
    


}
export default LogoutUser