import React from 'react'
import CustomerSupportTicketItem from './CustomerTicketItem'
import { Link } from 'react-router-dom'


const CustomerTicketsList = ({ data, handleSearch, Reload, options }) => {

  // console.log(options)
  return (
    <div>



      <div className="col-12 ">


        <div className="card border-0 shadow-none shadow-md bg-transparent bg-md-white ">


          <div className="card-header d-none d-md-flex mb-2 mb-md-0 border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Customer Support Requests
            </h4>
            {
              options?.view === false ? <></> :
                <Link to='/customer-support' className='text-decoration-none text-primary m-0 h6'><strong>VIEW ALL</strong> </Link>

            }          <div>

        
            </div>
          </div>

          {/* end card header */}
          <div className="card-body">
            <div className="table-responsive table-card">
              <div className="table table-borderless table-centered align-middle mb-0">
          
                <div>


                  {data ? <>

                    {data.map((item) => (
                      <>

                        <CustomerSupportTicketItem options={options} Reload={Reload} item={item} />
                      </>
                    ))}

                  </> :
                    <>


                    </>}
                </div>
                {/* end tbody */}
              </div>
              {/* end table */}
            </div>
          </div>
        </div>{" "}

      </div>



    </div>
  )
}

export default CustomerTicketsList