import React, { useState, useEffect } from "react";
import axios from "axios";
import ExpenseItem from "./ExpenseItem"; // Create ExpenseItem component for rendering each expense
import { Link } from "react-router-dom";
import ENDPOINT from "../../context/EndPoint";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Toast, Modal } from "react-bootstrap";
import SecurityHeaders from "../../context/SecurityHeaders";
import { CircularProgress } from "@material-ui/core";
import ExpensesTable from "./ExpensesTable";

import ExpenseRow from "./ExpenseRow";
const Expenses = ({options}) => {
  const [expenses, setExpenses] = useState([]);
  const { user } = useContext(AuthContext);
  // fetch Expenses
  const fetchExpenses = async () => {
    try {
      const data = new FormData();
      data.append("session", user.SessionId);

      if(options?.limit){
        data.append('limit',options?.limit)
      }

      const response = await axios.post(
        ENDPOINT + "admin-get-expenses.php",
        data,
        SecurityHeaders
      );

      setExpenses(response.data.expenses);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, []);

  const openModal = (open) => {
    setModal(open);
  };
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState("pending");

  const [updateStatus, setUpdateStatus] = useState(false);
  //add and updateExpenses
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [expenseId, setExpenseId] = useState("");
  //toast
  const [toastTitle, setToastTitle] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toast, setToast] = useState(false);

  //Add Expenses

  const addExpenses = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData();
      data.append("session", user.SessionId);
      data.append("title", title);
      data.append("description", description);
      data.append("type", type);
      data.append("amount", amount);
      data.append("category", category);
      data.append("status", status);
      const res = await axios.post(
        ENDPOINT + "admin-add-expense.php",
        data,
        SecurityHeaders
      );

      if (res.data.status === "success") {
        setToastMessage("Add successfully");
        setToast(true);
        setModal(false);
        fetchExpenses();
      } else {
        setToastTitle("Something went wrong!");
        setToastMessage("Please try again...");
        setToast(true);
      }
    } catch (error) {
      setToastTitle("Error");
      setToastMessage("An error occurred. Please try again.");
      setToast(true);
    }
  };
  //Update Expenses



  return (
    <>
      <div className="position-fixed bottom-60 left-0 w-100 justify-center">
        <Toast
          className="bg-warning"
          onClose={() => setToast(false)}
          show={toast}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastTitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </div>
      <Modal
        show={modal}
        size="md"
        animation={false}
        onHide={() => setModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Expenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={addExpenses}>
              <div className="form-group">
                <label>title</label>
                <input
                  className="form-control"
                  placeholder="title"
                  value={title}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>description</label>
                <input
                  className="form-control"
                  placeholder="description"
                  value={description}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
             <div className="d-flex align-items-end">

             <div className="form-group col-6">
                <label>category</label>
                <input
                required
                  className="form-control"
                  placeholder="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
              <div className="form-group col-6">
            
                <select className="form-control"  onChange={(e) => setType(e.target.value)}>
                  <option>Expense Tpye</option>
                  <option>revenue</option>
                </select>
              </div>
             </div>
            

            <div className="d-flex my-2">
            <div className="form-group col-6">
                <label>amount</label>
                <input
                  className="form-control "
                  placeholder="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className="form-group  col-6">
                <label>status</label>
                <select
                  className="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option></option>
                  <option value="pending">pending</option>
                  <option value="granted">granted</option>
                </select>
              </div>
            </div>


              <button type="submit" className="btn btn-primary mt-3 w-100">
                {updateStatus ? (
                  <CircularProgress className="text-white" size={20} />
                ) : (
                  ""
                )}
                Add Expense
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <div>
   {
    options?.search === false?<></>:<>     <div className="container-fluid mt-4">


    {/*end row*/}
    <div className="row">
                      <div className="col-lg-3 col-md-3 col-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                  <i className="ri-arrow-down-circle-fill align-middle" />
                                </span>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                  Initiated{" "}
                                </p>
                                <h4 className=" mb-0">
                                  <span
                                    className="counter-value"
                                    data-target="14799.44"
                                  >
                                3
                                  </span>
                                </h4>
                              </div>
                              <div className="flex-shrink-0 align-self-end">
                                <span className="badge bg-danger-subtle text-danger">
                                  <i className="ri-arrow-down-s-fill align-middle me-1" />
                                3 %
                                  <span> </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* end card body */}
                        </div>
                        {/* end card */}
                      </div>
                      {/* end col */}
  
                      {/* end col */}
                      <div className="col-lg-3 col-md-3 col-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                  <i className="ri-arrow-down-circle-fill align-middle" />
                                </span>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                  Closed{" "}
                                </p>
                                <h4 className=" mb-0">
                                  <span
                                    className="counter-value"
                                    data-target="14799.44"
                                  >
                                   4
                                  </span>
                                </h4>
                              </div>
                              <div className="flex-shrink-0 align-self-end">
                                <span className="badge bg-danger-subtle text-danger">
                                  <i className="ri-arrow-down-s-fill align-middle me-1" />
                                  34 %<span> </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* end card body */}
                        </div>
                        {/* end card */}
                      </div>
                      {/* end col */}
  
                      {/* end col */}
                      <div className="col-lg-3 col-md-3 col-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                  <i className="ri-arrow-down-circle-fill align-middle" />
                                </span>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                  Total{" "}
                                </p>
                                <h4 className=" mb-0">
                                  <span
                                    className="counter-value"
                                    data-target="14799.44"
                                  >
                                43
                                  </span>
                                </h4>
                              </div>
                              <div className="flex-shrink-0 align-self-end">
                                <span className="badge bg-danger-subtle text-danger">
                                  <i className="ri-arrow-down-s-fill align-middle me-1" />
                                  <span> </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* end card body */}
                        </div>
                        {/* end card */}
                      </div>
                      {/* end col */}
  
                      {/* end col */}
                      <div className="col-lg-3 col-md-3 col-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                  <i className="ri-arrow-down-circle-fill align-middle" />
                                </span>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                  Avg Resolution Time{" "}
                                </p>
                                <h4 className=" mb-0">
                                  <span
                                    className="counter-value"
                                    data-target="14799.44"
                                  >
                                   97
                                  </span>
                                </h4>
                              </div>
                              <div className="flex-shrink-0 align-self-end">
                                <span className="badge bg-danger-subtle text-danger">
                                  <i className="ri-arrow-down-s-fill align-middle me-1" />
                                  %<span> </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* end card body */}
                        </div>
                        {/* end card */}
                      </div>
                      {/* end col */}
                    </div>{" "}
                    {/* end row*/}
  
                    <div className="row">
                  {/* end col sliding section*/}
                  <div className="col-12 order-xxl-0 order-first">
                    <div className="col-12 ">
                      <div className="card ">
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                     
                            <div className="row g-3">
                              <div className="col-xxl-3 col-sm-4">
                                <div className="search-box">
                                  <input
                                   
                                    type="text"
                                    className="form-control search"
                                    placeholder="Search for order ID, customer, order status or something..."
                                  />
                                  <i className="ri-search-line search-icon" />
                                </div>
                              </div>
                              {/*end col*/}
                              <div className="col-xxl-2 col-sm-6">
                                <div>
                                  <input
                                    type="date"
                                   
                                    className="form-control"
                                    data-provider="flatpickr"
                                    data-date-format="d M, Y"
                                    data-range-date="true"
                                    id="demo-datepicker"
                                    placeholder="Select date"
                                  />
                                </div>
                              </div>
                              {/*end col*/}
                              <div className="col-xxl-2 col-sm-4">
                                <div>
                                  <select
                                    className="form-control"
                              
                                    data-choices=""
                                    data-choices-search-false=""
                                    name="choices-single-default"
                                    id="idStatus"
                                  >
                                    <option value="">Status</option>
                                    <option value="unresolved">Unresolved</option>
                                    <option value="initiated">Initiated</option>
                                    <option value="rejected">Rejected</option>
                                    <option value="resolved">Resolved</option>
                                  </select>
                                </div>
                              </div>
                              {/*end col*/}
                              <div className="col-xxl-2 col-sm-4">
                                <div>
                                  <select
                                    className="form-control"
                                    data-choices=""
                                    data-choices-search-false=""
                                    name="choices-single-default"
                                    id="idPayment"
                                  >
                                    <option value="">Select Payment</option>
                                    <option value="all" selected="">
                                      All
                                    </option>
                                    <option value="Mastercard">Mastercard</option>
                                    <option value="Paypal">Paypal</option>
                                    <option value="Visa">Visa</option>
                                    <option value="COD">COD</option>
                                  </select>
                                </div>
                              </div>
                              {/*end col*/}
                              <div className="col-xxl-3 col-sm-4">
                                <div className="d-flex">
                                  <button
                                    type="button"
                                    className="btn me-2 btn-primary "
                                    onclick="SearchData();"
                                  >
                                    {" "}
                                    <i className="ri-equalizer-fill me-1 align-bottom" />
                                    Filters
                                  </button>
  
                                  <button onClick={() => setModal(true)} className="btn btn-danger w-fit h-fit">
                                    Add Expense
                                  </button>
                                </div>
                              </div>
                              {/*end col*/}
                            </div>
                            {/*end row*/}
                     
                        </div>
                        {/* end card header */}
                       
                      </div>{" "}
                    </div>
  
                
                  </div>
                  {/* end col */}
                </div>
  
  
          </div></>
   }

        <div className="container-fluid sm-p-15 ">
      
      
      <ExpensesTable data={<>
        {expenses
            ? expenses.map((item) => (
      <ExpenseRow item={item}/>
              ))
            : null}
    

      </>}/>
          {/* Map through the expenses array and render ExpenseItem components */}
   
        </div>
      </div>
    </>
  );
};

export default Expenses;
