import React from 'react'
import { Toast } from 'react-bootstrap'

const BasicToast = ({item,visibility,ToastHandleClose}) => {
  return (
    <>

<div className="position-fixed toast-container w-100 bottom-60 justify-content-center">
        <Toast
          className="bg-warning"
          onClose={ToastHandleClose}
          show={visibility}
          delay={1000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{item.title?item.title:null}</strong>
          </Toast.Header>
          <Toast.Body>{item.message?item.message:null}</Toast.Body>
        </Toast>
      </div>

        </>
  )
}

export default BasicToast