import React, { useContext, useEffect, useState } from 'react'
import CustomModal from './CustomModal'

import { AuthContext } from '../../context/AuthContext';
import { useActionsContext } from '../../context/ActionsContext';
import { Close } from '@material-ui/icons';

const ClassRescheduleModal = ({visibility,handleClose,item}) => {

  const {response,RescheduleClass} = useActionsContext()

    const [startdate, setstartdate] = useState(null);
    const [enddate, setenddate] = useState(null);
    const [reschedule, setreschedule] = useState(false);

    const {user} = useContext(AuthContext)
useEffect(()=>{

 // console.log("rescheduling ",response)


},[response.rescheduling_class])
  
  return (
    <>
    <CustomModal visibility={visibility} handleClose={handleClose}>
      <div className="modal-header">
        <h4>Reschedule Class</h4>
        <Close onClick={handleClose}/>
      </div>
<hr></hr>
    <form
              onSubmit={(e) => {
                e.preventDefault();
                RescheduleClass(  {ayload: {
                  user: user.SessionId,
                  class_starts: startdate,
                  class_ends: enddate,
                  class_id: item.id,
                }});
              }}
            >
              <div className="container">
                <h6>Class start time</h6>
                <div className="d-flex">
                  <input
                    required
                    className="form-control"
                    onChange={(e) => setstartdate(e.target.value)}
                    type="datetime-local"
                  ></input>
                </div>
              </div>

              <div className="container mt-4">
                <h6>Class end time</h6>
                <div className="d-flex">
                  <input
                    required
                    className="form-control"
                    onChange={(e) => setenddate(e.target.value)}
                    type="datetime-local"
                  ></input>
                </div>
              </div>

              <button className="btn btn-primary mt-3 w-100" type="submit">
                Save Changes
              </button>
            </form>

    </CustomModal>
    </>
  )
}

export default ClassRescheduleModal