import React, { useContext } from 'react'
import { useState } from 'react'
import AdminGiftCourseBadge from '../../actions/student/AdminGiftCourseBadge'
import AdminGiftPersonalBadge from '../../actions/user/AdminGiftPersonalBadge'
import { AuthContext } from '../../context/AuthContext'
import { connect } from 'react-redux'

const BadgeItem = ({item,course,student}) => {
const {user} = useContext(AuthContext)



const Unlock = async()=>{
  setunlocked(true)
  const res = await AdminGiftPersonalBadge({payload:{
    user:user.SessionId,
    user_id:student.user_id,
    badge_id:item.badge_id

  }})
  if(res){
    if(res.status==="success"){

    }
    else{
      setunlocked(false)
    }
  }else{
    setunlocked(false)
  }
}



const UnlockCourseBadge = async()=>{
  setunlocked(true)
  const res = await AdminGiftCourseBadge({payload:{
    user:user.SessionId,
    user_id:student.user_id,
    badge_id:item.badge_id,
    course_url:student.course_url

  }})
  if(res){
    if(res.status==="success"){

    }
    else{
      setunlocked(false)
    }
  }else{
    setunlocked(false)
  }
}
const [unlocked,setunlocked] = useState(item.status==="earned"?true:false)
     const [read,setread] = useState(false)
  return (
    <>
    
    <div className=" text-center h-fit br-4 p-2">
      <img src={item.image??item.image} onClick={()=>setread(!read)} className='w-100 mb-3 click'/>

{read?
<>
<p className="extra-small">{item.description}</p>
</>:null}


<div className="d-flex w-100 justify-content-between">



{course?
<>
{unlocked||item.status==="earned"?
<>



</>:
<>

<button onClick={()=>UnlockCourseBadge()} className="btn btn-outline-primary click w-100 btn-sm">Unlock</button>


</>}


</>:

<>
{unlocked||item.status==="earned"?
<>



</>:
<>

<button onClick={()=>Unlock()} className="btn btn-outline-primary w-100 btn-sm">Unlock</button>


</>}

</>}
</div>


</div> 
    </>
  )
}
const reduxStore = (prop)=>({
  student:prop.studentHandler.profile
})

export default connect(reduxStore) (BadgeItem);