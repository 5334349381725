import styled from "styled-components"
import PieChart from "../../components/charts/PieChart"
import Users from "../users/Users"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext"
import ENDPOINT from "../../context/EndPoint"
import { Info } from "react-bootstrap-icons"
import { NavItem } from "react-bootstrap"
import axios from "axios"
import { useParams } from "react-router-dom"
import StudentTicketItem from "../support/StudentTicketItem"
import CustomerSupportTicketItem from "../customer/CustomerTicketItem"
import GetStatistics from "../../actions/statistics/GetStatistics";
import SecurityHeaders from "../../context/SecurityHeaders"
import StudentsSupportPage from "../support/StudentsSupportPage"
import CustomerSupportPage from "../customer/CustomerSupportPage"
import Localbase from "localbase"

function SuperAdminDashboard({permission}) {
  const [total, setTotal] = useState()
  const [doubts, setdoubts] = useState([])
  const [info, setinfo] = useState();
  const [response, setResponse] = useState(doubts);
  const [statistics, setstatistics] = useState(null)
  const { user } = useContext(AuthContext);
  const userdata = user;
  const params = useParams();
  const db = new Localbase('db')


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getresult = async () => {
    try {
      const data = new FormData()
      const res = await axios.post(
        ENDPOINT + 'super-admin-statstics.php',
        data,
        { 'content-type': 'multipart/form-data' }
      );

      if (res.data) {
        setTotal(res.data)
        db.collection('paths').add({total:res.data},'total')
         // console.log(res.data.revenue)
      } else {
        console.error(res.data.revenue)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const GetDoubts = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
    const res = await axios.post(
      ENDPOINT + "admin-get-doubt-requests.php",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      console.log("hoilday",res.data.data)
       // console.log("Resp  is " + JSON.stringify(res.data.data))
      if (res.data.status === "success") {
        setdoubts(res.data.data)
        
        db.collection('paths').add({doubts:res.data.data},'doubts')
        //  // console.log(res.data.data)

      }
    }

  };  const FetchCustomerRequests = async () => {
    const data = new FormData()
    data.append("session", user.SessionId)

    const res = await axios.post(ENDPOINT + "admin-get-tickets.php", data, SecurityHeaders)

    if (res) {

      if (res.data.status === "success") {
        setdoubts(res.data.data)
        
        db.collection('paths').add({customer_doubts:res.data.data},'customer_doubts')
      }
    }
  }
  const load = () => {
    FetchCustomerRequests();
  }

  const Reload = () => {
  GetDoubts();
  };

  const GetStats = async () => {
    const res = await GetStatistics({ payload: { SessionId: user.SessionId } });

    if (res) {
      if (res.status === "success") {
        setinfo(res.data);
        db.collection('paths').add({info:res.data},'info')
      }
       // console.log("stat is" + JSON.stringify(res));
    }
  };
  const [courses, setCourses] = useState()
  const GetCourses = async () => {
    const data = new FormData()
    const res = await axios.post(ENDPOINT + "get-course-enrollment.php", data, SecurityHeaders)
    if (res.data) {
      setCourses(res.data.data)
      db.collection('paths').add({courses:res.data.data},'courses')
       // console.log(res.data.data)
    }
  }
  useEffect(()=>{
    
      GetCourses();
  },[])


  useEffect(() => {
    setResponse(doubts);
  }, [doubts]);

  useEffect(() => {
    
      GetDoubts({ payload: { id: null } });
    
     // console.log("doubts " + doubts);
  }, []);

  useEffect(() => {
    
    getresult();
    
  }, []);

  const Getofflinedata = () =>{
    db.collection('paths').doc('total').get().then((data)=>{
      if(data)
      {
        setTotal(data.total)
      }
    })
    db.collection('paths').doc('info').get().then((data)=>{
      if(data){
        setinfo(data.info)
      }
    })
    db.collection('paths').doc('doubts').get().then((data)=>{
      if(data)
      {
        setdoubts(data.doubts)
      }
    })
    db.collection('paths').doc('courses').get().then((data)=>{
      if(data)
      {
        setCourses(data.courses)
      }
    })
  }


  useEffect(() => {
      GetStats()
  }, []);

  useEffect(()=>{Getofflinedata()},[])


  return (

    <>
      {total ?
        <>


          <div className="container-fluid  dashboard-page">
            <div className="row">
              <div className="col">
                <div className="h-100">

             {parseInt(permission.super_admin)===1?
             <>
                  {/*end row*/}
                  <div className="row dashboard-data">
                    <div className="col-xl-3 col-md-6 p-2 col-6">
                      {/* card */}
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                
                                Total Earnings
                              </p>
                            </div>
                            <div className="flex-shrink-0 d-none d-md-block">
                              <h5 className="text-success fs-14 mb-0">
                                <i className="ri-arrow-right-up-line fs-13 align-middle" />
                                +16.24 %
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-1 mt-md-4">
                            <div>
                              <h4 className="fs-20 fs-md-22 fw-semibold ff-secondary mb-3 mb-md-4">
                                ₹
                                <span
                                  className="counter-value"
                                  data-target="559.25"
                                >
                                  {"+" + total.revenue ? total.revenue : 2000}
                                </span>
                                
                              </h4>
                              <a href="#" className="text-decoration-underline">
                              View <span className="d-none d-md-block">Payments</span>
                              </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-success-subtle rounded fs-3">
                                <i className="bx bx-dollar-circle text-success" />
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}
                    <div className="col-xl-3 col-md-6 p-2 col-6">
                      {/* card */}
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                Total Students
                              </p>
                            </div>
                            <div className="flex-shrink-0 d-none d-md-block">
                              <h5 className="text-danger fs-14 mb-0">
                                <i className="ri-arrow-right-down-line fs-13 align-middle" />
                                -3.57 %
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-1 mt-md-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-3 mb-md-4">
                                <span
                                  className="counter-value"
                                  data-target={36894}
                                >
                                  {"+" + total.students ? total.students : 2000}
                                </span>
                              </h4>
                              <a href="#" className="text-decoration-underline">
                              View <span className="d-none d-md-block">Orders</span>
                           
                              </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-info-subtle rounded fs-3">
                                <i className="bx bx-shopping-bag text-info" />
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>





                    {/* end col */}
                    <div className="col-xl-3 col-md-6 p-2 col-6">
                      {/* card */}
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                Total Team
                              </p>
                            </div>
                            <div className="flex-shrink-0 d-none d-md-block">
                              <h5 className="text-success fs-14 mb-0">
                                <i className="ri-arrow-right-up-line fs-13 align-middle" />
                                +29.08 %
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-1 mt-md-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-3 mb-md-4">
                                <span
                                  className="counter-value"
                                  data-target="183.35"
                                >
                                  {"+" + total.teams ? total.teams : 2000}
                                </span>

                              </h4>
                              <a href="#" className="text-decoration-underline">
                              View <span className="d-none d-md-block">Team</span>
                           
                              </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-warning-subtle rounded fs-3">
                                <i className="bx bx-user-circle text-warning" />
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}


                    {/* end col */}
                    <div className="col-xl-3 col-md-6 p-2 col-6">
                      {/* card */}
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                Balance
                              </p>
                            </div>
                            <div className="flex-shrink-0 d-none d-md-block">
                              <h5 className="text-success fs-14 mb-0">
                                <i className="ri-arrow-right-up-line fs-13 align-middle" />
                                +29.08 %
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-1 mt-md-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-3 mb-md-4">
                                <span
                                  className="counter-value"
                                  data-target="183.35"
                                >
                                  {"+" + total.total_balance ? total.total_balance : 2000}
                                </span>

                              </h4>
                              <a href="#" className="text-decoration-underline">
                              View <span className="d-none d-md-block">Payments</span>
                           
                              </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-warning-subtle rounded fs-3">
                                <i className="bx bx-user-circle text-warning" />
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}
                  </div>
                  {/* end row*/}


             
             </>:
             <>
             
             </>}


                  <div className="row">

                    {/* end col sliding section*/}
                    <div className="col-xxl-8 col-lg-8 order-xxl-0 order-first d-none d-md-flex flex-wrap">
                    <div className="no-wrap d-none overflow-x-scroll">

{/* end col */}
<div className="col-lg-4 me-2 col-md-4">
  <div className="card">
    <div className="card-body">
      <div className="d-flex align-items-center">
        <div className="avatar-sm flex-shrink-0">
          <span className="avatar-title bg-light text-primary rounded-circle fs-3">
            <i className="ri-arrow-up-circle-fill align-middle" />
          </span>
        </div>
        <div className="flex-grow-1 ms-3">
          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
            
            Pending Registrations
          </p>
          <h4 className=" mb-0">
            
            <span
              className="counter-value"
              data-target="19523.25"
            >
              {"+" + total.pending_registrations ? total.pending_registrations : 2000}
            </span>
          </h4>
        </div>
        <div className="flex-shrink-0 align-self-end">
          <span className="badge bg-success-subtle text-success">
            {"+" + total.registrations ? total.registrations : 2000} Total<span> </span>
          </span>
        </div>
      </div>
    </div>
    {/* end card body */}
  </div>
  {/* end card */}
</div>
{/* end col */}
<div className="col-lg-5 me-2 col-md-6">
  <div className="card">
    <div className="card-body">
      <div className="d-flex align-items-center">
        <div className="avatar-sm flex-shrink-0">
          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
            <i className="ri-money-dollar-circle-fill align-middle" />
          </span>
        </div>
        <div className="flex-grow-1 ms-3">
          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
            
            Active Workshop Leads
          </p>
          <h4 className=" mb-0">
            
            <span
              className="counter-value"
              data-target="2390.68"
            >
              {"+" + total.pending_leads ? total.pending_leads : 2000}
            </span>
          </h4>
        </div>
        <div className="flex-shrink-0 align-self-end">
          <span className="badge bg-success-subtle text-success">
            {total.today_total_leads ? total.today_total_leads : 2000} Total<span> </span>
          </span>
        </div>
      </div>
    </div>
    {/* end card body */}
  </div>
  {/* end card */}
</div>

<div className="col-lg-5 me-2 col-md-6">
  <div className="card">
    <div className="card-body">
      <div className="d-flex align-items-center">
        <div className="avatar-sm flex-shrink-0">
          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
            <i className="ri-arrow-down-circle-fill align-middle" />
          </span>
        </div>
        <div className="flex-grow-1 ms-3">
          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
            Active Ambassadors                         </p>
          <h4 className=" mb-0">
            
            <span
              className="counter-value"
              data-target="14799.44"
            >
              {"+" + total.pending_ambassadors ? total.pending_ambassadors : 2000}
            </span>
          </h4>
        </div>
        <div className="flex-shrink-0 align-self-end">
          <span className="badge bg-danger-subtle text-danger">
            <span>{"+" + total.ambassadors ? total.ambassadors : 2000} Total</span>
          </span>
        </div>
      </div>
    </div>
    {/* end card body */}
  </div>
  {/* end card */}
</div>
{/* end col */}

{/* end col */}
<div className="col-lg-4 me-2 col-md-6">
  <div className="card">
    <div className="card-body">
      <div className="d-flex align-items-center">
        <div className="avatar-sm flex-shrink-0">
          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
            <i className="ri-arrow-down-circle-fill align-middle" />
          </span>
        </div>
        <div className="flex-grow-1 ms-3">
          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
            Active Jobs                            </p>
          <h4 className=" mb-0">
            
            <span
              className="counter-value"
              data-target="14799.44"
            >
              {"+" + total.pending_internships ? total.pending_internships : 2000}
            </span>
          </h4>
        </div>
        <div className="flex-shrink-0 align-self-end">
          <span className="badge bg-danger-subtle text-danger">
            <span>  {"+" + total.internships ? total.internships : 2000} Total
            </span>
          </span>
        </div>
      </div>
    </div>
    {/* end card body */}
  </div>
  {/* end card */}
</div>
{/* end col */}



{/* end col */}
<div className="col-lg-4 col-md-6">
  <div className="card">
    <div className="card-body">
      <div className="d-flex align-items-center">
        <div className="avatar-sm flex-shrink-0">
          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
            <i className="ri-arrow-down-circle-fill align-middle" />
          </span>
        </div>
        <div className="flex-grow-1 ms-3">
          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
            Active Jobs                            </p>
          <h4 className=" mb-0">
            
            <span
              className="counter-value"
              data-target="14799.44"
            >
              {"+" + total.pending_jobs ? total.pending_jobs : 2000}
            </span>
          </h4>
        </div>
        <div className="flex-shrink-0 align-self-end">
          <span className="badge bg-danger-subtle text-danger">
            <span>{"+" + total.jobs ? total.jobs : 2000} Total </span>
          </span>
        </div>
      </div>
    </div>
    {/* end card body */}
  </div>
  {/* end card */}
</div>
{/* end col */}



{/* end col */}
<div className="col-lg-4 col-md-6">
  <div className="card">
    <div className="card-body">
      <div className="d-flex align-items-center">
        <div className="avatar-sm flex-shrink-0">
          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
            <i className="ri-arrow-down-circle-fill align-middle" />
          </span>
        </div>
        <div className="flex-grow-1 ms-3">
          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
            Active Batches                            </p>
          <h4 className=" mb-0">
            
            <span
              className="counter-value"
              data-target="14799.44"
            >
              {"+" + total.pending_cai_batches ? total.pending_cai_batches : 2000}
            </span>
          </h4>
        </div>
        <div className="flex-shrink-0 align-self-end">
          <span className="badge bg-danger-subtle text-danger">
            <span>{"+" + total.cai_batches ? total.cai_batches : 2000} Total</span>
          </span>
        </div>
      </div>
    </div>
    {/* end card body */}
  </div>
  {/* end card */}
</div>
{/* end col */}
</div>

                      <div className="d-flex w-100">

                        <div className="col-lg-12 ">


                          <div className="card overflow-scroll" style={{height:400}}>


                            <div className="card-header border-0 align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">
                               Recent Course Registrations
                              </h4>
                              <div>

                                <div className="d-flex">

                                  <button
                                    type="button"
                                    className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
                                  >
                                    7D
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
                                  >
                                    1M
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
                                  >
                                    1Y
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-soft-primary btn-sm material-shadow-none"
                                  >
                                    ALL
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* end card header */}
                            <div className="card-body">
                              <div className="table-responsive table-card">
                                <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                  <thead className="text-muted table-light">
                                    <tr>

                                      <th scope="col">Student</th>
                                     
                                      <th scope="col">Course Url</th>
                                      <th scope="col">Purchase Price</th>

                                      <th scope="col">Purchase Status</th>

                                    </tr>
                                  </thead>
                                  {courses && courses.length > 0 ? <>
                                  {courses.map((item)=>(
                                    <tbody>
                                    <tr>
                                       <td>
                                       <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-2">
                                            <img
                                              src={item.profile_pic}
                                              alt=""
                                              className="avatar-xs rounded-circle material-shadow"
                                            />
                                          </div>
                                          <div className="flex-grow-1">
                                           
                                          </div>
                                          <h5>{item.student_first_name}</h5>
                                        </div>
                                       
                                       </td>
                                      
                                      <td>{item.course_url}</td>
                                      <td>
                                        <span className="text-success">₹{item.purchase_price}</span>
                                      </td>

                                      <td>
                                        <span className="badge bg-success-subtle text-success">
                                          {item.purchase_status}
                                        </span>
                                      </td>

                                    </tr>
                                    {/* end tr */}
                                    {/* end tr */}
                                  </tbody>
                                  ))}
                                  </>:<>
                                  </>}
                                  
                                  {/* end tbody */}
                                </table>
                                {/* end table */}
                              </div>
                            </div>
                          </div>

                        </div>


                        {/*Graphical data */}



                        <div className="col-lg-5 ps-2">

                        </div>
                      </div>




                    </div>
                    {/* end col */}


                    <div className="col-lg-4 ">


                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Upcoming Activities (Today)
                          </h4>
                          <div className="flex-shrink-0">
                            <div className="dropdown card-header-dropdown">
                              <a
                                className="text-reset dropdown-btn"
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span className="text-muted fs-18">
                                  <i className="mdi mdi-dots-vertical" />
                                </span>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  Edit
                                </a>
                                <a className="dropdown-item" href="#">
                                  Remove
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end card header */}
                        <div className="card-body pt-0">
                          <ul className="list-group list-group-flush border-dashed">
                            <li className="list-group-item ps-0">
                              <div className="row align-items-center g-3">
                                <div className="col-auto">
                                  <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3 material-shadow">
                                    <div className="text-center">
                                      <h5 className="mb-0">{"+" + total.total_classes ? total.total_classes : 2000}</h5>
                                      <div className="text-muted"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <a href="#" className="text-reset fs-14 mb-0">
                                    Total Classes
                                  </a>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {"+" + total.total_pending_classes ? total.total_pending_classes : 2000} pending
                                  </h5>

                                </div>
                            
                              </div>
                              {/* end row */}
                            </li>
                            {/* end */}
                            <li className="list-group-item ps-0">
                              <div className="row align-items-center g-3">
                                <div className="col-auto">
                                  <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3 material-shadow">
                                    <div className="text-center">
                                      <h5 className="mb-0">{"+" + total.today_total_meetings ? total.today_total_meetings : 2000}</h5>
                                      <div className="text-muted"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <a href="#" className="text-reset fs-14 mb-0">
                                    Total Meetings
                                  </a>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {"+" + total.pending_meetings ? total.pending_meetings : 2000} pending
                                  </h5>

                                </div>
                              
                              </div>
                              {/* end row */}
                            </li>
                            {/* end */}
                            <li className="list-group-item ps-0">
                              <div className="row align-items-center g-3">
                                <div className="col-auto">
                                  <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3 material-shadow">
                                    <div className="text-center">
                                      <h5 className="mb-0">{"+" + total.today_total_doubt ? total.today_total_doubt : 2000}</h5>
                                      <div className="text-muted"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <a href="#" className="text-reset fs-14 mb-0">
                                    {"+" + total.total_doubts ? total.total_doubts : 2000} Student Doubts
                                  </a>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {"+" + total.pending_doubts ? total.pending_doubts : 2000} Pending
                                  </h5>

                                </div>
                            
                              </div>
                              {/* end row */}
                            </li>
                            {/* end */}
                            <li className="list-group-item ps-0">
                              <div className="row align-items-center g-3">
                                <div className="col-auto">
                                  <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3 material-shadow">
                                    <div className="text-center">
                                      <h5 className="mb-0">{"+" + total.today_logs ? total.today_logs : 2000}</h5>
                                      <div className="text-muted"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <a href="#" className="text-reset fs-14 mb-0">
                                    Customer Tickets
                                  </a>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {"+" + total.pending_logs ? total.pending_logs : 2000} pending
                                  </h5>

                                </div>
                          
                              </div>
                              {/* end row */}
                            </li>
                            {/* end */}

                            {/* end */}
                            <li className="list-group-item ps-0">
                              <div className="row align-items-center g-3">
                                <div className="col-auto">
                                  <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3 material-shadow">
                                    <div className="text-center">
                                      <h5 className="mb-0">{"+" + total.today_total_leads ? total.today_total_leads : 2000}</h5>
                                      <div className="text-muted"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <a href="#" className="text-reset fs-14 mb-0">
                                    New Leads
                                  </a>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {"+" + total.pending_leads ? total.pending_leads : 2000} Pending Interaction
                                  </h5>

                                </div>
                       
                              </div>
                              {/* end row */}
                            </li>
                            {/* end */}
                          </ul>
                          {/* end */}
                          {/* <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
                            <div className="col-sm">
                              <div className="text-muted">
                                Showing<span className="fw-semibold">4</span> of
                                <span className="fw-semibold">125</span> Results
                              </div>
                            </div>
                            <div className="col-sm-auto">
                              <ul className="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                                <li className="page-item disabled">
                                  <a href="#" className="page-link">
                                    ←
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a href="#" className="page-link">
                                    1
                                  </a>
                                </li>
                                <li className="page-item active">
                                  <a href="#" className="page-link">
                                    2
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a href="#" className="page-link">
                                    3
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a href="#" className="page-link">
                                    →
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div> */}
                        </div>
                        {/* end card body */}
                      </div>

                      <div className="card card-height-100 d-none">
                        <div className="card-header border-0 align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            <div className="dropdown">
                              <button
                                className="btn btn-soft-primary btn-sm material-shadow-none"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span className="text-uppercase">
                                  Student Doubt Requests
                                  <i className="mdi mdi-chevron-down align-middle ms-1" />
                                </span>
                              </button>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  Customer Support Requests
                                </a>
                                <a className="dropdown-item" href="#">
                                  Trainer Support Requests
                                </a>
                                <a className="dropdown-item" href="#">
                                  College Support Requests
                                </a>

                                <a className="dropdown-item" href="#">
                                  Leave Requests
                                </a>
                              </div>
                            </div>
                          </h4>
                          <div>

                          </div>
                        </div>

                        <PieChart />
                        {/* end cardheader */}
                        <div className="card-body">
                          <div
                            id="portfolio_donut_charts"
                            data-colors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success"]'
                            data-colors-minimal='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.65", "--vz-primary-rgb, 0.50"]'
                            data-colors-interactive='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.65", "--vz-primary-rgb, 0.50"]'
                            data-colors-corporate='["--vz-primary", "--vz-secondary", "--vz-info", "--vz-success"]'
                            data-colors-galaxy='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.65", "--vz-primary-rgb, 0.50"]'
                            className="apex-charts"
                            dir="ltr"
                          />
                          <ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
                            <li className="list-group-item px-0">
                              <div className="d-flex">
                                <div className="flex-shrink-0 avatar-xs">
                                  <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                    <img
                                      src="assets/images/svg/crypto-icons/btc.svg"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                  <h6 className="mb-1">Bitcoin</h6>
                                  <p className="fs-12 mb-0 text-muted">
                                    <i className="mdi mdi-circle fs-10 align-middle text-primary me-1" />
                                    BTC
                                  </p>
                                </div>
                                <div className="flex-shrink-0 text-end">
                                  <h6 className="mb-1">BTC 0.00584875</h6>
                                  <p className="text-success fs-12 mb-0">19,405.12</p>
                                </div>
                              </div>
                            </li>
                            {/* end */}
                            <li className="list-group-item px-0">
                              <div className="d-flex">
                                <div className="flex-shrink-0 avatar-xs">
                                  <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                    <img
                                      src="assets/images/svg/crypto-icons/eth.svg"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                  <h6 className="mb-1">Ethereum</h6>
                                  <p className="fs-12 mb-0 text-muted">
                                    <i className="mdi mdi-circle fs-10 align-middle text-info me-1" />
                                    ETH
                                  </p>
                                </div>
                                <div className="flex-shrink-0 text-end">
                                  <h6 className="mb-1">ETH 2.25842108</h6>
                                  <p className="text-danger fs-12 mb-0">40552.18</p>
                                </div>
                              </div>
                            </li>
                            {/* end */}
                            <li className="list-group-item px-0">
                              <div className="d-flex">
                                <div className="flex-shrink-0 avatar-xs">
                                  <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                    <img
                                      src="assets/images/svg/crypto-icons/ltc.svg"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                  <h6 className="mb-1">Litecoin</h6>
                                  <p className="fs-12 mb-0 text-muted">
                                    <i className="mdi mdi-circle fs-10 align-middle text-warning me-1" />
                                    LTC
                                  </p>
                                </div>
                                <div className="flex-shrink-0 text-end">
                                  <h6 className="mb-1">LTC 10.58963217</h6>
                                  <p className="text-success fs-12 mb-0">15824.58</p>
                                </div>
                              </div>
                            </li>
                            {/* end */}
                            <li className="list-group-item px-0 pb-0">
                              <div className="d-flex">
                                <div className="flex-shrink-0 avatar-xs">
                                  <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                    <img
                                      src="assets/images/svg/crypto-icons/dash.svg"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                  <h6 className="mb-1">Dash</h6>
                                  <p className="fs-12 mb-0 text-muted">
                                    <i className="mdi mdi-circle fs-10 align-middle text-success me-1" />
                                    DASH
                                  </p>
                                </div>
                                <div className="flex-shrink-0 text-end">
                                  <h6 className="mb-1">DASH 204.28565885</h6>
                                  <p className="text-success fs-12 mb-0">30635.84</p>
                                </div>
                              </div>
                            </li>
                            {/* end */}
                          </ul>
                          {/* end */}
                        </div>
                        {/* end card body */}
                      </div>
                    </div>



                  </div>



                  <div className="row d-none">
                    <div className="col-xl-6 d-none">
                      <div className="card overflow-scroll" style={{ height: 500 }}>
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Students Doubts
                          </h4>
                          <div className="flex-shrink-0">
                            <div className="dropdown card-header-dropdown">
                              <a
                                className="text-reset dropdown-btn"
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span className="fw-semibold text-uppercase fs-12">
                                  Sort by:
                                </span>
                                <span className="text-muted">
                                  Today
                                  <i className="mdi mdi-chevron-down ms-1" />
                                </span>
                              </a>
                              {/* <div className="dropdown-menu dropdown-menu-end">
                              <a className="dropdown-item" href="#">
                                Today
                              </a>
                              <a className="dropdown-item" href="#">
                                Yesterday
                              </a>
                              <a className="dropdown-item" href="#">
                                Last 7 Days
                              </a>
                              <a className="dropdown-item" href="#">
                                Last 30 Days
                              </a>
                              <a className="dropdown-item" href="#">
                                This Month
                              </a>
                              <a className="dropdown-item" href="#">
                                Last Month
                              </a>
                            </div> */}
                            </div>
                          </div>
                        </div>
                        {/* end card header */}



                        <div className="card-body">
                   {/* <StudentsSupportPage options={{userinfo:true, limit:5}}/> */}

                          <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                            <div className="col-sm">
                              <div className="text-muted">
                                Showing <span className="fw-semibold">5</span> of
                                <span className="fw-semibold">25</span> Results
                              </div>
                            </div>
                            <div className="col-sm-auto  mt-3 mt-sm-0">
                              <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                                <li className="page-item disabled">
                                  <a href="#" className="page-link">
                                    ←
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a href="#" className="page-link">
                                    1
                                  </a>
                                </li>
                                <li className="page-item active">
                                  <a href="#" className="page-link">
                                    2
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a href="#" className="page-link">
                                    3
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a href="#" className="page-link">
                                    →
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="card overflow-scroll" style={{ height: 500 }}>
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Customer Support
                          </h4>
                          <div className="flex-shrink-0">
                            <div className="dropdown card-header-dropdown">
                              <a
                                className="text-reset dropdown-btn"
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span className="text-muted">
                                  Report
                                  <i className="mdi mdi-chevron-down ms-1" />
                                </span>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  Download Report
                                </a>
                                <a className="dropdown-item" href="#">
                                  Export
                                </a>
                                <a className="dropdown-item" href="#">
                                  Import
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end card header */}
                        <div className="card-body">
                        {/* <CustomerSupportPage options={{userinfo:true,limit:5,search:false}}/> */}
                        </div>
                        {/* .card-body*/}
                      </div>
                      {/* .card*/}
                    </div>
                    {/* .col*/}
                  </div>
                  {/* end row*/}
                  <div className="row d-none">
                    <div className="col-xl-4">
                      <div className="card overflow-scroll" style={{ height: 500 }}>
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Store Visits by Source
                          </h4>
                          <div className="flex-shrink-0">
                            <div className="dropdown card-header-dropdown">
                              <a
                                className="text-reset dropdown-btn"
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span className="text-muted">
                                  Report
                                  <i className="mdi mdi-chevron-down ms-1" />
                                </span>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  Download Report
                                </a>
                                <a className="dropdown-item" href="#">
                                  Export
                                </a>
                                <a className="dropdown-item" href="#">
                                  Import
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
     
                        <div className="card-body">
                          <div
                            id="store-visits-source"
                            data-colors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'
                            data-colors-minimal='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.70", "--vz-primary-rgb, 0.60", "--vz-primary-rgb, 0.45"]'
                            data-colors-interactive='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.70", "--vz-primary-rgb, 0.60", "--vz-primary-rgb, 0.45"]'
                            data-colors-galaxy='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.70", "--vz-primary-rgb, 0.60", "--vz-primary-rgb, 0.45"]'
                            className="apex-charts"
                            dir="ltr"
                          />
                        </div>
                      </div>
              
                    </div>
                 
                    <div className="col-xl-8">


                      <Users />


                    </div>
                  </div>
         
                </div>
              </div>
              <div className="col-auto layout-rightside-col">
                <div className="overlay" />
                <div className="layout-rightside">
                  <div className="card h-100 rounded-0">
                    <div className="card-body p-0">
                      <div className="p-3">
                        <h6 className="text-muted mb-0 text-uppercase fw-semibold">
                          Recent Activity
                        </h6>
                      </div>
                      <div
                        data-simplebar=""
                        style={{ maxHeight: 410 }}
                        className="p-3 pt-0"
                      >
                        <div className="acitivity-timeline acitivity-main">
                          <div className="acitivity-item d-flex">
                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div className="avatar-title bg-success-subtle text-success rounded-circle material-shadow">
                                <i className="ri-shopping-cart-2-line" />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1 lh-base">
                                Purchase by James Price
                              </h6>
                              <p className="text-muted mb-1">
                                Product noise evolve smartwatch
                              </p>
                              <small className="mb-0 text-muted">
                                02:14 PM Today
                              </small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div className="avatar-title bg-danger-subtle text-danger rounded-circle material-shadow">
                                <i className="ri-stack-fill" />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1 lh-base">
                                Added new
                                <span className="fw-semibold">
                                  style collection
                                </span>
                              </h6>
                              <p className="text-muted mb-1">
                                By Nesta Technologies
                              </p>
                              <div className="d-inline-flex gap-2 border border-dashed p-2 mb-2">
                                <a
                                  href="apps-ecommerce-product-details.html"
                                  className="bg-light rounded p-1"
                                >
                                  <img
                                    src="assets/images/products/img-8.png"
                                    alt=""
                                    className="img-fluid d-block"
                                  />
                                </a>
                                <a
                                  href="apps-ecommerce-product-details.html"
                                  className="bg-light rounded p-1"
                                >
                                  <img
                                    src="assets/images/products/img-2.png"
                                    alt=""
                                    className="img-fluid d-block"
                                  />
                                </a>
                                <a
                                  href="apps-ecommerce-product-details.html"
                                  className="bg-light rounded p-1"
                                >
                                  <img
                                    src="assets/images/products/img-10.png"
                                    alt=""
                                    className="img-fluid d-block"
                                  />
                                </a>
                              </div>
                              <p className="mb-0 text-muted">
                                <small>9:47 PM Yesterday</small>
                              </p>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src="assets/images/users/avatar-2.jpg"
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar material-shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1 lh-base">
                                Natasha Carey have liked the products
                              </h6>
                              <p className="text-muted mb-1">
                                Allow users to like products in your WooCommerce
                                store.
                              </p>
                              <small className="mb-0 text-muted">
                                25 Dec, 2021
                              </small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <div className="avatar-xs acitivity-avatar">
                                <div className="avatar-title rounded-circle bg-secondary material-shadow">
                                  <i className="mdi mdi-sale fs-14" />
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1 lh-base">
                                Today offers by
                                <a
                                  href="apps-ecommerce-seller-details.html"
                                  className="link-secondary"
                                >
                                  Digitech Galaxy
                                </a>
                              </h6>
                              <p className="text-muted mb-2">
                                Offer is valid on orders of Rs.500 Or above for
                                selected products only.
                              </p>
                              <small className="mb-0 text-muted">
                                12 Dec, 2021
                              </small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <div className="avatar-xs acitivity-avatar">
                                <div className="avatar-title rounded-circle bg-danger-subtle text-danger material-shadow">
                                  <i className="ri-bookmark-fill" />
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1 lh-base">Favorite Product</h6>
                              <p className="text-muted mb-2">
                                Esther James have Favorite product.
                              </p>
                              <small className="mb-0 text-muted">
                                25 Nov, 2021
                              </small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <div className="avatar-xs acitivity-avatar">
                                <div className="avatar-title rounded-circle bg-secondary material-shadow">
                                  <i className="mdi mdi-sale fs-14" />
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1 lh-base">
                                Flash sale starting
                                <span className="text-primary">Tomorrow.</span>
                              </h6>
                              <p className="text-muted mb-0">
                                Flash sale by
                                <a
                                  href="javascript:void(0);"
                                  className="link-secondary fw-medium"
                                >
                                  Zoetic Fashion
                                </a>
                              </p>
                              <small className="mb-0 text-muted">
                                22 Oct, 2021
                              </small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <div className="avatar-xs acitivity-avatar">
                                <div className="avatar-title rounded-circle bg-info-subtle text-info material-shadow">
                                  <i className="ri-line-chart-line" />
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1 lh-base">
                                Monthly sales report
                              </h6>
                              <p className="text-muted mb-2">
                                <span className="text-danger">2 days left</span>
                                notification to submit the monthly sales report.
                                <a
                                  href="javascript:void(0);"
                                  className="link-warning text-decoration-underline"
                                >
                                  Reports Builder
                                </a>
                              </p>
                              <small className="mb-0 text-muted">15 Oct</small>
                            </div>
                          </div>
                          <div className="acitivity-item d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src="assets/images/users/avatar-3.jpg"
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar material-shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1 lh-base">
                                Frank Hook Commented
                              </h6>
                              <p className="text-muted mb-2 fst-italic">
                                " A product that has reviews is more likable to be
                                sold than a product. "
                              </p>
                              <small className="mb-0 text-muted">
                                26 Aug, 2021
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 mt-2">
                        <h6 className="text-muted mb-3 text-uppercase fw-semibold">
                          Top 10 Categories
                        </h6>
                        <ol className="ps-3 text-muted">
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Mobile &amp; Accessories
                              <span className="float-end">(10,294)</span>
                            </a>
                          </li>
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Desktop <span className="float-end">(6,256)</span>
                            </a>
                          </li>
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Electronics
                              <span className="float-end">(3,479)</span>
                            </a>
                          </li>
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Home &amp; Furniture
                              <span className="float-end">(2,275)</span>
                            </a>
                          </li>
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Grocery <span className="float-end">(1,950)</span>
                            </a>
                          </li>
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Fashion <span className="float-end">(1,582)</span>
                            </a>
                          </li>
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Appliances
                              <span className="float-end">(1,037)</span>
                            </a>
                          </li>
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Beauty, Toys &amp; More
                              <span className="float-end">(924)</span>
                            </a>
                          </li>
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Food &amp; Drinks
                              <span className="float-end">(701)</span>
                            </a>
                          </li>
                          <li className="py-1">
                            <a href="#" className="text-muted">
                              Toys &amp; Games
                              <span className="float-end">(239)</span>
                            </a>
                          </li>
                        </ol>
                        <div className="mt-3 text-center">
                          <a
                            href="javascript:void(0);"
                            className="text-muted text-decoration-underline"
                          >
                            View all Categories
                          </a>
                        </div>
                      </div>
                      <div className="p-3">
                        <h6 className="text-muted mb-3 text-uppercase fw-semibold">
                          Products Reviews
                        </h6>
                        {/* Swiper */}
                        <div
                          className="swiper vertical-swiper"
                          style={{ height: 250 }}
                        >
                          <div className="swiper-wrapper">
                            <div className="swiper-slide">
                              <div className="card border border-dashed shadow-none">
                                <div className="card-body">
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 avatar-sm">
                                      <div className="avatar-title bg-light rounded material-shadow">
                                        <img
                                          src="assets/images/companies/img-1.png"
                                          alt=""
                                          height={30}
                                        />
                                      </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <div>
                                        <p className="text-muted mb-1 fst-italic text-truncate-two-lines">
                                          
                                          " Great product and looks great, lots of
                                          features. "
                                        </p>
                                        <div className="fs-11 align-middle text-warning">
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                        </div>
                                      </div>
                                      <div className="text-end mb-0 text-muted">
                                        - by
                                        <cite title="Source Title">
                                          Force Medicines
                                        </cite>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="card border border-dashed shadow-none">
                                <div className="card-body">
                                  <div className="d-flex">
                                    <div className="flex-shrink-0">
                                      <img
                                        src="assets/images/users/avatar-3.jpg"
                                        alt=""
                                        className="avatar-sm rounded material-shadow"
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <div>
                                        <p className="text-muted mb-1 fst-italic text-truncate-two-lines">
                                          
                                          " Amazing template, very easy to
                                          understand and manipulate. "
                                        </p>
                                        <div className="fs-11 align-middle text-warning">
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-half-fill" />
                                        </div>
                                      </div>
                                      <div className="text-end mb-0 text-muted">
                                        - by
                                        <cite title="Source Title">
                                          Henry Baird
                                        </cite>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="card border border-dashed shadow-none">
                                <div className="card-body">
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 avatar-sm">
                                      <div className="avatar-title bg-light rounded">
                                        <img
                                          src="assets/images/companies/img-8.png"
                                          alt=""
                                          height={30}
                                        />
                                      </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <div>
                                        <p className="text-muted mb-1 fst-italic text-truncate-two-lines">
                                          
                                          "Very beautiful product and Very helpful
                                          customer service."
                                        </p>
                                        <div className="fs-11 align-middle text-warning">
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-line" />
                                          <i className="ri-star-line" />
                                        </div>
                                      </div>
                                      <div className="text-end mb-0 text-muted">
                                        - by
                                        <cite title="Source Title">
                                          Zoetic Fashion
                                        </cite>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="card border border-dashed shadow-none">
                                <div className="card-body">
                                  <div className="d-flex">
                                    <div className="flex-shrink-0">
                                      <img
                                        src="assets/images/users/avatar-2.jpg"
                                        alt=""
                                        className="avatar-sm rounded material-shadow"
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <div>
                                        <p className="text-muted mb-1 fst-italic text-truncate-two-lines">
                                          " The product is very beautiful. I like
                                          it. "
                                        </p>
                                        <div className="fs-11 align-middle text-warning">
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-fill" />
                                          <i className="ri-star-half-fill" />
                                          <i className="ri-star-line" />
                                        </div>
                                      </div>
                                      <div className="text-end mb-0 text-muted">
                                        - by
                                        <cite title="Source Title">
                                          Nancy Martino
                                        </cite>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3">
                        <h6 className="text-muted mb-3 text-uppercase fw-semibold">
                          Customer Reviews
                        </h6>
                        <div className="bg-light px-3 py-2 rounded-2 mb-2">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <div className="fs-16 align-middle text-warning">
                                <i className="ri-star-fill" />
                                <i className="ri-star-fill" />
                                <i className="ri-star-fill" />
                                <i className="ri-star-fill" />
                                <i className="ri-star-half-fill" />
                              </div>
                            </div>
                            <div className="flex-shrink-0">
                              <h6 className="mb-0">4.5 out of 5</h6>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <div className="text-muted">
                            Total <span className="fw-medium">5.50k</span> reviews
                          </div>
                        </div>
                        <div className="mt-3">
                          <div className="row align-items-center g-2">
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0">5 star</h6>
                              </div>
                            </div>
                            <div className="col">
                              <div className="p-1">
                                <div className="progress animated-progress progress-sm">
                                  <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    style={{ width: "50.16%" }}
                                    aria-valuenow="50.16"
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0 text-muted">2758</h6>
                              </div>
                            </div>
                          </div>
                          {/* end row */}
                          <div className="row align-items-center g-2">
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0">4 star</h6>
                              </div>
                            </div>
                            <div className="col">
                              <div className="p-1">
                                <div className="progress animated-progress progress-sm">
                                  <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    style={{ width: "29.32%" }}
                                    aria-valuenow="29.32"
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0 text-muted">1063</h6>
                              </div>
                            </div>
                          </div>
                          {/* end row */}
                          <div className="row align-items-center g-2">
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0">3 star</h6>
                              </div>
                            </div>
                            <div className="col">
                              <div className="p-1">
                                <div className="progress animated-progress progress-sm">
                                  <div
                                    className="progress-bar bg-warning"
                                    role="progressbar"
                                    style={{ width: "18.12%" }}
                                    aria-valuenow="18.12"
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0 text-muted">997</h6>
                              </div>
                            </div>
                          </div>
                          {/* end row */}
                          <div className="row align-items-center g-2">
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0">2 star</h6>
                              </div>
                            </div>
                            <div className="col">
                              <div className="p-1">
                                <div className="progress animated-progress progress-sm">
                                  <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    style={{ width: "4.98%" }}
                                    aria-valuenow="4.98"
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0 text-muted">227</h6>
                              </div>
                            </div>
                          </div>
                          {/* end row */}
                          <div className="row align-items-center g-2">
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0">1 star</h6>
                              </div>
                            </div>
                            <div className="col">
                              <div className="p-1">
                                <div className="progress animated-progress progress-sm">
                                  <div
                                    className="progress-bar bg-danger"
                                    role="progressbar"
                                    style={{ width: "7.42%" }}
                                    aria-valuenow="7.42"
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="p-1">
                                <h6 className="mb-0 text-muted">408</h6>
                              </div>
                            </div>
                          </div>
                          {/* end row */}
                        </div>
                      </div>
                      <div className="card sidebar-alert bg-light border-0 text-center mx-4 mb-0 mt-3">
                        <div className="card-body">
                          <img src="assets/images/giftbox.png" alt="" />
                          <div className="mt-4">
                            <h5>Invite New Seller</h5>
                            <p className="text-muted lh-base">
                              Refer a new seller to us and earn 100 per refer.
                            </p>
                            <button
                              type="button"
                              className="btn btn-primary btn-label rounded-pill"
                            >
                              <i className="ri-mail-fill label-icon align-middle rounded-pill fs-16 me-2" />
                              Invite Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end card*/}
                </div>
                {/* end .rightbar*/}
              </div>

            </div>
          </div>
        </> :
        <>


        </>}

    </>
  )


}

export default SuperAdminDashboard



const StyledSuperAdminDashboard = styled.div`



h1{

    color:red;
}




`