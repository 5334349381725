import React, { useEffect } from 'react'
import { useActionsContext } from '../../context/ActionsContext'
import dateFormat from 'dateformat'
import { Avatar } from '@mui/material'
const EmployeeLeaderboard = () => {

  const { internalleaderboard, fetchInternalLeaderboard } = useActionsContext()


  useEffect(() => {


    fetchInternalLeaderboard()

  }, [])
  return (
    <>
      <div className="card p-3">
        <h3>Leaderboard</h3>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive">
              <table className="table table-borderless align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Leave Type</th>
                    <th scope="col">Rank</th>
                    <th scope="col">Efficiency</th>
                    <th scope="col">Work hours</th>
                    <th scope="col">Tasks Completed</th>
                    <th scope="col">Work Days</th>

                  </tr>
                </thead>
                <>
                  {internalleaderboard && internalleaderboard.data ?
                    <>
                      {internalleaderboard.data.map((item, index) => (
                        <>

                          <tr>

                            <td>
                              <div className="d-flex align-items-center">

                                <div className="ms-3 flex-grow-1">
                                  <div className="d-flex align-items-center">

                                    <Avatar src={item.profile_pic} className='me-2' />

                                    <div>
                                      <h6>{item.first_name + " " + item.last_name}</h6>
                                      <p className='p-0 m-0'>{item.role}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>{index + 1}</td>

                            <td>{item.efficiency}</td>
                            <td>{item.total_work_hours}</td>


                            <td>{item.total_tasks_completed}</td>

                            <td>{item.work_days}</td>

                          </tr>
                        </>
                      ))}

                    </> :
                    <>


                    </>}
                </>
              </table>
            </div>

          </div>
        </div>
      </div>


    </>
  )
}

export default EmployeeLeaderboard