import styled from "styled-components"
import PieChart from "../../components/charts/PieChart"
import Users from "../users/Users"
import DemoData from "../../data/DemoData"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext"
import { useParams } from "react-router-dom"
import axios from "axios"
import ENDPOINT from "../../context/EndPoint"
import SecurityHeaders from "../../context/SecurityHeaders"
import GetStatistics from "../../actions/statistics/GetStatistics"
import { connect } from "react-redux";
import AdminDeleteTickets from "../../actions/student/AdminDeleteTickets";
import AdminUpdateDoubtRequest from "../../actions/support/AdminUpdateDoubtRequest"
import { StudentDoubtSupportProvider, useContactRequests } from "../../context/support/StudentDoubtSupportContext"
import CustomerSupportTicketItem from "./CustomerTicketItem"
import { useActionsContext } from "../../context/ActionsContext"
import FetchCustomerTickets from "../../actions/support/FetchCustomerTickets"
import CustomerTicketsList from "./CustomerTicketsList"
import Localbase from "localbase"
function CustomerSupportPage({ value, dispatch, options }) {


  const { user } = useContext(AuthContext);
  const userdata = user;


  const { enablestats } = useActionsContext()
  const [doubts, setdoubts] = useState(null)
  const params = useParams();
  const [status, setstatus] = useState("all");
const db = new Localbase("db")
  const [query, setquery] = useState(null);

  const [resolutions, setresolutions] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);




  

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [info, setinfo] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);
  const GetStats = async () => {
    const res = await GetStatistics({ payload: { SessionId: user.SessionId } });

    if (res) {
      if (res.status === "success") {
        setinfo(res.data);
        // db.collection('paths').add({info})
      }
      // console.log("stat is" + JSON.stringify(res));
    }
  };

  const [requestDelete, setRequestDelete] = useState("");


  useEffect(() => {
    db.collection('paths').doc('info').get().then((data)=>{
      if(data){
        setinfo(data.info)
      }
      GetStats();
      
    })
    
    
  }, []);

  // Initialize the response state with the provided data

  const [response, setResponse] = useState(doubts);
  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle changes in the search input
  const {searchquery} = useActionsContext()
  useEffect(()=>{
    handleSearch({term:searchquery})
  },[searchquery])
  const handleSearch = ({ term }) => {
    if (!term) {
      setResponse(doubts);
      setSearchTerm("");
      return;
    }
  
    if (term && doubts) {
      const filteredData = doubts.filter((item) => {
        return (
          (item.email && item.email.toLowerCase().includes(term.toLowerCase())) ||
          (item.phone && item.phone.includes(term)) ||
          (item.name && item.name.toLowerCase().includes(term.toLowerCase())) ||
          (item.message && item.message.toLowerCase().includes(term.toLowerCase())) ||
          (item.status && item.status.toLowerCase().includes(term.toLowerCase())) ||
          (item.create_datetime && item.create_datetime.toLowerCase().includes(term.toLowerCase()))
        );
      });
  
      setResponse(filteredData);
      setSearchTerm(term);
    }
  };
  

  const Reload = () => {

    FetchCustomerRequests()
  }
  const [statistics, setstatistics] = useState(null)
  const Statistics = async () => {
    const data = new FormData()
    data.append("session", user.SessionId)

    const res = await axios.post(ENDPOINT + "customer-support-statistics.php", data, SecurityHeaders)

    if (res) {

      if (res.data.status === "success") {
        setstatistics(res.data.data)
        ///
        db.collection('paths').add({statistics:res.data.data},'statistics')
        ///
      }
    }
  }
  const [limit, setlimit] = useState(100)

  const FetchCustomerRequests = async () => {

    const res = await FetchCustomerTickets({
      payload:
        { SessionId: user.SessionId, limit: options.limit || limit }
    }
    )

    if (res) {


      db.collection("paths").add({customer_tickets:res.data},"customer_tickets")
      setdoubts(res.data)
      console.log(res);

    }
  }
  ///
  useEffect(() => {
    db.collection('paths').doc('statistics').get().then((data)=>{
      if(data){
        setstatistics(data.statistics)
      }
      
    })
    Statistics()
  }, [])

  ///
  useEffect(() => {
    db.collection("paths").doc("customer_tickets").get().then(data=>{
      if(data){
        setdoubts(data.customer_tickets)
      }
     })
     FetchCustomerRequests()
  }, [])


  useEffect(() => {

    setResponse(doubts)
  }, [doubts])

  const [stats, setstats] = useState(value.statisticsHandler.statistics)
  useEffect(() => {
    setstats(value.statisticsHandler.statistics)
  }, [value.statisticsHandler.statistics])

const {voiceinput} = useActionsContext()
  useEffect(()=>{
    console.log(voiceinput)
    if(response){
      if(voiceinput.includes("searchfor")||voiceinput.includes("search")){
    
        var name = voiceinput
        if(voiceinput.includes("searchfor")){
         name =  voiceinput.replace("searchfor","")
        }
        else if (voiceinput.includes("search")){
        name =  voiceinput.replace("search","")
        }
      
    
    
        
        handleSearch({term:name})
        
        }
    }
      },[voiceinput])
  useEffect(() => {
    if (searchTerm) {
      handleSearch({ term: searchTerm })
    }
  }, [searchTerm,voiceinput])



  return (

    <>

      <div className="container-fluid p-0">
        <div className="w-100">
          <div className="w-100">
            <div className="h-100">


              {stats && (options && options.statistics) ?
                <>


                  {statistics ?
                    <>

                      {/*end row*/}
                      <div className="w-100">

                        <div className="col-lg-3 col-md-3 col-6">
                          <div className="card border-0 border-md-1">
                            <div className="card-body p-0">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                    <i className="ri-arrow-down-circle-fill align-middle" />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    Initiated
                                  </p>
                                  <h4 className=" mb-0">

                                    <span
                                      className="counter-value"
                                      data-target="14799.44"
                                    >
                                      {statistics.total_initiated}
                                    </span>
                                  </h4>
                                </div>
                                <div className="flex-shrink-0 align-self-end">
                                  <span className="badge bg-danger-subtle text-danger">
                                    <i className="ri-arrow-down-s-fill align-middle me-1" />
                                    {Math.round(statistics.percentage_initiated)}  %<span> </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/* end col */}
                        {/* end col */}
                        <div className="col-lg-3 col-md-3 col-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                    <i className="ri-arrow-down-circle-fill align-middle" />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    Closed                     </p>
                                  <h4 className=" mb-0">

                                    <span
                                      className="counter-value"
                                      data-target="14799.44"
                                    >
                                      {Math.round(statistics.total_closed)}
                                    </span>
                                  </h4>
                                </div>
                                <div className="flex-shrink-0 align-self-end">
                                  <span className="badge bg-danger-subtle text-danger">
                                    <i className="ri-arrow-down-s-fill align-middle me-1" />
                                    {statistics.percentage_closed} %<span> </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/* end col */}

                        {/* end col */}
                        <div className="col-lg-3 col-md-3 col-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                    <i className="ri-arrow-down-circle-fill align-middle" />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    Total
                                  </p>
                                  <h4 className=" mb-0">

                                    <span
                                      className="counter-value"
                                      data-target="14799.44"
                                    >
                                      {statistics.total_doubts}
                                    </span>
                                  </h4>
                                </div>
                                <div className="flex-shrink-0 align-self-end">
                                  <span className="badge bg-danger-subtle text-danger">
                                    <i className="ri-arrow-down-s-fill align-middle me-1" />
                                    <span> </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/* end col */}

                        {/* end col */}
                        <div className="col-lg-3 col-md-3 col-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                    <i className="ri-arrow-down-circle-fill align-middle" />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    Avg Resolution Time                         </p>
                                  <h4 className=" mb-0">

                                    <span
                                      className="counter-value"
                                      data-target="14799.44"
                                    >
                                      {parseInt(statistics.average_closed_time_minutes) > 60 ? (parseInt(statistics.average_closed_time_minutes) / 60) + " hrs" : statistics.average_closed_time_minutes + " mins"}
                                    </span>
                                  </h4>
                                </div>
                                <div className="flex-shrink-0 align-self-end">
                                  <span className="badge bg-danger-subtle text-danger">
                                    <i className="ri-arrow-down-s-fill align-middle me-1" />
                                    %<span> </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/* end col */}

                      </div>{" "}
                      {/* end row*/}

                    </> :
                    <>

                    </>}


                </> :
                <>

                </>}

              <div className="row">

                {/* end col sliding section*/}
                <div className="col-12 order-xxl-0 order-first">



                  <CustomerTicketsList data={response} Reload={Reload} options={options} handleSearch={handleSearch} />

                  <div className="d-flex mt-4">


                    {/*Graphical data */}

                    <div className="col-lg-5 ps-3">
                      <div className="card">

                      </div>
                    </div>

                    <div className="col-lg-5 ps-2">

                    </div>
                  </div>

                </div>
                {/* end col */}

              </div>

              <div className="row">
                <div className="col-xl-8">

                  {/* end card */}
                </div>
                {/* end col */}
                <div className="col-xl-4">
                  {/* card */}
                  <div className="card card-height-100">

                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}
              </div>

            </div>{" "}
            {/* end .h-100*/}
          </div>{" "}
          {/* end col */}

          {/* end col */}
        </div>
      </div>

    </>
  )


}





const SetStatistics = (props) => ({
  value: props
})
export default connect(SetStatistics)(CustomerSupportPage)

const StyledSuperAdminDashboard = styled.div`



h1{

    color:red;
}




`