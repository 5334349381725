import React, { useContext, useEffect, useState } from 'react'
import CustomModal from './CustomModal'
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import BasicToast from '../toasts/BasicToast';
import { Close } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

const AddClassModal = ({ visibility, handleClose, value, dispatch ,modal}) => {
  const params = useParams()
  const course_url = params.course_url
  const batch_id = params.batch_id
  const [title, settitle] = useState(null);
  const [starts, setstarts] = useState(null);
  const [ends, setends] = useState(null);
  const [date, setdate] = useState(null);
  const [topics, settopics] = useState(null);
  const [meetinglink, setmeetinglink] = useState(null);
  const { user } = useContext(AuthContext)
  const RescheduleClass = async () => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    formData.append('session', user.SessionId);

    if (value) {
      formData.append('notification', value)

    }

    if(date ===null||starts=== null || ends === null){
      setoastdata({title:"Enter time"})
      settoast(true)

      return;
    }
    
    formData.append('course_url', course_url);
    formData.append('title', title);
    formData.append('topics', topics);

    formData.append('meeting_link', meetinglink);

    formData.append('date', date);

    formData.append('starts', starts);

    formData.append('batch_id', batch_id);

    formData.append('ends', ends);

    const res = await axios.post(
      ENDPOINT + "admin-add-schedule.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      if (res.data.status === "success") {
        settoast(true)
        setoastdata(res.data)
        handleClose()
        dispatch({type:'MODAL_CLOSE',payload:'add_class'})
      dispatch({type:'FUNCTION_RELOAD',payload:'classes'})
       
     
      } else {


      }
      setoastdata({ title: res.data.title, message: res.data.message })
      settoast(true);
    }
  };


  const [toast, settoast] = useState(false)
  const [toastdata, setoastdata] = useState({ title: null, message: null })

  useEffect(() => {
    let timeOut;
    if (toast) {
        timeOut = setTimeout(() => {
            settoast(false);
        }, 2500);
    }

   
    return () => clearTimeout(timeOut);
}, [toast]);
  


  return (
    <>
      <BasicToast visibility={toast} item={toastdata} />
      <CustomModal visibility={visibility} handleClose={()=>{handleClose();
        dispatch({type:'MODAL_CLOSE',payload:'add_class'})

          }}>

        <div className="d-flex justify-content-between">
          <h4>Add Class Schedule</h4>
          <Close className='click' onClick={()=>{handleClose();
        dispatch({type:'MODAL_CLOSE',payload:'add_class'})

          }} />
        </div>
        <hr></hr>
        <p>Class Title :</p>
        <input
          className="form-control"
          value={title}
          onChange={(e) => {
            settitle(e.target.value);
          }}
        ></input>
        <br />
        <p>Topics List:</p>
        <input
          className="form-control"
          value={topics}
          onChange={(e) => {
            settopics(e.target.value);
          }}
        ></input>


        <p>Meeting Link:</p>
        <input
          className="form-control"
          value={meetinglink}
          onChange={(e) => {
            setmeetinglink(e.target.value);
          }}
        ></input>

        <>
          <p className="mb-0">Date</p>
          <input
            className="form-control"
            type="date"
            onChange={(e) => {
              setdate(e.target.value);
            }}
          ></input>
          <div className="flex mt-3">
            <input
              className="form-control"
              type="time"
              onChange={(e) => {
                setstarts(e.target.value);
              }}
            ></input>
            <input
              className="form-control"
              type="time"
              onChange={(e) => {
                setends(e.target.value);
              }}
            ></input>
          </div>

        </>

        <button className="btn btn-primary w-100 mt-3" onClick={RescheduleClass}>
          Save Changes
        </button>


      </CustomModal>
    </>
  )
}

const Notification = (prop) => ({
  value: prop.sendNotification.notification,
  modal: prop.modalHandler
})

export default connect(Notification)(AddClassModal)