import React, { useContext, useState } from 'react'

import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import { AuthContext } from '../../context/AuthContext';
import { Close } from '@material-ui/icons';
import DefaultModal from '../../components/modals/DefaultModal';

const EditLeadModal = ({visibility,handleClose,item,Reload}) => {
    const [currentid, setcurrentid] = useState(null);
    const [leadname, setleadname] = useState(item.name);
    const [lemail, setlemail] = useState(item.email);
    const [lphone, setlphone] = useState(item.phone);
    const [lwhatsapp, setlwhatsapp] = useState(item.whatsapp);
    const [lnote, setlnote] = useState(item.note);
    const [lstatus, setlstatus] = useState(item.status);
    const [lcategory, setlcategory] = useState(item.category);
  
    const [initialData, setInitialData] = useState([]);
    const [response, setResponse] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const EditLead = async () => {
       
        if (user !== null) {
          const data = new FormData();
          data.append("name", leadname);
          data.append("email", lemail);
          data.append("phone", lphone);
          data.append("whatsapp", lwhatsapp);
       
          data.append("category", lcategory);
          data.append("note", lnote);
          data.append("id", item.id);
          data.append("session", user.SessionId);
    
          const res = await axios.post(ENDPOINT + "admin-edit-lead.php", data, {
            headers: { "content-type": "multipart/form-data" },
          });
          if (res) {
            //  // console.log("lead "+JSON.stringify(res))
            if (res.data.status === "success") {
             Reload()
            
            } else {
             
            }
          }
        }
      };

      const {user} = useContext(AuthContext)
  return (
    <>
    
    <DefaultModal content={<>
    
<div className="p-2">
<>

<div className="flex justify-content-between">
  <h4>Edit Lead</h4>
  <Close type="button" onClick={handleClose} />
</div>
<hr />

<div className="d-flex">
<div>
  <h6>{}: Name</h6>
  <input
    className="form-control"
    placeholder="name"
    onChange={(e) => setleadname(e.target.value)}
    value={leadname}
    minLength={4}
  ></input>
</div>

<div>
  <h6>Email</h6>

  <input
    className="form-control"
    placeholder="name"
    onChange={(e) => setlemail(e.target.value)}
    value={lemail}
    minLength={10}
    maxLength={100}
  ></input>
</div>

</div>


<div className="d-flex mt-3">
<div>
  <h6>Phone</h6>
  <input
    className="form-control"
    placeholder="name"
    onChange={(e) => setlphone(e.target.value)}
    value={lphone}
    minLength={10}
    maxLength={12}
  ></input>
</div>

<div>
  <h6>Whatsapp</h6>
  <input
    className="form-control"
    placeholder="name"
    onChange={(e) => setlwhatsapp(e.target.value)}
    value={lwhatsapp}
    minLength={10}
    maxLength={12}
  ></input>
</div>
</div>
<div className="d-flex mt-3">

<div>
  <h6>Category</h6>
  <select
    className="form-control"
    onChange={(e) => {
      setlcategory(e.target.value);
    }}
  >
    <option value={"Interested to join course"}>
      Interested to join course
    </option>
    <option value={"General Query"}>General query</option>
    <option value={"Workshop query"}>Workshop query</option>
    <option value={"Others"}>Others</option>
  </select>
</div>

<div>
  <h6>Status</h6>
  <select
    className="form-control"
    onChange={(e) => {
      setlcategory(e.target.value);
    }}
  >
    <option value={"interested"}>Interested</option>
    <option value={"demo_scheduled"}>Demo Scheduled</option>
    <option value={"pending_payment"}>Pending Payment</option>
    <option value={"joined"}>Joined Course</option>
    <option value={"not_interested"}>Not interested</option>
  </select>
</div>

</div>
<div className="">
  <h6>Note</h6>
  <textarea
    className="form-control"
    placeholder="name"
    value={lnote}
    onChange={(e) => setlnote(e.target.value)}
    maxLength={300}
  />
</div>
<button onClick={EditLead} className="btn btn-primary w-100 mt-3">
  Save Changes
</button>
</>

</div>
    
    </>} show={visibility} handleClose={handleClose}></DefaultModal>
    
    </>
  )
}

export default EditLeadModal