import styled from "styled-components";
import PieChart from "../../components/charts/PieChart";
import Users from "../users/Users";
import DemoData from "../../data/DemoData";
import ENDPOINT from "../../context/EndPoint";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import AssignmentSubmissionItem from "./AssignmentSubmissionItem";
import AssignmentSubmissionsList from "./AssignmentSubmissionsList";
import { connect } from "react-redux";
import Localbase from "localbase";
import { useActionsContext } from "../../context/ActionsContext";

function AssignmentSubmissions({value,dispatch}) {
  const { user } = useContext(AuthContext);

  const [edit, setedit] = useState(false);

  const tasktype = useRef();
const db = new Localbase("db")
  const changeTaskStatus = (event) => {
    settaskstatus(event);
    getTasks(event);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [tasks, settasks] = useState(null);
  const [category, setcategory] = useState("all");

  const [taskstatus, settaskstatus] = useState("all");

  const [taskid, settaskid] = useState(null);
  const [remove, setremove] = useState(false);
  const [removeid, setremoveid] = useState(null);
  const [submit, setsubmit] = useState(false);

  const showSubmit = (e) => {
    const id = e.target.id;
    settaskid(id.replace("submit", ""));
    setsubmit(true);
  };

  const closeSubmit = () => {
    setsubmit(false);
  };
  const editTask = (assignmentid) => {
    settaskid(assignmentid);

    setedit(true);
  };

  const closeEdit = () => {
    setedit(false);
  };
  const [add, setadd] = useState(false);

  const closeAddition = () => {
    setadd(false);
    getTasks(taskstatus);
  };

  const showRemove = (e) => {
    const id = e.target.id;

    settaskid(id.replace("delete", ""));
    setremove(true);
  };
  const closeRemove = () => {
    setremove(false);
  };

  useEffect(() => {
    getTasks(taskstatus);
  }, []);

  const getTasks = async (status) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    formData.append("status", status);

    const res = await axios.post(
      ENDPOINT + "admin-get-students-tasks.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      if (res.data.status === "success") {
        db.collection("paths").add({assignment_submissions:res.data.data},"assignment_submissions")
        settasks(res.data.data);
        setResponse(res.data.data);
      } else {
      }
    }
    
  };

  //delete task code

  const deleteTask = async () => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("id", taskid);

    const res = await axios.post(ENDPOINT + "admin-delete-task.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res.data[0].status === "success") {
      getTasks();
      closeRemove();
    } else {
    }
  };

  const Reload = () => {
    getTasks();
  };

  const [response, setResponse] = useState(tasks);
  const [searchTerm, setSearchTerm] = useState("");
  const {searchquery} = useActionsContext() 
  const handleSearch = ({term}) => {
   if(term && tasks)
   {
    const filteredData = tasks.filter((item) => {
      return (
        item.first_name.toLowerCase().includes(term) ||
        item.last_name.toLowerCase().includes(term) ||
        item.status.toLowerCase().includes(term)||
        item.submission_time.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
   }

    
  };


 useEffect(()=>{
  handleSearch({term:searchquery})

 },[searchquery])

  useEffect(() => {


    db.collection("paths").doc("assignment_submissions").get().then(data=>{
      if(data){
      settasks(data.assignment_submissions);
      setResponse(data.assignment_submissions);
      }
    })
    if(value.functionName === 'assignment'){


      getTasks();

    }
    
  }, [value]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="h-100">
              {tasks ? (
                <>
                  {/*end row*/}
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Submitted{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {/* {tasks?tasks.assignment_submit:null} */}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Completed{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {/* {tasks?tasks.assignment_complete.assignment_complete:null} */}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    
                    {/* end col */}
                  </div>{" "}
                  {/* end row*/}
                </>
              ) : (
                <></>
              )}

              <div className="tab-pane " id="documents" role="tabpanel">
                <div className="card">
                  <div className="card-body">
                  {/* Previous Search Filters */}
                    {/* <div className="card-body border border-dashed border-end-0 border-start-0">
                      <form className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        <div className="row g-3">
                          <div className="col-xxl-3 col-sm-4">
                            <div className="search-box">
                              <input
                                onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                                type="text"
                                className="form-control search"
                                placeholder="Search for order ID, customer, order status or something..."
                              />
                              <i className="ri-search-line search-icon" />
                            </div>
                          </div>
                          <div className="col-xxl-2 col-sm-6">
                            <div>
                              <input
                                type="date"
                                  onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                                className="form-control"
                                data-provider="flatpickr"
                                data-date-format="d M, Y"
                                data-range-date="true"
                                id="demo-datepicker"
                                placeholder="Select date"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-2 col-sm-4">
                            <div>
                              <select
                               onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                                className="form-control"
                                data-choices=""
                                data-choices-search-false=""
                                name="choices-single-default"
                                id="idStatus"
                              >
                                <option value="">Status</option>
                              
                                <option value="submitted">Submitted</option>
                               
                              </select>
                            </div>
                          </div>
                          <div className="col-xxl-2 col-sm-4">
                            <div>
                              <select
                                className="form-control"
                                data-choices=""
                                data-choices-search-false=""
                                name="choices-single-default"
                                id="idPayment"
                              >
                                <option value="">Select Payment</option>
                                <option value="all" selected="">
                                  All
                                </option>
                                <option value="Mastercard">Mastercard</option>
                                <option value="Paypal">Paypal</option>
                                <option value="Visa">Visa</option>
                                <option value="COD">COD</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-xxl-3 col-sm-4">
                            <div className="d-flex">
                              <button
                                type="button"
                                className="btn me-2 btn-primary "
                                onclick="SearchData();"
                              >
                                {" "}
                                <i className="ri-equalizer-fill me-1 align-bottom" />
                                Filters
                              </button>

                              <button className="btn btn-danger w-fit h-fit">
                                Add new Task
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div> */}

                    <AssignmentSubmissionsList
                      tasks={response}
                     
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end .h-100*/}
          </div>{" "}
          {/* end col */}
          {/* end col */}
        </div>
      </div>
    </>
  );
}

const reduxStore = (prop)=>({
  value:prop.modalHandler
})

export default connect(reduxStore) (AssignmentSubmissions);

const StyledSuperAdminDashboard = styled.div`
  h1 {
    color: red;
  }
`;
