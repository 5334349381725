import { Delete, MoreVert } from '@material-ui/icons'
import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../context/EndPoint'
import SecurityHeaders from '../../context/SecurityHeaders'
import { Dropdown } from 'react-bootstrap';
import { Chip } from '@mui/material'
import DetectDevice from '../../context/DetectDevice'
const ResourceManagement = () => {
const device = DetectDevice()
    const [title,settitle] = useState(null)
    const params = useParams()
    const course_url = params.course_url
    const [description,setdescription] = useState(null)
    const [type,settype] = useState("link")
    const [url,seturl] = useState(null)
const {user} = useContext(AuthContext)
const AddResource = async (e)=>{
e.preventDefault()
const data = new FormData()
data.append("title",title)
data.append("description",description)
data.append("type",type)
data.append("link",url)
data.append("course_url",course_url)
data.append("session",user.SessionId)
const res = await axios.post(ENDPOINT+"admin-add-course-resource.php",data,SecurityHeaders)

if(res){
    if(res.data.status==="success"){
GetResources()
    }
}

}

const [resources,setresources] = useState(null)

const GetResources =  async ()=>{
  const data = new FormData()
  data.append("course_url",course_url)
  data.append("session",user.SessionId)
  const res = await axios.post(ENDPOINT+"admin-get-resources.php",data,SecurityHeaders)


  if(res){
 // console.log("Resources "+JSON.stringify(res))
if(res.data){
  if(res.data.status==="success"){
    setresources(res.data.data)
  }
}
  }
}

const DeleteResource = async ({resource_id})=>{
  const data = new FormData()
  data.append("resource_id",resource_id)
  data.append("session",user.SessionId)
  const res = await axios.post(ENDPOINT+"admin-delete-resource.php",data,SecurityHeaders)

if(res){
  if(res.data){
    if(res.data[0].status==="success"){
      GetResources()
    }
  }
}
}
useEffect(()=>{
GetResources()
},[])

  return (
    <div>
      {device==="mobile"?
      <>

        <div className="col-lg-5 p-3">
                <h4>Add Resource</h4>
                  <form onSubmit={(e)=>AddResource(e)}>
                  <input required className="form-control mt-3" value={title} onChange={(e)=>settitle(e.target.value)} placeholder="title"></input>
                  <input required className="form-control mt-2" onChange={(e)=>setdescription(e.target.value)} placeholder="Description"></input>
                
                <select required className="form-control mt-2"  onChange={(e)=>settype(e.target.value)}>
                  <option value="pdf">PDF</option>
                  <option value="link">Link</option>
                
                </select>
                  <input required className="form-control mt-2" onChange={(e)=>seturl(e.target.value)} placeholder={type==="pdf"?type+" Link":"Resource Link"}></input>
                
                  <button className="btn btn-primary mt-4">Add resource</button>
            
                  </form>
              
                </div>
        <div className="d-flex justify-content-between align-items-center mt-1 p-3">
            <h4>Course Resources</h4>
          
        </div>
        
        <div className="mt-2">
            <div className="d-flex flex-wrap justify-content-between ">


              <div className="col-lg-6">
                {resources?
                <>
                  {resources.map((resource)=>(
                    <>
                  <div className="border p-3 mb-2">

                  <div className="d-flex justify-content-between">
                  <h6>{resource.title}</h6>


                  <div className="d-flex">
                    
                  <Dropdown className="options-dropdown">   
                          <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
                          <MoreVert className="text-dark"/>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                          <Dropdown.Item   >Edit</Dropdown.Item>
                          <Dropdown.Item onClick={()=>DeleteResource({resource_id:resource.id})} ><Delete className='me-2' />Delete</Dropdown.Item>
                        
                            

                            </Dropdown.Menu>
                        </Dropdown>
                        
                  </div>


                  </div>
                  <hr className='mt-1 mb-0'></hr>
                  <p className='text-secondary extra-small'>{resource.description}</p>
                  <a target='_blank' href={resource.url}>View {resource.type}</a>
                  </div>

                    </>
                  ))}
                </>:
                <>


                </>}
              </div>
    
    
              </div>
            </div>
      </>:
      <>
        <div className="d-flex justify-content-between align-items-center mt-1">
            <h4>Course Resources</h4>
          
        </div>
        
        <div className="mt-2">
            <div className="d-flex flex-wrap justify-content-between ">


              <div className="col-lg-6">
                {resources?
                <>
                  {resources.map((resource)=>(
                    <>
                  <div className="border p-3 mb-2">

                  <div className="d-flex justify-content-between">
                  <h6>{resource.title}</h6>


                  <div className="d-flex">
                    
                  <Dropdown className="options-dropdown">   
                          <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
                          <MoreVert className="text-dark"/>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                          <Dropdown.Item   >Edit</Dropdown.Item>
                          <Dropdown.Item onClick={()=>DeleteResource({resource_id:resource.id})} ><Delete className='me-2' />Delete</Dropdown.Item>
                        
                            

                            </Dropdown.Menu>
                        </Dropdown>
                        
                  </div>


                  </div>
                  <hr className='mt-1 mb-0'></hr>
                  <p className='text-secondary extra-small'>{resource.description}</p>
                  <a target='_blank' href={resource.url}>View {resource.type}</a>
                  </div>

                    </>
                  ))}
                </>:
                <>


                </>}
              </div>
    
    
              <div className="col-lg-5">
                <h4>Add Resource</h4>
                  <form onSubmit={(e)=>AddResource(e)}>
                  <input required className="form-control mt-3" value={title} onChange={(e)=>settitle(e.target.value)} placeholder="title"></input>
                  <input required className="form-control mt-2" onChange={(e)=>setdescription(e.target.value)} placeholder="Description"></input>
                
                <select required className="form-control mt-2"  onChange={(e)=>settype(e.target.value)}>
                  <option value="pdf">PDF</option>
                  <option value="link">Link</option>
                
                </select>
                  <input required className="form-control mt-2" onChange={(e)=>seturl(e.target.value)} placeholder={type==="pdf"?type+" Link":"Resource Link"}></input>
                
                  <button className="btn btn-primary mt-4">Add resource</button>
            
                  </form>
              
                </div>
                      </div>
            </div>
      </>}
        
    </div>
  )
}

export default ResourceManagement