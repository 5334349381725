import axios from "axios";
import ENDPOINT from "../../context/EndPoint";

const GetActiveBatches = async ({payload})=>{
    const formData = new FormData();
       
  //formData.append('whyus',document.getElementById('whyus').value)
  formData.append('session',payload.session);
  if(payload.status ==="active"){
    formData.append("status",payload.status);
  }



  const res = await axios.post(ENDPOINT+"get-active-batches.php", formData,{
    
  
  headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
  return res.data
  }
  }
  export default GetActiveBatches