import axios from 'axios';
import React, { useContext, useState } from 'react'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import { Snackbar } from '@material-ui/core';
import { AuthContext } from '../../context/AuthContext';
import { useDispatch } from 'react-redux';

const AddTeamGroup = ({closeModal,item,action}) => {

    const dispatch = useDispatch();


    const {user} = useContext(AuthContext)
    const [inputs,setInputs] = useState({
        group_name:item?item.group_name:'',
        batch_id:item?item.batch_id:'',
        course_url:item?item.course_url:'',  
        invite_link:item?item.invite_link:'',
        id:item?item.group_id:null
    

    })
    const [snackbar,setSnackbar] = useState({
        message:'',
        show:false,       

    });
    const handelForm = (e)=>{
        setInputs({
            ...inputs,
            [e.target.name]:e.target.value
        })
    }



    const addGroup = async(e)=>{
        e.preventDefault()
        try{

            const data = new FormData();
            data.append('session',user.SessionId);
            data.append('group_name',inputs.group_name);
            data.append('batch_id',inputs.batch_id);
            data.append('course_url',inputs.course_url);
            data.append('invite_link',inputs.invite_link);
            data.append('action',action);
          data.append('id',inputs.id)

            const res = await axios.post(ENDPOINT+"admin-teams-group.php",data,SecurityHeaders);

            if(res){
                if(res.data.status === 'success'){
                    closeModal()
                    setInputs({
                        ...inputs,
                    invite_link:'',
                    group_name:'',
                    batch_id:'',
                    course_url:'',

                    })
                    dispatch({type:'FUNCTION_RELOAD',payload:'get-groups'})

                }

                setSnackbar({
                    ...snackbar,
                    message:res.data.message,
                    show: true
                })
            }

        }
        catch(err){
             // console.log(' Error Inserting',err.message);

        }
    }
  return (
    <div className="container">
            <form onSubmit={addGroup}>
                <div className="form-group">
                    <label>Name</label>
                    <input type="text" value={inputs.group_name} className="form-control" name='group_name' onChange={handelForm} />
                </div>
                <div className="form-group">
                    <label>Batch Id</label>
                    <input type="text" value={inputs.batch_id} className="form-control" name='batch_id' onChange={handelForm} />
                </div>
                <div className="form-group">
                    <label>course_url</label>
                    <input type="text" value={inputs.course_url} className="form-control" name='course_url' onChange={handelForm} />
                </div> 
                <div className="form-group">
                    <label>Invite Link</label>
                    <input type="text" value={inputs.invite_link} className="form-control" name='invite_link' onChange={handelForm} />
                </div> 
               
                <button type="submit" className="btn mt-3 w-100 btn-primary">
                    {"Submit"}
                </button>
            </form>

            <Snackbar
                open={snackbar.show}
                autoHideDuration={3000}
                onClose={() => setSnackbar({...snackbar,show:false})}
                message={snackbar.message}
            />
        </div>
  )
}

export default AddTeamGroup
