import React, { useContext, useState } from 'react'
import CustomModal from './CustomModal'
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import BasicToast from '../toasts/BasicToast';
import { Close } from '@material-ui/icons';

const EditClassTopic = ({visibility,handleClose,item,Reload}) => {

    const [title, settitle] = useState(item.class_title);
    const [starts, setstarts] = useState(item.class_starts);
    const [ends, setends] = useState(item.class_ends);
    const [date, setdate] = useState(null);
    const [topics, settopics] = useState(item.class_description);
    const [link, setLink] = useState(item.recoding?item.recoding:null);
    const [close,setClose] = useState(visibility)
    
  const {user} = useContext(AuthContext)
    const RescheduleClass = async () => {
        const formData = new FormData();
    
        //formData.append('whyus',document.getElementById('whyus').value)
        formData.append("session", user.SessionId);
    
        formData.append("course_url", item.course_url);
        formData.append("class_title", title);
        formData.append("id",item.id)
        formData.append("class_description", topics);
       if(link){
        formData.append('url',link);
       }
    
      
      
    
        const res = await axios.post(
          ENDPOINT + "admin-edit-class-topic.php",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
        if (res) {
          if (res.data.status === "success") {
     handleClose(false)
     Reload()
        
          } else {
        
         
          }
          setoastdata({title:res.data.title,message:res.data.message})
          settoast(true);
        }
      };
    

      const [toast,settoast] = useState(false)
      const [toastdata,setoastdata] = useState({title:null,message:null})
    

 // console.log(handleClose,"handleClose")
      
  return (
   <>
   <BasicToast visibility={toast} item={toastdata} ToastHandleClose={()=>settoast(false)}/>
   <CustomModal visibility={visibility}  handleClose={handleClose}>

<div className="d-flex justify-content-between">
    <h4>Edit Class Topic</h4>
    <Close className='click' onClick={handleClose}/>
</div>
<hr></hr>
            <p>Class Title :</p>
            <input
              className="form-control"
              value={title}
              onChange={(e) => {
                settitle(e.target.value);
              }}
            ></input>
            <br />
            <p>Topics List:</p>
            <input
              className="form-control"
              value={topics}
              onChange={(e) => {
                settopics(e.target.value);
              }}
            ></input>
            <br />

             <p>Edit Recording Url:</p>
            <input
              className="form-control"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
              }}
            ></input>
        

   

            <button className="btn btn-primary w-100 mt-3" onClick={RescheduleClass}>
         Save Changes
            </button>
         

   </CustomModal>
   </>
  )
}

export default EditClassTopic