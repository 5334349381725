import React, { useRef, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { Modal, Container, Row, Col, Table, Button } from 'react-bootstrap';
import { GoKebabVertical } from 'react-icons/go';
import { BsFillFileBarGraphFill } from 'react-icons/bs';
import ENDPOINT from '../../context/EndPoint';
import Checkbox from '@mui/material/Checkbox';
import UploadFile from '@mui/icons-material/UploadFile';
import { Link } from 'react-router-dom';
import SecurityHeaders from '../../context/SecurityHeaders';
import { Delete } from '@material-ui/icons';
import UploadImage from './UploadImage'; // Component for uploading new images

const AdminGetImages = ({ page, role }) => {
  const { user } = useContext(AuthContext);

  const [edit, setEdit] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [images, setImages] = useState(null);

  const taskType = useRef();

  useEffect(() => {
    getImages();
  }, []);

  const getImages = async () => {
    const formData = new FormData();
    formData.append('session', user.SessionId);
    const res = await axios.post('https://skillsuprise.com/admin-get-images.php', formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });


    if (res) {
      console.log(res.data.data)
      setImages(res.data.data)
    
    }
  };
  
  // const deleteImage = async ({ id }) => {
  //   const formData = new FormData();
  //   formData.append('session', user.SessionId);
  //   formData.append('image_id', id);

  //   const res = await axios.post(ENDPOINT + 'admin-delete-image.php', formData, SecurityHeaders);

  //   if (res.data.status === 'success') {
  //     getImages(); // Refresh the list after deletion
  //   }
  // };

  // const handleUploadSubmit = () => {
  //   setUploadImage(false); // Close modal after upload
  //   getImages(); // Refresh image list
  // };

  return (
    <>
 <div className="container-fluid pt-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2>Manage Images</h2>
          <Button variant="primary" onClick={() => setUploadImage(true)}>
            Add New Image
          </Button>
        </div>

        <div className="table-responsive">
          <Table bordered hover>
            <thead>
              <tr>
                <th>Select</th>
                <th>Image</th>
                <th>Title</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {images && images.length > 0 ? (
                images.map((image) => (
                  <tr key={image.image_id}>
                    <td>
                      <Checkbox />
                    </td>
                    <td>
                      <div className="image-container">

                      <img className="image img-fluid" src={image.image_url} alt={image.title}/>
                      
                      </div>
                     
                    </td>
                    <td>{image.title}</td>
                    <td>Active</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center empty-message">
                    No images found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

     
    </>
  );
};

export default AdminGetImages;
