import React, { useContext, useState } from 'react'
import CustomModal from './CustomModal'
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import BasicToast from '../toasts/BasicToast';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';

const RescheduleTeamMeetngModal = ({visibility,handleClose,item,value,dispatch}) => {

    const [title, settitle] = useState(item.class_title);
    const [starts, setstarts] = useState(item.class_starts);
    const [ends, setends] = useState(item.class_ends);
    const [date, setdate] = useState(null);
    const [topics, settopics] = useState(item.topics);
    const [meetinglink, setmeetinglink] = useState(item.meeting_link);
  const {user} = useContext(AuthContext)
    const RescheduleClass = async () => {
        const formData = new FormData();
    
        //formData.append('whyus',document.getElementById('whyus').value)
        formData.append("session", user.SessionId);
    
        formData.append("course_url", item.course_url);
       
        formData.append("date", date);
    
        formData.append("starts", starts);
        formData.append("id",item.id)
        formData.append("batch_id", item.batch_id);
    
        formData.append("ends", ends);
    
        const res = await axios.post(
          ENDPOINT + "reschedule-team-meeting.php",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
        if (res) {
          if (res.data.status === "success") {
      handleClose()
      dispatch({type:'FUNCTION_RELOAD',payload:'get-intern-meetings'});
      
      
        
          } else {
        
         
          }
          setoastdata({title:res.data.title,message:res.data.message})
          settoast(true);
        }
      };
    

      const [toast,settoast] = useState(false)
      const [toastdata,setoastdata] = useState({title:null,message:null})
  return (
   <>
   <BasicToast visibility={toast} item={toastdata} ToastHandleClose={()=>settoast(false)}/>
   <CustomModal visibility={visibility}  handleClose={handleClose}>

<div className="d-flex justify-content-between">
    <h4>Edit Class Schedule</h4>
    <Close className='click' onClick={handleClose}/>
</div>
<hr></hr>
        

          {item.class_status==="completed"||item.class_status==="ongoing"?
          <>
          
          
          </>:
          <>
  <p className="mb-0">Date</p>
            <input
              className="form-control"
              type="date"
              onChange={(e) => {
                setdate(e.target.value);
              }}
            ></input>
            <div className="flex mt-3">
              <input
                className="form-control"
                type="time"
                onChange={(e) => {
                  setstarts(e.target.value);
                }}
              ></input>
              <input
                className="form-control"
                type="time"
                onChange={(e) => {
                  setends(e.target.value);
                }}
              ></input>
            </div>
          
          </>}

            <button className="btn btn-primary w-100 mt-3" onClick={RescheduleClass}>
         Save Changes
            </button>
         

   </CustomModal>
   </>
  )
}
const reload = (prop)=>({
  value:prop.modalHandler
})

export default connect(reload) (RescheduleTeamMeetngModal)