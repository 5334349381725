import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import axios from "axios";
import ENDPOINT from "./EndPoint";
import Localbase from "localbase";
import SecurityHeaders from "./SecurityHeaders";
import FetchingErrorModal from "../components/modals/FetchingErrorModal";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ModalStyle from "./ModalStyle";
import SocialShare from "../components/SocialShare";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { ref, push, onValue, getDatabase } from "firebase/database";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
const ActionsContext = createContext()

export const useActionsContext = ()=>useContext(ActionsContext)

export const ActionsProvider = ({children})=>{



const [hearing,sethearing] = useState(false)
  const [voiceinput,setvoiceinput] = useState()

const localdb = new Localbase("db")
const db= new Localbase("db")
const {user} = useContext(AuthContext)
const [errorresponse,seterrorresponse] = useState(null)
const [errormodal,seterrormodal] = useState(false)
const [internalteamlist,setinternalteamlist] = useState(null)
const [account,setaccount] = useState(null)


const [searchquery,setsearchquery] = useState(null)
const  getaccount = async ()=>{
  
    
  try{
  
   fetch(`${ENDPOINT}get-account.php?session=${user.SessionId}`)
   .then(r=>r.json())
   .then(result=>{
   
  if(result){
  
  if((result.status==="success")&&(result.data!=null)){
  
  
   
   setaccount(result.data);
   db.collection('paths').add({
     user:result.data
   },'user')
   //GetPermissions() 
  }else {
  try{
    db.collection('paths').doc('user').delete()
  
  db.collection("paths").doc("notifications").delete()
  
  db.collection('paths').doc('profile').delete()
  }
  catch(error){
  
  
  }
   //localStorage.setItem("user",null);
  // window.location.replace("/")
  }
  
  }
   else{
  
     localStorage.setItem("user",null);
   window.location.replace("/")
   }
   
  
  
  
  
   
       
   })
  }
  catch(err){
  
  }
         
  
    
         }    ;
  
  useEffect(()=>{
getaccount()
  },[user])









const [loading,setloading] = useState(false)

const [deleting,setdeleting] = useState(false)
const [updating,setupdating] = useState(false)
const [togglesidebar,settogglesidebar] = useState(true)

const [enablestats,setenablestats] = useState(localStorage.getItem("stats")||false)

useEffect(()=>{

  localStorage.setItem("stats",enablestats)


},[enablestats])

const StartListening = ()=>{

  SpeechRecognition.startListening()
}
const StopListening = ()=>{
  SpeechRecognition.stopListening()
}
    const fetchInternalTeamList = async ({payload}) => {
        try {
          const formData = new FormData();
          formData.append('session', user.SessionId);
    
          const res = await axios.post(ENDPOINT + "get-internal-team-list.php", formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          });
          if(res){
    if(res.data.status==="success"){
var data = res.data.data
        localdb.collection("paths").add({data},"internal_team")
    }
     
          }
    
          if (res) {
         setinternalteamlist(res.data.data)
          } else {
            // Handle error
          }
        } catch (error) {
          // Handle error
        }
      };

const [internalleaderboard,setinternalleaderboard] = useState(null)
      const fetchInternalLeaderboard = async () => {
        try {
          const formData = new FormData();
          formData.append('session', user.SessionId);
    
          const res = await axios.post(ENDPOINT + "internal-leaderboard.php", formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          });
          if(res){
    if(res.data.status==="success"){
setinternalleaderboard(res.data)
       
    }else{
seterrorresponse(res.data)
      seterrormodal(true)
    }
     
          }
    
        else {
            // Handle error
          }
        } catch (error) {
          // Handle error
        }
      };




    





const [internstartworkres,setinternstartworkres] = useState(null)
        
   const InternStartWork = async ({payload})=>{
        

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    

    formData.append('attendance',payload.status)

    formData.append('internship_id',payload.internship_id)
    
    
    const res = await axios.post(ENDPOINT+"start-working.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     setinternstartworkres(res.data)
    }
    }

   

const [studenrreport,setstudentreport] = useState(null)

      const AddStudentReport = async ({payload}) => {

        const formData = new FormData();

         formData.append('session',payload.user);
         formData.append('user_id',payload.user_id);
         
         formData.append('report',payload.report);
     
         formData.append('course_url',payload.course_url);
     
         formData.append('purchase_id',payload.purchase_id);
     
         
         
         

         try{
             const res = await axios.post(ENDPOINT+"add-student-report.php", formData,{
                  headers: {
                      'content-type': 'multipart/form-data'
                  }
              });
              if(res.data){
                  setstudentreport(res.data)
           
              }
         }
         catch(error){


         }

   




}

const [dailyreports,setdailyreports] = useState(null)

const AddDailyReportRequest = async({payload}) => {

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    
      formData.append('report',payload.report);
   
    const res = await axios.post(ENDPOINT+"admin-add-daily-report.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     
 if(res.data){

    setdailyreports(res.data)
 }
    }
 

  
}


const AddInternReport = async ({payload}) => {

    const formData = new FormData();

     formData.append('session',user.SessionId);
     formData.append('user_id',payload.user_id);
     
     formData.append('report',payload.report);
 
     formData.append('course_url',payload.course_url);
 
     formData.append('internship_id',payload.purchase_id);
 
     
     
     

     try{
         const res = await axios.post(ENDPOINT+"add-intern-report.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res.data){
              return res.data
       
          }
     }
     catch(error){

return error
     }






}





const FetchReminders = async({payload}) => {

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',payload.SessionId);
    
      formData.append('status',"joined");
    
      formData.append('category',"joined");
 
    
    const res = await axios.post(ENDPOINT+"team-reminders.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     
     if(res.data){

      return res.data
     }
     else{
    
     }
    }
 
}





const EvaluateTask = async ({payload}) => {
    const data= new FormData()
    data.append("session",payload.session)
    data.append("id",payload.id)
    data.append("corrections",payload.corrections)
    data.append("earned_marks",payload.earned_marks)
    data.append("status",payload.status)
    const res = await axios.post(ENDPOINT+"admin-evaluate-student-assignment.php",data,SecurityHeaders)
    
    if(res){
         return res.data
    }
    
    
    
    }



    const FetchBatchAssignments = async ({payload})=>{
        

      const formData = new FormData();
      
      
      //formData.append('whyus',document.getElementById('whyus').value)
      
      formData.append('session',payload.session);
      
        formData.append('course_url',payload.course_url);
      formData.append("batch_id",payload.batch_id)
      
      const res = await axios.post(ENDPOINT+"admin-get-course-assignments.php", formData,{
          headers: {
              'content-type': 'multipart/form-data'
          }
      });
      if(res){
     return res.data
      }
  }












  const FetchBatchAssignmentSubmissions = async ({payload}) =>{
     // console.log(payload.course_url)
     // console.log(payload.batch_id)
     // console.log(payload.session)

    const  data = new FormData();
    data.append("course_url",payload.course_url);
    data.append("batch_id",payload.batch_id);
    data.append("session",payload.session)

    const res = await axios.post(ENDPOINT+"admin-get-batchwise-assignments.php",data,SecurityHeaders);
    
    if(res){
    
         if(res.data.status ==="success"){
            return res.data
         }
        
    }

}


const UnlockAccess = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('key',payload.key);
   formData.append('value',payload.value);
   formData.append('purchase_id',payload.purchase_id)


   try{
       const res = await axios.post(ENDPOINT+"unlock-access.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }

}







const DeleteProject = async ({payload})=>{
  const formData = new FormData();
     
//formData.append('whyus',document.getElementById('whyus').value)
formData.append('session',payload.session);
formData.append('id',payload.id);


const res = await axios.post(ENDPOINT+"delete-project.php", formData,{
  

headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
return res.data
}
}


const FetchActiveBatches = async ({payload})=>{
  const formData = new FormData();
     
//formData.append('whyus',document.getElementById('whyus').value)
formData.append('session',payload.session);



const res = await axios.post(ENDPOINT+"get-active-batches.php", formData,{
  

headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
return res.data
}
}





const FetchBatchClassSchedules = async ({payload})=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',payload.session);
  
    formData.append('course_url',payload.course_url);
  
    formData.append('batch_id',payload.batch_id);
  
  
  const res = await axios.post(ENDPOINT+"admin-get-course-schedules.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){

      return res.data
  }
  }



  const FetchBatches = async ({payload})=>{
    const formData = new FormData();
       
  //formData.append('whyus',document.getElementById('whyus').value)
  formData.append('session',payload.session);
  formData.append('course_id',payload.course_id);


  const res = await axios.post(ENDPOINT+"admin-get-batches.php", formData,{
    
  
  headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
 return res.data
  }
  }
  

  const FetchCourseProjects = async ({payload})=>{
    const formData = new FormData();
       
  //formData.append('whyus',document.getElementById('whyus').value)
  formData.append('session',payload.session);
  formData.append('course_url',payload.course_url);


  const res = await axios.post(ENDPOINT+"get-course-projects.php", formData,{
    
  
  headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
 return res.data
  }
  }
  
var [response,setresponse] = useState(false)
  const StartClass = async ({payload})=>{
    setresponse({...response,class_starting:true})
    const data = new FormData()
    if(payload.notification){
      data.append("notification",payload.notification)
    }
    data.append("session",user.SessionId)
    data.append("batch_id",payload.batch_id)
    data.append("id",payload.id)
    data.append("course_url",payload.course_url)
    const res = await axios.post(ENDPOINT+"start-class.php",data,SecurityHeaders)
    if(res){
       // console.log(" res "+JSON.stringify(res))
      setresponse({...response,start_class:res.data})
      setresponse({...response,class_starting:false})
    setresponse({...response,class_started:true})
    }
  }


const [enablevoice,setenablevoice] = useState(false)
  const RescheduleClass = async ({payload}) => {
setresponse({...response,rescheduling_class:true,class_rescheduled:false})
    const formData = new FormData();

     formData.append('session',payload.user);
     formData.append('class_starts',payload.class_starts);
     formData.append('class_ends',payload.class_ends);
     formData.append('class_id',payload.class_id)


     try{
         const res = await axios.post(ENDPOINT+"admin-reschedule-class.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res.data){
              return res.data
              
       setresponse({...response,rescheduling_class:false,class_rescheduled:true})
          }
     }
     catch(error){

return error
     }






}







const DeletePaymentHistory = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.session);

   formData.append('purchase_id',payload.purchase_id);
   

   

   try{
       const res = await axios.post(ENDPOINT+"delete-payment-history.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }

}












const AddPostPoints = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('post_id',payload.post_id);
   formData.append('points',payload.points);


   try{
       const res = await axios.post(ENDPOINT+"add-post-points.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}




const DeleteComment = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('id',payload.id);



   try{
       const res = await axios.post(ENDPOINT+"delete-comment.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}











const DeletePost = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('post_id',payload.post_id);


   try{
       const res = await axios.post(ENDPOINT+"delete-post.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}









const FetchBatchwiseProjectSubissions = async({payload}) => {

            
    
  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',payload.session);
  formData.append("course_url",payload.course_url)
    formData.append('batch_id',payload.batch_id);
  
  
  const res = await axios.post(ENDPOINT+"get-batchwise-project-submissions.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
   
  return res.data
  }
  }





  const DeleteQuiz = async ({payload}) => {

    const formData = new FormData();

     formData.append('session',payload.user);
     formData.append('quiz_id',payload.quiz_id);


     try{
         const res = await axios.post(ENDPOINT+"delete-quiz.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res.data){
              return res.data
       
          }
     }
     catch(error){

return error
     }






}




const FetchBatchReports = async({payload}) => {

            
    
  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',payload.session);
  formData.append("course_url",payload.course_url)
    formData.append('batch_id',payload.batch_id);
  
  
  const res = await axios.post(ENDPOINT+"get-batch-reports.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
   
  return res.data
  }
  }



  const FetchStatistics = async ({payload}) => {




    const data = new FormData()
    data.append("session",payload.SessionId)
    
    const res = await axios.post(ENDPOINT+"admin-student-management.php",data,SecurityHeaders)
          
    if(res){
        if(res.data){
    
            return res.data
        }
    }      
     
    }




    const EditStudent = async ({payload}) => {

      const formData = new FormData();

       formData.append('session',payload.user);
       formData.append('purchase_id',payload.purchase_id);
       
       formData.append('team_id',payload.team_id);
       
       formData.append('student_id',payload.student_id);
       
       formData.append('batch_id',payload.batch_id);
       
       formData.append('progress',payload.progress);
       formData.append('points',payload.points);
       formData.append('total_marks',payload.total_marks);
       
       
       

       try{
           const res = await axios.post(ENDPOINT+"admin-edit-student.php", formData,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
            if(res.data){
                return res.data
         
            }
       }
       catch(error){

return error
       }

 




}














const AddJourney = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('user_id',payload.user_id);

   formData.append('course_url',payload.course_url);

   formData.append('enrolled_on',payload.enrolled_on);

   formData.append('batch_id',payload.batch_id);

   formData.append('purchase_id',payload.purchase_id);

   
   
   

   try{
       const res = await axios.post(ENDPOINT+"add-journey.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }

}



















const DeletePlacementRequest = async ({payload}) => {
  const data = new FormData();

  data.append('id',payload.id);

  const res = await axios.post(ENDPOINT+'admin-delete-placement-request.php',data,SecurityHeaders);
  
  if(res){
      return res.data
  }
}









const DeleteSupportRequest = async ({payload}) => {
  const data = new FormData();

  data.append('id',payload.id);

  const res = await axios.post(ENDPOINT+'admin-delete-support-request.php',data,SecurityHeaders);
  
  if(res){
      return res
  }
}




const DeleteTickets = async ({payload}) => {
  const data = new FormData();

  data.append('id',payload.id);
data.append("session",payload.session)
  const res = await axios.post(ENDPOINT+'admin-delete-doubt-request.php',data,SecurityHeaders);
  
  if(res){
      return res.data
  }

}






const GetAssignmentSubmissions = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);

   formData.append('course_url',payload.course_url);
   
   formData.append('user_id',payload.user_id);
   
   
   

   try{
       const res = await axios.post(ENDPOINT+"assignment-submissions.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}


const GetCheckList = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('user_id',payload.user_id);

   formData.append('course_url',payload.course_url);

   
   
   

   try{
       const res = await axios.post(ENDPOINT+"get-checklist.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}





const GetProjectSubmissions = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);

   formData.append('course_url',payload.course_url);
   
   formData.append('user_id',payload.user_id);
   
   
   

   try{
       const res = await axios.post(ENDPOINT+"student-course-submissions.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}





const GetStudentDoubts = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);

   formData.append('course_url',payload.course_url);
   
   formData.append('user_id',payload.user_id);
   
   
   

   try{
       const res = await axios.post(ENDPOINT+"student-doubts.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}




const GetStudentReports = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('user_id',payload.user_id);
   
   formData.append('report',payload.report);

   formData.append('course_url',payload.course_url);

   
   
   

   try{
       const res = await axios.post(ENDPOINT+"get-student-report.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}





const GetStudentsList = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.session);
   formData.append('mode',payload.mode);
   
   if(payload.query!==null){
       formData.append('query',payload.query);


   }
   if(payload.url){
    formData.append('course_url',payload.url)
   }

   
   
   

   try{
       const res = await axios.post(ENDPOINT+"admin-get-students.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}









const GiftCourseBadge = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('user_id',payload.user_id);
  
   formData.append('badge_id',payload.badge_id);
   formData.append('course_url',payload.course_url);

   try{
       const res = await axios.post(ENDPOINT+"admin-gift-course-badge.php", formData,SecurityHeaders);
        
       if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}



const UpdateChecklist = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('user_id',payload.user_id);

   formData.append('course_url',payload.course_url);
formData.append("mode",payload.mode)
formData.append("batch_id",payload.batch_id)
   formData.append('key',payload.key);

   
   
   

   try{
       const res = await axios.post(ENDPOINT+"update-checklist.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}

const FetchStudentCourseBadges = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('user_id',payload.user_id);
   
   formData.append('course_url',payload.course_url);
   

   try{
       const res = await axios.post(ENDPOINT+"admin-student-badges.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}





const UpdateDoubtRequest = async({payload}) => {

   
  const data = new FormData();
  data.append("session", payload.session);
  data.append("status",payload.status);
  data.append("id", payload.id);
  const res = await axios.post(
    `${ENDPOINT}admin-update-doubt-request.php`,
    data,
    SecurityHeaders
  );

if(res){

  return res.data
}


}









const UpdatePlacementRequest = async({payload}) => {

   
  const data = new FormData();
  data.append("session", payload.session);
  data.append("status",payload.status);
  data.append("id", payload.id);
  const res = await axios.post(
    `${ENDPOINT}admin-update-placement-request.php`,
    data,
    SecurityHeaders
  );

if(res){

  return res.data
}


}




const DeleteTeamReminder = async({payload}) => {












  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',payload.SessionId);
  
    formData.append('id',payload.id);
  
 
  
  const res = await axios.post(ENDPOINT+"delete-team-reminder.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
   
   if(res.data){

    return res.data
   }
   else{
  
   }
  }


}



const FetchRemainders = async({payload}) => {












  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',payload.SessionId);
  
    formData.append('status',"joined");
  
    formData.append('category',"joined");

  
  const res = await axios.post(ENDPOINT+"team-reminders.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
   
   if(res.data){

    return res.data
   }
   else{
  
   }
  }


}
const GiftPersonalBadge = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('user_id',payload.user_id);
  
   formData.append('badge_id',payload.badge_id);

   try{
       const res = await axios.post(ENDPOINT+"admin-gift-personal-badge.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}


const ChangeAccountStatus = async ({ payload }) => {
  const formData = new FormData();
  formData.append("session", payload.SessionId);
  formData.append("user_id", payload.user_id);
  formData.append("status", payload.status);

  const res = await axios.post(
    ENDPOINT + "admin-change-account-status.php",
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );

  if (res) {
      return res.data
  }
 
};


const DeleteUser = async ({ payload }) => {
  const formData = new FormData();
  formData.append("session", payload.SessionId);
  formData.append("user_id", payload.user_id);
 

  const res = await axios.post(ENDPOINT + "admin-delete-user.php", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });

  if (res) {
   return res.data
  }
 
};















const FetchUserPersonalBadges = async ({payload}) => {

  const formData = new FormData();

   formData.append('session',payload.user);
   formData.append('user_id',payload.user_id);
   

   try{
       const res = await axios.post(ENDPOINT+"admin-get-user-badges.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res.data){
            return res.data
     
        }
   }
   catch(error){

return error
   }






}










const SendOTP = async ({ payload }) => {
  const formData = new FormData();
  formData.append("session", payload.SessionId);
  formData.append("user_email", payload.user_email);

  const res = await axios.post(ENDPOINT + "admin-send-otp.php", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });

  if (res) {

      return res.data
  }
 
};





<FetchingErrorModal show={errormodal} response={errorresponse}/>



const [addinternmeetingres,setaddinternmeetingres] = useState(null)

    const ScheduleInternMeeting = async ({payload})=>{
        

        const formData = new FormData();
        
        //  // console.log(" submit" + currentinternship+" "+startdate+" "+begins+" "+meetingtype)
        //formData.append('whyus',document.getElementById('whyus').value)
        
        formData.append('session',user.SessionId);
        

        formData.append('internship_id',payload.internship_id)
        if(payload.intern_id){
             formData.append('intern_id',payload.intern_id)
        }
        formData.append('date',payload.startdate)
        formData.append('begins',payload.begins)
         formData.append('ends',payload.ends)
        formData.append('meeting_type',payload.meetingtype)
       
        formData.append('meeting_link',payload.meetlink)
        formData.append('description',payload.description)
        
        const res = await axios.post(ENDPOINT+"admin-add-interns-meeting.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
   
       setaddinternmeetingres(res.data)
        }
        }













const [interntasks,setinterntasks] = useState(null)

     

        const [internleaves,setinternleaves] = useState(null)
        const fetchInternLeaves = async ({payload})=>{
        

            const formData = new FormData();
            
            
            //formData.append('whyus',document.getElementById('whyus').value)
            
            formData.append('session',user.SessionId);
            
              formData.append('status',payload.status);
            formData.append("internship_id",payload.internship_id)
            formData.append("intern_id",payload.intern_id)
            
            const res = await axios.post(ENDPOINT+"get-intern-leaves.php", formData,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
            if(res){
            
           setinternleaves(res.data)
            }
        }

const [internsleaves,setinternsleaves] = useState(null)

        const fetchInternsLeaves = async ({payload})=>{
        

          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          
            formData.append('status',payload.status);
          formData.append("internship_id",payload.internship_id)
          formData.append("intern_id",payload.intern_id)
          
          const res = await axios.post(ENDPOINT+"admin-get-leaves.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
          
         setinternsleaves(res.data)
          }
      }


const [internmeetings,setinternmeetings] = useState(null)

        const fetchInternMeetings = async ({payload})=>{
        

            const formData = new FormData();
            
            
            //formData.append('whyus',document.getElementById('whyus').value)
            
            formData.append('session',user.SessionId);
            
              formData.append('status',payload.status);
            formData.append("internship_id",payload.internship_id)
            formData.append("intern_id",payload.intern_id)
            
            const res = await axios.post(ENDPOINT+"intern-meetings.php", formData,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
            if(res){
            
           setinternmeetings(res.data)
            }
        }










        const [internaddtaskres,setinternaddtaskres] = useState(null)
      const AddInternTask = async ({payload})=> {



const formdata = new FormData();
formdata.append('title',payload.title);
formdata.append('description',payload.description);
formdata.append('status',payload.status);
formdata.append('corrections',payload.corrections)
formdata.append('marks',payload.marks)
formdata.append('id',payload.id)
formdata.append('deadline',payload.deadline+" "+payload.time)
formdata.append('reference',payload.reference)
formdata.append('session',user.SessionId)
formdata.append('internship_id',payload.internship_id)
formdata.append('intern_id',payload.user_id)
         
    const res = await axios.post(ENDPOINT+'intern-add-task.php',formdata,{
      headers:{'content-type':'multipart/form-data'}
    })      
       
    if(res){
setinternaddtaskres(res.data.data)
    }

        
               
          }




      
const [leads,setleads] = useState(null)

          const fetchLeads = async () => {
            const formData = new FormData();
        
            //formData.append('whyus',document.getElementById('whyus').value)
            //  // console.log("getting")
            formData.append("session", user.SessionId);
        
         
            ////  // console.log("getting leads")
            const res = await axios.post(ENDPOINT + "admin-get-leads.php", formData, {
              headers: {
                "content-type": "multipart/form-data",
              },
            });
            if (res) {
              if (res.data.status === "success") {
                if (res.data.data) {
            setleads(res.data)
                }
              } else {
                seterrorresponse(res.data)
                seterrormodal(true)
              }
            }
          };



          const updateLead = async ({ ticketstatus, ticketid }) => {
            if (user !== null) {
        
              const data = new FormData();
              data.append('session',user.SessionId);
              data.append('status',ticketstatus);
              data.append('id',ticketid);
              const res = await axios.post(`${ENDPOINT}admin-update-lead.php`,data,SecurityHeaders);
              if(res){
                if (res.data.status === "success") {
                
                } else {
               
                }
              }
        
            } else {
            }
          };



          const EditLead = async ({payload}) => {
         
            if (user !== null) {
              const data = new FormData();
              data.append("name", payload.name);
              data.append("email", payload.email);
              data.append("phone", payload.phone);
              data.append("whatsapp", payload.whatsapp);
              data.append("status", payload.status);
              data.append("category", payload.category);
              data.append("note", payload.note);
              data.append("id", payload.id);
              data.append("session", user.SessionId);
        
              const res = await axios.post(ENDPOINT + "admin-edit-lead.php", data, {
                headers: { "content-type": "multipart/form-data" },
              });
              if (res) {
                //  // console.log("lead "+JSON.stringify(res))
                if (res.data.status === "success") {
             
                } else {
               
                }
              }
            }
          };




          const  fetchInternships = async ()=>{

            fetch(`${ENDPOINT}admin-get-internships.php?session=${user.SessionId}`)
            .then(r=>r.json())
            .then(result=>{
           
                  if(result){
      ////  // console.log("internshis"+JSON.stringify(result.data))
                     if(result.status==="success"){
                //    setinternships(result.data);
                   
                   }
                  }
            
                        
            
                
            })
            
            }    ;

          
            
            const [username,setusername] = useState("manoj")
            const Addition = ()=>{
               // console.log(76898+6867)
            }
          





const [startclassmodal,setstartclassmodal] = useState(false)
const [confirmclassstart,setconfirmclassstart] = useState(false)
const CloseStartClassModal = ()=>{
  setstartclassmodal(false)
}

const ShowStartClassModal = ()=>{
  setstartclassmodal(true)
   // console.log("click")
}
      

const DeleteClass = async ({payload})=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  formData.append('session',user.SessionId);
  
    formData.append('class_id',payload.id);
    formData.append('batch_id',payload.batch_id);
  
  const res = await axios.post(ENDPOINT+"admin-delete-class.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
 
    if(res.data.status==="success"){
    
  
   }
   else{
   }
  }
  }

const AddClass = async ({payload})=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  formData.append('session',user.SessionId);
  
    formData.append('course_url',payload.course_url);
    formData.append('title',payload.title);
    formData.append('topics',payload.topics);
  
    formData.append('meeting_link',payload.meeting_link);
  
    formData.append('date',payload.date);
  
    formData.append('starts',payload.starts);
  
    formData.append('batch_id',payload.batch_id);
  
    formData.append('ends',payload.ends);
  
  const res = await axios.post(ENDPOINT+"admin-add-schedule.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
     if(res.data.status==="success"){
 // setchapters(res.data.data)

   }
   else{
  
   }
  }
  }
  const [permission,setpermission] = useState(JSON.parse(sessionStorage.getItem("permissions"))||null)

  const GetPermissions = async ()=>{
  const data = new FormData()
  if(user.SessionId){
    data.append("session",user.SessionId)
    const res = await axios.post(ENDPOINT+"admin-permissions.php",data,SecurityHeaders)
  
  if(res){
  
  if(res.data){
    if(res.data.status==="success"&res.data.permissions!==null){
      setpermission(res.data.permissions)
      sessionStorage.setItem("permissions",JSON.stringify(res.data.permissions))
    }else{
      
    }
  }
  }
  }
  
  
  }
useEffect(()=>{
GetPermissions()
},[user])

 
const [notifications,setnotifications] = useState(null);
const [unread,setunread] = useState(0);


const  getnotifications = async ()=>{
   ////  // console.log("getting notifications")
try{
  fetch(`${ENDPOINT}get-notifications.php?session=${user.SessionId}`)
  .then(r=>r.json())
  .then(result=>{
    
      
 if(result[0].status==="success"){
     

  if((result[0].data)!==null){
    if (result[0].unread>unread) {
      if(document.getElementById("audio")){
        document.getElementById("audio").play()
      }
    }
      setnotifications(result[0].data);
   setunread(result[0].unread)
   
  }

 }
  
              
  
      
  })
}
catch{

}
  
  }    ;


  const dbRef = ref(
    getDatabase(),
    account ? "/admins/"+account.user_id + "/notifications" : "anonymous",
  );
  
  
    useEffect(() => {
      // Listen for changes in the 'messages' node in the database
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messageList = Object.values(data);
          if (account) {
            getnotifications();
            // // console.log("retreving data");
          }
        }
      });
    }, [account]);
return (<>

<Modal
  open={loading}
  onClose={()=>{}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle}>
    <div className="text-center">
    <CircularProgress/>
    </div>
 
  </Box>
</Modal>

<ActionsContext.Provider value={{
  notifications,unread,


internleaves,fetchInternLeaves,
internmeetings,fetchInternMeetings,

internalleaderboard,
fetchInternalLeaderboard,
internsleaves,
fetchInternsLeaves,
fetchInternalTeamList,
internalteamlist,
loading,setloading,

leads,fetchLeads,username,Addition,
StartClass,confirmclassstart,
response,
RescheduleClass,
DeleteClass,
permission,
togglesidebar,settogglesidebar,
GetPermissions,
enablevoice,
hearing,sethearing,
StartListening,StopListening,
enablestats,
setenablestats,
deleting,setdeleting,updating,setupdating,
ShowStartClassModal,CloseStartClassModal,voiceinput,setvoiceinput,searchquery,setsearchquery
}}>
    {children}
</ActionsContext.Provider>
</>)

}




