import React from 'react'
import BatchReportItem from './BatchReportItem'

const BatchReportsList = ({ reports }) => {
  return (
    <>

      <div className="card-body">
        <div className="tab-content text-muted">
          <div
            className="tab-pane active"
            id="today"
            role="tabpanel"
          >
            <div className="profile-timeline">
              <div
                className="accordion accordion-flush"
                id="todayExample"
              >



                {reports.map((item, index) => (
                  <>
                    <BatchReportItem item={item} index={index} />
                  </>
                ))}
              </div>
              {/*end accordion*/}
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default BatchReportsList