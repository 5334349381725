import React, { useContext, useEffect } from 'react'
import GetCourseProjects from '../../actions/course/GetCourseProjects'
import { AuthContext } from '../../context/AuthContext'
import { useParams } from 'react-router-dom'
import DemoData from '../../data/DemoData'
import ProjectItem from './ProjectItem'

const ProjectsList = ({projects}) => {
const {user} = useContext(AuthContext)
const params = useParams()

  return (
    <div>
        
        
        
<div className="d-flex flex-wrap">


{projects.map((item)=>(
    <>
    
    <div className="col-lg-4 p-3">
<div className="shadow">

<ProjectItem item={item}/>

</div>
</div>
    </>
))}

</div>


        </div>
  )
}

export default ProjectsList