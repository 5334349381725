const DetectDevice = () => {

 let device = null
      
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            
               device = "mobile"
             }else{
               
          device = "desktop"
             }
    

  return (device)
}

export default DetectDevice

