import React, { useContext, useEffect, useState } from 'react'
import { useActionsContext } from '../../context/ActionsContext'
import { useParams } from 'react-router-dom'
import dateFormat from 'dateformat'
import ENDPOINT from '../../context/EndPoint'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
const EmployeeWorkHistory = () => {
  const params = useParams()
  const { user } = useContext(AuthContext)
  const intern_id = params.user_id
  const internship_id = params.internship_id
  const [internattendance, setinternattendance] = useState(null)
  const FetchInternAttendance = async () => {

    const formData = new FormData();
    formData.append('session', user.SessionId);
    formData.append('intern_id', intern_id);
    formData.append('internship_id', internship_id);

    const res = await axios.post(ENDPOINT + "admin-get-work-history.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });

    if (res) {
      setinternattendance(res.data)
      //  // console.log("work "+JSON.stringify(res))
    }

  }

  useEffect(() => {
    FetchInternAttendance()
  }, [])
  return (
    <>
      <div className="tab-pane" id="documents" role="tabpanel">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center mb-4">
              <h5 className="card-title flex-grow-1 mb-0">
                Employee Attendace
              </h5>
              <div className="flex-shrink-0">

                <label

                >
                  <i className="ri-upload-2-fill me-1 align-bottom" />{" "}
                  Export Data
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">LoggedIn</th>
                        <th scope="col">LoggedOut</th>
                        <th scope="col">Work Hours</th>
                        <th scope="col">Tasks</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <>
                      {internattendance && internattendance.work_days ?
                        <>
                          {internattendance.work_days.map((item) => (
                            <>

                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">

                                    <div className="ms-3 flex-grow-1">
                                      <h6 className="fs-15 mb-0">
                                        <a href="javascript:void(0)">
                                          {dateFormat(item.start_time, "dS mmm yyyy")}
                                        </a>
                                      </h6>
                                    </div>
                                  </div>
                                </td>
                                <td>{dateFormat(item.start_time, "hh:mm tt")}</td>
                                <td>{item.end_time ? dateFormat(item.end_time, "hh:mm tt") : "Working"}</td>

                                <td>8 hrs</td>
                                <td>3</td>
                                <td>
                                  <div className="dropdown">
                                    <a
                                      href="javascript:void(0);"
                                      className="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                    >
                                      <i className="ri-equalizer-fill" />
                                    </a>
                                    <ul
                                      className="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                    >
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          <i className="ri-eye-fill me-2 align-middle text-muted" />
                                          View
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                          Download
                                        </a>
                                      </li>
                                      <li className="dropdown-divider" />
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                          Delete
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}

                        </> :
                        <>


                        </>}
                    </>
                  </table>
                </div>
                <div className="text-center mt-3">
                  <a
                    href="javascript:void(0);"
                    className="text-success"
                  >
                    <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" />{" "}
                    Load more{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default EmployeeWorkHistory