
const initialState = {
    course_image : '',
    course_name : '',
    course_mode: ''
}

const CourseManagement = (state = initialState,action)=>{

    if(action.type === 'ADD_COURSE_DETAILS'){
        return {...state,course_image:action.payload.image,course_name:action.payload.name,course_mode:action.payload.mode}
    }
    return state;
}

export default CourseManagement;