import React from 'react'
import dateFormat from 'dateformat'
import { MoreVert } from '@material-ui/icons'
import { Dropdown } from 'react-bootstrap'
import { Avatar } from '@mui/material'
const BatchAttendence = ({attendence}) => {
    
  return (
<>


<div className="table-responsive">
                              <table className="table table-borderless align-middle mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">Student</th>
                                    <th scope="col">Class Date</th>
                                    <th scope="col">Login Time</th>
                                    <th scope="col">Loggedout Time</th>
                                    
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                  </tr>
                                </thead>
                             <>
                             {attendence?
                             <>
                             {attendence.map((item)=>(
                              <>

<tr>   <td>
  <div className="d-flex">
  <Avatar src={item.profile_pic}/>
  <div>
    <h6>{item.first_name+" "+item.last_name}</h6>
  </div>
  </div>
  </td> 
                                    <td>
                                      <div className="d-flex align-items-center">
                                       
                                        <div className="ms-3 flex-grow-1">
                                          <h6 className="fs-15 mb-0">
                                            <a href="javascript:void(0)">
                                             {dateFormat(item.class_datetime,"dS mmm yyyy hh:MM tt")}
                                            </a>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>{dateFormat(item.loggedin_time," hh:MM tt")}</td>
                                    <td>{item.loggedout_time?dateFormat(item.loggedout_time," hh:MM tt"):"NA"}</td>
                                  
                                 
                                     <td>{item.status}</td>
                                    <td>
                                   <Dropdown>
                                    <Dropdown.Toggle><MoreVert/></Dropdown.Toggle>

                                    <Dropdown.Menu>
                                       </Dropdown.Menu>
                                   </Dropdown>
                                    </td>
                                  </tr>
                              </>
                             ))}
                             
                             </>:
                             <>
                             
                             
                             </>}
                             </>
                              </table>
                            </div>
</>
  )
}

export default BatchAttendence