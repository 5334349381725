import axios from 'axios'
import React, { useEffect, useState } from 'react'
import SecurityHeaders from '../../context/SecurityHeaders'
import dateFormat from 'dateformat'
import DefaultAlertModal from '../../components/modals/DeafaultAlertModal'
import DetectDevice from '../../context/DetectDevice'
const StudentIDGenerator = ({profile,Reload,saveidcard}) => {
    const device = DetectDevice();
    const [loadingidcard,setloadingidcard] = useState(false)
    const [idcard,setidcard] = useState(null)
    const [selectedname,setselectedname] = useState(profile.first_name||null)
    useEffect(()=>{
      GetIdCard({method:"get"})
    },[selectedname])
    useEffect(()=>{
 if(saveidcard){
  GetIdCard({method:"save"})
 }
    },[saveidcard])
    const [idcode,setidcode] = useState(null)
    const GetIdCard = async({method})=>{
    const data = new FormData()
    setloadingidcard(true)
    data.append("username",profile.username)
    data.append("student_id",profile.purchase_id)
    data.append("image",profile.profile_pic)
    data.append("phone",profile.phone_number)
    data.append("joining_date",dateFormat(profile.purchase_datetime,"d:mm:yyyy"))
    if(method==="save"){
      data.append("save",true)
    
    }
    data.append("course",profile.course_url)
    data.append("name",selectedname)
      const res = await axios.post("https://skillsuprise.com/id-card-template.php",data,SecurityHeaders)
    
      if(res){
        setloadingidcard(false)
    
        if(res.data.status==="success"){
    
            setidcard(res.data.idcard)
            let id = document.getElementById("idcard")
            id.innerHTML = res.data.idcard
        setidcode(res.data)
    if(res.data.msg==="saved"){
      settoasttitle("ID CARD Saved")
      settoastmessage("Idcard is successfully created.")
   settoast(true)
   Reload()

    }
    
        }else{
       
        }
     
    
      
      }
    
    }
    const [toasttitle,settoasttitle] = useState(null)
    const [toastmessage,settoastmessage] = useState(null)
const [toast,settoast] = useState(false)

  return (
    <div className='container-fluid'>
  <DefaultAlertModal title={toasttitle} message={toastmessage} HandleClose={()=>settoast(false)} show={toast}/>

  {device==="mobile"?
  <>
      <div className="container p-0 my-3">

        <div className='px-3'>
        <div className="w-fit">
        <p className='fs-16'>Select Preferred Name</p>
        </div>
        <div className="d-flex">


          <button className='btn btn-primary me-2 fs-10'  disabled={loadingidcard}  onClick={()=>setselectedname(profile.first_name)}>{profile.first_name}</button>
          <button className='btn btn-primary me-2 fs-10' disabled={loadingidcard}  onClick={()=>setselectedname(profile.last_name)}>{profile.last_name}</button>
          <button className='btn btn-primary fs-10' disabled={loadingidcard} onClick={()=>setselectedname(profile.first_name+" "+profile.last_name)}>{profile.first_name+" "+profile.last_name}</button>
        </div>
        <hr className='mt-2 mb-2'></hr>

        <div >
          <p className='fs-16'>ID Card</p>

          <div className='w-100' id='idcard'></div>

        </div>

          <button className='btn btn-success h-fit mt-3'   disabled={loadingidcard} onClick={()=>GetIdCard({method:"save"})}>{loadingidcard?"Loading ID Card...":"SAVE ID CARD"}</button>
          

        </div>
      </div>
  </>:
  <>
      <div className="d-flex container">

      <div className="col-lg-4">
      <h4>ID Card</h4>

      <div className='w-100' id='idcard'></div>

      </div>
      <div className='col-lg-8 px-4'>
      <div className="w-fit">
      <h4>Select Preferred Name</h4>
        <hr></hr>
      </div>
      <div className="d-flex">


        <button className='btn btn-primary me-2'  disabled={loadingidcard}  onClick={()=>setselectedname(profile.first_name)}>{profile.first_name}</button>
        <button className='btn btn-primary me-2' disabled={loadingidcard}  onClick={()=>setselectedname(profile.last_name)}>{profile.last_name}</button>
        <button className='btn btn-primary' disabled={loadingidcard} onClick={()=>setselectedname(profile.first_name+" "+profile.last_name)}>{profile.first_name+" "+profile.last_name}</button>
      </div>

        <button className='btn btn-success h-fit mt-3'   disabled={loadingidcard} onClick={()=>GetIdCard({method:"save"})}>{loadingidcard?"Loading ID Card...":"SAVE ID CARD"}</button>


      </div>
    </div>
  </>}

  </div>
  )
}

export default StudentIDGenerator