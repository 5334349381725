const getInitialStatisticsState = () => {
    try {
        const storedStatistics = localStorage.getItem('statistics');
        return storedStatistics !== null ? JSON.parse(storedStatistics) : {};
    } catch (error) {
        //console.error('Error reading Statistics from localStorage:', error);
        return {};
    }
};

const initialStatisticsState = {
    statistics: getInitialStatisticsState()
};

const statisticsReducer = (state = initialStatisticsState, action) => {
    switch (action.type) {
        case 'STATISTICS_UPDATE':
            const newState = { ...state, statistics: action.payload };
            try {
                localStorage.setItem('statistics', JSON.stringify(action.payload));
            } catch (error) {
                //console.error('Error saving Statistics to localStorage:', error);
            }
            return newState;
        default:
            return state;
    }
};

export default statisticsReducer;
