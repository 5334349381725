

const initialState = {
    profile : Object()
}


const studentProfile = (state = initialState,action)=>{

   if(action.type === 'STUDENT_PROFILE'){
    return {...state,profile:action.payload}
   }
   else{
    return state;
   }
}

export default studentProfile;