// TeamContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import { AuthContext } from '../../context/AuthContext';
import Localbase from 'localbase';
const TeamContext = createContext();

export const useTeamData = () => useContext(TeamContext);

export const TeamProvider = ({ children }) => {
 
  const db  = new Localbase("db")
  const [teamData, setTeamData] = useState(null);
  useEffect(()=>{
    db.collection("paths").get().then((data)=>{
      setTeamData(data[0].internal_team)
          })
  },[])
  const {user} = useContext(AuthContext)
    const fetchTeamData = async (payload) => {
        try {
          const formData = new FormData();
          formData.append('session', user.SessionId);
    
          const res = await axios.post(ENDPOINT + "get-internal-team-list.php", formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          });
          if(res){
    if(res.data.status==="success"){
var data = res.data.data
        db.collection("paths").add({data},"internal_team")
    }
     
          }
    
          if (res) {
            setTeamData(res.data.data);
          } else {
            // Handle error
          }
        } catch (error) {
          // Handle error
        }
      };








  return (
    <TeamContext.Provider value={{teamData,fetchTeamData}}>
      {children}
    </TeamContext.Provider>
  );
};




