import React, { useContext, useEffect, useState } from 'react'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import axios from 'axios';
import StudentsList from '../students/StudentsList';
import { Link } from 'react-router-dom';
import StudentsPlacementSupport from '../support/StudentsPlacementSupport';
export const convertMinutesToTime = (minutes) => {
  const days = Math.floor(minutes / 1440);
  const hours = Math.floor((minutes % 1440) / 60);
  const mins = Math.floor(minutes % 60);
  return `${days}d  ${hours}h  ${mins}m`;
};
const PlacementOfficerDashboard = () => {

  const data = [0, 1, 2, 3, 4, 5, 6]



  const { user } = useContext(AuthContext);
  const [statistics, setStatistics] = useState('')
  const [placement, setPlacement] = useState('');

  const GetStatistics = async () => {
      try {
          const data = new FormData();
          data.append('session', user.SessionId);

          const res = await axios.post(ENDPOINT + "placement-dashboard-statistics.php", data, SecurityHeaders);
          if (res) {
              if (res.data.status === "success") {
                  setStatistics(res.data)
              }
          }

          const response = await axios.post(ENDPOINT + "placement-request-statistics.php", data, SecurityHeaders);
          if (response) {
              if (response.data.status === "success") {
                  setPlacement(response.data.data)
              }
          }

      }
      catch (err) {
           // console.log(err.message);
      }
  }

  useEffect(() => {
      GetStatistics();
  }, []);

  return (
    <>
      <div className='container-fluid'>
        <section className='d-flex flex-wrap'>
          <div className='col-lg-6 col-md-12 col-12'>
            <h3 className='ms-2 text-uppercase'>Students list</h3>
            <div className='d-flex flex-wrap col-lg-12 col-md-12 col-sm-12 col-12'>
              <div className='col-6 p-2'>
                <div className='p-3 bg-white shadow rounded text-uppercase'>
                  <span><b>Online Students</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>{statistics.online_count}</b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>4.80%</b>
                    </div>
                  </div>

                </div>
              </div>

              <div className='col-6 p-2'>
                <div className='p-3 bg-white shadow rounded text-uppercase'>
                  <span><b>Offline Students</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>{statistics.offline_count}</b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>4.80%</b>
                    </div>
                  </div>

                </div>
              </div>
              <div className='col-6 p-2'>
                <div className='p-3 bg-white shadow rounded text-uppercase'>
                  <span><b>Selfpaced Students</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>{statistics.selfpaced_count} </b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>4.80%</b>
                    </div>
                  </div>

                </div>
              </div>
              <div className='col-6 p-2'>
                <div className='p-3 bg-white shadow rounded text-uppercase'>
                  <span><b>Specialization Students</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>{statistics.specialization_count}</b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>4.80%</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 col-md-12 col-12'>
          <h3 className='ms-2 text-uppercase'>Placement Training Schedule</h3>
            <div className='d-flex flex-wrap col-lg-12 col-md-12 col-sm-12 col-12'>
             
              <div className='col-6 p-2'>
                <div className='p-3 text-bg shadow rounded text-uppercase'>
                  <span><b>Initiated</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>
                    {placement?.total_initiated}
                    </b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>10%</b>
                    </div>
                  </div>

                </div>
              </div>

              <div className='col-6 p-2'>
                <div className='p-3 text-bg shadow rounded text-uppercase'>
                  <span><b>Closed</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b> {placement?.total_closed}</b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>0%</b>
                    </div>
                  </div>

                </div>
              </div>
              <div className='col-6 p-2'>
                <div className='p-3 text-bg shadow rounded text-uppercase'>
                  <span><b>Total</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b> {placement?.total_supports}</b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>90%</b>
                    </div>
                  </div>

                </div>
              </div>
              <div className='col-6 p-2'>
                <div className='p-3 text-bg shadow rounded text-uppercase'>
                  <span><b>AVG Resolution Time</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>{convertMinutesToTime(placement?.average_initiated_time_minutes)}
                    </b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>0%</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container-fluid mt-4'>
          <div className='row'>
            <div className='col-lg-6 p-2'>
              <div className="card p-2">
<div className='d-flex justify-content-between align-items-center m-3'>
<h4 className=' text-uppercase'><b>Students List</b></h4>
<Link to='/students' className='text-uppercase text-decoration-none h5 text-primary'>View all</Link>
</div>               <StudentsList options={{search:false,date:false,limit:10,batch:false}}/>
              </div>
            </div>



            <div className='col-lg-6 p-2'>
              <div className="card p-2">

              <div className='d-flex justify-content-between align-items-center m-3'>
              <h4 className='text-uppercase'><b>Placement </b></h4>

<Link to='/placement-support' className='text-uppercase text-decoration-none h5 text-primary'>View all</Link>
</div>
            <StudentsPlacementSupport options={{search:false,limit:10,course:false}}/>
              </div>
            </div>

          </div>
        </section>
      </div>
    </>
  )
}

export default PlacementOfficerDashboard