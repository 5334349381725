import styled from "styled-components"
import Sidebar from "../components/Sidebar"
import PieChart from "../components/charts/PieChart"
import Navbar from "../components/Navbar"
import logo from '../assets/images/logo-main.png'
import { useActionsContext } from "../context/ActionsContext"
import DetectDevice from "../context/DetectDevice"
import { Link } from "react-router-dom"
import { useEffect } from "react"
function NavbarTheme({ children, showsidebar, shownavbar, account }) {


  const {togglesidebar,settogglesidebar} = useActionsContext()
const device = DetectDevice()

useEffect(()=>{

  if(device==="mobile"){
    settogglesidebar(false)
  }

},[window.location.href,window.location])
  return (

    <>

      <>
        {/* Begin page */}
        <div id="layout-wrapper">
          {shownavbar ?
            <Navbar account={account} />

            : null}
 {/* ========== App Menu ========== */}
 <div className={(showsidebar && togglesidebar)?"app-menu navbar-menu override-sidebar":togglesidebar?"app-menu navbar-menu":"app-menu navbar-menu d-none"}>
                {/* LOGO */}
                <div className="navbar-brand-box">

           <Link to="/">       <img className="w-100 mt-2" src={logo}></img></Link>
                  <hr></hr>
                </div>
                <div className="dropdown sidebar-user m-1 rounded">
                  <button
                    type="button"
                    className="btn material-shadow-none"
                    id="page-header-user-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"  
                    aria-expanded="false"
                  >
                    <span className="d-flex align-items-center gap-2">
                      <img
                        className="rounded header-profile-user"
                        src="assets/images/users/avatar-1.jpg"
                        alt="Header Avatar"
                      />
                      <span className="text-start">
                        <span className="d-block fw-medium sidebar-user-name-text">
                          Anna Adame
                        </span>
                        <span className="d-block fs-14 sidebar-user-name-sub-text">
                          <i className="ri ri-circle-fill fs-10 text-success align-baseline" />{" "}
                          <span className="align-middle">Online</span>
                        </span>
                      </span>
                    </span>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    {/* item*/}
                    <h6 className="dropdown-header">Welcome Anna!</h6>
                    <a className="dropdown-item" href="pages-profile.html">
                      <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />{" "}
                      <span className="align-middle">Profile</span>
                    </a>
                    <a className="dropdown-item" href="apps-chat.html">
                      <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1" />{" "}
                      <span className="align-middle">Messages</span>
                    </a>
                    <a className="dropdown-item" href="apps-tasks-kanban.html">
                      <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1" />{" "}
                      <span className="align-middle">Taskboard</span>
                    </a>
                    <a className="dropdown-item" href="pages-faqs.html">
                      <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1" />{" "}
                      <span className="align-middle">Help</span>
                    </a>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item" href="pages-profile.html">
                      <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1" />{" "}
                      <span className="align-middle">
                        Balance : <b>$5971.67</b>
                      </span>
                    </a>
                    <a className="dropdown-item" href="pages-profile-settings.html">
                      <span className="badge bg-success-subtle text-success mt-1 float-end">
                        New
                      </span>
                      <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1" />{" "}
                      <span className="align-middle">Settings</span>
                    </a>
                    <a className="dropdown-item" href="auth-lockscreen-basic.html">
                      <i className="mdi mdi-lock text-muted fs-16 align-middle me-1" />{" "}
                      <span className="align-middle">Lock screen</span>
                    </a>
                    <a className="dropdown-item" href="auth-logout-basic.html">
                      <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />{" "}
                      <span className="align-middle" data-key="t-logout">
                        Logout
                      </span>
                    </a>
                  </div>
                </div>


                <div className="overflow-y-scroll">
                  <Sidebar  account={account}/>
                </div>  <div className="sidebar-background" />

              </div>































          {/* Left Sidebar End */}
          {/* Vertical Overlay*/}
          <div className="vertical-overlay" />
          {/* ============================================================== */}
          {/* Start right Content here */}
          {/* ============================================================== */}
          <div className={showsidebar &&togglesidebar? "main-content" : "main-content ms-0"}>
            <div className={shownavbar ? "page-content" : "page-content pt-0"}>
              {children}
            </div>

          </div>
          {/* end main content*/}
        </div>
        {/* END layout-wrapper */}
        {/*start back-to-top*/}
        <button
          onclick="topFunction()"
          className="btn btn-danger btn-icon"
          id="back-to-top"
        >
          <i className="ri-arrow-up-line" />
        </button>
        {/*end back-to-top*/}
        {/*preloader*/}



        {/* JAVASCRIPT */}
        {/* apexcharts */}
        {/* Vector map*/}
        {/*Swiper slider js*/}
        {/* Dashboard init */}
        {/* App js */}
      </>


    </>
  )


}

export default NavbarTheme



const StyledSuperAdminDashboard = styled.div`



h1{

    color:red;
}




`