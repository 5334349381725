import React, { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../context/EndPoint';
import axios from 'axios';
import { connect } from 'react-redux';

const AddNewProject = ({ courseId, batchId, dispatch, course_url,closeModal }) => {
    const { user } = useContext(AuthContext);

 
    const [duration, setDuration] = useState('');
    const [resourcesUrl, setResourcesUrl] = useState('');
    const [solutionLink, setSolutionLink] = useState('');
    const [projectSubmissionUrl, setProjectSubmissionUrl] = useState('');
  
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [form, setForm] = useState({ title: '', description: '', image: '', course_url: '', project_id: null });



    const handelForm = (e) => {
        setForm((prev) => (
            {
                ...prev,
                [e.target.name]: e.target.value
            }
        ))
    }

    const addProject = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('session', user.SessionId);
        formData.append('course_id', courseId);
        formData.append('course_url', course_url);
        formData.append('batch_id', batchId);
        formData.append('project_id', form.project_id);
        formData.append('title', form.title);
        formData.append('description', form.description);
        formData.append('image', form.image);       

        try {
            const res = await axios.post(ENDPOINT + "add-project.php", formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });

            if (res.data.status === "success") {
                setSnackbarMessage("Project Added");
                setSnackbar(true);
                dispatch({ type: 'FUNCTION_RELOAD', payload: 'projects' })
                closeModal()
            } else {
                setSnackbarMessage("Something went wrong!");
                setSnackbar(true);
            }
        } catch (error) {
            console.error("Error adding project:", error);
            setSnackbarMessage("Error adding project. Please try again.");
            setSnackbar(true);
        }

        setLoading(false);
    };

    return (
        <div className="container">
            <form onSubmit={addProject}>
                <div className="form-group">
                    <label>Title</label>
                    <input type="text" className="form-control" name='title' onChange={handelForm} />
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <input type="text" className="form-control" name='description' onChange={handelForm} />
                </div>
                <div className="form-group">
                    <label>Image Url</label>
                    <input type="text" className="form-control" name='image' onChange={handelForm} />
                </div>
                <div className="form-group">
                    <label>Project Id</label>
                    <input type="text" className="form-control" name='project_id' onChange={handelForm} />
                </div>




                <button type="submit" className="btn mt-3 w-100 btn-primary" disabled={loading}>
                    {loading ? <CircularProgress size={20} /> : "Add Project"}
                </button>
            </form>

            <Snackbar
                open={snackbar}
                autoHideDuration={6000}
                onClose={() => setSnackbar(false)}
                message={snackbarMessage}
            />
        </div>
    );
};

const reload = (prop) => ({
    value: prop.modalHandler
})
export default connect(reload)(AddNewProject);
