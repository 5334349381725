import { MoreVert } from '@material-ui/icons'
import { Delete, Edit, Update } from '@mui/icons-material'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'

const HackingInternshipRegistrationItem = ({item,SelectUser}) => {
    const [show,setshow] = useState(false)

    const HandleClose = ()=>{

setshow(false)

    }
    const HandleDelete = ()=>{



    }

    const [edit,setedit] = useState(false)
    const CloseEdit = ()=>{

        setedit(false)
    }
    const [selected,setselected] = useState(false)
  return (
  
  <>


  <tr>
                            <th scope="row">
                              <div className="form-check">
                                <input
checked={selected}
                                  className="form-check-input"
                                  type="checkbox"
                                  name="checkAll"
                                  onClick={()=>{SelectUser();setselected(!selected)}}
                                  defaultValue="option1"
                                />
                              </div>
                            </th>
                          
                            <td className="customer_name">{item.name}</td>
                            <td className="product_name">{item.phone_number}</td>
                            <td className="date">
                              {item.email}
                              </td>
                            <td className="amount">{item.create_datetime}</td>
                         
                            <td className="status">
                              <span className="badge bg-warning-subtle text-warning text-uppercase">
                               {item.status}
                              </span>
                            </td>
                            <td>
                              <ul className="list-inline hstack gap-2 mb-0">
                                <li
                                  className="list-inline-item"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  data-bs-placement="top"
                                  title="View"
                                >
                                  <a
                                    href="apps-ecommerce-order-details.html"
                                    className="text-primary d-inline-block"
                                  >
                                    <i className="ri-eye-fill fs-16" />
                                  </a>
                                </li>
                                <li
                                  className="list-inline-item edit"
                                 onClick={()=>setedit(true)}
                                  title="Edit"
                                >
                                  <a
                          
                                    className="text-primary d-inline-block edit-item-btn"
                                  >
                                    <i className="ri-pencil-fill fs-16" />
                                  </a>
                                </li>
                                <li
                                  className="list-inline-item"
                             
                                  onClick={()=>setshow(true)}
                                  title="Remove"
                                >
                                  <a
                                    className="text-danger d-inline-block remove-item-btn"
                                   
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16" />
                                  </a>
                                </li>
                              </ul>
                            </td>

                            <td className="amount">
                            <Dropdown>
      <Dropdown.Toggle variant="transparent" id="dropdown-basic">
        <MoreVert/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1"><Edit/> Edit</Dropdown.Item>
        <Dropdown.Item href="#/action-2"><Update/> update</Dropdown.Item>
        <Dropdown.Item href="#/action-3"><Delete/> Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>  
                                </td>
                          </tr>
  </>
    )
}

export default HackingInternshipRegistrationItem