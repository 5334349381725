import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DailyReport from './DailyReport';
import { Link } from 'react-router-dom';
import ENDPOINT from '../../context/EndPoint';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Localbase from 'localbase';

const DailyReports = () => {


  const db = new Localbase("db")



  // Assuming you have a state to store the reports
  const [reports, setReports] = useState(null);
  const { user } = useContext(AuthContext)
  // Assuming you have a function to fetch reports from the PHP endpoint
  const fetchReports = async () => {
    try {

      const data = new FormData()
      data.append("session", user.SessionId)
      // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint URL
      const response = await axios.post(ENDPOINT + "admin-get-daily-reports.php");

      if (response) {

        if (response.data && response.data.reports !== null) {

          const data=response.data.reports
          db.collection("paths").add({data},"reports")

        }
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

 

  const getofflinereports =()=>{


    db.collection("paths").doc("reports").get().then((data)=>{
      
      setReports(data.data)
      console.log(data)
    })
  }


  useEffect(() => {
    // Fetch reports when the component mounts
    fetchReports();
    getofflinereports()
  }, []);


  return (
    <div>
      <div className="container-fluid d-flex justify-content-between">
        <h2>Daily Reports</h2>
        <Link to="/add-daily-report" className="btn btn-primary">
          Add Report
        </Link>
      </div>
      <hr></hr>
      <div className="container-fluid sm-p-15 d-flex flex-wrap">
        {/* Map through the reports array and render DailyReport components */}
        {reports ?
          <>

            {reports.map((report) => (
              <DailyReport key={report.id} report={report} />
            ))}
          </> :
          <>

          </>}
      </div>
    </div>
  );
};

export default DailyReports;
