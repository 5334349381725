import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import React, { useContext, useState } from 'react'
import { NavItem } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'
import dateFormat from 'dateformat';
import { Dropdown } from "react-bootstrap";
import { Delete, MoreVert } from '@material-ui/icons';
import DeleteModal from '../../components/modals/DeleteModal';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import { useDispatch } from 'react-redux';
const BatchItem = ({item}) => {
  const dispatch = useDispatch()
  const {user} = useContext(AuthContext)
const params = useParams()

const course_url = params.course_url
const [deleteModal,setDeleteModal] = useState(false)
const DeleteClass = async () => {
  const formData = new FormData();


  formData.append("session", user.SessionId);

  formData.append("id", item.id);
 

  const res = await axios.post(
    ENDPOINT + "admin-delete-batch.php",
    formData,
  SecurityHeaders
  );
  if (res) {

    if (res.data.status === "success") {

dispatch({type:'FUNCTION_RELOAD',payload:'batches'})
      setDeleteModal(false);
    } else {
    }
  }
};

  return (
    <div>

<DeleteModal
        HandleClose={() => setDeleteModal(false)}
        HandleDelete={DeleteClass}
        show={deleteModal}
      />
<div className="card border batch-item w-100 shadow card-height-100 mb-0 mb-md-3">
                <div className="card-body">
                  <div className="d-flex flex-column h-100">
                   
                    <div className="d-flex mb-2">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <span className="avatar-title bg-warning-subtle rounded ">
                            <img
                              src= {item.students&&item.students.length>0?item.students[0].profile_pic:item.class_starts} Batch
                     
                              alt=""
                              className="img-fluid p-1"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="mb-1 fs-15">
                          <Link
                           to={"/batch-management/"+(course_url!==undefined?course_url:item.course)+"/"+item.batch_id}
                            className="text-body"
                          >
                            {
                            item.batch_name?item.batch_name:item.students&&item.students.length>0?item.students[0].first_name+" "+item.students[0].last_name:item.class_starts
                            }
                         Batch
                          </Link>
                        </h5>
                        <p className="text-muted text-truncate-two-lines mb-3">
                     {item.course}
                        </p>
                      </div>

                      <div>
                      <Dropdown className="options-dropdown">
            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
              <MoreVert className="text-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>

              {/* <Dropdown.Item href="#" id={"edit" + item.id} onClick={() => setevaluate(true)}> <Edit /> Evaluate</Dropdown.Item> */}

              <Dropdown.Item href="#"  onClick={() => setDeleteModal(true)}><Delete /> Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
                      </div>
                    </div>
                    <div className="mt-auto">

                        <div className="d-flex justify-content-between mb-2">
                        <div>Timings : {item.class_starts} - {item.class_ends} </div>
                        <div>   Batch ID : {item.batch_id}</div>
                        </div>
                      {/* <div className="d-flex mb-2 mt-2">
                        <div className="flex-grow-1">
                          <div>{item.incomplete_classes?item.incomplete_classes+" Pending classes":""}</div>
                        </div>
                        <div className="flex-shrink-0">
                          <div>
                            <i className="ri-list-check align-bottom me-1 text-muted" />{" "}
                            {item.completed_classes}/{item.total_classes}
                          </div>
                        </div>
                      </div> */}
                      <div className="progress progress-sm animated-progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          aria-valuenow={item.class_progress}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: `${item.class_progress}%` }}
                        />
                        {/* /.progress-bar */}
                      </div>
                      {/* /.progress */}
                    </div>
                  </div>
                </div>
                {/* end card body */}
                <div className="card-footer bg-transparent border-top-dashed py-2">
                  <div className="d-flex justify-content-between align-items-center">
                
                    <AvatarGroup className='avatar-group d-flex' total={item.students?item.students.length:null} avatar-group max={4} >
{item.students?
<>


{item.students.map((student,index)=>(
  <>
{index<5?
<>


<Avatar sx={{height:30,width:30}} alt={student.first_name+" "+student.last_name} src={student.profile_pic} />

</>:null}
  </>
))}


</>:
<></>}
</AvatarGroup>
                     
                   
                      <Link className="btn btn-outline-primary" to={"/batch-management/"+(course_url!==undefined?course_url:item.course)+"/"+item.batch_id} >
                       
                       Manage Batch
                      </Link>
                  
                  </div>
                </div>
                {/* end card footer */}
              </div>

    </div>
  )
}

export default BatchItem