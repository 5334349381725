import React, { useContext, useEffect, useState } from 'react'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Avatar } from '@mui/material'
import ReactApexChart from 'react-apexcharts';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import PaymentsList from '../payments/PaymentsList';
import { Link } from 'react-router-dom';
import Expenses from '../expenses/Expenses';

const FinanceManagerDashboard = () => {

    const {user} = useContext(AuthContext)
    const [stats,setStats] = useState('')
    const getStatistics = async ()=>{
        try{
        const data = new FormData();

            data.append('session',user.SessionId);

            const res = await axios.post(ENDPOINT+"finance-dashboard.php",data,SecurityHeaders);
            if(res){
              if(res.data.status === 'success'){
              setStats(res.data)
              }
            }
        }
        catch(err){
 // console.log(err.message);
        }
    }
    useEffect(() => {
        getStatistics();
    }, []);

    const series = {
        monthDataSeries1: {
          prices: [34, 36, 37, 38, 38, 38, 40, 41],
          dates: [
            '2024-06-01', '2024-06-02', '2024-06-03', '2024-06-04',
            '2024-06-05', '2024-06-10', 
            '2024-06-11', '2024-06-12'
          ]
        }
      };
      
      const [options, setOptions] = useState({
        chart: {
         
          type: 'area',
          toolbar: {
            show: false // Disable the icons here
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        labels: series.monthDataSeries1.dates,
        xaxis: {
          type: 'datetime',
          labels: {
            show: false // Remove x-axis text
          }
        },
        yaxis: {
          labels: {
            show: false // Remove y-axis text
          }
        },
        legend: {
          horizontalAlign: 'left'
        }
      });
      
      const [chartSeries, setChartSeries] = useState([{
        name: "STOCK ABC",
        data: series.monthDataSeries1.prices
      }]);


       // console.log(stats?.month_paid_amount);



    const data = [0, 1, 2, 3, 4, 5, 6]
     
   
    return (
        <>
            <div className='container-fluid'>
                <section className='d-flex flex-wrap'>
                    <div className='col-lg-6 col-md-12 col-12'>
                        <h3 className='ms-2 text-uppercase'>Revenue</h3>
                        <div className='d-flex flex-wrap col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='col-6'>
                                <div className='bg-white shadow roundedtext-uppercase ps-3 pt-3 '>
                                    <span><b>Total Revenue</b></span>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h3 className='m-0'><b>0</b></h3>
                                    </div>
                                  <div className='container-fluid'>
                                  <ReactApexChart options={options} series={chartSeries} type="area" />
                                  </div>
                                </div>
                            </div>

                            <div className='col-6 p-2'>
                                <div className='p-3 bg-white shadow rounded text-uppercase'>
                                    <span><b>Total Expensive</b></span>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h3 className='m-0'><b>5</b></h3>
                                       
                                    </div>

                                </div>
                            </div>
                            <div className='col-6 p-2'>
                                <div className='p-3 bg-white shadow rounded text-uppercase'>
                                    <span><b>Monthly Revenue</b></span>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h3 className='m-0'><b>5</b></h3>
                                        
                                    </div>

                                </div>
                            </div>
                            <div className='col-6 p-2'>
                                <div className='p-3 bg-white shadow rounded text-uppercase'>
                                    <span><b>Monthly Expensive</b></span>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h3 className='m-0'><b>5</b></h3>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-12 col-12'>
                    <h3 className='ms-2 text-uppercase'>Payment</h3>
                        <div className='d-flex flex-wrap col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='col-6 p-2'>
                                <div className='p-3 text-bg shadow rounded text-uppercase'>
                                    <span><b>Total Payments</b></span>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h3 className='m-0'><b>{stats?.paid_amount?stats?.paid_amount:0}</b></h3>
                                        
                                    </div>

                                </div>
                            </div>

                            <div className='col-6 p-2'>
                                <div className='p-3 text-bg shadow rounded text-uppercase'>
                                    <span><b>Total Pending</b></span>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h3 className='m-0'><b>{stats?.pending_amount?stats?.pending_amount:0}</b></h3>
                                       
                                    </div>

                                </div>
                            </div>
                            <div className='col-6 p-2'>
                                <div className='p-3 text-bg shadow rounded text-uppercase'>
                                    <span><b>Current Month Amount</b></span>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h3 className='m-0'><b>{stats?.month_paid_amount? stats?.month_paid_amount:0}</b></h3>
                                       
                                    </div>

                                </div>
                            </div>
                            <div className='col-6 p-2'>
                                <div className='p-3 text-bg shadow rounded text-uppercase'>
                                    <span><b>Current Month Pending</b></span>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h3 className='m-0'><b>{stats?.month_pending_amount? stats?.month_pending_amount:0}</b></h3>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='container-fluid mt-4'>
                    <div className='row'>
                      

                        <div className='col-lg-6 p-2'>
                        <div className="card">
                        <div className='d-flex justify-content-between m-3'>
                        <h4 className=' text-uppercase'><b>Recent Expensive</b></h4>

                        <Link className='text-decoration-none h6 text-primary' to={"/expenses"}>VIEW ALL</Link>
                        </div>
                   <Expenses options={{search:false,limit:10}}/>
                </div>
                        </div>

                        <div className='col-lg-6 p-2'>
                        <div className="card">
                        <div className='d-flex justify-content-between m-3'>
                        <h4 className=' text-uppercase'><b>Pending Payment</b></h4>
                        <Link className='text-decoration-none h6 text-primary' to={"/payments"}>VIEW ALL</Link>
                        </div>        
                                    <div className="row">
                    <PaymentsList options={{search:false,actions:false,id:false,method:false,option:false,limit:10,status:'EMI'}}/>

                    </div>
                </div>
                        </div>
                        <div className='col-lg-12 p-2'>
                        <div className="card">
                  <div className='d-flex justify-content-between m-3'>
                  <h4 className='text-uppercase'><b>Recent Payment</b></h4>
                  <Link className='text-decoration-none h6 text-primary' to={"/payments"}>VIEW ALL</Link>
                  </div>
                    <PaymentsList options={{search:false,actions:false,id:false,method:false,limit:10}}/>
                </div>
                        </div>
                    </div>
                </section>
                
            </div>
        </>
    )
}

export default FinanceManagerDashboard
