import axios from 'axios';

import ENDPOINT from '../../context/EndPoint';

const AdminGetProjectSubmissions = async ({payload}) => {

          const formData = new FormData();

           formData.append('session',payload.user);
        
           formData.append('course_url',payload.course_url);
           
           formData.append('user_id',payload.user_id);
           
           
           

           try{
               const res = await axios.post(ENDPOINT+"student-course-submissions.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res.data){
                    return res.data
             
                }
           }
           catch(error){

return error
           }

     




}

export default AdminGetProjectSubmissions