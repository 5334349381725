const getInitialMicState = () => {
    try {
        const storedMic = localStorage.getItem('mic');
        return storedMic !== null ? JSON.parse(storedMic) : true;
    } catch (error) {
        //console.error('Error reading Mic from localStorage:', error);
        return true; 
    }
};

const initialState = {
    mic: getInitialMicState()
}

const micReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MIC_HANDEL':
            const newState = { ...state, mic: action.payload };
            try {
                localStorage.setItem('mic', JSON.stringify(action.payload));
            } catch (error) {
                //console.error('Error saving Mic to localStorage:', error);
            }
            return newState;
        default:
            return state;
    }
}

export default micReducer;
