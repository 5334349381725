import React, { useContext, useEffect, useState } from "react";
import EmployeeWorkHistory from "./EmployeeWorkHistory";
import TeamWorkSubmissions from "./TeamWorkSubmissions";
import { useActionsContext } from "../../context/ActionsContext";
import { Link, useParams } from "react-router-dom";
import EmployeeLeaveHistory from "./EmployeeLeaveHistory";
import EmployeeMeetingHistory from "./EmployeemMeetingHistory";
import EmployeeLeaderboard from "./EmployeeLeaderboard";
import DefaultModal from "../../components/modals/DefaultModal";
import { Close, DeleteForever } from "@material-ui/icons";
import NoPermissionModal from "../../components/modals/NoPermissionModal";
import ENDPOINT from "../../context/EndPoint";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Edit } from "@mui/icons-material";
import PermissionsManagement from "../security/PermissionsManagement";
import { connect } from "react-redux";
import Localbase from "localbase";

const TeamMemberProfile = ({ value, dispatch }) => {
  const params = useParams();
  const { user } = useContext(AuthContext);
  const internship_id = params.internship_id;
  const intern_id = params.user_id;
  const [edit, setedit] = useState(false);
  const { permission } = useActionsContext();
  const [member, setmember] = useState(null);

  const [teammember, setteammember] = useState(null);
  const fetchTeamMember = async () => {
    try {
      const formData = new FormData();
      formData.append("session", user.SessionId);
      formData.append("internship_id", internship_id);

      formData.append("intern_id", intern_id);

      const res = await axios.post(ENDPOINT + "team-member-profile.php",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      if (res) {
        if (res.data.status === "success") {

          setteammember(res.data.data);
          setmember(res.data.data);
          setdesignation(res.data.data.role);
          setefficiency(res.data.data.efficiency);
         
  //--------------offline

          var data = res.data.data
          db.collection("paths").add({ data }, "team_member" + params.user_id)

        }
        else {
        }
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    let payload = {
      internship_id: internship_id,
      intern_id: intern_id,
    };
  }, []);

  const [interntasks, setinterntasks] = useState(null);
  const fetchInternTasks = async () => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    formData.append("status", "all");
    formData.append("internship_id", internship_id);
    formData.append("intern_id", intern_id);

    const res = await axios.post(ENDPOINT + "get-intern-tasks.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      setinterntasks(res.data);
    }
  };
  useEffect(() => {
    fetchInternTasks();
    fetchTeamMember();
  }, []);
  const [designation, setdesignation] = useState(null);
  const [efficiency, setefficiency] = useState(null);

  const [section, setsection] = useState(
    JSON.parse(localStorage.getItem("team_section")) || "overview"
  );

  const ChangeSection = ({ team_section }) => {
    setsection(team_section);
  };

  useEffect(() => {
    localStorage.setItem("team_section", JSON.stringify(section));
  }, [section]);

  const [nopermission, setnopermission] = useState(false);

  useEffect(() => {
    // console.log(value.functionName);
    if (value.functionName === 'get-intern-tasks') {
      fetchInternTasks()
    }



  }, [value]);

//-----------offline functionality
  const db = new Localbase("db")

  var GetOfflineData = () => {

    db.collection('paths').doc("team_member" + params.user_id).get().then((data) => {
      if(data){
        setteammember(data.data)
      }
    })


  }

  useState(() => {

    GetOfflineData()
  }, [])

  //offline func.. end

  return (
    <>
      <NoPermissionModal
        show={nopermission}
        HandleClose={() => setnopermission(false)}
      />

      {teammember ? (
        <>
          <DefaultModal
            show={edit}
            content={
              <>
                <div className="p-4">
                  <div className="modal-header">
                    <h4>Edit Profile</h4>
                    <Close onClick={() => setedit(false)} />
                  </div>
                  <hr></hr>
                  <input
                    className="form-control"
                    value={designation}
                    placeholder="Enter Designation"
                  ></input>
                  <input
                    className="form-control mt-2"
                    value={efficiency}
                    placeholder="efficiency"
                  ></input>

                  <button className="btn btn-success w-100 mt-3">
                    Save Changes
                  </button>
                </div>
              </>
            }
          ></DefaultModal>
          <div className="container-fluid">
            <div className="profile-foreground position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg">
                <img
                  src="assets/images/profile-bg.jpg"
                  alt=""
                  className="profile-wid-img"
                />
              </div>
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
              <div className="row g-4">
                <div className="col-auto">
                  <div className="avatar-lg">
                    <img
                      src={teammember.profile_pic}
                      alt="user-img"
                      className="img-thumbnail rounded-circle"
                    />
                  </div>
                </div>
                {/*end col*/}
                <div className="col">
                  <div className="p-2">
                    <h3 className="text-white mb-1">
                      {teammember
                        ? teammember.first_name + " " + teammember.last_name
                        : null}
                    </h3>
                    <p className="text-white text-opacity-75 mb-1">
                      {teammember.role}
                    </p>
                    <div className="hstack text-white-50 gap-1">
                      <div className="me-2">
                        <i className=" me-1 text-white text-opacity-75 fs-16 align-middle" />
                        Employee ID :{teammember.internship_id}
                      </div>
                    </div>
                  </div>
                </div>

                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="d-flex profile-wrapper">
                    {/* Nav tabs */}
                    <ul
                      className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                      role="tablist"
                    >
                      <li
                        onClick={() =>
                          ChangeSection({ team_section: "overview" })
                        }
                        className="nav-item"
                      >
                        <a className="nav-link fs-14 active" role="tab">
                          <i className="ri-airplay-fill d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Overview
                          </span>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          parseInt(permission.tasks)
                            ? ChangeSection({ team_section: "tasks" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14" role="tab">
                          <i className="ri-list-unordered d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Tasks
                          </span>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          parseInt(permission.attendence)
                            ? ChangeSection({ team_section: "attendence" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14" role="tab">
                          <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Attendance
                          </span>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          parseInt(permission.meetings)
                            ? ChangeSection({ team_section: "meetings" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14" role="tab">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Meetings
                          </span>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          parseInt(permission.leaves)
                            ? ChangeSection({ team_section: "leaves" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14" role="tab">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Leaves
                          </span>
                        </a>
                      </li>

                      <li
                        onClick={() => {
                          parseInt(permission.team_leaderboard)
                            ? ChangeSection({
                              team_section: "team_leaderboard",
                            })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14" role="tab">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Team Leaderboard
                          </span>
                        </a>
                      </li>

                      <Link

                        onClick={() => {
                          parseInt(permission.team_permissions)
                            ? ChangeSection({
                              team_section: "team_permissions",
                            })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14" role="tab">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Permissions
                          </span>
                        </a>
                      </Link>
                    </ul>
                    <div className="flex-shrink-0">
                      <button
                        onClick={() => {
                          parseInt(permission.initiate_placement_support)
                            ? setedit(true)
                            : setnopermission(true);
                        }}
                        className="btn btn-success"
                      >
                        <i className="ri-edit-box-line align-bottom" /> Edit
                        Profile
                      </button>
                    </div>
                  </div>
                  {/* Tab panes */}
                  <div className="tab-content pt-4 text-muted">
                    {section === "overview" ? (
                      <>
                        <div
                          className="tab-pane active"
                          id="overview-tab"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-xxl-3">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-5">
                                    Effeciency
                                  </h5>
                                  <div className="progress animated-progress custom-progress progress-label">
                                    <div
                                      className="progress-bar bg-danger"
                                      role="progressbar"
                                      style={{
                                        width: `${teammember.efficiency}%`,
                                      }}
                                      aria-valuenow={teammember.efficiency}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    >
                                      <div className="label">
                                        {teammember.efficiency}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-3">Info</h5>
                                  <div className="table-responsive h-fit">
                                    <table className="table table-borderless mb-0">
                                      <tbody>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Name :
                                          </th>
                                          <td className="text-muted">
                                            {teammember.last_name +
                                              " " +
                                              teammember.last_name}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Mobile :
                                          </th>
                                          <td className="text-muted">
                                            {teammember.phone_number}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            E-mail :
                                          </th>
                                          <td className="text-muted">
                                            {teammember.email}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Location :
                                          </th>
                                          <td className="text-muted">
                                            {teammember.address
                                              ? teammember.address
                                              : "NA"}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Joining Date :
                                          </th>
                                          <td className="text-muted">
                                            {teammember.joining_datetime}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {/* end card body */}
                              </div>
                              {/* end card */}
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-4">Portfolio</h5>
                                  <div className="d-flex flex-wrap gap-2">
                                    <div>
                                      <a
                                        href="javascript:void(0);"
                                        className="avatar-xs d-block"
                                      >
                                        <span className="avatar-title rounded-circle fs-16 bg-body text-body material-shadow">
                                          <i className="ri-github-fill" />
                                        </span>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="javascript:void(0);"
                                        className="avatar-xs d-block"
                                      >
                                        <span className="avatar-title rounded-circle fs-16 bg-primary material-shadow">
                                          <i className="ri-global-fill" />
                                        </span>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="javascript:void(0);"
                                        className="avatar-xs d-block"
                                      >
                                        <span className="avatar-title rounded-circle fs-16 bg-success material-shadow">
                                          <i className="ri-dribbble-fill" />
                                        </span>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="javascript:void(0);"
                                        className="avatar-xs d-block"
                                      >
                                        <span className="avatar-title rounded-circle fs-16 bg-danger material-shadow">
                                          <i className="ri-pinterest-fill" />
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* end card body */}
                              </div>
                              {/* end card */}
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-4">Skills</h5>
                                  <div className="d-flex flex-wrap gap-2 fs-15">
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      Photoshop
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      illustrator
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      HTML
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      CSS
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      Javascript
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      Php
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      Python
                                    </a>
                                  </div>
                                </div>
                                {/* end card body */}
                              </div>
                              {/* end card */}

                            </div>
                            {/*end col*/}
                            <div className="col-xxl-9">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-3">About</h5>
                                  <p>{teammember.about}</p>

                                  <div className="row">
                                    <div className="col-6 col-md-4">
                                      <div className="d-flex mt-4">
                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                          <div className="avatar-title bg-light rounded-circle fs-16 text-primary material-shadow">
                                            <i className="ri-user-2-fill" />
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                          <p className="mb-1">Designation :</p>
                                          <h6 className="text-truncate mb-0">
                                            {teammember.role}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    {/*end col*/}

                                  </div>
                                  {/*end row*/}
                                </div>
                                {/*end card-body*/}
                              </div>
                              {/* end card */}
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="card">
                                    <EmployeeWorkHistory />
                                  </div>

                                  {/* end card */}
                                </div>
                                {/* end col */}
                              </div>
                            </div>
                            {/*end col*/}
                          </div>
                          {/*end row*/}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/*end tab-pane*/}
                    <div className="tab-pane active" id="tasks" role="tabpanel">
                      {section === "tasks" ? (
                        <>
                          {interntasks && interntasks.data ? (
                            <>
                              <TeamWorkSubmissions
                                item={teammember}
                                tasks={interntasks.data}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {section === "attendence" ? (
                      <>
                        <div
                          className="tab-pane active"
                          id="attendance"
                          role="tabpanel"
                        >
                          <EmployeeWorkHistory />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {section === "meetings" ? (
                      <>
                        <div
                          className="tab-pane active"
                          id="leaves"
                          role="tabpanel"
                        >
                          <EmployeeMeetingHistory item={teammember} />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {section === "leaves" ? (
                      <>
                        <div
                          className="tab-pane active"
                          id="leaves"
                          role="tabpanel"
                        >
                          <EmployeeLeaveHistory />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {section === "team_leaderboard" ? (
                      <>
                        {" "}
                        <div
                          className="tab-pane active"
                          id="leaderboard"
                          role="tabpanel"
                        >
                          <EmployeeLeaderboard />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}



                    {section === "team_permissions" ? (
                      <>
                        {" "}
                        <div
                          className="tab-pane active"
                          id="permissions"
                          role="tabpanel"
                        >
                          <PermissionsManagement userid={member.user_id} />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/*end tab-pane*/}
                  </div>
                  {/*end tab-content*/}
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const reload = (prop) => ({
  value: prop.modalHandler
})
export default connect(reload)(TeamMemberProfile);
