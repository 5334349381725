import Avatar from '@mui/material/Avatar';
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import AdminDeleteTickets from '../../actions/student/AdminDeleteTickets';
import { Check, Delete, MoreVert } from '@material-ui/icons';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminUpdateDoubtRequest from '../../actions/support/AdminUpdateDoubtRequest';
import { AuthContext } from '../../context/AuthContext';
import { Pending } from '@mui/icons-material';
import DefaultToast from '../../components/toasts/DefaultToast';
import dateFormat from 'dateformat';
import { useTeamData } from '../../actions/team/TeamContext';
import { useActionsContext } from '../../context/ActionsContext';
import AdminUpdateSupportRequest from '../../actions/support/AdminUpdateSuppportRequest';
import axios from 'axios';
import SecurityHeaders from '../../context/SecurityHeaders';
import ENDPOINT from '../../context/EndPoint';
import defaultUser from '../../assets/images/users/user-dummy-img.jpg'
import StatusChip from '../../components/StatusChip';

const CustomerSupportTicketItem = ({ item, Reload, options }) => {
  const { user } = useContext(AuthContext)
  const [deletemodal, setdeletemodal] = useState(false)
  const [toastdata, setoastdata] = useState(null)
  const [toast, settoast] = useState(false)


  const { deleting, setdeleting } = useActionsContext()
  const DeleteSupportRequest = async ({ payload }) => {
    const data = new FormData();
    setdeleting(true)
    data.append('id', payload.id);

    const res = await axios.post(ENDPOINT + 'admin-delete-support-request.php', data, SecurityHeaders);

    if (res) {
      setdeleting(false)
      if (res.data.status === "success") {
        Reload()
        setdeletemodal(false)
      }
    }
  }


  useEffect(() => {

  }, [])
  const UpdateRequest = async ({ status }) => {


    const res = await AdminUpdateSupportRequest({
      payload: {
        session: user.SessionId,
        id: item.id,
        status: status
      }
    })
    if (res) {

      setoastdata(res.data)
      if (res.status === "success") {

        Reload()

      }
      else {


      }

    }
  }
  return (
    <>
      <DefaultToast toastdata={toastdata} show={toast} />
      <DeleteModal deleting={deleting} show={deletemodal} HandleClose={() => setdeletemodal(false)} HandleDelete={() => DeleteSupportRequest({ payload: { id: item.id } })} />


      <div className=" col-12 px-0 px-md-2">
        <div className="card border-1 border-md-0 rounded mb-2 bg-white">
          <div className="card-body p-0">
            <div className="d-lg-flex d-md-flex d-sm-block d-block align-items-center">

              <div className='col-lg-3 col-md-3 col-sm-12 col-12 d-flex  justify-content-between align-items-center'>

                {options.user === false ? null :
       
 <Link  to={"/students-support/" + item.id} className="col-lg-12 col-md-12 col-sm-8 col-8 d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                      <Avatar src={item.profile_pic ? item.profile_pic : null} />
                    </div>
                    <div className="flex-grow-1 ">
                      <h5 className='m-0 p-0 fs-sm-14'>{item.name}</h5>
                      <p className='p-0 m-0 sm-12 fs-sm-10'>{dateFormat(item.create_datetime, "dS mmm hh:MM TT")}</p>
                    </div>
                  </Link>

                 
                  
                  
                  }

                <div className='col-lg-1 col-md-1 col-sm-2 col-2 d-md-none d-sm-block d-block'>
                <StatusChip item={item}/>
                </div>


                <Dropdown className="options-dropdown col-lg-1 col-md-1 d-md-none d-sm-block d-block">
                  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
                    <MoreVert className="text-dark" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => UpdateRequest({ status: "initiated" })}  ><Pending /> Initiate</Dropdown.Item>

                    <Dropdown.Item onClick={() => UpdateRequest({ status: "closed" })} ><Check />Closed</Dropdown.Item>

                    <Dropdown.Item onClick={() => setdeletemodal(true)} ><Delete />Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>


              </div>

              {options.contact === false ? null :
                <div className=' col-lg-2 col-md-2 d-md-block d-sm-none d-none gap-3 mt-0 text-muted'>
                  <h6 className=''>{item.phone}</h6>
                  <p className='mb-0'>{item.email}</p>
                </div>}
                <hr className=' d-md-none my-1'></hr>
              <div className='col-lg-4 col-md-3 gap-2 align-items-center my-0 my-md-3 mt-2 mt-md-0 my-lg-0'>
                <p className='mb-0 fs-sm-12'>{item.message}</p>
              </div>

              <div className='col-lg-1 col-md-1 col-sm-2 d-md-block d-sm-none d-none'>
              <StatusChip item={item}/>
              </div>


              <Dropdown className="options-dropdown col-lg-1 col-md-1 d-md-block d-sm-none d-none">
                <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
                  <MoreVert className="text-dark" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => UpdateRequest({ status: "initiated" })}  ><Pending /> Initiate</Dropdown.Item>

                  <Dropdown.Item onClick={() => UpdateRequest({ status: "closed" })} ><Check />Closed</Dropdown.Item>

                  <Dropdown.Item onClick={() => setdeletemodal(true)} ><Delete />Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>


              {options.view === false ? null :
                <div className='col-lg-1 col-md-1 d-md-block d-sm-none d-none'>
                  <Link to={"/students-support/" + item.id} className="btn btn-outline-primary">View</Link>
                </div>}

            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default CustomerSupportTicketItem