import React, { useContext, useState } from "react";
import { Close, Delete, Edit, MoreVert } from "@material-ui/icons";
import { Dropdown, Modal } from "react-bootstrap";
import { Avatar, TextField } from "@mui/material";
import DefaultModal from "../../components/modals/DefaultModal";
import EvaluateTask from "./EvaluateTask";
import ENDPOINT from "../../context/EndPoint";
import DeleteModal from "../../components/modals/DeleteModal";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import ModalStyle from "../../context/ModalStyle";
import DefaultAlertModal from "../../components/modals/DeafaultAlertModal";
import DefaultToast from "../../components/toasts/DefaultToast";
import SecurityHeaders from "../../context/SecurityHeaders";
import dateFormat from "dateformat";
import EditCourseAssignment from "../courses/EditCourseAssignment";
import { useDispatch } from "react-redux";
const AssignmentItem = ({ item, Reload }) => {
  const { user } = useContext(AuthContext);
  const [evaluate, setevaluate] = useState(false);
  const [toast, settoast] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [alert, setalert] = useState(false);
  const [toastdata, settoastdata] = useState(null);
  const [corrections, setcorrections] = useState(item.corrections);
  const [earnedmarks, setearnedmarks] = useState(item.marks_given);
  const [status, setstatus] = useState(item.status);
  const CloseEvaluation = () => {
    setevaluate(false);
  };

  const dispatch = useDispatch();

  //delete task code

  const deleteSubmission = async () => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("assignment_id", item.assignment_id);

    const res = await axios.post(
      ENDPOINT + "admin-delete-assignment.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );


    if (res.data.status === "success") {
      settoastdata({ message: 'The assignment has been deleted successfully.' });
      settoast(true);
      dispatch({ type: 'FUNCTION_RELOAD', payload: 'assignment' })
      setdeletemodal(false);

    }
    else {
      settoastdata(res.data);
      settoast(true);

    }

  };

  const SaveChanges = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("id", item.id);
    data.append("session", user.SessionId);
    data.append("earned_marks", earnedmarks);
    data.append("status", status);
    data.append("corrections", corrections);
    const res = await axios.post(
      ENDPOINT + "admin-update-assignment-submission.php",
      data,
      SecurityHeaders
    );
    if (res) {
      settoastdata(res.data);
      settoast(true);

      if (res.data.status === "success") {
        Reload();
      } else {
      }
    }
  };
 
  const AssignmentLock = async ({ batch_id, url, assign_id, status }) => {
    try {
      const data = new FormData();
      data.append('session', user.SessionId);
      data.append('batch_id', batch_id);
      data.append('course_url', url);
      data.append('assignment_id', assign_id);
  
      const endpoint = status ? "lock-assignment.php" : "unlock-batch-assignment.php";
      const res = await axios.post(`${ENDPOINT}${endpoint}`, data, SecurityHeaders);
  
      if (res.data && res.data.status === 'success') {
        dispatch({ type: 'FUNCTION_RELOAD', payload: 'assignment' });
        settoastdata({ message: `The assignment has been ${status ? 'Locked' : 'Unlocked'}.` });
        settoast(true);
      } else {
        settoastdata({ message: 'An error occurred while updating the assignment status.' });
        settoast(true);
      }
    } catch (err) {
      console.error("Error updating assignment status:", err);
      settoastdata({ message: 'An unexpected error occurred. Please try again later.' });
      settoast(true);
    }
  };
  



  return (
    <>
      <DeleteModal
        show={deletemodal}
        HandleClose={() => setdeletemodal(false)}
        HandleDelete={deleteSubmission}
      />

      <DefaultToast toastdata={toastdata} show={toast} ToastHandleClose={() => settoast(false)} />

      <DefaultModal
        className="col-lg-5 col-sm-12 col-12"
        HandleClose={CloseEvaluation}
        show={evaluate}
        content={
          <>
            <div className="p-3 ">
              <div className="modal-header">
                <h4>Edit Assignment</h4>
                <Close onClick={() => setevaluate(false)} />
              </div>
              <hr></hr>
              <EditCourseAssignment CloseEvaluation={CloseEvaluation} item={item} />
            </div>
          </>
        }
      ></DefaultModal>

      <tr>
        <td>
          <div>
            <h5> {item.title}</h5>
            <p>{item.description}</p>
          </div>
        </td>

        <td>{dateFormat(item.given, "dS mmm yyyy hh:mm tt")}</td>
        <td>{dateFormat(item.deadline, "dS mmm yyyy hh:mm tt")}</td>
        <td>
          <button className="btn btn-outline-primary"
            onClick={() => {
              AssignmentLock({ batch_id: item?.batch_id, url: item?.course_url, assign_id: item?.assignment_id, status: item?.status === 'unlocked' ? true : false })
            }}>  {item.status}</button>
        </td>


        <td>
          <Dropdown className="options-dropdown">
            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
              <MoreVert className="text-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href="#"
                id={"edit" + item.id}
                onClick={() => setevaluate(true)}
              >
                {" "}
                <Edit />
                Edit Assigment
              </Dropdown.Item>

              <Dropdown.Item
                href="#"
                id={"delete" + item.id}
                onClick={() => setdeletemodal(true)}
              >
                <Delete /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};

export default AssignmentItem;
