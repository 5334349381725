import React from 'react'
import {
    AttachEmail,
    Delete,
    Email,
    Preview,
    ScheduleSend,
    WhatsApp,
  } from "@mui/icons-material";
import dateFormat from "dateformat";
import { Dropdown } from "react-bootstrap";
import { Save } from "react-bootstrap-icons";
import { MoreVert } from "@material-ui/icons";
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';


const RegistrationItem = ({ item, Reload }) => {

    const DeleteRegistration = async (e)=>{
        const data = new FormData();
        data.append('id',e)

        const res = await axios.post(ENDPOINT+"delete-college-registration.php",data,SecurityHeaders);
        if(res.data){
         if(res.data.status==="success"){
            Reload()
         }
        }
    }

  return (
   <>
    <div className="container-fluid d-flex align-items-center">
        <div className="col-lg-4 col-6 d-flex align-items-center">
        
          <div>
          
              {" "}
              <div>
                <h6>{item.name}</h6>
                <p className="d-md-none d-block">
                  {dateFormat(item.create_datetime, "dS mmm hh:MM tt")}
                </p>
              </div>
         
          </div>
        </div>
        <div className="col-lg-2">
          <h6>{item.phone}</h6>
        </div>
        <div className="col-lg-3 d-none d-md-block">
          <h6>{item.email}</h6>
        </div>

        <div className="col-lg-2">
          <h6>{item.status}</h6>
        </div>
        <div className="">
          <div className="d-flex align-items-center">
           
            <Dropdown>
              <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                <MoreVert />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={()=>DeleteRegistration(item.id)}>
                  <Delete /> Delete
                </Dropdown.Item>
                <Dropdown.Item>
                  <AttachEmail /> Send Email
                </Dropdown.Item>
                <Dropdown.Item>
                  <ScheduleSend /> Send Meeting Details
                </Dropdown.Item>
                <Dropdown.Item>
                  <WhatsApp /> Send WhatsApp Group Link
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
     
      </>
  )
}

export default RegistrationItem