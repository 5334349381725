import { Avatar } from "@mui/material";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import dateFormat from "dateformat";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import AdminDeletePost from "../../actions/post/AdminDeletePost";
import { AuthContext } from "../../context/AuthContext";
import ModalStyle from "../../context/ModalStyle";
import { MoreVert } from "@mui/icons-material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AdminAddPostPoints from "../../actions/post/AdminAddPostPoints";
const PostItem = ({ post }) => {
  const [showcomments, setshowcomments] = useState(false);
  const { user } = useContext(AuthContext);
  const NewDeletePost = async () => {
    const res = await AdminDeletePost({
      payload: {
        post_id: post.post_id,
        user: user.SessionId,
      },
    });
  };

  const [modal, setmodal] = useState(false);
  const [pointsmodal, setpointsmodal] = useState(false);
  const NewAddPoints = async () => {
    const res = await AdminAddPostPoints({
      payload: {
        user: user.SessionId,
        post_id: post.post_id,
        points: points,
      },
    });
    if (res) {
       // console.log("points are " + JSON.stringify(res));
    }
  };
  const [points, setpoints] = useState(0);
  return (
    <PostItemStyled>
      <Modal  open={modal} onClose={() => setmodal(false)}>
        <Box  sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Warning !
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p>Are you sure to delete the post ?</p>
            <div className="d-flex justify-content-between">
              <button className="btn btn-sm btn-danger rounded-pill">
                Cancel
              </button>

              <button className="btn btn-sm btn-primary rounded-pill">
                Delete Post
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>

      <Modal  open={pointsmodal} onClose={() => setpointsmodal(false)}>
        <Box className="p-3"  sx={ModalStyle} clasName="p-2 border-0">
          <Typography variant="h6" component="h2">
            <div className="modal-header">
              <h4>Add Points </h4>
              <CloseIcon
                className="click bg-danger text-light rounded-2"
                onClick={() => setpointsmodal(false)}
              />
            </div>
          </Typography>

          <Typography>
            <h6>Points : {points}</h6>
            <div className="d-flex mt-4 justify-content-between">
              <button
                onClick={() => setpoints(10)}
                className="btn btn-outline-primary m-1"
              >
                10
              </button>

              <button
                onClick={() => setpoints(20)}
                className="btn btn-outline-primary m-1"
              >
                20
              </button>

              <button
                onClick={() => setpoints(30)}
                className="btn btn-outline-primary m-1"
              >
                30
              </button>
              <button
                onClick={() => setpoints(40)}
                className="btn btn-outline-primary m-1"
              >
                40
              </button>
              <button
                onClick={() => setpoints(50)}
                className="btn btn-outline-primary m-1"
              >
                50
              </button>
            </div>
            <div className="d-flex mt-4 justify-content-between flex-wrap-reverse">
              <button className="btn btn-sm col-12 btn-danger rounded-pill m-1">
                Cancel
              </button>

              <button
                className="btn btn-sm col-12 btn-primary rounded-pill m-1"
                onClick={() => NewAddPoints()}
              >
                Add Points
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>

      <div className="post-item mt-3 mb-3 shadow br-4 ">
        <div className=" flex justify-content-between p-2">
          <div className="flex">
            <img src={post.profile_pic} className="post-dp" alt="" />
            <div className="post-info">
              <h5>{post.first_name + " " + post.last_name}</h5>
              <p className="mb-0 extra-small">
                {dateFormat(post.date_time, "dS mmm yyyy, hh:mm tt")}
              </p>
            </div>
          </div>

          <div>
            <Dropdown className="options-dropdown">
              <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                <MoreVert className="text-dark" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setpointsmodal(true);
                  }}
                >
                  <ControlPointIcon className="click" />
                  Add Points
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    NewDeletePost();
                  }}
                >
                  <DeleteIcon className="click" />
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="post-description">
          {post.description ? (
            <>
              <p>{post.description}</p>
            </>
          ) : null}
        </div>
        <div className="post-img-holder">
          <img class="post-image" src={post.image} alt="" />

          <div className="d-flex justify-content-between p-2">
            <p className="mb-0">Points : {post.points}</p>
            <p
              className="click  mb-0"
              onClick={() => setshowcomments(!showcomments)}
            >
              Show Comments
            </p>
          </div>

          {showcomments ? (
            <>
              <hr />
              {post.comments ? (
                <>
                  {post.comments.map((comment) => (
                    <>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <Avatar size="small" src={comment.profile_pic} />
                          <div>
                            <h6>
                              {comment.first_name + " " + comment.last_name}
                            </h6>
                            <p>{comment.comment}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <p clasName="text-secondary p-2">No Comments</p>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </PostItemStyled>
  );
};

export default PostItem;

export const PostItemStyled = styled.div`
  .element-box {
    width: 100%;
  }
  .user-info {
    display: flex;
  }
  .post-dp {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    margin-right: 10px;
  }
  .post-image {
    height: 200px;
    width: 100%;
  }
  .post-img-holder {
    width: 100%;
  }
`;
