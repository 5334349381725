import React from 'react'
import ModalStyle from '../../context/ModalStyle'
import { Box, CircularProgress, Modal } from '@mui/material'

const DeleteModal = ({show,HandleClose,HandleDelete,deleting}) => {
  return (
<>


                  <Modal
  open={show}
  onClose={HandleClose}
  
>
  <Box sx={ModalStyle} className="col-lg-3 col-md-4 col-sm-4 col-11">
  <div className="modal-content ">
                        <div className="modal-body p-4 rounded text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#405189,secondary:#f06548"
                            style={{ width: 90, height: 90 }}
                          />
                          <div className="mt-4 text-center">
                            <h4>You are about to delete ?</h4>
                            <p className="text-muted fs-15 mb-4">
                          
                            </p>
                            <div className="hstack gap-2 justify-content-center remove">
                              <button
                                className="btn btn-link link-success fw-medium text-decoration-none"
                             onClick={HandleClose}
                              >
                                <i className="ri-close-line me-1 align-middle" />{" "}
                                Close
                              </button>
                              <button
                              onClick={HandleDelete}
                              disabled={deleting}
                                className="btn btn-danger"
                                id="delete-record"
                              >
                     
                               
                                {deleting?"Deleting...":"Yes, Delete It"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
  </Box>
</Modal>
</>
  )
}

export default DeleteModal