import React from 'react'
import dateFormat from 'dateformat'
const BatchReportItem = ({item,index}) => {
  return (
   <>
   
       
   <div className="accordion-item border-0">
                                          <div
                                            className="accordion-header"
                                            id={"heading"+index}
                                          >
                                            <a
                                              className="accordion-button p-2 shadow-none"
                                              data-bs-toggle="collapse"
                                              href={"#collapse"+index}
                                              aria-expanded="false"
                                            >
                                              <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                  <img
                                                    src={item.profile_pic}
                                                    alt=""
                                                    className="avatar-xs rounded-circle material-shadow"
                                                  />
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                  <h6 className="fs-14 mb-1">
                                                    {item.title}
                                              
                                                  </h6>
                                                  <small className="text-muted">
                                                    {dateFormat(item.create_datetime,"dS mmm yyyy hh:MM tt")+" | Reported by "+item.first_name+" "+item.last_name}
                                                  </small>
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                          <div
                                            id={"collapse"+index}
                                            className="accordion-collapse collapse "
                                            aria-labelledby={"heading"+index}
                                            data-bs-parent="#accordionExample"
                                          >
                                            <div className="accordion-body ms-2 ps-5">
                                           {item.report}
                                            </div>
                                          </div>
                                        </div>
   </>
  )
}

export default BatchReportItem