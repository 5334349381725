import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CircularProgress, Snackbar } from "@material-ui/core";
import ENDPOINT from "../../context/EndPoint";
import { useParams } from "react-router-dom";
import axios from "axios";
import SecurityHeaders from "../../context/SecurityHeaders";
import { useDispatch } from "react-redux";

const EditCourseAssignment = ({ item ,CloseEvaluation}) => {
  const params = useParams();
  const courseurl = params.course_url;
  const batchid = params.batch_id;
  const { user } = useContext(AuthContext);

  const dispatch = useDispatch();

  const [title, settitle] = useState(item.title);
  const [description, setdescription] = useState(item.description);
  const [assignedto, setassignedto] = useState(null);
  const [reference, setreference] = useState(null);
  const [deadline, setdeadline] = useState(null);
  const [marks, setmarks] = useState(item.marks);
  const [earnedmarks, setearnedmarks] = useState(null);

  const [corrections, setcorrections] = useState(null);



  const changeTitle = (e) => {
    settitle(e.target.value);
  };
  const changeDescription = (e) => {
    setdescription(e.target.value);
  };
  const changeAssignto = (e) => {
    setassignedto(e.target.value);
  };
  const changeMaxMarks = (e) => {
    setmarks(e.target.value);
  };
  const changeMarksGiven = (e) => {
    setearnedmarks(e.target.value);
  };
  const changeCorrections = (e) => {
    setcorrections(e.target.value);
  };
  const changeReference = (e) => {
    setreference(e.target.value);
  };
  const changeDeadline = (e) => {
    setdeadline(e.target.value);
  };
  const changeStatus = (e) => {
    setstatus(e.target.value);
  };
  const [status, setstatus] = useState("pending");
  const [addstatus, setaddstatus] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);

  const onSubmit = async (e) => {
   try{
    e.preventDefault();
    setaddstatus(true);

    const data = new FormData();
    data.append('assignment_id', item.assignment_id);
    data.append('title', title);
    data.append('description', description);
    data.append('marks', marks);
   
    data.append('reference', reference);
    data.append('session', user.SessionId);
    data.append('course', courseurl);
    data.append('batch_id', batchid);

    const result = await axios.post(
      ENDPOINT + "edit-assignment.php",
      data,
      SecurityHeaders
    );

    if (result) {
       // console.log("addedd assignment" + result.data);

      if (result.data.status === "success") {
        dispatch({type:'FUNCTION_RELOAD',payload:'assignment'})    
        setaddstatus(false);
        setsnackbarMessage("Changes Saved Successfully");
        setsnackbar(true);
        CloseEvaluation()

      } else {
        setaddstatus(false);

        setsnackbarMessage("Something went wrong");
        setsnackbar(true);
      }
    }

   }
   catch(error){
     // console.log("edit assignment",error)
   }
  };

  const options = ["Option 1", "Option 2"];

  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  return (
    <div>
      {/*account form*/}

      <>
        <div className="flex-wrap container">
          <div className="">
            <form>
              <div class="form-row">
                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Assignment Title</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputEmail4"
                    value={title}
                    onChange={changeTitle}
                    maxLength={200}
                  />
                </div>

                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Description</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputEmail4"
                    value={description}
                    onChange={changeDescription}
                    maxLength={500}
                  />
                </div>

                <div class="form-group col-lg-6">
                  <label for="inputEmail4">Reference</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputEmail4"
                    value={reference}
                    onChange={changeReference}
                    maxLength={50}
                  />
                </div>
              

                <div className="col-lg-12">
                  <p>Notify Students</p>
                  <input type="checkbox" checked></input>
                </div>

                <Snackbar
                  open={snackbar}
                  autoHideDuration={6000}
                  onClose={() => setsnackbar(false)}
                  message={snackbarMessage}
                />

                <div className="form-row">
                  <div class="form-group col-lg-6">
                    <label for="inputPassword4">Max Marks</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputPassword4"
                      value={marks}
                      onChange={changeMaxMarks}
                      maxLength={30}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group"></div>
              <button
                type="submit"
                class="btn btn-primary w-100"
                onClick={onSubmit}
              >
                {addstatus ? (
                  <CircularProgress className="text-white" size={20} />
                ) : (
                  ""
                )}
                Save Changes
              </button>
            </form>
          </div>
        </div>
      </>

      {/*account form*/}
    </div>
  );
};

export default EditCourseAssignment;
