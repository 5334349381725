import React, { useContext, useEffect, useState } from "react";
import CustomModal from "./CustomModal";
import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import { AuthContext } from "../../context/AuthContext";
import SecurityHeaders from "../../context/SecurityHeaders";
import { CircularProgress, Snackbar } from "@mui/material";
import { connect } from "react-redux";

const EvaluateTeamTaskModal = ({ visibility, handleClose, item,value ,dispatch}) => {
  const { user } = useContext(AuthContext);

  const [title, settitle] = useState(null);

  const [earnedmarks, setearnedmarks] = useState(null);
  const arr = [];
  const [corrections, setcorrections] = useState(arr);



  const [status, setstatus] = useState(false);

  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    setstatus(true);

    const data = new FormData();

    data.append("marks_received", earnedmarks);
    data.append("id", item.id);
    data.append("corrections", corrections);
    data.append("action", "update");
    data.append("session", user.SessionId);
    const res = await axios.post(ENDPOINT + "evaluate-team-task.php", data, {
      headers: { "content-type": "form-data/multipart" },
    });

    if (res) {
      if (res.data.status === "success") {
        handleClose();
        setstatus(false);
      dispatch({type:'FUNCTION_RELOAD',payload:'get-intern-tasks'});

        setsnackbarMessage("Changes Saved Successfully");
        setsnackbar(true);
      } else {
        setstatus(false);

        setsnackbarMessage("Something went wrong");
        setsnackbar(true);
      }
    }
  };

  const [time, settime] = useState(null);

  const [date, setdate] = useState(null);
  return (
    <>
      <CustomModal visibility={visibility} handleClose={handleClose}>
        <div>
          <div className="flex-wrap ">
            <div className="w-100">
              <form onSubmit={onSubmit}>
                <div class="form-row w-100">
                  <Snackbar
                    className="bg-success text-light"
                    open={snackbar}
                    autoHideDuration={6000}
                    onClose={() => setsnackbar(false)}
                    message={snackbarMessage}
                  />

                  <div className="form-row w-100">
                    <div class="form-group col-12">
                      <label for="inputPassword4">Marks Given</label>
                      <input
                        type="number"
                        class="form-control "
                        id="inputPassword4"
                        value={earnedmarks}
                        onChange={(e) => setearnedmarks(e.target.value)}
                        maxLength={3}
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group mt-2  w-100">
                  <label for="inputPassword4">Corrections</label>
                  <textarea
                    type="text"
                    class="form-control"
                    id="inputPassword4"
                    value={corrections}
                    onChange={(e) => setcorrections(e.target.value)}
                    maxLength={500}
                  />
                </div>

                <div class="form-group"></div>
                <button
                  type="submit"
                  class="btn mt-3 btn-primary w-100"
                  onClick={onSubmit}
                >
                  {status ? (
                    <CircularProgress className="text-white" size={20} />
                  ) : (
                    ""
                  )}
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

const reload = (prop)=>({
  value : prop.modalHandler
})

export default connect(reload) (EvaluateTeamTaskModal);
