
const initialState = {
    invoice : Object()
}

const paymentInvoice = (state = initialState,action)=>{

    switch(action.type){
        case 'INSERT_INVOICE':
            return {...state,invoice:action.payload}
            default:
                 return state
    }

}

export default paymentInvoice