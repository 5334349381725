import React, { useContext, useState } from 'react'
import { Close, Delete, Edit, MoreVert } from '@material-ui/icons'
import { Dropdown, Modal } from 'react-bootstrap'
import { Avatar, TextField } from '@mui/material'
import DefaultModal from '../../components/modals/DefaultModal'
import EvaluateTask from './EvaluateTask'
import ENDPOINT from '../../context/EndPoint'
import DeleteModal from '../../components/modals/DeleteModal'
import axios from 'axios'
import dateFormat from 'dateformat'
import { AuthContext } from '../../context/AuthContext'
import ModalStyle from '../../context/ModalStyle'
import DefaultAlertModal from '../../components/modals/DeafaultAlertModal'
import DefaultToast from '../../components/toasts/DefaultToast'
import SecurityHeaders from '../../context/SecurityHeaders'
import { connect } from 'react-redux'
const AssignmentSubmissionItem = ({ item, nValue, dispatch,student }) => {
  const { user } = useContext(AuthContext)
  const [evaluate, setevaluate] = useState(false)
  const [toast, settoast] = useState(false)
  const [deletemodal, setdeletemodal] = useState(false)
  const [alert, setalert] = useState(false)
  const [toastdata, settoastdata] = useState(null)
  const [corrections, setcorrections] = useState(item.corrections)
  const [earnedmarks, setearnedmarks] = useState(item.marks_given)
  const [status, setstatus] = useState(item.status)
  const CloseEvaluation = () => {
    setevaluate(false)
  }

  //delete task code

  const deleteSubmission = async () => {

    const formData = new FormData();
    formData.append('session', user.SessionId);
    formData.append('id', item.id);

    const res = await axios.post(ENDPOINT + "admin-delete-assignment-submission.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {

      settoastdata(res.data)
      settoast(true)
      setdeletemodal(false)
    }

    if (res.data.status === "success") {
      dispatch({type:'FUNCTION_RELOAD',payload:'assignment'})
    }
    else {

    }
  }




  const SaveChanges = async (e) => {

    try {
      e.preventDefault()
      const data = new FormData()
      data.append("id", item.id)
      data.append("session", user.SessionId)
      data.append("earned_marks", earnedmarks)
      data.append("status", status)
      data.append("corrections", corrections)
      data.append('batch_id', item.batch_id);
      data.append('course_url', item.course_url);
      if (nValue) {
        data.append('notification', nValue);

      }

      const res = await axios.post(ENDPOINT + "admin-update-assignment-submission.php", data, SecurityHeaders)
      if (res) {

        settoastdata(res.data)
        settoast(true)
        setevaluate(false)


        if (res.data.status === "success") {
          //  // console.log("reload",Reload())
          dispatch({type:'FUNCTION_RELOAD',payload:'project-submissions'})

        } else {



        }

      }
    }
    catch (error) {
       // console.log("update error", error)
    }
  }

  return (
    <>
      <DeleteModal show={deletemodal} HandleClose={() => setdeletemodal(false)} HandleDelete={deleteSubmission} />




      <DefaultToast toastdata={toastdata} show={toast} ToastHandleClose={() => settoast(false)} />












      <DefaultModal
        className="col-lg-5 col-sm-12 col-12"
        HandleClose={() => setevaluate(false)}

        show={evaluate}
        content={<>
          <div className='p-3 '>
            <div className="modal-header">
              <h4>Evaluate Assignment</h4>
              <Close onClick={() => setevaluate(false)} />
            </div>
            <hr></hr>
            <form onSubmit={SaveChanges}>


              <div className="d-flex">
                <TextField value={earnedmarks} onChange={(e) => setearnedmarks(e.target.value)} placeholder='Enter Marks' className='col form-control mb-3' />

                <select className='form-control col' value={status} onChange={(e) => setstatus(e.target.value)}>
                  <option value="submitted">Mark Submitted</option>

                  <option value="rejected">Reject</option>


                  <option value="completed">Mark Complete</option>
                </select>
              </div>
              <textarea value={corrections} onChange={(e) => setcorrections(e.target.value)} placeholder='Enter corrections' className='form-control '></textarea>


              <button type='submit' className='mt-3 w-100 btn btn-primary'>Save Changes</button>
            </form>













          </div>


        </>}
      ></DefaultModal>

      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className="avatar-sm">
              <Avatar src={item.profile_pic?item.profile:student.profile_pic} />
            </div>
            <div className="ms-1 ">
              <h6 className="fs-15 mb-0">
          
                  {item.first_name && item.last_name?item.first_name + " " + item.last_name:student.first_name+" "+ student.last_name}
            
              
              </h6>
              <a target='_blank' href={item.solution_link}>
                  Download
                </a>
            </div>
          </div>
        </td>
        <td>{item.course_url}</td>
        <td>{item.title}</td>
   
        <td>{item.deadline}</td>
        <td>{dateFormat(item.submission_time, "dS mmm hh:mm tt")}</td>
        <td>{item.status}</td>

        <td>
          <Dropdown className="options-dropdown">
            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
              <MoreVert className="text-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>

              <Dropdown.Item href="#" id={"edit" + item.id} onClick={() => setevaluate(true)}> <Edit /> Evaluate</Dropdown.Item>

              <Dropdown.Item href="#" id={"delete" + item.id} onClick={() => setdeletemodal(true)}><Delete /> Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  )
}

const Notification = (prop) => ({
  nValue: prop.sendNotification.notification,
  student: prop.studentHandler.profile

})

export default connect(Notification)(AssignmentSubmissionItem);