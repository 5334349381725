import React, { useContext, useEffect, useState } from 'react';
import DefaultModal from '../../components/modals/DefaultModal';
import { Close } from '@material-ui/icons';
import AddTeamGroup from './AddTeamGroup';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import { CircularProgress, Snackbar } from "@mui/material";
import { useSelector } from 'react-redux';
import {
  Card, CardContent, Typography, IconButton, Menu, MenuItem, Button, Grid
} from '@mui/material';
import { MoreVert, Edit, Delete } from '@mui/icons-material';
import styled from 'styled-components';
import DeleteModal from '../../components/modals/DeleteModal';
import { useActionsContext } from '../../context/ActionsContext';

const TeamGroups = () => {
  const { user } = useContext(AuthContext);
  const stored = useSelector((state) => state.modalHandler);

  const [anchorEl, setAnchorEl] = useState(null);
  const [addGroup, setAddGroup] = useState({
    show: false, action: '', name: '', item: null
  });
  const [groups, setGroups] = useState([]);
  const [snackbar, setSnackbar] = useState({
    show: false, message: ''
  });
  const [deleteModal, setDeleteModal] = useState({
    id: '', show: false
  });

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getGroups = async () => {
    try {
      const data = new FormData();
      data.append('session', user.SessionId);

      const res = await axios.post(ENDPOINT + 'get-teams-groups.php', data, SecurityHeaders);
      if (res && res.data.status === 'success') {
        setGroups(res.data.data);
        setResponse(res.data.data);
        setInitialData(res.data.data)
      }
    } catch (err) {
       // console.log(err.message);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (stored.functionName === 'get-groups') {
      getGroups();
    }
  }, [stored]);

  const deleteGroup = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append('session', user.SessionId);
      data.append('id', deleteModal.id);

      const res = await axios.post(ENDPOINT + "delete-teams-group.php", data, SecurityHeaders);
      if (res && res.data.status === "success") {
        getGroups();
      }
      setSnackbar({ ...snackbar, show: true, message: res.data.message });
      setDeleteModal({ ...deleteModal, show: false });
    } catch (err) {
       // console.log(err.message);
    }
  };

  const [initialData, setInitialData] = useState('')


  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');
  const {searchquery} = useActionsContext()
  useEffect(()=>{
    handleSearch({term:searchquery})
  },[searchquery])
  const handleSearch = ({term}) => {
    if(term && initialData)
    {
      const filteredData = initialData.filter((item) => {
        return (
          (item.user_email && item.user_email.toLowerCase().includes(term)) ||
          (item.first_name && item.first_name.toLowerCase().includes(term)) ||
          (item.last_name && item.last_name.toLowerCase().includes(term)) ||
          (item.course_title && item.course_title.toLowerCase().includes(term)) ||
          (item.purchase_status && item.purchase_status.toLowerCase().includes(term))
        );
      });
    
      setResponse(filteredData);
      setSearchTerm(term);
    }
  

  };

  return (
    <>
      <Snackbar
        className="bg-success text-light"
        open={snackbar.show}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
        message={snackbar.message}
      />

      <DeleteModal
        HandleClose={() => setDeleteModal({ ...deleteModal, show: false })}
        HandleDelete={deleteGroup}
        show={deleteModal.show}
      />

      <DefaultModal
        show={addGroup.show}
        HandleClose={() => setAddGroup({ ...addGroup, show: false })}
        content={
          <div className="p-3">
            <div className="modal-header">
              <h4>{addGroup.name}</h4>
              <Close onClick={() => setAddGroup({ ...addGroup, show: false })} />
            </div>
            <hr />
            <AddTeamGroup action={addGroup.action} item={addGroup.item} closeModal={() => setAddGroup({ ...addGroup, show: false })} />
          </div>
        }
      />

      <div className="container">
        <div className="d-flex justify-content-between ">
          <h3>Teams Groups</h3>

          <div className="col-xxl-3 col-sm-4 mb-3 d-none d-sm-block d-md-block d-lg-block d-xl-block">
          <div className="search-box">
            <input
              onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
              value={searchTerm}
              type="text"
              className="form-control search"
              placeholder="Search for course name phone email  status or something..."
            />
            <i className="ri-search-line search-icon" />
          </div>
        </div>
          <button className="btn btn-primary" onClick={() => setAddGroup({ show: true, name: "Add Group", action: 'insert', item: null })}>Add Group</button>
        </div>

        <div className="d-flex flex-wrap">
          {groups.length > 0 ? (
            groups.map((item, index) => (
              <div key={index} className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 p-2'>
                <StyledCard className='col-12'>
                  <Card className="batch-card">
                    <CardContent className="card-content">
                      <div className="header mb-2">
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Detail label="Batch Id:" value="20240525" />
                            <Detail label="Start Time:" value="12:26am" />
                          </Grid>
                          <Grid item xs={6}>
                            <Detail label="Date:" value="18-07-2024" />
                            <Detail label="End Time:" value="12:30am" />
                          </Grid>
                        </Grid>
                        
                        <IconButton
                          aria-controls={open ? 'options-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id="options-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'options-button',
                          }}
                        >
                          <MenuItem onClick={() => { handleClose(); setAddGroup({ show: true, name: "Update Group", action: 'update', item }) }}>
                            <Edit /> Edit
                          </MenuItem>
                          <MenuItem onClick={() => { handleClose(); setDeleteModal({ show: true, id: item.group_id }) }}>
                            <Delete /> Delete
                          </MenuItem>
                        </Menu>
                      </div>
                      <Detail label="Course URL:" value="Ethical-hacking-specilization" />
                      <Detail label="Batch Name:" value="8pm CEH Batch" />

                      <Button href={item.invite_link} target='_blank' variant="contained" color="primary" className="action-button">
                        View GROUP
                      </Button>
                    </CardContent>
                  </Card>
                </StyledCard>
              </div>
            ))
          ) : (
            <h3 className="mt-5 ms-5">Could not find any team groups</h3>
          )}
        </div>
      </div>
    </>
  );
};

const Detail = ({ label, value }) => (
  <Typography variant="body2" className="detail">
    <strong>{label}</strong> {value}
  </Typography>
);

const StyledCard = styled.div`
  .batch-card {
    max-width: 400px;
    margin: 10px auto;
  }

  .card-content {
    padding: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .detail {
    margin-bottom: 5px;
  }

  .action-button {
    margin-top: 10px;
    width: 100%;
  }
`;

export default TeamGroups;
