import React from 'react'

const EditWorkshopRegistration = () => {
  return (
  <>
  

   
   
<div className="modal-content p-3 rounded">

<form className="tablelist-form" autoComplete="off">
  <div className="modal-body">
   
    <div className="mb-3">
      <label
        htmlFor="customername-field"
        className="form-label"
      >
         Name
      </label>
      <input
        type="text"
        id="customername-field"
        className="form-control"
        placeholder="Enter name"
        required=""
      />
    </div>

    <div className="mb-3">
      <label
        htmlFor="customername-field"
        className="form-label"
      >
         Email
      </label>
      <input
        type="text"
        id="customername-field"
        className="form-control"
        placeholder="Enter name"
        required=""
      />
    </div>

    <div className="mb-3">
      <label
        htmlFor="customername-field"
        className="form-label"
      >
         Phone
      </label>
      <input
        type="text"
        id="customername-field"
        className="form-control"
        placeholder="Enter name"
        required=""
      />
    </div>
    <div className="mb-3">
      <label
        htmlFor="productname-field"
        className="form-label"
      >
        Status
      </label>
      <select
        className="form-control"
        data-trigger=""
        name="productname-field"
        id="productname-field"
        required=""
      >
        <option value="">Product</option>
        <option value="Puma Tshirt">Puma Tshirt</option>
        <option value="Adidas Sneakers">
          Adidas Sneakers
        </option>
        <option value="350 ml Glass Grocery Container">
          350 ml Glass Grocery Container
        </option>
      
      
      </select>
    </div>
    <div className="mb-3">
      <label
        htmlFor="customername-field"
        className="form-label"
      >
         Certificate Link
      </label>
      <input
        type="text"
        id="customername-field"
        className="form-control"
        placeholder="Enter name"
        required=""
      />
    </div>
  </div>
  <div className="modal-footer">
    <div className="hstack gap-2 justify-content-end">
      <button
        type="button"
        className="btn btn-light"
    
      >
        Close
      </button>
      <button
        type="submit"
        className="btn btn-success"
       
      >
        Add Order
      </button>
      {/* <button type="button" class="btn btn-success" id="edit-btn">Update</button> */}
    </div>
  </div>
</form>
</div>
  
  </>
  )
}

export default EditWorkshopRegistration