import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CircularProgress, Snackbar } from "@material-ui/core";
import ENDPOINT from "../../context/EndPoint";
import { useParams } from "react-router-dom";
import axios from "axios";
import SecurityHeaders from "../../context/SecurityHeaders";
import { MoreVert } from "@material-ui/icons";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Dropdown } from "react-bootstrap";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

const CouponCodes = ({ id }) => {
  const params = useParams();

  const { user } = useContext(AuthContext);

  const [status, setstatus] = useState("pending");
  const [addstatus, setaddstatus] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);

  const [code, setcode] = useState(null);
  const [percentage, setpercentage] = useState(null);
  const [coupons, setcoupons] = useState(null);

  const GetCoupons = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
    const res = await axios.post(
      ENDPOINT + "admin-get-coupons.php",
      data,
      SecurityHeaders
    );
    if (res) {
      if (res.data) {
        if (res.data.status === "success") {
          setcoupons(res.data.data);
        }
      }
    }
  };
  const [expiry, setexpiry] = useState(null);

  const AddCoupon = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("code", code);
    data.append("percentage", percentage);
    data.append("expiry", expiry);
    const res = await axios.post(
      ENDPOINT + "add-coupon-code.php",
      data,
      SecurityHeaders
    );
    if (res) {
      if (res.data) {
        if (res.data.status === "success") {
          GetCoupons();
        }
      }
    }
  };

  const DeleteCoupon = async ({ id }) => {
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("id", id);
    const res = await axios.post(
      ENDPOINT + "delete-coupon.php",
      data,
      SecurityHeaders
    );

    if (res) {
      if (res.data) {
        if (res.data.status === "success") {
          GetCoupons();
        }
      }
    }
  };

  useState(() => {
    GetCoupons();
  }, []);
  return (
    <div>
      {/*account form*/}

      <>
        <div className="row">
          <div className="col-xl-8 order-2 order-lg-1">
            <div className="card card-height-100">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Coupon Codes</h4>
                <div className="flex-shrink-0">
                  <button className="btn btn-soft-primary btn-sm">24H</button>
                </div>
                <div className="flex-shrink-0 ms-2">
                  <div className="dropdown card-header-dropdown">
                    <a
                      className="btn btn-soft-primary btn-sm"
                      role="button"
                      href="#"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Get Report
                      <i className="mdi mdi-chevron-down align-middle ms-1" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      <a className="dropdown-item" href="#">
                        <DownloadForOfflineIcon className="me-1" />
                        Download Report
                      </a>
                      <a className="dropdown-item" href="#">
                        <FileUploadRoundedIcon className="me-1" />
                        Export
                      </a>
                      <a className="dropdown-item" href="#">
                        <DownloadRoundedIcon className="me-1" />
                        Import
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* end card header */}
              <div className="card-body">
                <div className="table-responsive table-card">
                  <table className="table table-hover table-borderless table-centered align-middle table-nowrap mb-0">
                    <thead className="text-muted bg-light-subtle">
                      <tr>
                        <th>Coupon code</th>
                        <th>%OFF</th>
                        <th>Expires</th>
                        <th>Created On</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupons ? (
                        <>
                          {coupons.map((coupon) => (
                            <>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <h6 className="fs-14 mb-0">
                                        {coupon.coupon_code}
                                      </h6>
                                    </div>
                                  </div>
                                </td>
                                <td>{coupon.coupon_price}%</td>
                                <td>
                                  <h6 className=" fs-13 mb-0">
                                    <i className=" align-middle me-1" />
                                    {coupon.validity}
                                  </h6>
                                </td>

                                <td>1.25634801</td>
                                <td>
                                  <Dropdown className="options-dropdown">
                                    <Dropdown.Toggle className="bg-white options d-flex align-items-center justify-content-between p-0 w-fit h-fit w-100 border-0">
                                      <MoreVert className="text-dark" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() =>
                                          DeleteCoupon({ id: coupon.coupon_id })
                                        }
                                      >
                                        <DeleteForeverIcon className="me-1" />
                                        Delete Coupon
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}

                      {/* end */}
                    </tbody>
                    {/* end tbody */}
                  </table>
                  {/* end table */}
                </div>
                {/* end tbody */}
              </div>
              {/* end cardbody */}
            </div>
            {/* end card */}
          </div>
          {/* end col */}
          <div className="col-xl-4 order-1 order-lg-2">
            <div className="card card-height-100 p-3">
              <h4>Add Coupon Code</h4>
              <hr></hr>
              <div className="w-100">
                <input
                  required
                  onChange={(e) => setcode(e.target.value)}
                  placeholder="COUPON CODE"
                  className="form-control mb-3"
                ></input>

                <input
                  required
                  onChange={(e) => setpercentage(e.target.value)}
                  placeholder="%OFF"
                  className="form-control mb-3"
                ></input>

                <div className="mt-2">
                  <label>Expiry Date</label>
                  <input
                    required
                    type="date"
                    onChange={(e) => setexpiry(e.target.value)}
                    placeholder="Expiry date"
                    className="form-control mb-3"
                  ></input>
                </div>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => AddCoupon()}
                >
                  Add Coupon Code
                </button>
              </div>
            </div>
            {/* end card */}
          </div>
          {/* end col */}
        </div>
        <div className="flex-wrap container">
          <div className="col-lg-6"></div>
        </div>
      </>

      {/*account form*/}
    </div>
  );
};

export default CouponCodes;
