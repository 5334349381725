import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'

import { Dropdown, Modal } from 'react-bootstrap'
import { Delete, Edit, MoreVert, Share } from '@material-ui/icons'
import {Container,Row,Col,Table} from 'react-bootstrap';



import ENDPOINT from '../../context/EndPoint'
import Checkbox from '@mui/material/Checkbox';
import { Download, UploadFile } from '@mui/icons-material'

import { Link } from 'react-router-dom'

import SecurityHeaders from '../../context/SecurityHeaders'
import { Chip } from '@mui/material'
const ClassRecordings = () => {
      
const [classes,setclasses] = useState(null)
const {user} = useContext(AuthContext)
const getChapters = async (status)=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  
  
  
  
  const res = await axios.post(ENDPOINT+"get-class-recordings.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
   if(res.data.status==="success"){
    setclasses(res.data.data)
   }
   else{
  
   }
  }
  }
  useEffect(()=>{
getChapters()
},[])

  return (
  <>
  
  
  <Table bordered>
    <thead>
    <tr>
      <th><Checkbox/></th>
      <th>Video</th>
    
      <th>Privacy</th>
      <th>Published</th>
      <th>Date</th>
      <th>Views</th>
      <th>Comments</th>
      <th>Like(vs.dislikes)</th>
    </tr>
  </thead>
  <tbody>
   

 
{classes?
<>
{classes.map((item)=>(
<>



<tr>

      <td className="col-lg-2">
         <div className="d-flex ">
           
             <video controls className="w-100" src={item.recording}></video>
            
             
         </div>
      </td>
      <td>
      <Col className="v-data w-100">
             <div className="title w-100">
                 
                 <h6 className="mt-2">{item.class_title}</h6>
                 
             <Chip label={item.course_url} size='small'></Chip>  
                 <p className="truncate truncate-4 extra-small">{item.class_description}</p>
            
              </div>
             <div className="hide space-evenly w-100 mt-2">
                <Link to={"/internal/video-management/edit/"+item.id}> </Link>
             
             </div>
             </Col>
      </td>
     
      <td>None</td>
      <td><p className="m-0 extra-small">{item.upload_date}</p> </td>
      <td>2</td>
      <td>0</td>
      <td>-</td>
    </tr>

    {/*
   <div className="container-fluid p-2 mt-1 mb-1 flex">
<div className="col-2">
  <img className="w-100" src={item.cover_image}/>
</div>
<div className="continer-fluid col-lg-5">
<h6>{item.title}</h6>
<p className="text-secondary truncate truncate-1">{item.description}</p>

<div className="flex align-items-center">
<Edit  className="text-secondary mr-2"/>
<Share  className="text-secondary mr-2"/>
<Download  className="text-secondary mr-2"/>
<Delete  className="text-secondary mr-2"/>


</div>
</div>


</div> 
    */}


</>


))}
</>:
<>
no videos
</>}

</tbody>
</Table>


  
  </>
  )
}

export default ClassRecordings