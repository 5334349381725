import React from 'react'
import { useActionsContext } from '../context/ActionsContext'

const Payment = () => {
    const {setsearchquery} = useActionsContext()
  return (
    <>
      <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Option</option>
                <option value="emi">EMI</option>
                <option value="Single Payment">Single Payment</option>
              
                </select> 
    </>
  )
}

export default Payment