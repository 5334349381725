import { combineReducers, createStore } from 'redux';
import whatsappChatProfileReducer from './whatsappChatProfileReducer';
import notificationSentReducer from './notificationSentReducer';
import modalReducer from './modalReducer';
import studentProfile from './studentProfile';
import CourseManagement from './CourseManagement';
import micReducer from './micReducer';
import paymentInvoice from './paymentInvoice';
import statisticsReducer from './statisticsReducer';



const rootReducer = combineReducers({
  whatsappUser:whatsappChatProfileReducer ,
  sendNotification : notificationSentReducer,
  modalHandler: modalReducer,
  studentHandler:studentProfile,
  courseDetails: CourseManagement,
  micHandler: micReducer,
  invoice : paymentInvoice,
  statisticsHandler: statisticsReducer
});

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store;


