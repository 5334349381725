import React from 'react'
import { useActionsContext } from '../context/ActionsContext'

const Mode = () => {
    const {searchquery,setsearchquery} = useActionsContext()
  return (
    <>

<select
              class="form-control w-45 m-2"
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Mode</option>
              <option value="live">Live</option>
              <option value="offline">Offline</option>
              <option value="specialization">Specialization</option>
              <option value="selfpaced">Selfpaced</option>
          </select>
    </>
  )
}

export default Mode