import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DefaultModal from '../../components/modals/DefaultModal';

const ClassesCalender = ({ classSchedules }) => {
  const [hoveredDate, setHoveredDate] = useState(null);

  const tileContent = ({ date, view }) => {
    // Check if the date has a class schedule
    const hasClassSchedule = classSchedules.some(schedule => {
      const scheduleDate = new Date(schedule.class_starts);
      return date.getDate() === scheduleDate.getDate() &&
             date.getMonth() === scheduleDate.getMonth() &&
             date.getFullYear() === scheduleDate.getFullYear();
    });

    // If the date has a class schedule, return the circular element
    return hasClassSchedule && view === 'month' && <div className="circle" />;
  };

  const tileClassName = ({ date }) => {
    // Check if the date has a class schedule
    const hasClassSchedule = classSchedules.some(schedule => {
      const scheduleDate = new Date(schedule.class_starts);
      return date.getDate() === scheduleDate.getDate() &&
             date.getMonth() === scheduleDate.getMonth() &&
             date.getFullYear() === scheduleDate.getFullYear();
    });

    // If the date has a class schedule, add the 'green' class
    return hasClassSchedule ? 'class-green' : '';
  };

  const handleMouseOver = ({ date }) => {
    // Find the class schedule for the hovered date
    const schedule = classSchedules.find(schedule => {
      const scheduleDate = new Date(schedule.class_starts);
      return date.getDate() === scheduleDate.getDate() &&
             date.getMonth() === scheduleDate.getMonth() &&
             date.getFullYear() === scheduleDate.getFullYear();
    });

    // Set the hovered date and schedule
    setHoveredDate(schedule);
  };

  const handleMouseLeave = () => {
    // Clear the hovered date and schedule when mouse leaves
    setHoveredDate(null);
  };

  const [modal,setmodal] = useState(false)
  return (
    <div className='w-100'>
  <DefaultModal show={modal} HandleClose={()=>setmodal(false) }  
  content={<>
  
  <p>Class Time: {hoveredDate?hoveredDate.class_starts:" jhf"}</p>
  </>}
  
  />
      <Calendar
        tileContent={tileContent}
        tileClassName={tileClassName}
        onMouseOver={()=>setmodal(true)}
        onClickDay={()=>setmodal(true)}
        onMouseLeave={()=>setmodal(false)}
      />
      {hoveredDate && (
        <div className="popover">
          <p>Class Time: {hoveredDate?hoveredDate.class_starts:" jhf"}</p>
        </div>
      )}
    </div>
  );
};

export default ClassesCalender;
