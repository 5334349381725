import React from 'react'
import ModalStyle from '../../context/ModalStyle'
import { Box, Modal } from '@mui/material'
import { Close } from '@material-ui/icons'

const DefaultAlertModal = ({show,HandleClose,title,message}) => {
  return (
<>


                  <Modal
  open={show}
  onClose={HandleClose}
>
  <Box sx={ModalStyle} className="col-lg-3 col-sm-12 col-12">
  <div className="modal-content p-3 rounded">
                  
<div className="modal-header">
<h5> {title}</h5>
  <Close onClick={HandleClose}/>
</div>
<p>{message}</p>
<hr></hr>

    <div className="d-flex justify-content-center">
        <button className='btn btn-warning' onClick={HandleClose}>Close</button>
        </div>              
                      </div>
  </Box>
</Modal>
</>
  )
}

export default DefaultAlertModal

