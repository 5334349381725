import React, { useContext, useEffect, useState } from 'react'
import ClassScheduleItem from './ClassScheduleItem'
import DemoData from '../../data/DemoData'
import ClassesList from './ClassesList'
import { AuthContext } from '../../context/AuthContext'
import { useParams } from 'react-router-dom'
import ENDPOINT from '../../context/EndPoint'
import axios from 'axios';
import SecurityHeaders from '../../context/SecurityHeaders';
import Localbase from 'localbase'
import { useActionsContext } from '../../context/ActionsContext'

const ClassesManagement = ({ options }) => {
  const db = new Localbase('db')
  const [classes, setclasses] = useState(null)
  const { user } = useContext(AuthContext)
  const {searchquery} = useActionsContext()
  const getChapters = async () => {

    const formData = new FormData();

    formData.append('session', user.SessionId);
    if (options?.limit) {
      formData.append('limit', options?.limit);
    }
    const res = await axios.post(ENDPOINT + "get-all-class-schedules.php", formData, SecurityHeaders);
    if (res) {
      if (res.data.status === "success") {
        setclasses(res.data.data)
        setInitialData(res.data.data)
        setResponse(res.data.data)
        db.collection('paths').add({class:res.data.data},'class')
      }
      else {

      }
    }
  }
  useEffect(()=>{
    getChapters()
  },[])
  useEffect(() => {
    Getofflinedata()
  }, [])

  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setInitialData(classes);
    setResponse(classes);
  }, [classes]);
useEffect(()=>{
   handleSearch({term:searchquery})
},[searchquery])
  const handleSearch = ({term}) => {
    if(!term)
    {
      setResponse(initialData)
      setSearchTerm("")
      return
    }
    
if(term)
{
  
  const filteredData = initialData.filter((item) => {
    return (
      item.course_url.toLowerCase().includes(term) ||
      item.class_title.toLowerCase().includes(term) ||
      item.class_starts.includes(term) ||
      item.class_status.toLowerCase().includes(term)
    );
  });

  setResponse(filteredData);
  setSearchTerm(term);
}
  };
  const Getofflinedata = () =>{
    db.collection('paths').doc('class').get().then((data)=>{
      if(data)
      {
        setclasses(data.class)
        setInitialData(data.class)
        setResponse(data.class)
      }

    })
  }


  return (
    <>

      <div className="tab-pane min-vh-80" id="documents" role="tabpanel">
        <div className="card bg-transparent border-0 shadow-none shadow-md border-md-1">
          <div className="card-body p-0 p-md-3 bg-transparent">
            {
              options?.search === false ? <></> : <div className="d-none d-md-flex align-items-center mb-4 d-sm-block d-lg-block d-xl-block">
                <h5 className="card-title flex-grow-1 mb-0">
                  All Classes
                </h5>
                {/* <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-5 col-sm-6">
                        <div className="search-box">
                          <input
                              onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                            type="text"
                            className="form-control search"
                            placeholder="Search for class title, description, course..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                     
                      <div className="col-xxl-2 col-sm-6">
                        <div >
                          <input
                            type="date"
                              onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                            className="form-control"
                            data-provider="flatpickr"
                            data-date-format="d M, Y"
                            data-range-date="true"
                            id="demo-datepicker"
                            ty
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                    
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                              onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idStatus"
                          >
                            <option value="">Status</option>

                            <option value="ongoing">Ongoing</option>
                            <option value="incomplete">Incomplete</option>

                          </select>
                        </div>
                      </div>

                      <div className="col-xxl-1 col-sm-4">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary h-fit"

                          >

                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>
                        </div>
                      </div>
                     
                    </div>
                   
                  </form>
                </div> */}
              </div>
            }

            {response ?
              <>


                <ClassesList options={options} Reload={() => { getChapters() }} classes={response} />
              </> :
              <>

              </>}
          </div>
        </div>
      </div>

    </>
  )
}

export default ClassesManagement