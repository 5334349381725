import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import { useParams } from 'react-router'
import TextField from '@mui/material/TextField';
import ENDPOINT from '../../context/EndPoint'
import Checkbox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import axios from 'axios'
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SecurityHeaders from '../../context/SecurityHeaders'
import AdminDeleteQuiz from '../../actions/quiz/AdminDeleteQuiz'
const MockTestQuestions = ({test_type}) => {
const params = useParams();
const course_url = params.course_url
const test_code = params.test_code
const {user} =  useContext(AuthContext)

    const [info,setinfo] = useState(null);

    


    useEffect(()=>{
       
    
       
    getQuizzes();
       
    }
    
    ,[])
    
    const  getQuizzes = async ()=>{

const data = new FormData()
data.append("session",user.SessionId)
data.append("course_url",course_url)
data.append("test_code",test_code)
data.append("test_type",test_type)
     const res = await axios.post(ENDPOINT+"admin-get-questions.php",data,SecurityHeaders)

     if(res){
 // console.log("test")

          if(res.data){
           
               if(res.data.status==="success"){

 // console.log("test data")
                    setposts(res.data.data)
               }
          }
         
         
        
     }
     
     }    ;
    
  
    const [account,setaccount] = useState(null);
  const [role,setrole] = useState(null);


    
    
    const [question,setquestion] = useState(null)
    
    
    const [option1,setoption1] = useState(null)
    const [option2,setoption2] = useState(null)
    
    const [option3,setoption3] = useState(null)
    
    const [option4,setoption4] = useState(null)
    const [answer,setanswer] = useState(null)
    
    
    const [posts,setposts] = useState(null)
    
    

const [allcomments,setallcomments] = useState(null)
const [qno,setqno] = useState(null)
const AddQuiz = async (e)=>{
     e.preventDefault()
const data = new FormData()
data.append("question",question)
data.append("option1",option1)
data.append("option2",option2)
data.append("option3",option3)
data.append("option4",option4)
data.append("answer",answer)
data.append("session",user.SessionId)
data.append("question_number",qno)
data.append("test_code",test_code)
data.append("course_url",course_url)
     const res = await axios.post(ENDPOINT+"admin-add-question.php",data,SecurityHeaders)

     if(res){
           // console.log(" quiz "+JSON.stringify(res))
          if(res.data.status==="success"){
getQuizzes()
          }
        
     }
}


const DeleteQuiz = async ({quiz_id})=>{
     const data = new FormData()
     data.append("id",quiz_id)
     data.append("session",user.SessionId)
     const res = await axios.post(ENDPOINT+"admin-delete-question.php",data,SecurityHeaders)
  if(res.data){
     if(res.data.status==="success"){
          getQuizzes()
     }
  }
}

const [answer1,setanswer1] = useState(false)
const [answer2,setanswer2] = useState(false)
const [answer3,setanswer3] = useState(false)
const [answer4,setanswer4] = useState(false)



useEffect(()=>{
     if(answer1){
          setanswer("option1")
          setanswer2(false)
          setanswer3(false)
          setanswer4(false)
     }
     if(answer2){
          setanswer("option2")
          setanswer1(false)
      
          setanswer3(false)
          setanswer4(false)
     }
     if(answer3){
          setanswer("option3")
          setanswer1(false)
          setanswer2(false)
     
          setanswer4(false)
     }
     if(answer4){
          setanswer("option4")
          setanswer1(false)
          setanswer2(false)
          setanswer3(false)
        
     }
      // console.log("answer is "+answer)
},[answer1,answer2,answer3,answer4])


useEffect(()=>{
getQuizzes()
},[])
    return (

<>




<div className="flex justify-content-between">
<div className="col-lg-7">
<h4>{posts?posts.length:0} Questions</h4>

<div className="overflow-y vh-90 p-3">

{posts?
<>
{posts.map((quiz)=>(
     <>
<div to={"/mock-test/"+course_url+"/test-code/"+quiz.test_code} className="mb-2 border br-4 p-3">
     <div className="w-100">
          <div className="d-flex justify-content-between">
          <h6>#{quiz.id} :{quiz.question}</h6>
          <DeleteIcon className="click" onClick={()=>DeleteQuiz({quiz_id:quiz.id})}/>
  
          </div>
     
     <p>{quiz.course_url}</p>

     <p>1: {quiz.option1}</p>
     <p>2: {quiz.option2}</p>
     <p>3: {quiz.option3}</p>
     <p>4: {quiz.option4}</p>
      </div>


</div>
     </>
))}
</>:null}
</div>

</div>

<div className="col-lg-4">
<h5>New Quiz</h5>
{answer?
<>
<p className="mb-0">Selected Answer : {answer}</p>
</>:"Answer not selected"}

<form className="" onSubmit={(e)=>AddQuiz(e)}>
<div className="d-flex mt-2 mb-2">

<TextField
 className="mb-2 w-100"
  label="Question Number"

  required
  onChange={(e)=>{setqno(e.target.value);}}
/>
</div>
<TextareaAutosize
  maxRows={4}
  minRows={4}
  aria-label="maximum height"
  placeholder="Enter your question"
  defaultValue=""
  value={question}
  className="mb-2 w-100 border"
  onChange={(e)=>{setquestion(e.target.value)}}
  required
/>


<div className="d-flex">
<Checkbox  checked={answer1} onChange={()=>{setanswer1(!answer1)}}/>
<TextField
 className="mb-2 w-100"
  label="Option 1"

  required
  onChange={(e)=>{setoption1(e.target.value); }}
/>
</div>


<div className="d-flex">
<Checkbox  checked={answer2} onChange={()=>{setanswer2(!answer2)}}/>
<TextField
   className="mb-2 w-100"
  label="Option 2"

  required
  onChange={(e)=>{setoption2(e.target.value)}}
/>
</div>


<div className="d-flex">

<Checkbox  checked={answer3} onChange={()=>{setanswer3(!answer3)}}/>
<TextField
 className="mb-2 w-100"
  label="Option 3"
 
  required
  onChange={(e)=>{setoption3(e.target.value)}}
/>
</div>



<div className="d-flex">


<Checkbox  checked={answer4} onChange={()=>{setanswer4(!answer4)}}/>
<TextField
 className="mb-2 w-100"
  label="Option 4"

  required
  onChange={(e)=>{setoption4(e.target.value)}}
/>
</div>



<button type="submit" className="btn btn-primary w-100">Add Quiz</button>


</form>
</div>


</div>





</>
    )
}

export default MockTestQuestions







