import React, { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../context/EndPoint';
import axios from 'axios';

import SecurityHeaders from '../../context/SecurityHeaders';
const EditBatch = ({closeModal,batch_id,course_url,course_id}) => {
  
    const { user } = useContext(AuthContext);
    const [name, setName] = useState('')
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const editBatch = async (e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('session',user.SessionId);
        formData.append('batch_id',batch_id);
        formData.append('course_url',course_url);
        formData.append('name',name);
  

        const res = await axios.post(ENDPOINT+"admin-edit-batch.php",formData,SecurityHeaders);
        if(res){
            if(res.data.status === "success"){
                setSnackbarMessage("Batch Updated");
                setSnackbar(true);
                closeModal();
            }else{
                setSnackbarMessage("Something went wrong");
                setSnackbar(true); 
                closeModal();
            }
        }
    }
    return (
        <div className="container">
            <form onSubmit={editBatch}>
                <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" name='title' onChange={(e) => setName(e.target.value)} />
                </div>
                <button type="submit" className="btn mt-3 w-100 btn-primary">
                    {"Submit"}
                </button>
            </form>

            <Snackbar
                open={snackbar}
                autoHideDuration={6000}
                onClose={() => setSnackbar(false)}
                message={snackbarMessage}
            />
        </div>
    )
}

export default EditBatch
