import React from 'react'
import { useActionsContext } from '../context/ActionsContext'

const Status = ({currentPage}) => {
    const page = currentPage.slice(1)
    const {setsearchquery} = useActionsContext()
  return (
    <>
  
            
    {page==="classes"?<>
                <select

              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="ongoing">Ongoing</option>
                <option value="incomplete">incomplete</option>
                </select> 
              </>:
              page==='students-support'?
              <>
              <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="unresolved">unresolved </option>
                <option value="closed">closed </option>
                <option value="initiated">Initiated</option>
 
                </select> 
              </>:
              page==="assignment-submissions"?
              <>
     <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="submitted">Submitted</option>
                </select> 

              </>
              :
            page==="project-submissions"
            ?
            <>
            <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="submitted">Submitted</option>
                <option value="pending">Pending</option>
                
                </select> 

              
            </>
            :
            page==="placement-support"
            ?
            <>
             <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="unresolved">Unresolved</option>
                <option value="initiated">Initiated</option>
                <option value="closed">closed</option>
                
                </select> 
            </>
            
            :
            page==="payments" ||   page==="course-registrations"
            ?
            <>
             <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="pending">Pending</option>
                <option value="Success">success</option>
                </select> 
            </>
            :
            page==="team-task-submissions"?
            <>
              <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="pending">Pending</option>
                <option value="submitted">Submitted</option>
                
                </select> 
            </>
            :
            page==="team-meetings"
            ?
            <>
               <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="incomplete">Incomplete</option>
                <option value="completed">Completed</option>
                </select> 
            </>
            :
            page==="team-leaves"
            ?
            <>
              <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="pending">Pending</option>
                <option value="Accepted">Accepted</option>
                </select> 
            </>
            :
            page==="team-members-all"
            ?
            <>
             <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="all">All</option>
                <option value="Active">Active</option>
                <option value="offline">Offline</option>
                </select> 

            </>
            :
            page==="customer-support"
            ?
            <>
                         <select
              class="form-control w-45 m-2 "
              data-choices=""
              data-choices-search-false=""
              name="choices-single-default"
              id="idStatus"
              onChange={(e) => setsearchquery(e.target.value.toLowerCase())}
          >
              <option value="">Status</option>
                <option value="unresolved">Unresolved</option>
                <option value="initiated">Initiated</option>
                <option value="closed">closed</option>
                </select> 
            </>
            :
          
           
              <></>}

         
    </>
  )
}

export default Status