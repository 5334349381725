import React, { useContext, useEffect, useState } from "react";
import ENDPOINT from "../../context/EndPoint";
import { AuthContext } from "../../context/AuthContext";
import AdminDeleteComment from "../../actions/post/AdminDeleteComment";
import DeleteIcon from "@mui/icons-material/Delete";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

const AllComments = () => {
  const [allcomments, setallcomments] = useState(null);
  const { user } = useContext(AuthContext);
  const GetComments = async () => {
    fetch(`${ENDPOINT}admin-get-comments.php?session=${user.SessionId}`)
      .then((r) => r.json())
      .then((result) => {
        if (result) {
          if (result.status === "success") {
            setallcomments(result.comments);
          }
        }
      });
  };

  const DeleteComment = async ({ comment_id }) => {
    const res = await AdminDeleteComment({
      payload: {
        user: user.SessionId,
        id: comment_id,
      },
    });
    if (res.status === "success") {
      GetComments();
    }
  };

  useEffect(() => {
    GetComments();
  }, []);

  return (
    <div>
      All Comments
      <div className="d-flex align-items-center mb-4">
        
        <div className="card-body border border-dashed border-end-0 border-start-0">
          <form className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
            <div className="row g-3">
              <div className="col-xxl-5 col-sm-6">
                <div className="search-box">
                  <input
                    type="text"
                    className="form-control search"
                    placeholder="Search for class title, description, course..."
                  />
                  <i className="ri-search-line search-icon" />
                </div>
              </div>
              {/*end col*/}
              <div className="col-xxl-2 col-sm-6">
                <div>
                  <input
                    type="text"
                    className="form-control"
                    data-provider="flatpickr"
                    data-date-format="d M, Y"
                    data-range-date="true"
                    id="demo-datepicker"
                    placeholder="Select date"
                  />
                </div>
              </div>
              {/*end col*/}
              <div className="col-xxl-2 col-sm-4">
                <div>
                  <select
                    className="form-control"
                    data-choices=""
                    data-choices-search-false=""
                    name="choices-single-default"
                    id="idStatus"
                  >
                    <option value="">Status</option>
                    <option value="all" selected="">
                      All
                    </option>
                    <option value="Pending" className="d-flex" >
                      
                        <PendingActionsIcon className="w-100 text-dark " />
                        
                        Pending
                
                    </option>
                    <option value="Inprogress">Inprogress</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Pickups">Pickups</option>
                    <option value="Returns">Returns</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </div>
              </div>

              <div className="col-xxl-1 col-sm-4">
                <div>
                  <button type="button" className="btn btn-primary h-fit">
                    <i className="ri-equalizer-fill me-1 align-bottom" />
                    Filters
                  </button>
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </form>
        </div>
      </div>
      <hr></hr>
      {allcomments ? (
        <>
          {allcomments.map((comment) => (
            <>
              <div className="comment-item flex justify-content-between p-2">
                <p className="mb-2">{comment.comment}</p>

                <DeleteIcon
                  onClick={() => {
                    DeleteComment({ comment_id: comment.comment_id });
                  }}
                  className="click"
                />
              </div>
            </>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default AllComments;
