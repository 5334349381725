import React from 'react'
import BadgeItem from './BadgeItem'

const CourseBadgesList = ({badges}) => {
  
  return (
   <>
   
   <div className="d-flex flex-wrap">

{badges.map((item,index)=>(
  
    
    <div key={index} className="col-lg-2">
        <BadgeItem item={item} course={true} />
    </div>
    
    
))}

    
   </div>
   
   
   
   </>
  )
}

export default CourseBadgesList