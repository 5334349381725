import React, { useContext, useEffect, useState } from "react";
import DemoData from "../../data/DemoData";
import BatchItem from "./BatchItem";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import ENDPOINT from "../../context/EndPoint";
import axios from "axios";
import SecurityHeaders from "../../context/SecurityHeaders";
import GetActiveBatches from "../../actions/course/GetActiveBatches";
import CourseBatchesList from "./CourseBatchesList";

const BatchesList = () => {
  const { user } = useContext(AuthContext);

  const params = useParams();

  const course_id = params.course_id;

  const course_url = params.course_url;

  const [category, setcategory] = useState("all");

  const [query, setquery] = useState(null);

  const [batches, setbatches] = useState(null);

  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);

  const getBatches = async () => {
    const res = await GetActiveBatches({
      payload: {
        session: user.SessionId,
        status: "all",
      },
    });

    if (res) {
      if (res.status === "success") {
      setbatches(res.data)
      }
    }
  };

  useEffect(() => {
    getBatches();
  }, []);

  const updateBatchStatus = async ({ cid, bid, bstatus }) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);
    formData.append("course_id", cid);
    formData.append("id", bid);
    formData.append("status", bstatus);

    const res = await axios.post(
      ENDPOINT + "admin-update-batch-status.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      if (res.data.status === "success") {
        getBatches();
      } else {
      }
    }
  };

  const options = ["Option 1", "Option 2"];

  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  const [delete_id, setdelete_id] = useState(null);

  const DeleteBatch = async ({ id }) => {
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("id", id);
    const res = await axios.post(
      ENDPOINT + "admin-delete-batch.php",
      data,
      SecurityHeaders
    );

    if (res) {
      if (res.data.status === "success") {
        getBatches();
         // console.log(JSON.stringify(res));
      }
    }
  };

 

  return (
    <>
      <div className="container-fluid ">
        {/* <div className="row mt-3 g-4 mb-3">
          <div className="col-sm">
            <div className="d-flex  gap-2">
              <div className="search-box ms-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <i className="ri-search-line search-icon" />
              </div>
            </div>
          </div>

          <div className="col-sm-auto">
            <div>
              <Link
                to={"/add-batch/" + course_url + "/" + course_id}
                className="btn btn-success"
              >
                <i className="ri-add-line align-bottom me-1" /> Add New Batch
              </Link>
            </div>
          </div>
        </div>
        <hr></hr> */}
        <div className="row">
        
               
                  {batches?
                    <CourseBatchesList addbatch={false} batches={batches} />:null}
                    {/* end card */}
                  
              
        </div>

        <div className="row g-0 text-center text-sm-start align-items-center mb-4">
          <div className="col-sm-6">
            <div>
              <p className="mb-sm-0 text-muted">
                Showing <span className="fw-semibold">1</span> to{" "}
                <span className="fw-semibold">10</span> of{" "}
                <span className="fw-semibold text-decoration-underline">
                  12
                </span>{" "}
                entries
              </p>
            </div>
          </div>
          {/* end col */}
          <div className="col-sm-6">
            <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
              <li className="page-item disabled">
                <a href="#" className="page-link">
                  Previous
                </a>
              </li>
              <li className="page-item active">
                <a href="#" className="page-link">
                  1
                </a>
              </li>
              <li className="page-item ">
                <a href="#" className="page-link">
                  2
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  3
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  4
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  5
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  Next
                </a>
              </li>
            </ul>
          </div>
          {/* end col */}
        </div>
        {/* end row */}
      </div>
    </>
  );
};

export default BatchesList;
