import { Avatar } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import ChangeAccountStatus from '../../actions/user/ChangeAccountStatus';
import { MoreVert } from '@material-ui/icons'
import SendOTP from '../../actions/user/SendOTP';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { AuthContext } from '../../context/AuthContext'
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
const UserMobile = ({item,permission}) => {
    const [deleteuser,setdeleteuser] = useState(false)
    const [activity,setactivity] = useState(false)
    const {user} = useContext(AuthContext)
    const ChangeAccountActiveStatus = async({status})=>{

        const res = await ChangeAccountStatus({
            payload:{
                SessionId:user.SessionId,
                user_id:item.user_id,
                status:status
            }
        })
    }
    const ResendOTP = async ()=>{


        const res = await SendOTP({  payload:{
            SessionId:user.SessionId,
            user_email:item.user_email
        }})
    }



  return (
    <>
 <div key={item.id} className='w-100 p-2'>
                        <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                        <Avatar src={item.profile_pic} className='me-2'/>
                        <div>
                        <h5 className='mb-0'>{item.first_name+" "+item.last_name}</h5>
                        <p className='mb-0'>ID :{item.user_id}</p>
                        </div>
                        </div>
                       <div className='d-flex '>
                       <div>
                       <span className={`badge ${item.status === "active" ? "bg-success-subtle text-success" :  "bg-warning-subtle text-warning" }`}>
                         {item.status}
                       </span>
                       </div>
                       <Dropdown className="options-dropdown">
        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
          <MoreVert className="text-dark" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {item.status === "pending" ||
          item.status === "inactive" ? (
            <>
              <Dropdown.Item
                onClick={() =>
                  ChangeAccountActiveStatus({status:"active"})
                }
              >
                Activate Account
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  ResendOTP()
                }
              >
                Send Verification Code
              </Dropdown.Item>
            </>
          ) : (
            <>
              {item.status === "active" ? (
                <>
                  <Dropdown.Item className='d-flex'
                    onClick={() =>
                        ChangeAccountActiveStatus({status:"pending"})
                      }
                  >
                    <PendingActionsIcon className='fs-5 text-primary me-2'/>
                   <p> Mark Pending Verification</p>
                  </Dropdown.Item>

                  <Dropdown.Item className='d-flex'
                  onClick={() =>
                    ChangeAccountActiveStatus({status:"inactive"})
                
                  }
                  >
                    <PersonOffIcon className='text-danger me-2 fs-5'/>
                    <p>Deactivate Account</p>
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <Dropdown.Item className='d-flex'
            onClick={() =>setactivity(true)}
          >
            <PersonIcon className='fs-5 me-2' />
          <p> Show User Activity</p>
          </Dropdown.Item>
          {parseInt(permission.delete_user) === 1 ? (
            <>
              <Dropdown.Item
                onClick={()=>setdeleteuser(true)}
              >
                <PersonRemoveIcon className='fs-5 me-2' />
                Delete Users
              </Dropdown.Item>
            </>
          ) : (
            <></>
          )}
        </Dropdown.Menu>
      </Dropdown>
                       </div>
                        </div>
                        <div className='mt-1 d-flex justify-content-between'>
                          <p className='mb-0'>{item.email}</p>
                          <p>{item.phone_number}</p>
                        </div>
                      </div>
                      <hr className='mb-1 mt-0'/>

    </>
  )
}

export default UserMobile