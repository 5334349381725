import axios from 'axios';
import React, { useState } from 'react';
import SecurityHeaders from '../../context/SecurityHeaders';
import ENDPOINT from '../../context/EndPoint';
import { Link } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';

const AddTeamMember = () => {
    const [profilePic, setProfilePic] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [designation, setDesignation] = useState('');
    const [category, setCategory] = useState('');
    const [onlineStatus, setOnlineStatus] = useState('active'); // Default to 'active'
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
          setProfilePic(file); // Store the file object directly
      }
  };
  
  const addTeamMember = async (e) => {
      e.preventDefault();
      const data = new FormData();
      if (profilePic) {
          data.append('profile_pic', profilePic); // Append the file directly
      }
      data.append('first_name', firstName);
      data.append('last_name', lastName);
      data.append('email', email);
      data.append('phone', phone);
      data.append('designation', designation);
      data.append('category', category);
      data.append('status', onlineStatus || 'active'); // Default to 'active'
  
      try {
          const res = await axios.post(ENDPOINT + "add-team-member.php", data, SecurityHeaders);
          if (res.data.status === 'success') {
            window.location.replace('/team-members-all')
              console.log('Team member added successfully:', res.data);
          } else {
              console.error('Error adding team member:', res.data.message);
          }
      } catch (error) {
          console.error('Error while uploading data:', error);
      }
  };

    return (
        <div className="container">
            <div className="container card col-11 col-lg-6 col-sm-12 col-md-10 p-4 shadow rounded-3">
                <h2 className='mb-3'>Add Team Member</h2>
                <form onSubmit={addTeamMember}>
                    <div className="mb-3">
                        <label htmlFor="profilePic" className="form-label">Profile Picture</label>
                        <div>
                            <div
                            style={{
                                height: 90,
                                width: 90,
                                borderRadius: '50%',
                                overflow: 'hidden',
                                cursor: 'pointer',
                                display: 'inline-block',
                                backgroundColor: '#f0f0f0',
                                backgroundImage: profilePic ? `url(${URL.createObjectURL(profilePic)})` : 'none',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                            onClick={() => {
                                const input = document.createElement('input');
                                input.type = 'file';
                                input.accept = 'image/*';
                                input.onchange = handleFileChange;
                                input.click();
                            }}
                        >
                            {!profilePic && (
                                <AccountCircle style={{ height: '100%', width: '100%', color: '#ccc' }} />
                            )}
                        </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">First Name</label>
                        <input type="text" className="form-control" onChange={(e) => setFirstName(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">Last Name</label>
                        <input type="text" className="form-control" onChange={(e) => setLastName(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="phone" className="form-label">Phone Number</label>
                        <input type="text" className="form-control" onChange={(e) => setPhone(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="designation" className="form-label">Designation</label>
                        <input type="text" className="form-control" onChange={(e) => setDesignation(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="category" className="form-label">Category</label>
                        <input type="text" className="form-control" onChange={(e) => setCategory(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="onlineStatus" className="form-label">Online Status</label>
                        <input type="text" className="form-control" onChange={(e) => setOnlineStatus(e.target.value)} value={onlineStatus} />
                    </div>
                    <button type="submit" className="btn btn-primary">Add Team Member</button>
                    <Link to="/team-members-all" className="btn btn-secondary ms-2">Cancel</Link>
                </form>
            </div>
        </div>
    );
};

export default AddTeamMember;
