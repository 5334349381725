import React from 'react'

import ModalStyle from '../../context/ModalStyle'
import { Box, Modal } from '@mui/material'

const DefaultModal = ({show,HandleClose,content}) => {
  return (
<>


                  <Modal
  open={show}
  onClose={HandleClose}
>
  <Box sx={ModalStyle} className="col-lg-3 col-sm-12 col-12">
  <div className="modal-content">
                  
{content}
                  
                      </div>
  </Box>
</Modal>
</>
  )
}

export default DefaultModal

