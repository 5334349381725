import axios from "axios";
import ENDPOINT from "../../context/EndPoint";

const GetBatches = async ({payload})=>{
    const formData = new FormData();
       
  //formData.append('whyus',document.getElementById('whyus').value)
  formData.append('session',payload.session);
  formData.append('course_id',payload.course_id);


  const res = await axios.post(ENDPOINT+"admin-get-batches.php", formData,{
    
  
  headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
 return res.data
  }
  }
  
    
  export default GetBatches