import axios from 'axios';

import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';

const AdminGiftCourseBadge = async ({payload}) => {

          const formData = new FormData();

           formData.append('session',payload.user);
           formData.append('user_id',payload.user_id);
          
           formData.append('badge_id',payload.badge_id);
           formData.append('course_url',payload.course_url);

           try{
               const res = await axios.post(ENDPOINT+"admin-gift-course-badge.php", formData,SecurityHeaders);
                
               if(res.data){
                    return res.data
             
                }
           }
           catch(error){

return error
           }

     




}

export default AdminGiftCourseBadge