
import React from 'react'
import ModalStyle from '../../context/ModalStyle'
import { Box, Modal } from '@mui/material'
import { Warning } from '@material-ui/icons'

const NoPermissionModal = ({show,HandleClose,content}) => {
  return (
<>


                  <Modal
  open={show}
  onClose={HandleClose}
>
  <Box sx={ModalStyle} className="col-lg-3 col-sm-12 col-12 p-3 rounded">
  <div className="modal-content">
       <div className="d-flex">
       <Warning className='text-danger me-2'/>
       
       <div>
       <h3> No permission</h3>
                 <p>You dont have permission to perform this action</p>


       </div>
       </div>           


<hr></hr>

                 <div className="d-flex justify-content-center">
                  <button onClick={HandleClose} className='btn btn-link'>Ok</button></div> 
                      </div>
  </Box>
</Modal>
</>
  )
}

export default NoPermissionModal

