import axios from "axios"
import ENDPOINT from "../../context/EndPoint"
import SecurityHeaders from "../../context/SecurityHeaders"

const FetchCustomerTickets = async({payload})=>{
    const data = new FormData()
    data.append("session",payload.SessionId)

if(payload.limit){

    data.append("limit",payload.limit)
}
    
      const res = await axios.post(ENDPOINT+"admin-get-tickets.php",data,SecurityHeaders)
    
      if(res){
    
        if(res.data.status==="success"){
    
          return res.data
          
        }
       
      }
    }


    export default FetchCustomerTickets
  