import React, { useContext, useEffect, useState } from 'react'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Avatar } from '@mui/material'
import ReactApexChart from 'react-apexcharts';
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import axios from 'axios';

const MarketingManagerDashboard = () => {

  const {user} = useContext(AuthContext)
  const [stats,setStats] = useState('')
  const getStatistics = async ()=>{
      try{
      const data = new FormData();

          data.append('session',user.SessionId);

          const res = await axios.post(ENDPOINT+"marketing-dashboard.php",data,SecurityHeaders);
          if(res){
            if(res.data.status === 'success'){
            setStats(res.data)
            }
          }
      }
      catch(err){
 // console.log(err.message);
      }
  }
  useEffect(() => {
      getStatistics();
  }, []);



  
  const [chartOptions, setChartOptions] = useState({
    series: [0, 0, 0, 0],  // Default values to prevent errors
    options: {
      chart: {
        type: 'donut',
      },
      labels: ["Insta", "Youtube", "Offline", "Referral"],
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8,
        },
      },
      responsive: [{
        options: {
          chart: {},
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            position: 'absolute',
          },
        },
      }],
    },
  });

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 4000);

    return () => clearTimeout(timer);  // Cleanup the timer on component unmount
  }, []);

  useEffect(() => {
    if (show) {
      setChartOptions({
        series: [
          stats?.insta ?? 0, 
          stats?.youtube ?? 500, 
          stats?.offline ?? 0, 
          stats?.referral ?? 0
        ],
        options: {
          chart: {
            type: 'donut',
          },
          labels: ["Insta", "Youtube", "Offline", "Referral"],
          dataLabels: {
            dropShadow: {
              blur: 3,
              opacity: 0.8,
            },
          },
          responsive: [{
            options: {
              chart: {},
              legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                position: 'absolute',
              },
            },
          }],
        },
      });
    }
  }, [show, stats]);


  const data = [0, 1, 2, 3, 4, 5, 6]

  return (
    <>
      <div className='container-fluid'>
        <section className='d-flex flex-wrap'>
          <div className='col-lg-6 col-md-12 col-12'>

            <div className='d-flex flex-wrap col-lg-12 col-md-12 col-sm-12 col-12'>

              <div className='col-6 p-2'>
                <div className=' p-3  rounded bg-white shadow text-uppercase'>
                  <span><b>Total Revenue Generated</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>{stats?.gen_amount?stats?.gen_amount:0}</b></h3>
                    <h3 className='m-0'><b></b></h3>
                    <div className='grn px-1 rounded-pill'>
                     {show?<> <TrendingUpIcon /></>:null}
                      <b>100%</b>
                    </div>
                  </div>
                  <p className='txt m-0 p-0'>Amount</p>
                </div>
              </div>

              <div className='col-6 p-2'>
                <div className='p-3 rounded bg-white shadow text-uppercase'>
                  <span><b>Total Amount Spent</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>{stats?.spent_amount?stats?.spent_amount:0}</b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>100%</b>
                    </div>
                  </div>
                  <p className='txt m-0 p-0'>Amount</p>
                </div>
              </div>

              <div className='col-6 p-2'>
                <div className='p-3 rounded bg-white shadow text-uppercase'>
                  <span><b>Amount Spent this Mounth</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>{stats?.month_spent_amount?stats?.month_spent_amount:0}</b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>100%</b>
                    </div>
                  </div>
                  <p className='txt m-0 p-0'>Amount</p>
                </div>
              </div>

              <div className='col-6 p-2'>
                <div className='p-3 rounded bg-white shadow text-uppercase'>
                  <span><b>Amount Generated this Month</b></span>
                  <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='m-0'><b>{stats?.month_gen_amount?stats?.month_gen_amount:0}</b></h3>
                    <div className='grn px-1 rounded-pill'>
                      <TrendingUpIcon />
                      <b>100%</b>
                    </div>
                  </div>
                  <p className='txt m-0 p-0'>Amount</p>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6'>

            <div className='border border-1 rounded p-2  bg-white'>

              <div className='d-flex flex-wrap'>
                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                  <ReactApexChart className="w-100" options={chartOptions.options} series={chartOptions.series} type="donut" />
                  <h6 className='p-2 text-center text-uppercase'><b>Total Amount Spent</b></h6>
                </div>


                <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-wrap '>


                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center shadow rounded text-bg '>
                      <span><b>Insta</b></span>
                      <h4 className='m-0 mt-3'><b>{stats?.insta?stats?.insta:0}</b></h4>
                      <p className='txt m-0 p-0'>Amount Spent</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center shadow rounded text-bg'>
                      <span><b>Youtube</b></span>
                      <h4 className='m-0 mt-3'><b>{stats?.youtube?stats?.youtube:0}</b></h4>
                      <p className='txt m-0 p-0'>Amount Spent</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center  rounded text-bg'>
                      <span><b>Offline</b></span>
                      <h4 className='m-0 mt-3'><b>{stats?.offline?stats?.offline:0}</b></h4>
                      <p className='txt m-0 p-0'>Amount Spent</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center rounded text-bg'>
                      <span><b>Referral</b></span>
                      <h4 className='m-0 mt-3'><b>{stats?.refrral?stats?.refrral:0}</b></h4>
                      <p className='txt m-0 p-0'>Amount Spent</p>
                    </div>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </section>




       





        <section className='container-fluid mt-4'>
          <div className='row'>
            <div className='col-lg-5 p-2'>
              <div className="card">
                <h4 className='p-2'><b>Note Points</b></h4>
                <div className="row ">

                  <div className="table-responsive">
                    <table className="table table-borderless align-middle mb-0">

                      <thead className="table-light text-center">
                        <tr>
                          <th scope="col">Title</th>
                          <th scope="col">Note</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>


                      <>
                        <tbody className='overflow text-center'>
                          {data.map((item, index) => (
                            <>

                              <tr>

                                <td>
                                  <div className="d-flex align-items-center">

                                    <div className="ms-2 flex-grow-1">

                                      <div>
                                        <h6>{"iuhjk"}</h6>

                                      </div>

                                    </div>
                                  </div>
                                </td>
                                <td>{index + 1}</td>

                                <td>{"09-07-2024"}</td>

                              </tr>
                            </>
                          ))}

                        </tbody>

                      </> :


                      <>



                      </>

                    </table>
                  </div>


                </div>
              </div>
            </div>

            <div className='col-lg-7 p-2'>
              <div className="card">
                <h4 className='p-2'><b>Campigns</b></h4>
                <div className="row ">

                  <div className="table-responsive">
                    <table className="table table-borderless align-middle mb-0">

                      <thead className="table-light text-center">
                        <tr>

                          <th scope="col">Title</th>
                          <th scope="col">Date</th>
                          <th scope="col">Amount Spent</th>
                          <th scope="col">Amount Generated</th>
                          <th scope="col">Amount Epected</th>
                          <th scope="col">Status</th>

                        </tr>
                      </thead>


                      <>
                        <tbody className='overflow text-center'>
                          {data.map((item, index) => (
                            <>

                              <tr>

                                <td>
                                  <div className="d-flex align-items-center">

                                    <div className="ms-2 flex-grow-1">

                                      <div>
                                        <h6>{"iuhjk"}</h6>

                                      </div>

                                    </div>
                                  </div>
                                </td>
                                <td>{index + 1}</td>

                                <td>{"4524"}</td>
                                <td>{"4658787"}</td>
                                <td>{"451223"}</td>
                                <td>{"Profit"}</td>
                              </tr>
                            </>
                          ))}

                        </tbody>

                      </> :


                      <>



                      </>

                    </table>
                  </div>


                </div>
              </div>
            </div>

          </div>
        </section>


      </div>
    </>
  )
}

export default MarketingManagerDashboard