import React, { useEffect } from 'react'

import { useState } from 'react'
import { useContext } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { AuthContext } from '../../context/AuthContext';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../context/EndPoint'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
const AddNewBatch = ({id,course_id}) => {



const {user} =  useContext(AuthContext)

const params = useParams();

const courseid = params.course_id;

const courseurl = params.course_url;








useEffect(()=>{


},[])




const [ctitle,setctitle] = useState(null);
const [cduration,setcduration] = useState(null);
const [cdescription,setcdescription] = useState(null);
const [crating,setcrating]=useState(null);
const [cratings,setcratings] = useState(null)
const [cprice,setcprice] = useState(null);
const [cutoff,setcutoff] = useState(null);
const [offerends,setofferends] = useState(null)
const [seatsleft,setseatsleft] = useState(null)
const [nextbatch,setnextbatch] = useState(null)
const [coursedata,setcoursedata] = useState(null)

const [cstatus,setcstatus] = useState("actve")
          const [promourl,setpromourl] = useState(null);
          const [coverurl,setcoverurl] = useState(null)
const [mode,setmode] = useState("live")

      const [status,setstatus]= useState("pending")
const [addstatus,setaddstatus] = useState(false)
      const [snackbar,setsnackbar] = useState(false);
      const [snackbarMessage,setsnackbarMessage] = useState(null)
      
   const [syllabus,setsyllabus] = useState(null)



const [batchid,setbatchid] = useState(null)

const [coursestatus,setcoursestatus] = useState(null)
const [registrationends,setregistrationends] = useState(null)
const [classstarts,setclassstarts] =useState(null)
const [classends,setclassends] = useState(null) 
const [students,setstudents] = useState(null)
const [ratings,setratings] = useState(null)
const [duration,setduration] = useState(null)
const [cutoffprice,setcutoffprice] = useState(null)
      const updateCourse = async (e)=>{
      
e.preventDefault();
        const formData = new FormData();
        
        
        //formData.append('whyus',document.getElementById('whyus').value)
        
        formData.append('session',user.SessionId);
        formData.append('course_id',courseid);
          formData.append('status',status);
          formData.append('course_url',courseurl);
          formData.append('syllabus_url',syllabus);
          formData.append('batch_id',batchid);
          formData.append('mode',mode);
          formData.append('registration_ends',registrationends);
          formData.append('seats_left',seatsleft);
          formData.append('next_batch',nextbatch);
          formData.append('rating',crating);
          formData.append('ratings',ratings);
          formData.append('students',students);
        
          formData.append('duration',duration);
        
          formData.append('class_starts',classstarts);
        
          formData.append('class_ends',classends);
        
          formData.append('cutoff_price',cutoffprice);
        
          formData.append('promo_url',promourl);
        
         
       
        const res = await axios.post(ENDPOINT+"admin-add-batch.php", formData,{
          
        
        headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
           // console.log(JSON.stringify(res))
         if(res.data.status==="success"){
           setsnackbarMessage("batch added")
         setsnackbar(true)
         }
         else{
        
         }
        }
        }
        
          



         


     
          const options = ['Option 1', 'Option 2'];
          
        
            const [value, setValue] = React.useState(options[0]);
            const [inputValue, setInputValue] = React.useState('');
          
   



      



    return (

<>
<>

<div className="container-fluid sm-p-15 pt-5">
            
            {/*account form*/}
          
            
        <>
<div className="flex-wrap ">
            
            <div className="">
            
            
            
            <form method="POST" >
            
         
            <div class="form-row">
                <div class="form-group col-lg-10">
     
<div className="flex mt-3">

<TextField label="Batch ID" 
className="w-100" 
value={batchid} onChange={(e)=>setbatchid(e.target.value)}  
 />


<FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Course Mode</InputLabel>
      
<Select
  
  value={mode}
  label="Batch Mode"
  onChange={(e)=>setmode(e.target.value)}
>
  <MenuItem value={"live"}>Online</MenuItem>
  <MenuItem value={"offline"}>Offline</MenuItem>
  <MenuItem value={"selfpaced"}>Selfpaced</MenuItem>
  <MenuItem value={"specialization"}>Specialization</MenuItem>
</Select>
      </FormControl>







<FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Batch Status</InputLabel>
      
<Select
  
  value={status}
  label="Batch Status"
  onChange={(e)=>setstatus(e.target.value)}
>
  <MenuItem value={"draft"}>Draft</MenuItem>
  <MenuItem value={"active"}>Active</MenuItem>
  <MenuItem value={"paused"}>Paused</MenuItem>
  <MenuItem value={"completed"}>Completed</MenuItem>
</Select>
      </FormControl>





</div>






<div className="flex mt-3">

<TextField label="Registration Ends" 
className="w-100" 
type="date"
InputLabelProps={{
  shrink: true,
}}
value={registrationends} onChange={(e)=>setregistrationends(e.target.value)}  
 />


<TextField label="Class Starts" 
className="w-100" 
type="time"
InputLabelProps={{
  shrink: true,
}}
value={classstarts} onChange={(e)=>setclassstarts(e.target.value)}  
 />
  <TextField label="Class Ends" 
className="w-100" 
type="time"
InputLabelProps={{
  shrink: true,
}}
value={classends} onChange={(e)=>setclassends(e.target.value)}  
 />            

</div>



<div className="flex mt-3">

<TextField label="Seats Left" 
className="w-100" 
InputLabelProps={{
  shrink: true,
}}
value={seatsleft} onChange={(e)=>setseatsleft(e.target.value)}  
 />


<TextField label="Total Students" 
className="w-100" 
value={students} onChange={(e)=>setstudents(e.target.value)}  
 />
  <TextField label="Average Rating" 
className="w-100" 
value={crating} onChange={(e)=>setcrating(e.target.value)}  
 />            

</div>










<div className="flex mt-3">

<TextField label="Duration" 
className="w-100" 
value={duration} onChange={(e)=>setduration(e.target.value)}  
 />


<TextField label="Total Ratings" 
className="w-100" 
value={ratings} onChange={(e)=>setratings(e.target.value)}  
 />

 
<TextField label="Cutoff price" 
className="w-100" 
value={cutoffprice} onChange={(e)=>setcutoffprice(e.target.value)}  
 />

</div>
















<TextField label="Promo Link" 
className="w-100 mt-3" 
value={promourl} onChange={(e)=>setpromourl(e.target.value)}  
 />            


<TextField label="Syllabus Link" 
className="w-100 mt-3" 
value={syllabus} onChange={(e)=>setsyllabus(e.target.value)}  
 />            




                   </div>
    

          
             
   

<Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={()=>setsnackbar(false)}
        message={snackbarMessage}
     
      />






            
             
</div>

           
           
            
            
     
            
            
           
            
             
            
            
           
              <button type="submit" class="btn btn-primary w-100"  onClick={updateCourse} >{addstatus?<CircularProgress className="text-white" size={20}/> :""}Save Changes</button>
            </form>
            
            </div>
  
            
            
            
                        </div>

        </>

       
                           {/*account form*/}
        </div>
</>


</>


 
    )
}

export default AddNewBatch
