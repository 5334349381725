import axios from "axios";
import ENDPOINT from "../../context/EndPoint";

const SendOTP = async ({ payload }) => {
    const formData = new FormData();
    formData.append("session", payload.SessionId);
    formData.append("user_email", payload.user_email);

    const res = await axios.post(ENDPOINT + "admin-send-otp.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res) {

        return res.data
    }
   
  };
  export default SendOTP