import Avatar from '@mui/material/Avatar';
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import AdminDeleteTickets from '../../actions/student/AdminDeleteTickets';
import { Check, Delete, MoreVert } from '@material-ui/icons';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminUpdateDoubtRequest from '../../actions/support/AdminUpdateDoubtRequest';
import { AuthContext } from '../../context/AuthContext';
import { Pending } from '@mui/icons-material';
import DefaultToast from '../../components/toasts/DefaultToast';

import { useTeamData } from '../../actions/team/TeamContext';
import { useActionsContext } from '../../context/ActionsContext';
import NoPermissionModal from '../../components/modals/NoPermissionModal';
import axios from 'axios'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import { useDispatch, useSelector } from 'react-redux';
import defaultUser from '../../assets/images/users/user-dummy-img.jpg'
import StatusChip from '../../components/StatusChip';
const StudentTicketItem = ({ item, Reload, options }) => {
  const notification = useSelector((state) => state.sendNotification.notification)
  const { user } = useContext(AuthContext)
  const [deletemodal, setdeletemodal] = useState(false)
  const [toastdata, setoastdata] = useState(null)
  const [toast, settoast] = useState(false)
  const { permission } = useActionsContext()
  const [nopermission, setnopermission] = useState(false)

  const { deleting, setdeleting } = useActionsContext();


  // console.log(notification);



  useEffect(() => {

  }, [])
  const [deletedoubtrequestresponse, setdeletedoubtrequestresponse] = useState(null)


  const DeleteDoubtRequest = async ({ payload }) => {
    setdeleting(true)
    try {
      const data = new FormData();

      data.append('id', item.id);
      data.append("session", user.SessionId)


      const res = await axios.post(ENDPOINT + 'admin-delete-doubt-request.php', data, SecurityHeaders);

      if (res) {
        setdeletemodal(false)
        setdeletedoubtrequestresponse(res.data)
        if (res.data.status === "success") {
          setdeletemodal(false)
        }
      }

    }
    catch (error) {

    }
  }

  useEffect(() => {
    setoastdata(deletedoubtrequestresponse)
    if (deletedoubtrequestresponse && deletedoubtrequestresponse.status === "success") {
      Reload()
      setdeletemodal(false)
    }
  }, [deletedoubtrequestresponse])
  const UpdateRequest = async ({ status }) => {


    const res = await AdminUpdateDoubtRequest({
      payload: {
        notification: notification,
        session: user.SessionId,
        id: item.id,
        status: status
      }
    })
    if (res) {

      setoastdata(res.data)
      if (res.status === "success") {

        Reload()
      }
      else {


      }

    }
  }
  const [deleteResponse, setDeleteResponse] = useState(null)
  const [updateResponse, setUpdateResponse] = useState(null)




  return (
    <>
      <DefaultToast toastdata={toastdata} show={toast} />
      <DeleteModal deleting={deleting} show={deletemodal} HandleClose={() => setdeletemodal(false)} HandleDelete={() => DeleteDoubtRequest({ payload: { id: item.id } })} />
      <NoPermissionModal show={nopermission} HandleClose={() => setnopermission(false)} />

      {/* <tr>

        {options?.user === false ? null : <td className='col-lg-3'>
          <div className="d-flex  align-items-center">
            <div className="flex-shrink-0 me-2">
              <Avatar src={item.profile_pic ? item.profile_pic : null} />
            </div>
            <div className="flex-grow-1 ">
              <h5> {item.first_name + " " + item.last_name}</h5>

            </div>
          </div>
        </td>}
        {options?.course === false ? null : <td>{item.course_url}</td>}
        <td>    <p>{item.create_datetime}</p></td>
        <td>
          <span className="badge bg-success-subtle text-success">
            {item.status}
          </span>
        </td>

        <td>
          <Dropdown className="options-dropdown">
            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
              <MoreVert className="text-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>


              <Dropdown.Item onClick={() => { parseInt(permission.initiate_student_support) ? UpdateRequest({ status: "initiated" }) : setnopermission(true) }}   ><Pending /> Initiate</Dropdown.Item>

              <Dropdown.Item onClick={() => { parseInt(permission.close_student_support) ? UpdateRequest({ status: "closed" }) : setnopermission(true) }} ><Check />Closed</Dropdown.Item>

              <Dropdown.Item onClick={() => { parseInt(permission.delete_student_support) ? setdeletemodal(true) : setnopermission(true) }} ><Delete />Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        {options?.view === false ? null : <td>
          <Link to={"/students-support/" + item.id} className="btn btn-outline-primary">View</Link>
        </td>}


      </tr> */}
  
      <div className=" col-12">

        <div className="card border border-1 rounded mb-2">

          <div className="card-body p-2 p-md-3">

            <div className="d-flex align-items-center">

              <div className="col-lg-3 col-md-3 col-sm-12 col-12 d-flex justify-content-between">
                <Link to={"/students-support/" + item.id} className='d-flex align-items-center w-100'>
                  <div className="flex-shrink-0">

                    <div className="avatar-xs rounded"> 
                      <img
                        src={item.profile_pic ? item.profile_pic : defaultUser}
                        alt=""
                        className="member-img img-fluid d-block rounded"
                      />
                    </div>
                  </div>
                  <div className=" my-md-3 my-0 ms-1 ms-md-3  w-100">

                      <h5 className="fs-16 fs-sm-14 mb-0">{item.first_name + " " + item.last_name}</h5>
                    
                    <p className="text-muted mb-0 d-md-none d-sm-block d-none  fs-sm-10">   {item.course_url}</p>

                    {options?.date === false ? <></> :
                      <div className="gap-2 align-items-center d-md-none d-sm-block d-block">
                        <div className="text-muted fs-sm-10">{item.create_datetime}</div>
                      </div>}

                  </div>
                </Link>


                <div className='col-sm-2 d-md-none d-sm-block d-flex flex-row-reverse flex-md-row text-end'>
                  <Dropdown className="options-dropdown mb-2">
                    <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
                      <MoreVert className="text-dark" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>


                      <Dropdown.Item onClick={() => { parseInt(permission.initiate_student_support) ? UpdateRequest({ status: "initiated" }) : setnopermission(true) }}   ><Pending /> Initiate</Dropdown.Item>

                      <Dropdown.Item onClick={() => { parseInt(permission.close_student_support) ? UpdateRequest({ status: "closed" }) : setnopermission(true) }} ><Check />Closed</Dropdown.Item>

                      <Dropdown.Item onClick={() => { parseInt(permission.delete_student_support) ? setdeletemodal(true) : setnopermission(true) }} ><Delete />Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>


                  <div className=''>
                <StatusChip item={item}/>
                  </div>
                </div>





              </div>


              <div className="col-lg-3 col-md-3 d-md-block d-sm-none d-none gap-3 mt-0 text-muted">

                <p className="text-muted mb-0">   {item.course_url}</p>
              </div>


              {options?.date === false ? <></> :

                <div className="col-lg-3 col-md-3 d-md-block d-sm-none d-none gap-2 align-items-center my-3 my-lg-0">


                  <div className="text-muted fs-sm-10">{item.create_datetime}</div>
                </div>}


              <div className='col-lg-1 col-md-1 col-sm-2 d-md-block d-sm-none d-none'>
                <StatusChip item={item}/>
              </div>


              {/* {options?.batch === false ? <></> :

                <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-3 my-lg-0">


                  <div className="text-muted">{item.batch_id + " - " + item.team_id}</div>
                </div>} */}





              <Dropdown className="options-dropdown col-lg-1 col-md-1 d-md-block d-sm-none d-none">
                <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
                  <MoreVert className="text-dark" />
                </Dropdown.Toggle>
                <Dropdown.Menu>


                  <Dropdown.Item onClick={() => { parseInt(permission.initiate_student_support) ? UpdateRequest({ status: "initiated" }) : setnopermission(true) }}   ><Pending /> Initiate</Dropdown.Item>

                  <Dropdown.Item onClick={() => { parseInt(permission.close_student_support) ? UpdateRequest({ status: "closed" }) : setnopermission(true) }} ><Check />Closed</Dropdown.Item>

                  <Dropdown.Item onClick={() => { parseInt(permission.delete_student_support) ? setdeletemodal(true) : setnopermission(true) }} ><Delete />Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>



              {options?.view === false ? null :
                <div className='col-lg-1 col-md-1  d-md-block d-sm-none d-none'>
                  <Link to={"/students-support/" + item.id} className="btn btn-outline-primary">View</Link>
                </div>}


              {/* <div className='d-flex d-md-none d-sm-flex justify-content-between align-items-center'>
                
                <div className=''>
                  <span className="badge bg-success-subtle text-success">
                    {item.status}
                  </span>
                </div>

                {options?.view === false ? null :
                <div className=''>
                  <Link to={"/students-support/" + item.id} className="btn btn-outline-primary">View</Link>
                </div>}

              </div> */}


            </div>
            <hr className=' d-md-none my-1'></hr>
         
            <p className="text-muted d-md-none fs-sm-10 mb-0">   {item.course_url}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentTicketItem