import React from 'react'
import ENDPOINT from '../../context/EndPoint'
import styled from 'styled-components'
import InternshipItem from './InternshipItem'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import Localbase from 'localbase'
import { AuthContext } from '../../context/AuthContext'
const InternshipsList = ({ page}) => {

const {user} = useContext(AuthContext)
const userdata = user;
let db = new Localbase('db')
useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    const [internships,setinternships] = useState(null);

    useEffect(()=>{
     
    
    
    getinternships();
           
    }
    
    ,[])
    
    const  getinternships = async ()=>{
      
        if (userdata!==null){

            fetch(`${ENDPOINT}get-internships.php?session=${user.SessionId} `)
            .then(r=>r.json())
            .then(result=>{

                if(result.status==="success"){
                    setinternships(result.data);
                   
             

                }
                
               
                
            })
        }
        else{
            fetch(`${ENDPOINT}get-internships.php`)
            .then(r=>r.json())
            .then(result=>{
        
                if(result.status==="success"){
                    setinternships(result.data);
                   
              
                }
            
                
            })
        }
          
            
            }    ;
    
    
    
    











    return (
      <>
      
      {/*end row*/}
      <div className="row">
              
              <div className="col-lg-3 col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-arrow-down-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
Initiated                           </p>
                          <h4 className=" mb-0">
                            
                            <span
                              className="counter-value"
                              data-target="14799.44"
                            >
                         df34
                            </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="ri-arrow-down-s-fill align-middle me-1" />
                           23  %<span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}



              



                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-arrow-down-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
Closed                     </p>
                          <h4 className=" mb-0">
                            
                            <span
                              className="counter-value"
                              data-target="14799.44"
                            >
                           23
                            </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="ri-arrow-down-s-fill align-middle me-1" />
23 %<span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}



              

                 {/* end col */}
                 <div className="col-lg-3 col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-arrow-down-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
Total                      </p>
                          <h4 className=" mb-0">
                            
                            <span
                              className="counter-value"
                              data-target="14799.44"
                            >
                           23
                            </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="ri-arrow-down-s-fill align-middle me-1" />
                           <span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}



    {/* end col */}
    <div className="col-lg-3 col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-arrow-down-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
Avg Resolution Time                         </p>
                          <h4 className=" mb-0">
                            
                            <span
                              className="counter-value"
                              data-target="14799.44"
                            >
                          23    </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="ri-arrow-down-s-fill align-middle me-1" />
                           %<span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}




              </div>{" "}
              {/* end row*/}

      
              <div className="internships-list">





{ internships?internships.map((internship,index)=>(


<InternshipItem className="fadein" key={index} internship={internship} userdata={userdata}/>
)):
<div className="container-fluid">



    </div>

}





</div>

      
      </>


    )
}

export default InternshipsList


export const InternshipsStyled = styled.div`
.internships-list{
    
    padding:90px 50px 0px 50px;
}
.internships-page{
    display: flex;
 
}
.internships-page{
        margin-top: -65px;
    }

@media only screen and (max-width:900px){

    .internships-list{
        width: 100%;
        padding: 80px 15px !important;
        padding-bottom:0px !important;
       
    }
    .internship-item{
        width: 100%;
    
    }
    .internships-page{
        margin-top: -40px;
    }
    .internships-page{
  padding-bottom: 50px;
 
}
}
@media only screen and (max-width:767px){
    .internships-page{
        margin-top: -80px;
    }
    .internships-list{
        width: 100%;
        padding-bottom:30px !important;
    }
}

`