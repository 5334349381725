import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import SecurityHeaders from '../../context/SecurityHeaders';
import ENDPOINT from '../../context/EndPoint';
import { AuthContext } from '../../context/AuthContext';
import { Col, Modal, Table } from 'react-bootstrap';
import { UploadFile } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Avatar, Checkbox } from '@mui/material';
import DeleteModal from '../../components/modals/DeleteModal';
import TeamMemberItem from './TeamMemberItem';
import Localbase from 'localbase';

const AllTeamMembers = () => {
  const db = new Localbase('db')
    const { user } = useContext(AuthContext);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const [team,setteam] = useState([])
    const getteam = async() =>{
        const data = new FormData();
        var res = await axios.post(ENDPOINT+"get-team-members.php",data, SecurityHeaders)
        if (res){
            console.log("team",res.data.data)
            setteam(res.data.data)
            db.collection('paths').add({support_team:res.data.data},'support_team')
        }
    }
    useEffect(()=>{
        getteam();
    },[])

   
    const Refresh = () =>{
      getteam();
    }
    
    useEffect(()=>{
      db.collection('paths').doc('suport_team').get().then((data)=>{
        console.log(data)
        setteam(data.support_team)
      }).catch((err)=>console.log(err))
    },[])


    

  return (
    <>
        
      <div className="container-fluid pt-2">
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">All Team Members</h5>
          <Link to="/team-members-all/add-member" className='btn btn-danger w-fit h-fit' >Add new Member</Link>
          {/* <div className="card-body border border-dashed border-end-0 border-start-0">
            <form>
              <div className="row g-4">
                <div className="col-xxl-5 col-sm-6">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control search"
                      placeholder="Search for name, designation, category."
                    />
                    <i className="ri-search-line search-icon" />
                  </div>
                </div>
                <div className="col-xxl-2 col-sm-6">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      data-provider="flatpickr"
                      data-date-format="d M, Y"
                      data-range-date="true"
                      id="demo-datepicker"
                      placeholder="Select date"
                    />
                  </div>
                </div>
                <div className="col-xxl-2 col-sm-4">
                  <div>
                    <select
                      className="form-control"
                      data-choices=""
                      data-choices-search-false=""
                      name="choices-single-default"
                      id="idStatus"
                    >
                      <option value="">Status</option>
                      <option value="all" selected="">
                        All
                      </option>
                      <option value="Active">Active</option>
                      <option value="Offline">Offline</option>
                    </select>
                  </div>
                </div>
                <div className="col-xxl-3 col-sm-4">
                        <div className='d-flex'>
                          <button
                            type="button"
                            className="btn me-2 btn-primary "
                            onclick="SearchData();"
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>

                          
                         
                        </div>
                      </div>
              </div>
            </form>
          </div> */}
        </div>
        <>


        <div className="row mt-3">
        {team ? (
            team.map((item) => (

                <TeamMemberItem item={item} Refresh = {Refresh}/>



                
            ))
        ) : (
            <div className="col-12 text-center">No team members</div>
        )}
        </div>

       


    
</>


        </div>
    </>
  )
}

export default AllTeamMembers