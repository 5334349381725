import React, { useContext, useState } from 'react'
import CustomModal from './CustomModal'
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import BasicToast from '../toasts/BasicToast';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';

const EditInternMeetingTopipc = ({visibility,handleClose,item,value,dispatch}) => {
 

    const [title, settitle] = useState(item.title);
    const [starts, setstarts] = useState(item.class_starts);
    const [ends, setends] = useState(item.class_ends);
    const [date, setdate] = useState(null);
    const [topics, settopics] = useState(item.description);
    const [meetinglink, setmeetinglink] = useState(item.meeting_link);
  const {user} = useContext(AuthContext)
    const RescheduleClass = async () => {
        const formData = new FormData();
    
        //formData.append('whyus',document.getElementById('whyus').value)
        formData.append("session", user.SessionId);
    
        formData.append("course_url", item.course_url);
        formData.append("title", title);
        formData.append("id",item.id)
        formData.append("description", topics);
    
      
      
    
        const res = await axios.post(
          ENDPOINT + "edit-intern-meeting-topic.php",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
        if (res) {
          if (res.data.status === "success") {
            handleClose()
            dispatch({type:'FUNCTION_RELOAD',payload:'get-intern-meetings'});
      
        
          } else {
        
         
          }
          setoastdata({title:res.data.title,message:res.data.message})
          settoast(true);
        }
      };
    

      const [toast,settoast] = useState(false)
      const [toastdata,setoastdata] = useState({title:null,message:null})
  return (
   <>
   <BasicToast ToastHandleClose={()=>settoast(false)} visibility={toast} item={toastdata}/>
   <CustomModal visibility={visibility}  handleClose={handleClose}>

<div className="d-flex justify-content-between">
    <h4>Edit Class Topic</h4>
    <Close className='click' onClick={handleClose}/>
</div>
<hr></hr>
            <p>Title :</p>
            <select value={title}  onChange={(e)=>{settitle(e.target.value)}} className="form-control" >
     <option>Select Options</option>
<option value="General Meeting">General Meeting</option>
<option value="Project Discussion">Project Discussion</option>
<option  value="Review Meeting">Review Meeting</option>
<option  value="Interactive Session">Interactive Session</option>
     </select>
          
            <br />
            <p>Description</p>
            <input
              className="form-control"
              value={topics}
              onChange={(e) => {
                settopics(e.target.value);
              }}
            ></input>
        

   

            <button className="btn btn-primary w-100 mt-3" onClick={RescheduleClass}>
         Save Changes
            </button>
         

   </CustomModal>
   </>
  )
}
const reload = (prop)=>({
  value:prop.modalHandler
})

export default connect(reload) (EditInternMeetingTopipc)