const ModalStyle = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     bgcolor: 'background.paper',
     border: '0.5px solid #000',
     boxShadow: 1,
  
  
   };
export default ModalStyle;