import React from 'react'
import { Link } from 'react-router-dom'
import defaultUser from '../../assets/images/users/user-dummy-img.jpg'
import StudentListItemsm from './StudentListItemsm'

function StudentListItem({ item, options }) {
  return (
    <>
      {/* large & medium devices */}
      <div className="col-md-6 col-lg-12 d-md-block d-sm-none d-none mb-2">

        <div className="card mb-0">

          <div className="card-body">

            <div className="d-lg-flex align-items-center">

              <div className="col-lg-3 d-flex">
                <div className="flex-shrink-0">

                  <div className="avatar-sm rounded">
                    <img
                      src={item.profile_pic ? item.profile_pic : defaultUser}
                      alt=""
                      className="member-img img-fluid d-block rounded"
                    />
                  </div>
                </div>
                <div className="ms-lg-3 ms-md-2 ms-2 my-0">

                  <a href="pages-profile.html">
                    <h5 className="fs-16 mb-2">{item.first_name + " " + item.last_name}</h5>
                  </a>
                  <p className="text-muted mb-0"> st_id : {item.purchase_id}</p>
                </div>
              </div>
              <div className="d-flex col-lg-3 gap-4  text-muted mx-lg-auto">

                <div className='mt-sm-2 mt-2 mt-md-2 mt-lg-0'>
                  {/* <i className=" text-primary  me-1 align-bottom" /> */}
                  <p className="text-muted text-lg-center mb-0">   {item.course_url}</p>


                  <p className="text-muted mb-0">{item.mode}</p>


                </div>
                <div>

                </div>
              </div>


              {options?.batch === false ? <></> :

                <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-2 my-lg-0">


                  <div className="text-muted">{item.batch_id + " - " + item.team_id}</div>
                </div>}

              {options?.date === false ? <></> :

                <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-2 my-lg-0">


                  <div className="text-muted fs-sm-12">{item.create_datetime}</div>
                </div>}
              <div>

                <Link to={"/student-profile/" + item.purchase_id} className="btn btn-soft-success">
                  View Details
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>




      {/* small devices */}
      <div className="col-sm-6 col-6 d-md-none d-sm-block d-block text-center mb-2 px-1">

        <div className="card mb-0">

          <div className="card-body p-0">

            <div className="">
              <div className="student-cover rounded-top">
                {/* <p className="left-10 position-absolute top-10 text-light"></p> */}
                <p className="text-muted text-start mb-0 p-2 sz-12">{item.mode}</p>


              </div>
              <div className="student-avatar-container img-sm position-relative mx-auto">
                <img

                  src={item.profile_pic ? item.profile_pic : defaultUser}
                  className="member-img w-100 img-fluid d-block rounded rounded-5 border border-2 border-primary-subtle"
                />
              </div>

              {/* {options?.date === false ? <></> :

                <div className="my-2">


                  <p className="text-muted text-start mb-0 sz-12">{item.mode}</p>
                </div>} */}

              <div className='px-2'>
                <div className="col-sm-12 col-12">

                  <div className=" my-0 mt-2">

                    <a href="pages-profile.html">
                      <h6 className=" mb-2">{item.first_name + " " + item.last_name}</h6>
                    </a>

                  </div>
                </div>
                <div className="col-12 gap-4  text-muted">

                  <div className='mt-sm-2 mt-2 mt-md-2 mt-lg-0'>

                    <p className="text-muted text-center mb-0 sz-12">   {item.course_url}</p>
                    {/* <p className="text-muted mb-0 sz-12">{item.purchase_id}</p> */}




                  </div>
                  <div>

                  </div>
                </div>


                {options?.batch === false ? <></> :

                  <div className="col-12 mx-auto my-2">
                    {/*<div className="text-muted fz-12 text-start">{item.create_datetime}</div> */}
                    <div className="text-muted sz-12">{item.batch_id + " - " + item.team_id}</div>

                  </div>}
              </div>



              {/* {options?.date === false ? <></> :

                <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-2 my-lg-0">


                  <div className="text-muted ">{item.create_datetime}</div>
                </div>} */}
              <div>

                <Link to={"/student-profile/" + item.purchase_id} className="btn btn-primary rounded-pill px-2 py-1 mb-2">
                  View Details
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>




    </>
  )
}

export default StudentListItem