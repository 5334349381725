import React, { useContext, useEffect, useState } from 'react'
import DemoData from '../../data/DemoData'
import StudentListItem from './StudentListItem'
import AdminGetStudentsList from '../../actions/student/AdminGetStudentsList';
import { AuthContext } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import ENDPOINT from '../../context/EndPoint';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useActionsContext } from '../../context/ActionsContext';
import Localbase from 'localbase';
const StudentsList = ({ page, course, props, options }) => {

  const { user } = useContext(AuthContext);
  const userdata = user;
  const db = new Localbase("db")
  const { voiceinput } = useActionsContext()
  const params = useParams();
  const url = params.course_url;

  const [category, setcategory] = useState("all");
  const [query, setquery] = useState(null);
  const {searchquery} = useActionsContext()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState(null);

  const searchStudent = () => {
    if (input.length > 4) {
      StudentsList();
    }
  };

  const [loading, setloading] = useState(false)
  const StudentsList = async () => {
    setloading(true)
    const res = await AdminGetStudentsList({
      payload: {
        session: user.SessionId,
        mode: mode,
        query: query,
        url: url ? url : null,
        limit: options?.limit ? options?.limit : null
      },
    });

    if (res) {
      setloading(false)
      if (res.status === "success") {

        db.collection("paths").add({students:res},"students")
        setinternships(res);
        setInitialData(res.data);
        setResponse(res.data);

      } else {
      }
    }
  };

  useEffect(() => {

    db.collection("paths").doc("students").get().then(data=>{
      if(data){
        setinternships(data.students);
      setInitialData(data.students.data);
      setResponse(data.students.data);
      }
    })
    StudentsList();
  }, [category]);

  const [mode, setmode] = useState("all");

  const [AddPoints, setAddPoints] = useState(false);

  const showAddPoints = () => {
    setAddPoints(true);
  };

  const closeAddPoints = () => {
    setAddPoints(false);
  };

  const [input, setinput] = useState(null);

  const [editstats, seteditstats] = useState(false);
  const [studentid, setstudentid] = useState(null);
  const [reward, setreward] = useState(false);
  const editStatistics = (studentid) => {
    setstudentid(studentid);

    seteditstats(true);
  };

  const [studentrewards, setstudentrewards] = useState(null);

  //delete task code

  const GiftReward = async ({ student_id }) => {
    setreward(true);
    setstudentid(student_id);
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("student_id", student_id);

    const res = await axios.post(
      ENDPOINT + "admin-student-badges.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      ////  // console.log("badges "+JSON.stringify(res))
    }
    if (res.data.status === "success") {
      // console.log(res.data.badges);
      setstudentrewards(res.data.badges);
    } else {
    }
  };

  const UnlockReward = async ({ badge_id }) => {
    setreward(true);
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("student_id", studentid);
    formData.append("badge_id", badge_id);

    const res = await axios.post(
      ENDPOINT + "admin-gift-personal-badge.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      // //  // console.log("unloacked "+JSON.stringify(res))
    }
    if (res.data.status === "success") {
      GiftReward({ student_id: studentid });
    } else {
    }
  };
  useEffect(()=>{
   
      handleSearch({term:searchquery})
    
           
  },[searchquery])
  const [sendnotification, setsendnotification] = useState(false);

  const [initialData, setInitialData] = useState("");

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = ({ term }) => {
    if(!term)
    {
      console.log("no search term")
      setResponse(initialData)
      setSearchTerm("")
      return
    }
    if(term)
    {
      term = term.replace(" ", '')
      // console.log("seaching |"+term)
      const filteredData = initialData.filter((item) => {
        return (
          item.user_email.toLowerCase().includes(term) ||
  
          item.first_name.toLowerCase().includes(term) ||
          item.last_name.toLowerCase().includes(term) ||
          item.batch_status.toLowerCase().includes(term) ||
          item.purchase_datetime.includes(term) ||
          item.batch_id.includes(term) ||
          item.mode.toLowerCase().includes(term) ||
          item.course_url.toLowerCase().includes(term)
  
        );
      });
      setResponse(filteredData);
      setSearchTerm(term);
    }



  };


  const { hearing } = useActionsContext()


  useEffect(()=>{
    console.log(voiceinput)
    if(initialData){
      if(voiceinput.includes("searchfor")||voiceinput.includes("search")){
    
        var name = voiceinput
        if(voiceinput.includes("searchfor")){
         name =  voiceinput.replace("searchfor","")
        }
        else if (voiceinput.includes("search")){
        name =  voiceinput.replace("search","")
        }
      
    
    
        
        handleSearch({term:name})
        
        }
    }
      },[voiceinput])
  useEffect(() => {
    if (searchTerm) {
      handleSearch({ term: searchTerm })
    }
  }, [searchTerm,voiceinput])





  
  return (
    <>

      <div className="container-fluid">

        {options?.search === false ? <></> : <>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-6 px-sm-2 px-1">
              <div className="card">
                <div className="card-body px-sm-2 px-2">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                        <i className="ri-arrow-down-circle-fill align-middle" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-sm-3 ms-1">
                      <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                        online students{" "}
                      </p>
                      <div className="flex-shrink-0 d-flex justify-content-between align-itens-center">
                        <h4 className=" mb-0">
                          <span className="counter-value" data-target="14799.44" >
                            {internships ? internships.online_count.online_students : null}
                          </span>
                        </h4>
                        <span className="badge bg-danger-subtle text-danger my-1">
                          <i className="ri-arrow-down-s-fill align-middle me-1" />
                          4.80 %
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}

            {/* end col */}
            <div className="col-lg-3 col-md-3 col-6  px-sm-2 px-1">
              <div className="card">
                <div className="card-body px-sm-2 px-2">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                        <i className="ri-arrow-down-circle-fill align-middle" />
                      </span>
                    </div>
                    <div className="flex-grow-1  ms-sm-3 ms-1">
                      <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                        offline students{" "}
                      </p>
                      <div className="flex-shrink-0 d-flex justify-content-between align-items-center">
                        <h4 className=" mb-0">
                          <span className="counter-value" data-target="14799.44">
                            {internships ? internships.offline_count.offline_students : null}
                          </span>
                        </h4>
                        <span className="badge bg-danger-subtle text-danger my-1">
                          <i className="ri-arrow-down-s-fill align-middle me-1" />
                          4.80 %<span> </span>
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}

            {/* end col */}
            <div className="col-lg-3 col-md-3 col-6 px-sm-2 px-1">
              <div className="card">
                <div className="card-body px-sm-2 px-2">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                        <i className="ri-arrow-down-circle-fill align-middle" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-sm-3 ms-1">
                      <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                        Selfpaced students{" "}
                      </p>
                      <div className="flex-shrink-0 d-flex justify-content-between align-items-center">
                        <h4 className=" mb-0">
                          <span
                            className="counter-value" data-target="14799.44">
                            {internships ? internships.selfpaced_count.selfpaced_students : null}
                          </span>
                        </h4>
                        <span className="badge bg-danger-subtle text-danger my-1">
                          <i className="ri-arrow-down-s-fill align-middle me-1" />
                          4.80 %<span> </span>
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}

            {/* end col */}
            <div className="col-lg-3 col-md-3 col-6 px-sm-2 px-1">
              <div className="card">
                <div className="card-body px-sm-2 px-2">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                        <i className="ri-arrow-down-circle-fill align-middle" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-sm-3 ms-1">
                      <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                        specialization students{" "}
                      </p>
                      <div className="flex-shrink-0 align-items-center d-flex justify-content-between">
                        <h4 className=" mb-0">
                          <span
                            className="counter-value"
                            data-target="14799.44"
                          >
                            {internships ? internships.specialization_count.specialization_students : null}
                          </span>
                        </h4>
                        <span className="badge bg-danger-subtle text-danger my-1">
                          <i className="ri-arrow-down-s-fill align-middle me-1" />
                          4.80 %<span> </span>
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>

          {/* end page title */}
          
          {/* Previous Search Filters */}
          {/* <div className="card d-none d-sm-block d-md-block d-lg-block d-xl-block">
            <div className="card-body border border-dashed border-end-0 border-start-0">
              <form>
                <div className="row g-3">
                  <div className="col-xxl-3 col-sm-4">
                    <div className="search-box">
                      <input
                        onChange={(e) => setSearchTerm(e.target.value)}
                        type="text"
                        className="form-control search"
                        placeholder="Search student name, course batch id"
                      />
                      <i className="ri-search-line search-icon" />
                    </div>
                  </div>
                 
                  <div className="col-xxl-2 col-sm-6">
                    <div>
                      <input
                        type="date"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control"
                        data-provider="flatpickr"
                        data-date-format="d M, Y"
                        data-range-date="true"
                        id="demo-datepicker"
                        placeholder="Select date"
                      />
                    </div>
                  </div>
                  
                  <div className="col-xxl-2 col-sm-4">
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        data-choices=""
                        data-choices-search-false=""
                        name="choices-single-default"
                        id="idStatus"
                      >
                        <option value="">Course Mode</option>

                        <option value="live">Online</option>
                        <option value="offline">Offline</option>
                        <option value="specialization">Specialization</option>
                        <option value="selfpaced">Self-peaced</option>
                        <option value="free">Free</option>





                      </select>
                    </div>
                  </div>

                  <div className="col-xxl-3 col-sm-4">
                    <div className='d-flex justify-content-end'>
                      <button
                        type="button"
                        className="btn me-2 btn-primary "
                        onclick="SearchData();"
                      >
                        {" "}
                        <i className="ri-equalizer-fill me-1 align-bottom" />
                        Filters
                      </button>

                      
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
          
          </>}
        <div className="row mb-2" id="candidate-list">

          {response ?
            <>

              {response.map((item, index) => (
                <>
                  <StudentListItem options={options} key={index} item={item} />
                </>
              ))}

            </> :
            <>



            </>}

        </div>
        {/* end row */}
        <div className='pt-4' />
        {/* end row */}
      </div>


    </>)
}

export default StudentsList