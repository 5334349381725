import axios from 'axios';

import ENDPOINT from '../../context/EndPoint';

const AdminEditStudent = async ({payload}) => {

          const formData = new FormData();

           formData.append('session',payload.user);
           formData.append('purchase_id',payload.purchase_id);
           
           formData.append('team_id',payload.team_id);
           
           formData.append('student_id',payload.student_id);
           
           formData.append('batch_id',payload.batch_id);
           
           formData.append('progress',payload.progress);
           formData.append('points',payload.points);
           formData.append('total_marks',payload.total_marks);
           
           
           

           try{
               const res = await axios.post(ENDPOINT+"admin-edit-student.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res.data){
                    return res.data
             
                }
           }
           catch(error){

return error
           }

     




}

export default AdminEditStudent