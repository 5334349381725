const initialState = {
    modal: false,
    modal_name: null,
    reload : false,
    functionName:''
};

const modalReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'MODAL_OPEN':
            return {...state, modal: true,modal_name:action.payload};
        case 'MODAL_CLOSE':
            if (action.payload === state.modal_name) { 
                return {...state, modal: false,modal_name:''};
            } else {
                return state; 
            }
            case 'FUNCTION_RELOAD':
                return {...state,reload:true,functionName:action.payload}
      
        default:
            return state;
    }
};

export default modalReducer;
