import React, { useEffect, useState } from 'react'
import AssignmentSubmissionItem from './AssignmentSubmissionItem'
import DefaultModal from '../../components/modals/DefaultModal'
import AddTeamTask from './AddTeamTask'
import { Close } from '@material-ui/icons'
import { useActionsContext } from '../../context/ActionsContext'
import DetectDevice from '../../context/DetectDevice'
import AssignmentSubmissionItemMobile from './AssignmentSubmissionItemMobile'

const TeamWorkSubmissions = ({tasks,item,options}) => {

const device = DetectDevice()


  const [addtask,setaddtask] = useState(false)
  const [initialData,setInitialData] = useState(tasks)


  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');
  const {searchquery} = useActionsContext()
  useEffect(()=>{
    handleSearch({term:searchquery})
  },[searchquery])
  const handleSearch = ({term}) => {
    if(!term)
    {
      setResponse(initialData)
      setSearchTerm("")
      return
    }
if(term)
{
  const filteredData = initialData.filter((item) => {
    return (

      item.intern.first_name.toLowerCase().includes(term) ||
      item.intern.last_name.toLowerCase().includes(term) ||
      item.title.toLowerCase().includes(term) ||
      item.description.toLowerCase().includes(term) ||
      item.create_datetime.includes(term)||
      item.status.toLowerCase().includes(term)
    );
  });
  
  setResponse(filteredData);
  setSearchTerm(term);
}
  

  };
    

  useEffect(()=>{
setResponse(tasks)
setInitialData(tasks)
  },[tasks])
  return (
<>

<DefaultModal show={addtask} HandleClose={()=>setaddtask(false)}
content={<>
<div className="p-2">
<div className="modal-header">
  <h4>Add Task</h4>
  <Close onClick={()=>setaddtask(false)}/>
</div>
<hr></hr>
<AddTeamTask  HandleClose={()=>setaddtask(false)} item={item} />
</div>
</>}
>


</DefaultModal>
<div className="card">
                      <div className="card-body">
                      {options?.search === false?<></>:
                        <>
                        <div className="d-flex align-items-center mb-4">
                          <h5 className="card-title flex-grow-1 mb-0">
                           Work Historys
                          </h5>
                          <div className="flex-shrink-0">
                          <button className='btn btn-danger w-fit h-fit' onClick={(e)=>{setaddtask(true);e.preventDefault();}}>Add new Task</button>
                       
                          </div>
                        </div>

                        {/* <div className="card-body border border-dashed border-end-0 border-start-0 ">
               
                  <form>
                  <div className="row">
                    <div className="col-xxl-3 col-sm-4 d-none d-sm-block d-md-block d-lg-block d-xl-block'">
                      <div className="search-box">
                        <input
                        onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                          type="text"
                          className="form-control search"
                          placeholder="Search for order ID, customer, order status or something..."
                        />
                        <i className="ri-search-line search-icon" />
                      </div>
                    </div>
                    <div className="col-xxl-2 col-sm-6 d-none d-sm-block d-md-block d-lg-block d-xl-block'">
                      <div>
                        <input
                          type="date"
                          onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                          className="form-control"
                          data-provider="flatpickr"
                          data-date-format="d M, Y"
                          data-range-date="true"
                          id="demo-datepicker"
                          placeholder="Select date"
                        />
                      </div>
                    </div>
                    <div className="col-xxl-2 col-sm-4 d-none d-sm-block d-md-block d-lg-block d-xl-block'">
                      <div>
                        <select
                          className="form-control"
                          onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}

                          data-choices=""
                          data-choices-search-false=""
                          name="choices-single-default"
                          id="idStatus"
                        >
                          
                          <option>
                            All
                          </option>
                          <option value="Pending">Pending</option>
                          <option value="submitted">Submitted</option>
                          <option value="rejected">Rejected</option>
                          <option value="evaluated">Evaluated</option>
                         
                        </select>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-sm-4 d-none d-sm-block d-md-block d-lg-block d-xl-block'">
                      <div>
                        <select
                          className="form-control"
                          data-choices=""
                          data-choices-search-false=""
                          name="choices-single-default"
                          id="idPayment"
                        >
                          <option value="">Select Payment</option>
                          <option value="all" selected="">
                            All
                          </option>
                          <option value="Mastercard">Mastercard</option>
                          <option value="Paypal">Paypal</option>
                          <option value="Visa">Visa</option>
                          <option value="COD">COD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-sm-4">
                      <div className='d-flex'>
                        <button
                          type="button"
                          className="btn me-2 btn-primary d-none d-sm-block d-md-block d-lg-block d-xl-block'"
                          onclick="SearchData();"
                        >
                          {" "}
                          <i className="ri-equalizer-fill me-1 align-bottom" />
                          Filters
                        </button>

                        
                      </div>
                    </div>
                  </div>
                </form>
                </div> */}
                
                </>}

                {device==="mobile"?<>

<div className="row">
{response && response.length > 0 ? (
response.map((item) => (
<AssignmentSubmissionItemMobile options={options}  item={item} />
))
) : (
<div className="text-center">
<p>No submissions found.</p>
</div>
)}
</div>
  
</>:
<>
  <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="table table-borderless align-middle mb-0">
              <thead className="table-light">
                <tr>
                {
                    options?.assigned_by=== false?<></>:
                    <th scope="col">Assigned Time</th>
                  }
            
                 
                  {
                    options?.title=== false?<></>:
                    <th scope="col">Title</th>
                  }
                  
                 
                  {
                    options?.employee=== false?<></>:
                    <th scope="col">Employee</th>
                  }
                    {
                    options?.assigned=== false?<></>:
                    <th scope="col">Assigned Time</th>
                  }
                   {
                    options?.submissions=== false?<></>:
                    <th scope="col">Submission Time</th>
                  }
                 
               
                  <th scope="col">Action</th>
                </tr>
              </thead>
         <tbody>

{response?
<>
{response.map((item)=>(
<>
<AssignmentSubmissionItem options={options}  item={item}/>

</>
))}

</>:
<>

</>}

         </tbody>
            </table>
          </div>
   
        </div>
      </div>
</>

}




                        {/* <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive">
                              <table className="table table-borderless align-middle mb-0">
                                <thead className="table-light">
                                  <tr>
                                  {
                                      options?.assigned_by=== false?<></>:
                                      <th scope="col">Assigned Time</th>
                                    }
                              
                                   
                                    {
                                      options?.title=== false?<></>:
                                      <th scope="col">Title</th>
                                    }
                                    
                                   
                                    {
                                      options?.employee=== false?<></>:
                                      <th scope="col">Employee</th>
                                    }
                                      {
                                      options?.assigned=== false?<></>:
                                      <th scope="col">Assigned Time</th>
                                    }
                                     {
                                      options?.submissions=== false?<></>:
                                      <th scope="col">Submission Time</th>
                                    }
                                   
                                 
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                           <tbody>

{response?
<>
{response.map((item)=>(
    <>
    <AssignmentSubmissionItem options={options}  item={item}/>
    
    </>
))}

</>:
<>

</>}

                           </tbody>
                              </table>
                            </div>
                     
                          </div>
                        </div> */}
                      </div>
                    </div>

</>
  )
}

export default TeamWorkSubmissions