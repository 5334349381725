import React, { useContext } from 'react'

import ModalStyle from '../../context/ModalStyle'
import { Box, Modal } from '@mui/material'
import ENDPOINT from '../../context/EndPoint'
import { AuthContext } from '../../context/AuthContext'
import Localbase from 'localbase'

const LogoutModal = ({show,HandleClose,email,password}) => {

  const {user} =useContext(AuthContext)
const db = new Localbase()
const Logout = ()=>{

       
 
      fetch(`${ENDPOINT}logoutall.php?email=${email}&password=${password}`)
      .then(r=>r.json())
      .then(result=>{
     
     
          if(result[0].status==="success"){
   
            localStorage.setItem("user", JSON.stringify((result[0].data[0])));
                       
            if((sessionStorage.getItem("next")==="null")||(sessionStorage.getItem("next")==="")||(sessionStorage.getItem("next")===null)){
              window.location.replace("/");
          }else{
              window.location.replace("/");
          }
             
              sessionStorage.setItem("next","");
      
  
          }
      else{
       
      }
  
      
          
      })
      }
  
  
  return (
<>


                  <Modal
  open={show}
  onClose={HandleClose}
>
  <Box sx={ModalStyle} className="col-lg-3 p-3 rounded col-sm-12 col-12">
  <div className="modal-content text-center">
                  <h2>login here</h2>
<button className='btn btn-success' onClick={Logout}>Proceed Login</button>
                  
                      </div>
  </Box>
</Modal>
</>
  )
}

export default LogoutModal

