import React, { useEffect, useState, useContext } from 'react';
import { CircularProgress, Snackbar } from '@material-ui/core';

import { Toast } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import SecurityHeaders from '../../context/SecurityHeaders';
import ENDPOINT from '../../context/EndPoint';

const UpdateShort = () => {
  const { user } = useContext(AuthContext);
  const params = useParams();
  const videoId = params.video_id;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coverImg, setCoverImg] = useState("");
  const [category,setCategory] = useState('');
  const [ access,setAccess] = useState('public');
  const [toastTitle,setToastTitle] = useState('');
  const[toastMessage,setToastMessage] = useState('');
  const[toast,setToast] = useState('');
  const [video,setVideo] = useState('')

  // ... (other video-related states)

  const [updateStatus, setUpdateStatus] = useState(false);

  const GetVideo = async ()=>{
    try{
      const data = new FormData();
      data.append('video_id',videoId)


      const res = await axios.post(ENDPOINT+"single-short-video.php",data,SecurityHeaders)
      if(res.data){
      setTitle(res.data[0].title)
     setDescription(res.data[0].description)
     setCoverImg(res.data[0].cover_image)
     setCategory(res.data[0].category)
     setAccess(res.data[0].access)
     setVideo(res.data[0].url)

      }

    }
    catch(error){
       // console.log("fetch video error",error)
    }
  }

 

  const updateVideoDetails = async (e) => {
    e.preventDefault()
    try {
      const data = new FormData()
      
      data.append('video_id',videoId)
      data.append('session',user.SessionId)
      data.append('title',title)
      data.append('description',description)
      data.append('cover_image',coverImg)
      data.append('access',access)
      const res = await axios.post(
        ENDPOINT + "admin-update-video-details.php",data,SecurityHeaders
        
      );

      if (res.data.status === "success") {
        setToastTitle("Update successful");
        setToastMessage("Video details updated successfully");
        setToast(true);
        updateStatus(true)
      } else {
        setToastTitle("Something went wrong!");
        setToastMessage("Please try again...");
        setToast(true);
      }
    } catch (error) {
      console.error("Error updating video details:", error);
      setToastTitle("Error");
      setToastMessage("An error occurred. Please try again.");
      setToast(true);
    }
  };

useEffect(() => {
  GetVideo()
 
}, [])
  return (
    <>

<div className="position-fixed bottom-60 left-0 w-100 justify-center">

<Toast className="bg-warning" onClose={() => setToast(false)} show={toast} delay={4000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastTitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>

</div>
      {/* JSX for the video details form */}
   <div className='d-flex justify-content-between'>
   <div className='col-lg-5 my-auto'>
    <video className='w-100' autoPlay loop muted>
  <source src={video} type="video/mp4"/>
</video>

    </div> 
   <div className='col-lg-6 mt-5'>
      <h1>Update Video Details</h1>
    <form onSubmit={(e)=>updateVideoDetails(e)}>
      <div className="form-group mt-3">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <input
            type="text"
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Cover Image</label>
          <input
            type="text"
            className="form-control"
            value={coverImg}
            onChange={(e) => setCoverImg(e.target.value)}
          />
          
          
        </div>
        <div className="form-group">
          <label>Category</label>
          <input
            type="text"
            className="form-control"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          
        </div>
        <div className="form-group">
          <label>Title</label>
          <select
            className="form-control"
            value={access}
            onChange={(e) => setAccess(e.target.value)}
          >
            <option value="access">access</option>
            <option value="public">public</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary mt-5">
          {updateStatus ? <CircularProgress className="text-white" size={20} /> : ""}
          Update Video Details
        </button>
      </form>
    </div>
   
   </div>


      
    </>
  );
};

export default UpdateShort;
