import axios from 'axios';
import React from 'react'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';

const GetStatistics = async ({payload}) => {




const data = new FormData()
data.append("session",payload.SessionId)

const res = await axios.post(ENDPOINT+"admin-student-management.php",data,SecurityHeaders)
      
if(res){
    if(res.data){

        return res.data
    }
}      
 
}

export default GetStatistics