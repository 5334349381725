import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { Await, Link } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "react-bootstrap";
import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import DeleteIcon from '@mui/icons-material/Delete';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

function AmbassadorListItem({ item }) {

  const[joined,setJoined]=useState(null);
  const[update,setUpdate]=useState(null);

  const campusambassador=async(payload)=>{
      const formData= new FormData()
      formData.append('status',payload);
      formData.append('id',item.id);
      

      const res= await axios.post(ENDPOINT + "cai-update.php",
      formData,
      {
        headers: {
          "content-type":"multipart/form-data",
        },
      }
      );

      

      if(res.data){
         setUpdate(payload);
      }

      
  }

  const deleteambassador = async()=>{
    const formData= new FormData()
    formData.append('id',item.id);
    const res= await axios.post(ENDPOINT + "cai-delete.php",
    formData,
    {
      headers: {
        "content-type":"multipart/form-data",
      },
    }
    ); 

    if(res){
      
    }

    
  }
  
  return (
    <>
      <div className="col-md-6 col-lg-12">
        <div className="card mb-0">
          <div className="card-body">
            <div className="d-lg-flex align-items-center">
              <div className="col-lg-1 flex-shrink-0">
                <div className="avatar-sm rounded">
                  {item.profile_pic ? (
                    <>
                      {" "}
                      <img
                        src={item.profile_pic}
                        alt=""
                        className="member-img img-fluid d-block rounded"
                      />
                    </>
                  ) : (
                    <>
                      <div className="mx-auto">
                        <Avatar className="fs-1 mx-auto" />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-3 ms-lg-3 my-3 my-lg-0">
                <a href="pages-profile.html">
                  <h5 className="fs-16 mb-2">
                    {item.first_name + " " + item.last_name}
                  </h5>
                </a>
                <p className="text-muted mb-0">{item.phone_number}</p>
              </div>

              <div className="col-lg-1">
                <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-3 my-lg-0">
                  <div className="text-muted">20243</div>
                </div>
              </div>

              <div className="col-lg-1">
                <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-3 my-lg-0">
                  <div className="text-muted">T1</div>
                </div>
              </div>

              <div className="col-lg-2">
                <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between mx-auto my-3 my-lg-0">
                  <div className="text-muted">{item.applied_date}</div>
                </div>
              </div>
              <div className="col-lg-3">
                <a
                  href={item.resume_link}
                  target="_blank"
                  className="btn btn-soft-success"
                >
                  View Resume
                </a>


                <Link to={"/campus-ambassadors-profile/" + item.user_id}


                  className="btn btn-soft-success ms-2"
                >
                  View Details
                </Link>
              </div>
              <div>
              <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVertIcon className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
    
  <Dropdown.Item onClick={()=>campusambassador("active")} > Mark as joined</Dropdown.Item>

  <Dropdown.Item  onClick={()=>campusambassador("complete")}><IndeterminateCheckBoxIcon className="me-2"/>Terminated</Dropdown.Item>
  
  <Dropdown.Item onClick={deleteambassador} ><DeleteIcon className="me-2"/>Delete</Dropdown.Item>
 </Dropdown.Menu>
</Dropdown>
                 
            </div>

            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default AmbassadorListItem;
