import React, { useContext, useEffect, useState } from 'react'
import { Close, Delete, Edit, MoreVert } from '@material-ui/icons'
import { Dropdown, Modal } from 'react-bootstrap'
import { Avatar, TextField } from '@mui/material'
import DefaultModal from '../../components/modals/DefaultModal'
import EvaluateTask from './EvaluateTask'
import ENDPOINT from '../../context/EndPoint'
import DeleteModal from '../../components/modals/DeleteModal'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import ModalStyle from '../../context/ModalStyle'
import DefaultAlertModal from '../../components/modals/DeafaultAlertModal'
import DefaultToast from '../../components/toasts/DefaultToast'
import SecurityHeaders from '../../context/SecurityHeaders'
import { connect } from 'react-redux'
const ProjectSubmissionItem = ({ item, dispatch }) => {
  const { user } = useContext(AuthContext)
  const [evaluate, setevaluate] = useState(false)
  const [toast, settoast] = useState(false)
  const [deletemodal, setdeletemodal] = useState(false)
  const [alert, setalert] = useState(false)
  const [toastdata, settoastdata] = useState(null)
  const [corrections, setcorrections] = useState(item.corrections)
  const [earnedmarks, setearnedmarks] = useState(item.marks_given)
  const [status, setstatus] = useState(item.status)
  const CloseEvaluation = () => {
    setevaluate(false)
  }

  //delete task code

  const deleteSubmission = async () => {

    const formData = new FormData();
    formData.append('session', user.SessionId);
    formData.append('id', item.id);

    const res = await axios.post(ENDPOINT + "admin-delete-project-submission.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {

      settoastdata(res.data)
      settoast(true)
      setdeletemodal(false)
    }

    if (res.data.status === "success") {
      dispatch({ type: 'FUNCTION_RELOAD', payload: 'project-submissions' });

    }
    else {

    }
  }




  const SaveChanges = async (e) => {

    e.preventDefault()
    const data = new FormData()
    data.append("id", item.id)
    data.append("session", user.SessionId)
    data.append("earned_marks", earnedmarks)
    data.append("status", status)
    data.append("corrections", corrections)
    const res = await axios.post(ENDPOINT + "admin-update-project-submission.php", data, SecurityHeaders)
    if (res) {

      settoastdata(res.data)
      settoast(true)

      setevaluate(false)
      if (res.data.status === "success") {
        //  // console.log(res.data.status);
        dispatch({ type: 'FUNCTION_RELOAD', payload:'project-submissions' });

      } else {



      }

    }
  }

  useEffect(() => {
    let timeOut;
    if (toast) {
      timeOut = setTimeout(() => {
        settoast(false);
      }, 2500);
    }


    return () => clearTimeout(timeOut);
  }, [toast]);

  return (
    <>
      <DeleteModal show={deletemodal} HandleClose={() => setdeletemodal(false)} HandleDelete={deleteSubmission} />

      <DefaultToast toastdata={toastdata} show={toast} />

      <DefaultModal
        className="col-lg-5 col-sm-12 col-12"
        HandleClose={CloseEvaluation}

        show={evaluate}
        content={<>
          <div className='p-3 '>
            <div className="modal-header">
              <h4>Evaluate Assignment</h4>
              <Close onClick={() => setevaluate(false)} />
            </div>
            <hr></hr>
            <form onSubmit={SaveChanges}>


              <div className="d-flex">
                <TextField value={earnedmarks} onChange={(e) => setearnedmarks(e.target.value)} placeholder='Enter Marks' className='col form-control mb-3' />

                <select className='form-control col' value={status} onChange={(e) => setstatus(e.target.value)}>
                  <option value="submitted">Mark Submitted</option>

                  <option value="rejected">Reject</option>


                  <option value="completed">Mark Complete</option>
                </select>
              </div>



              <button type='submit' className='mt-3 w-100 btn btn-primary'>Save Changes</button>
            </form>

          </div>

        </>}
      ></DefaultModal>

      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className="avatar-sm">
              <Avatar src={item.profile_pic} />
            </div>
            <div className="ms-1 flex-grow-1">
              <h6 className="fs-15 mb-0">
                <a href="javascript:void(0)">
                  {item.first_name + " " + item.last_name}
                </a>
              </h6>
            </div>
          </div>
        </td>
        <td>{item.course_url}</td>
        <td>{item.title}</td>
        <td>{item.submission_time}</td>
        <td>{item.deadline}</td>
        <td>{item.status}</td>

        <td>
          <Dropdown className="options-dropdown">
            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
              <MoreVert className="text-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>

              <Dropdown.Item href="#" id={"edit" + item.id} onClick={() => setevaluate(true)}> <Edit /> Evaluate</Dropdown.Item>

              <Dropdown.Item href="#" id={"delete" + item.id} onClick={() => setdeletemodal(true)}><Delete /> Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  )
}

const reload = (prop) => ({ value: prop.modalHandler })

export default connect(reload)(ProjectSubmissionItem)