import React, { useContext, useState } from "react";
import { Delete, Edit, MoreVert } from "@material-ui/icons";
import { Dropdown, Modal } from "react-bootstrap";
import { Avatar } from "@mui/material";
import dateFormat from "dateformat";
import EvaluateTeamTaskModal from "../../components/modals/EvaluateTeamTaskModal";
import DeleteModal from "../../components/modals/DeleteModal";
import { useActionsContext } from "../../context/ActionsContext";
import ENDPOINT from "../../context/EndPoint";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { connect } from "react-redux";
const AssignmentSubmissionItem = ({ item, value, dispatch, options }) => {
  const [evaluate, setevaluate] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const { permission } = useActionsContext();
  const { user } = useContext(AuthContext);

  const DeleteTaskSubmission = async () => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("id", item.id);

    const res = await axios.post(ENDPOINT + "admin-delete-task.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res.data.status === "success") {
      setdeletemodal(false);
      dispatch({ type: 'FUNCTION_RELOAD', payload: 'get-intern-tasks' });
    } else {
    }
  };
  return (
    <>
      <DeleteModal
        show={deletemodal}
        HandleClose={() => setdeletemodal(false)}
        HandleDelete={DeleteTaskSubmission}
      />
      <EvaluateTeamTaskModal
        visibility={evaluate}
        handleClose={() => setevaluate(false)}
        item={item}
      />
      <tr>

        {
          options?.assigned_by === false ? <></> :
            <td>
              <div className="d-flex align-items-center">
                <div className="avatar-sm">
                  <Avatar src={item.given_by.profile_pic} />
                </div>
                <div className="ms-3 flex-grow-1">
                  <h6 className="fs-15 mb-0">
                    <a href="javascript:void(0)">
                      {item.given_by.first_name + " " + item.given_by.last_name}
                    </a>
                  </h6>
                </div>
              </div>
            </td>
        }
   

        {
          options?.title === false ? <></> :
            <td>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
            </td>
        }


        {
          options?.employee === false ? <></> :
            <td>
              <div className="d-flex align-items-center">
                <div className="avatar-sm">
                  <Avatar src={item.intern.profile_pic} />
                </div>
                <div className="ms-3 flex-grow-1">
                  <h6 className="fs-15 mb-0">
                    <a href="javascript:void(0)">
                      {item.intern.first_name + " " + item.intern.last_name}
                    </a>
                  </h6>
                </div>
              </div>
            </td>
        }
        {
          options?.assigned === false ? <></> :
            <td>{dateFormat(item.create_datetime, "dS mmm hh:mm tt")}</td>

        }
        {
          options?.submissions === false ? <></> :
            <td>
              {item.submission_time
                ? dateFormat(item.submission_time, "dS mmm hh:mm tt")
                : "NA"}
            </td>
        }






        <td>
          <Dropdown className="options-dropdown">
            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
              <MoreVert className="text-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {parseInt(permission.evaluate_team_task) ? (
                <>
                  <Dropdown.Item
                    href="#"
                    id={"edit" + item.id}
                    onClick={() => setevaluate(true)}
                  >
                    {" "}
                    <Edit /> Evaluate
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}

              {parseInt(permission.delete_team_task) ? (
                <>
                  <Dropdown.Item
                    href="#"
                    id={"delete" + item.id}
                    onClick={() => setdeletemodal(true)}
                  >
                    <Delete /> Delete
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};
const reload = (prop) => ({
  value: prop.modalHandler
})
export default connect(reload)(AssignmentSubmissionItem);
