import React from 'react'
import StudentTicketItem from './StudentTicketItem'

const StudentsDoubtSupportList = ({doubts}) => {
  return (
 <>
 
 
 {/* end card header */}
 <div className="card-body">
          <div className="table-responsive table-card">
            <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
              <thead className="text-muted table-light">
                <tr>
                
                  <th scope="col">User</th>
                  <th scope="col">course</th>
                 
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                  <th scope="col">View</th>
                  
                  
                </tr>
              </thead>
             <tbody>


             {doubts?<>
             
              {doubts.map((item)=>(
                <>
                
                <StudentTicketItem item={item}/>
                </>
              ))}

             </>:
             <>
             
             
             </>}
             </tbody>
              {/* end tbody */}
            </table>
            {/* end table */}
          </div>
        </div>
 </>
  )
}

export default StudentsDoubtSupportList