import axios from "axios";
import ENDPOINT from "../../context/EndPoint";

const DeleteProject = async ({payload})=>{
    const formData = new FormData();
       
  //formData.append('whyus',document.getElementById('whyus').value)
  formData.append('session',payload.session);
  formData.append('id',payload.id);


  const res = await axios.post(ENDPOINT+"delete-project.php", formData,{
    
  
  headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
 return res.data
  }
  }
  
    
  export default DeleteProject