import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import { Avatar } from '@mui/material'
import { Close, Delete, Edit, MoreVert } from '@material-ui/icons'
import { Dropdown } from 'react-bootstrap';
import DeleteModal from '../../components/modals/DeleteModal';
import { Snackbar } from '@material-ui/core';
import { useActionsContext } from '../../context/ActionsContext';
import DetectDevice from '../../context/DetectDevice';
import dateFormat from "dateformat";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import Localbase from 'localbase';
const PaymentScreenShots = () => { 
    const [id,setId] = useState(null)
    const[snackbar,setSnackbar] =useState(false);
    const[snackbarMessage,setSnackbarMessage]=useState(null);
    const device = DetectDevice()
    
    let db = new Localbase('db')
    const Screenshots = async (query) => {
        const data = new FormData();
        if(query){
          data.append('query',query);

        }

        const res = await axios.post(ENDPOINT + "payment-screenshot-list.php", data, SecurityHeaders);
        if (res) {
            if (res.data.status === "success") {
              
                setInitialData(res.data.data)
                setResponse(res.data.data)
                const data = res.data.data
                db.collection('paths').add({data},'payment_screenshots')
            }
        }

    }
    useEffect(()=>{
        db.collection('paths').doc('payment_screenshots').get().then((data)=>{
            setInitialData(data.data)
                setResponse(data.data)
        })
    },[])
    useEffect(() => {
        Screenshots()
    }, [])
    const [deletemodal, setdeletemodal] = useState(false)
    const [response, setResponse] = useState([]);
    const [initialData, setInitialData] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    const {searchquery} = useActionsContext() 
    useEffect(()=>{
        handleSearch({term:searchquery})
    },[searchquery])
    const handleSearch = ({term}) => {
        if(term)
        {
            const filteredData = initialData.filter((item) => {
                return (
                    item.first_name.toLowerCase().includes(term) ||
                    item.last_name.toLowerCase().includes(term) 
                );
            });
            setResponse(filteredData);
            setSearchTerm(term);
        }


    };

    const DeleteScreenshot= async(e)=>{
        e.preventDefault()
        const data= new FormData();
        data.append("id",id)


        const res = await axios.post(ENDPOINT+"delete-screenshot.php",data,SecurityHeaders);
        if(res){
            if(res.data.status === "success"){
setdeletemodal(false)
setSnackbarMessage("screenshot has been deleted")
setSnackbar(true)
Screenshots()
            }
        }
    }

    return (
        <>
        <Snackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={()=>setSnackbar(false)}
        message={snackbarMessage}

        />
            <DeleteModal show={deletemodal} HandleClose={() => setdeletemodal(false)} HandleDelete={DeleteScreenshot} />

            <div className="card p-3">
                <div className='d-flex justify-content-between mb-2'>
                    <h4><b>Screenshots-Lists</b></h4>
             
                  <div className="col-xxl-3 col-sm-4 me-2 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            <div className="search-box ">
                              <input
                               onChange={(e)=>{handleSearch({term:e.target.value.toLowerCase()})}}
                                type="text"
                                className="form-control search"
                                placeholder="Search Name"
                              />
                              <i className="ri-search-line search-icon" />
                            </div>
                          </div>
                    <div className="d-flex d-none d-sm-block d-md-block d-lg-block d-xl-block">

                        <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
                            onClick={()=>Screenshots('WEEK')}

                        >
                            7D
                        </button>
                        <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
                            onClick={()=>Screenshots('MONTH')}
                        >
                            1M
                        </button>
                        <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
                            onClick={()=>Screenshots('YEAR')}

                        >
                            1Y
                        </button>
                        <button
                            type="button"
                            className="btn btn-soft-primary btn-sm material-shadow-none"
                            onClick={()=>Screenshots()}

                        >
                            ALL
                        </button>
                    </div>

            
                </div>

                {device==="mobile" ?
                <>
                    <div className="row">
                        {response && response.length > 0 ? (
                            response.map((item) => (
                            <div className="col-12 mb-1" key={item.id}>
                                <div className="card p-2 mb-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <Avatar src={item.profile_pic} className='me-2' />
                                            <div>
                                                <h6 className="mb-0"><strong>{item.first_name + " " + item.last_name}</strong></h6>
                                                <p className="m-0">{item.user_id}</p>
                                            </div>
                                        </div>
                                        <p className="m-0">{dateFormat(item.create_date_time,"dS mmm yy")}</p>
                                        {/* <div className="text-end">
                                            
                                        </div> */}
                                    </div>
                                    <div className="navbar">
                                        <div>
                                        <p className='m-0'><strong>Email: </strong>{item.email}</p>
                                        <p className="m-0"><strong>Phone: </strong> {item.phone_number}</p>
                                        </div>
                                        <a target='_blank' className="btn btn-primary" href={item.image_url}><RemoveRedEyeRoundedIcon className='fs-5 me-1' />Preview</a>
                                    </div>
                                    
                                </div>
                            </div>
                            ))
                        ) : (
                            <></>
                          )}
                        </div>
                      </>:
                <>
                        <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-borderless align-middle mb-0">

                                <thead className="table-light col-lg-12">
                                    <tr>
                                        <th scope="col">User_ID</th>
                                        <th scope="col">Profile</th>
                                        <th scope="col">Details</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Image</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>


                                <>
                                    <tbody className='overflow'>
                                        {response ? <>  {response.map((item, index) => (
                                            <>

                                                <tr>
                                                    <td>{item.user_id}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center ">

                                                            <div className="ms-2 flex-grow-1">
                                                                <div className="d-flex align-items-center">

                                                                    <Avatar src={item.profile_pic} className='me-2' />

                                                                    <div>
                                                                        <h6>{item.first_name}</h6>
                                                                        <p className='p-0 m-0'>{item.last_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>



                                                    <div>

                                                        <p className='m-0 p-0'> {item.email}</p>
                                                        <p className='m-0 p-0'>{item.phone_number}</p>

                                                    </div>
                                                    <td>
                                                        {item.create_date_time}
                                                    </td>

                                                    <td>
                                                        <a target='_blank' className='btn btn-primary' href={item.image_url}>
                                                            Preview
                                                        </a>
                                                    </td>

                                                    <td>
                                                        <Dropdown className="options-dropdown">

                                                            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
                                                                <MoreVert className="text-dark" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>


                                                                <Dropdown.Item href="#"  onClick={() =>{ setdeletemodal(true);setId(item.id)}}><Delete /> Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>

                                                    </td>

                                                </tr>
                                            </>
                                        ))}</> : null}

                                    </tbody>

                                </> :


                                <>



                                </>

                            </table>
                        </div>

                    </div>
                </div>
                </>}

                {/* <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-borderless align-middle mb-0">

                                <thead className="table-light col-lg-12">
                                    <tr>
                                        <th scope="col">User_ID</th>
                                        <th scope="col">Profile</th>
                                        <th scope="col">Details</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Image</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>


                                <>
                                    <tbody className='overflow'>
                                        {response ? <>  {response.map((item, index) => (
                                            <>

                                                <tr>
                                                    <td>{item.user_id}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center ">

                                                            <div className="ms-2 flex-grow-1">
                                                                <div className="d-flex align-items-center">

                                                                    <Avatar src={item.profile_pic} className='me-2' />

                                                                    <div>
                                                                        <h6>{item.first_name}</h6>
                                                                        <p className='p-0 m-0'>{item.last_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>



                                                    <div>

                                                        <p className='m-0 p-0'> {item.email}</p>
                                                        <p className='m-0 p-0'>{item.phone_number}</p>

                                                    </div>
                                                    <td>
                                                        {item.create_date_time}
                                                    </td>

                                                    <td>
                                                        <a target='_blank' className='btn btn-primary' href={item.image_url}>
                                                            Preview
                                                        </a>
                                                    </td>

                                                    <td>
                                                        <Dropdown className="options-dropdown">

                                                            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
                                                                <MoreVert className="text-dark" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>


                                                                <Dropdown.Item href="#"  onClick={() =>{ setdeletemodal(true);setId(item.id)}}><Delete /> Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>

                                                    </td>

                                                </tr>
                                            </>
                                        ))}</> : null}

                                    </tbody>

                                </> :


                                <>



                                </>

                            </table>
                        </div>

                    </div>
                </div> */}
            </div>



        </>
    )
}

export default PaymentScreenShots