import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PlacementStudent from './PlacementStudent'
import Localbase from 'localbase'

const PlacementStudents = () => {

    const db= new Localbase("db")

    const [placementData,setPlacementData]=useState([])

    const fetchPLacementsData = async () => {

        const res = await axios.post('https://skillsuprise.com/get-placed-students.php')
        if (res) {
            setPlacementData(res.data.data)
        }
    }
    useEffect(() => {
        fetchPLacementsData()
    }, [])
    const RefreshplacementStudents = () =>{
        fetchPLacementsData()
    }

    return (
        <div>
            <div>
                <div className="container-fluid d-flex justify-content-between">
                    <h2>Placed Students</h2>
                    <Link to="/add_student" className="btn btn-primary">
                        Add Student
                    </Link>
                </div>
                <hr></hr>
                <div className="container-fluid sm-p-15 d-flex flex-wrap">
                    {/* Map through the get-placed-students.php array */}
                    { placementData ?
                        <>

                            {placementData.map((student) => (
                                <PlacementStudent key={student.user_id} student={student} RefreshPla = {RefreshplacementStudents} />
                            ))}
                        </> :
                        <>

                        </>}
                </div>
            </div>
        </div>
    )
}

export default PlacementStudents