import React, { useState } from 'react';
import logo from '../../assets/images/logo-main.png';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';

const CreateInvoice = () => {
  const params = useParams();

   // console.log(params);
  const invoice = useSelector((state) => state.invoice.invoice);

  const [company, setCompany] = useState({
    address: ' ',
    postalCode: '',
    registrationNo: '',
    email: '',
    website: '',
    contact: '',
  });


  const handleDownloadInvoice = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const sectionSpacing = 10;
    const lineHeight = 8;
  
    // Add your logo image to the PDF (assuming your logo is base64 encoded)
 
    const imgWidth = 50;
    const imgHeight = 20;
    const imgX = (pageWidth - imgWidth) / 2;
  
    // Add Logo
    doc.addImage(logo, 'PNG', imgX, margin, imgWidth, imgHeight);
  
    // Add Invoice Header
    doc.setFontSize(18);
    doc.setTextColor(40);
    doc.text('Invoice', pageWidth / 2, margin + imgHeight + 10, { align: 'center' });
  
    // Add Invoice Details
    let y = margin + imgHeight + 20;
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(`Invoice No: ${invoice.id}`, margin, y);
    y += lineHeight;
    doc.text(`Date: ${invoice.create_date}`, margin, y);
    y += lineHeight;
    doc.text(`Payment Status: ${invoice.purchase_status}`, margin, y);
    y += lineHeight;
    doc.text(`Total Amount: ₹ ${invoice.paid_amount}`, margin, y);
    y += lineHeight;
    doc.text(`Payment Method: ${invoice.payment_method}`, margin, y);
  
    // Add a section separator
    y += sectionSpacing;
    doc.setLineWidth(0.5);
    doc.line(margin, y, pageWidth - margin, y);
    y += sectionSpacing;
  
    // Add Notes
    doc.setFontSize(12);
    doc.setTextColor(40);
    doc.text('Notes:', margin, y);
    y += lineHeight;
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`${invoice.notes}`, margin, y, { maxWidth: pageWidth - 2 * margin });
  
    // Move to next section
    y += sectionSpacing * 2;
  
    // Add Company Details Header
    doc.setFontSize(12);
    doc.setTextColor(40);
    doc.text('Company Details:', margin, y);
    y += lineHeight;
    
    // Add Company Details
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Address: ${company.address}`, margin, y);
    y += lineHeight;
    doc.text(`Postal Code: ${company.postalCode}`, margin, y);
    y += lineHeight;
    doc.text(`Registration No: ${company.registrationNo}`, margin, y);
    y += lineHeight;
    doc.text(`Email: ${company.email}`, margin, y);
    y += lineHeight;
    doc.text(`Website: ${company.website}`, margin, y);
    y += lineHeight;
    doc.text(`Contact: ${company.contact}`, margin, y);
  
    // Move to next section
    y += sectionSpacing * 2;
  
    // Add Payment Details Header
    doc.setFontSize(12);
    doc.setTextColor(40);
    doc.text('Payment Details:', margin, y);
    y += lineHeight;
  
    // Add Payment Details
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Payment Method: ${invoice.payment_method}`, margin, y);
    y += lineHeight;
    doc.text(`Card Holder Name: ${invoice.card_holder_name}`, margin, y);
    y += lineHeight;
    doc.text(`Card Number: ${invoice.card_number}`, margin, y);
    y += lineHeight;
    doc.text(`Paid Amount: ₹ ${invoice.paid_amount}`, margin, y);
  
    // Save the generated PDF
    doc.save(`invoice_${invoice.id}.pdf`);
  };
  
  
  

  return (
    <>
      <div className="container-fluid">
        {/* start page title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
              <h4 className="mb-sm-0">Create Invoice</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="javascript: void(0);">Invoices</a>
                  </li>
                  <li className="breadcrumb-item active">Create Invoice</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* end page title */}
        <div className="row justify-content-center">
          <div className="col-xxl-9">
            <div className="card">
              <form className="needs-validation" noValidate="" id="invoice_form">
                <div className="card-body border-bottom border-bottom-dashed p-4">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="profile-user mx-auto mb-3">
                        <input
                          id="profile-img-file-input"
                          type="file"
                          className="profile-img-file-input"
                        />
                        <label
                          htmlFor="profile-img-file-input"
                          className="d-block"
                          tabIndex={0}
                        >
                          <span
                            className="overflow-hidden border border-dashed d-flex align-items-center justify-content-center rounded"
                            style={{ height: 60, width: 256 }}
                          >
                            <img
                              src={logo}
                              className="card-logo card-logo-dark user-profile-image img-fluid"
                              alt="logo dark"
                            />
                            <img
                              src="assets/images/logo-main.png"
                              className="card-logo card-logo-light user-profile-image img-fluid"
                              alt="logo light"
                            />
                          </span>
                        </label>
                      </div>
                      <div>
                        <div>
                          <label htmlFor="companyAddress">Address</label>
                        </div>
                        <div className="mb-2">
                          <textarea
                            className="form-control bg-light border-0"
                            id="companyAddress"
                            rows={3}
                            placeholder="Company Address"
                            required=""
                            value={company.address}
                            onChange={(e) =>
                              setCompany({ ...company, address: e.target.value })
                            }
                          />
                          <div className="invalid-feedback">
                            Please enter a address
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control bg-light border-0"
                            id="companyaddpostalcode"
                            minLength={5}
                            maxLength={6}
                            placeholder="Enter Postal Code"
                            required=""
                            value={company.postalCode}
                            onChange={(e) =>
                              setCompany({
                                ...company,
                                postalCode: e.target.value,
                              })
                            }
                          />
                          <div className="invalid-feedback">
                            The US zip code must contain 5 digits, Ex. 45678
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-4 ms-auto">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="registrationNumber"
                          maxLength={12}
                          placeholder="Legal Registration No"
                          required=""
                          value={company.registrationNo}
                          onChange={(e) =>
                            setCompany({
                              ...company,
                              registrationNo: e.target.value,
                            })
                          }
                        />
                        <div className="invalid-feedback">
                          Please enter a registration no, Ex., 012345678912
                        </div>
                      </div>
                      <div className="mb-2">
                        <input
                          type="email"
                          className="form-control bg-light border-0"
                          id="companyEmail"
                          placeholder="Email Address"
                          required=""
                          value={company.email}
                          onChange={(e) =>
                            setCompany({ ...company, email: e.target.value })
                          }
                        />
                        <div className="invalid-feedback">
                          Please enter a valid email, Ex., example@gamil.com
                        </div>
                      </div>
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="companyWebsite"
                          placeholder="Website"
                          required=""
                          value={company.website}
                          onChange={(e) =>
                            setCompany({ ...company, website: e.target.value })
                          }
                        />
                        <div className="invalid-feedback">
                          Please enter a website, Ex., www.example.com
                        </div>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          data-plugin="cleave-phone"
                          id="compnayContactno"
                          placeholder="Contact No"
                          required=""
                          value={company.contact}
                          onChange={(e) =>
                            setCompany({ ...company, contact: e.target.value })
                          }
                        />
                        <div className="invalid-feedback">
                          Please enter a contact number
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end row*/}
                </div>
                <div className="card-body p-4">
                  <div className="row g-3">
                    <div className="col-lg-3 col-sm-6">
                      <label htmlFor="invoicenoInput">Invoice No</label>
                      <input
                        type="text"
                        className="form-control bg-light border-0"
                        id="invoicenoInput"
                        placeholder="Invoice No"
                        value={invoice.id}
                        readOnly="readonly"
                      />
                    </div>
                    {/*end col*/}
                    <div className="col-lg-3 col-sm-6">
                      <div>
                        <label htmlFor="date-field">Date</label>
                        <input
                          value={invoice.create_date}
                          className="form-control bg-light border-0"
                          placeholder="Select Date-time"
                          readOnly
                        />
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-3 col-sm-6">
                      <label htmlFor="choices-payment-status">
                        Payment Status
                      </label>
                      <input
                        value={invoice.purchase_status}
                        className="form-control bg-light border-0"
                        placeholder="Payment Status"
                        readOnly
                      />
                    </div>
                    {/*end col*/}
                    <div className="col-lg-3 col-sm-6">
                      <div>
                        <label htmlFor="totalamountInput">Total Amount</label>
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="totalamountInput"
                          placeholder="₹ 0.00"
                          value={invoice.paid_amount}
                          readOnly=""
                        />
                      </div>
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}
                </div>
                <div className="card-body p-4 border-top border-top-dashed">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6">
                      <div>
                        <label
                          htmlFor="billingName"
                          className="text-muted text-uppercase fw-semibold"
                        >
                          Billing Address
                        </label>
                      </div>
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="billingName"
                          placeholder="Full Name"
                          required=""
                        />
                        <div className="invalid-feedback">
                          Please enter a full name
                        </div>
                      </div>
                      <div className="mb-2">
                        <textarea
                          className="form-control bg-light border-0"
                          id="billingAddress"
                          rows={3}
                          placeholder="Address"
                          required=""
                          defaultValue={""}
                        />
                        <div className="invalid-feedback">
                          Please enter a address
                        </div>
                      </div>
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          data-plugin="cleave-phone"
                          id="billingPhoneno"
                          placeholder="(123)456-7890"
                          required=""
                        />
                        <div className="invalid-feedback">
                          Please enter a phone number
                        </div>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="billingTaxno"
                          placeholder="Tax Number"
                          required=""
                        />
                        <div className="invalid-feedback">
                          Please enter a tax number
                        </div>
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-sm-6 ms-auto">
                      <div className="row">
                        <div className="col-lg-8">
                          <div>
                            <label
                              htmlFor="shippingName"
                              className="text-muted text-uppercase fw-semibold"
                            >
                              Shipping Address
                            </label>
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control bg-light border-0"
                              id="shippingName"
                              placeholder="Full Name"
                              required=""
                            />
                            <div className="invalid-feedback">
                              Please enter a full name
                            </div>
                          </div>
                          <div className="mb-2">
                            <textarea
                              className="form-control bg-light border-0"
                              id="shippingAddress"
                              rows={3}
                              placeholder="Address"
                              required=""
                              defaultValue={""}
                            />
                            <div className="invalid-feedback">
                              Please enter a address
                            </div>
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control bg-light border-0"
                              data-plugin="cleave-phone"
                              id="shippingPhoneno"
                              placeholder="(123)456-7890"
                              required=""
                            />
                            <div className="invalid-feedback">
                              Please enter a phone number
                            </div>
                          </div>
                          <div>
                            <input
                              type="text"
                              className="form-control bg-light border-0"
                              id="shippingTaxno"
                              placeholder="Tax Number"
                              required=""
                            />
                            <div className="invalid-feedback">
                              Please enter a tax number
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}
                </div>
                <div className="card-body p-4">
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <div className="mb-2">
                        <label
                          htmlFor="choices-payment-type"
                          className="form-label text-muted text-uppercase fw-semibold"
                        >
                          Payment Details
                        </label>
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="totalamountInput"
                          placeholder="₹ 0.00"
                          value={invoice.payment_method}
                          readOnly=""
                        />
                      </div>
                      <div className="mb-2">
                        <input
                          className="form-control bg-light border-0"
                          type="text"
                          id="cardholderName"
                          placeholder="Card Holder Name"
                        />
                      </div>
                      <div className="mb-2">
                        <input
                          className="form-control bg-light border-0"
                          type="text"
                          id="cardNumber"
                          placeholder="xxxx xxxx xxxx xxxx"
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="totalamountInput"
                          placeholder="₹ 0.00"
                          value={invoice.paid_amount}
                          readOnly=""
                        />
                      </div>
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}
                  <div className="mt-4">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label text-muted text-uppercase fw-semibold"
                    >
                      NOTES
                    </label>
                    <textarea
                      className="form-control alert alert-info"
                      id="exampleFormControlTextarea1"
                      placeholder="Notes"
                      rows={2}
                      required=""
                      defaultValue={
                        "All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or credit card or direct payment online. If account is not paid within 7 days the credits details supplied as confirmation of work undertaken will be charged the agreed quoted fee noted above."
                      }
                    />
                  </div>
                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    <button type="submit" className="btn btn-success">
                      <i className="ri-printer-line align-bottom me-1" /> Save
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleDownloadInvoice}>
                      <i className="ri-download-2-line align-bottom me-1" /> Download Invoice
                    </button>
                    <a href="javascript:void(0);" className="btn btn-danger">
                      <i className="ri-send-plane-fill align-bottom me-1" /> Send Invoice
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
    </>
  );
};

export default CreateInvoice;
