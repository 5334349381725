import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import dateFormat from "dateformat";
import ENDPOINT from '../../context/EndPoint';
import DeleteModal from '../../components/modals/DeleteModal';
import EvaluateTeamTaskModal from '../../components/modals/EvaluateTeamTaskModal';
import { useActionsContext } from '../../context/ActionsContext';
import { Avatar } from '@mui/material';

const AssignmentSubmissionItemMobile = ({ item, value, dispatch, options }) => {
    const [evaluate, setevaluate] = useState(false);
    const [deletemodal, setdeletemodal] = useState(false);
    const [taskId, setTaskId] = useState(null);
    const { permission } = useActionsContext();
    const { user } = useContext(AuthContext);
  
    const DeleteTaskSubmission = async () => {
      const formData = new FormData();
      formData.append("session", user.SessionId);
      formData.append("id", item.id);
  
      const res = await axios.post(ENDPOINT + "admin-delete-task.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
  
      if (res.data.status === "success") {
        setdeletemodal(false);
        dispatch({ type: 'FUNCTION_RELOAD', payload: 'get-intern-tasks' });
      } else {
      }
    };
  return (
    <div> 

<DeleteModal
        show={deletemodal}
        HandleClose={() => setdeletemodal(false)}
        HandleDelete={DeleteTaskSubmission}
      />
      <EvaluateTeamTaskModal
        visibility={evaluate}
        handleClose={() => setevaluate(false)}
        item={item}
      />

        <div className="col-12 mb-1" key={item.id}>
    <div className="card p-3 mb-1">
      <div className="d-flex justify-content-between align-items-center">
        <div className='navbar p-0'>
          <Avatar src={item.intern.profile_pic} className="me-2" />
          <div>
            <p className="mb-1"><strong> {item.title}</strong></p>
            <h5 className='mb-0'>{item.intern.first_name + " " + item.intern.last_name}</h5>
          </div>
        </div>
        
        <span className={`badge ${item.status === 'pending' ? 'bg-warning-subtle text-warning' : item.status === "submitted" ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}`}>
          {item.status}
        </span>
      </div>
         <div className='navbar'>
          <p className="mb-0"><strong>{dateFormat(item.create_datetime, "dS mmm hh:mm tt")}</strong> </p>
          <div className="navbar">
          <small><Avatar src={item.given_by.profile_pic } className='me-2 sm-av'/></small>
          <p className="mb-0"><strong>{item.given_by.first_name }</strong></p>
          </div>
          <button
          className="btn btn-link text-muted"
          data-bs-toggle="collapse"
          data-bs-target={`#details${item.id}`}
          aria-expanded="false"
          aria-controls={`details${item.id}`}
        >
          <i className="ri-arrow-down-s-fill"></i> More
        </button>
        </div>
      

      <div className="collapse" id={`details${item.id}`}>
      <p className="mb-1"><strong>Description:</strong> {item.description}</p>
        {options?.submissions !== false && (
          <p className="mb-1"><strong>Submission Time:</strong> {item.submission_time ? dateFormat(item.submission_time, "dS mmm hh:mm tt") : "NA"}</p>
        )}
        <div className="navbar">
            <button className='btn btn-success' onClick={() => setevaluate(true)}>Evaluate</button>
            <button className='btn btn-danger' onClick={() => setdeletemodal(true)}>Delete</button>

        </div>

        
        {/* <div className="dropdown">
          <a
            href="javascript:void(0);"
            className="btn btn-light btn-icon"
            id={`dropdownMenuLink${item.id}`}
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="ri-equalizer-fill" />
          </a>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby={`dropdownMenuLink${item.id}`}
          >
            {parseInt(permission.evaluate_team_task) && (
              <li>
                <a className="dropdown-item" href="javascript:void(0);" onClick={() => setevaluate(true)}>
                  <i className="ri-pencil-fill me-2 align-middle text-muted" /> Evaluate
                </a>
              </li>
            )}
            {parseInt(permission.delete_team_task) && (
              <li>
                <a className="dropdown-item" href="javascript:void(0);" onClick={() => setdeletemodal(true)}>
                  <i className="ri-delete-bin-5-line me-2 align-middle text-muted" /> Delete
                </a>
              </li>
            )}
          </ul>
        </div> */}
      </div>
    </div>
  </div></div>
  )
}

export default AssignmentSubmissionItemMobile