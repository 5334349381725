import React from "react";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

const Withdrawls = () => {
  return (
    <>
      <div className="container-fluid">
        {/* start page title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
              <h4 className="mb-sm-0">Crypto</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="javascript: void(0);">Dashboards</a>
                  </li>
                  <li className="breadcrumb-item active">Crypto</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* end page title */}
        <div className="row">
          {/* end col */}
          <div className="col-xxl-9 order-xxl-0 order-first">
            <div className="d-flex flex-column h-100">
              <div className="row h-100">
                <div className="col-lg-4 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-money-dollar-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                            {" "}
                            Total Invested
                          </p>
                          <h4 className=" mb-0">
                            $
                            <span
                              className="counter-value"
                              data-target="2390.68"
                            >
                              0
                            </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-success-subtle text-success">
                            <i className="ri-arrow-up-s-fill align-middle me-1" />
                            6.24 %<span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}
                <div className="col-lg-4 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                            <i className="ri-arrow-up-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                            {" "}
                            Total Change
                          </p>
                          <h4 className=" mb-0">
                            $
                            <span
                              className="counter-value"
                              data-target="19523.25"
                            >
                              0
                            </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-success-subtle text-success">
                            <i className="ri-arrow-up-s-fill align-middle me-1" />
                            3.67 %<span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}
                <div className="col-lg-4 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                            <i className="ri-arrow-down-circle-fill align-middle" />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                            Day Change
                          </p>
                          <h4 className=" mb-0">
                            $
                            <span
                              className="counter-value"
                              data-target="14799.44"
                            >
                              0
                            </span>
                          </h4>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="ri-arrow-down-s-fill align-middle me-1" />
                            4.80 %<span> </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
          </div>
          {/* end col */}
        </div>

        <div className="row">
          <div className="col-xl-8">
            <div className="card card-height-100">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Withdrawls</h4>
                <div className="flex-shrink-0">
                  <button className="btn btn-soft-primary btn-sm">24H</button>
                </div>
                <div className="flex-shrink-0 ms-2">
                  <div className="dropdown card-header-dropdown">
                    <a
                      className="btn btn-soft-primary btn-sm"
                      role="button"
                      href="#"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Get Report
                      <i className="mdi mdi-chevron-down align-middle ms-1" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      <a className="dropdown-item" href="#">
                        <DownloadForOfflineIcon className="me-1" />
                        Download Report
                      </a>
                      <a className="dropdown-item" href="#">
                        <FileUploadRoundedIcon className="me-1" />
                        Export
                      </a>
                      <a className="dropdown-item" href="#">
                        <DownloadRoundedIcon className="me-1" />
                        Import
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* end card header */}
              <div className="card-body">
                <div className="table-responsive table-card">
                  <table className="table table-hover table-borderless table-centered align-middle table-nowrap mb-0">
                    <thead className="text-muted bg-light-subtle">
                      <tr>
                        <th>Coin Name</th>
                        <th>Price</th>
                        <th>24h Change</th>
                        <th>Total Balance</th>
                        <th>Total Coin</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {/* end thead */}
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src="assets/images/svg/crypto-icons/btc.svg"
                                alt=""
                                className="avatar-xxs material-shadow"
                              />
                            </div>
                            <div>
                              <h6 className="fs-14 mb-0">Bitcoin</h6>
                            </div>
                          </div>
                        </td>
                        <td>$48,568.025</td>
                        <td>
                          <h6 className="text-success fs-13 mb-0">
                            <i className="mdi mdi-trending-up align-middle me-1" />
                            5.26{" "}
                          </h6>
                        </td>
                        <td>$53,914.025</td>
                        <td>1.25634801</td>
                        <td>
                          <a
                            href="apps-crypto-buy-sell.html"
                            className="btn btn-sm btn-soft-secondary material-shadow-none"
                          >
                            Trade
                          </a>
                        </td>
                      </tr>
                      {/* end */}
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src="assets/images/svg/crypto-icons/ltc.svg"
                                alt=""
                                className="avatar-xxs material-shadow"
                              />
                            </div>
                            <div>
                              <h6 className="fs-14 mb-0">Litecoin</h6>
                            </div>
                          </div>
                        </td>
                        <td>$87,142.027</td>
                        <td>
                          <h6 className="text-danger fs-13 mb-0">
                            <i className="mdi mdi-trending-down align-middle me-1" />
                            3.07{" "}
                          </h6>
                        </td>
                        <td>$75,854.127</td>
                        <td>2.85472161</td>
                        <td>
                          <a
                            href="apps-crypto-buy-sell.html"
                            className="btn btn-sm btn-soft-secondary material-shadow-none"
                          >
                            Trade
                          </a>
                        </td>
                      </tr>
                      {/* end */}
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src="assets/images/svg/crypto-icons/eth.svg"
                                alt=""
                                className="avatar-xxs material-shadow"
                              />
                            </div>
                            <div>
                              <h6 className="fs-14 mb-0">Ethereum</h6>
                            </div>
                          </div>
                        </td>
                        <td>$33,847.961</td>
                        <td>
                          <h6 className="text-success fs-13 mb-0">
                            <i className="mdi mdi-trending-up align-middle me-1" />
                            7.13{" "}
                          </h6>
                        </td>
                        <td>$44,152.185</td>
                        <td>1.45612347</td>
                        <td>
                          <a
                            href="apps-crypto-buy-sell.html"
                            className="btn btn-sm btn-soft-secondary material-shadow-none"
                          >
                            Trade
                          </a>
                        </td>
                      </tr>
                      {/* end */}
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src="assets/images/svg/crypto-icons/bnb.svg"
                                alt=""
                                className="avatar-xxs material-shadow"
                              />
                            </div>
                            <div>
                              <h6 className="fs-14 mb-0">Binance</h6>
                            </div>
                          </div>
                        </td>
                        <td>$73,654.421</td>
                        <td>
                          <h6 className="text-success fs-13 mb-0">
                            <i className="mdi mdi-trending-up align-middle me-1" />
                            0.97
                          </h6>
                        </td>
                        <td>$48,367.125</td>
                        <td>0.35734601</td>
                        <td>
                          <a
                            href="apps-crypto-buy-sell.html"
                            className="btn btn-sm btn-soft-secondary material-shadow-none"
                          >
                            Trade
                          </a>
                        </td>
                      </tr>
                      {/* end */}
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src="assets/images/svg/crypto-icons/usdt.svg"
                                alt=""
                                className="avatar-xxs material-shadow"
                              />
                            </div>
                            <div>
                              <h6 className="fs-14 mb-0">Tether</h6>
                            </div>
                          </div>
                        </td>
                        <td>$66,742.077</td>
                        <td>
                          <h6 className="text-danger fs-13 mb-0">
                            <i className="mdi mdi-trending-down align-middle me-1" />
                            1.08{" "}
                          </h6>
                        </td>
                        <td>$53,487.083</td>
                        <td>3.62912570</td>
                        <td>
                          <a
                            href="apps-crypto-buy-sell.html"
                            className="btn btn-sm btn-soft-secondary material-shadow-none"
                          >
                            Trade
                          </a>
                        </td>
                      </tr>
                      {/* end */}
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src="assets/images/svg/crypto-icons/dash.svg"
                                alt=""
                                className="avatar-xxs material-shadow"
                              />
                            </div>
                            <div>
                              <h6 className="fs-14 mb-0">Dash</h6>
                            </div>
                          </div>
                        </td>
                        <td>$34,736.209</td>
                        <td>
                          <h6 className="text-success fs-13 mb-0">
                            <i className="mdi mdi-trending-up align-middle me-1" />
                            4.52{" "}
                          </h6>
                        </td>
                        <td>$15,203.347</td>
                        <td>1.85412740</td>
                        <td>
                          <a
                            href="apps-crypto-buy-sell.html"
                            className="btn btn-sm btn-soft-secondary material-shadow-none"
                          >
                            Trade
                          </a>
                        </td>
                      </tr>
                      {/* end */}
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src="assets/images/svg/crypto-icons/neo.svg"
                                alt=""
                                className="avatar-xxs material-shadow"
                              />
                            </div>
                            <div>
                              <h6 className="fs-14 mb-0">Neo</h6>
                            </div>
                          </div>
                        </td>
                        <td>$56,357.313</td>
                        <td>
                          <h6 className="text-danger fs-13 mb-0">
                            <i className="mdi mdi-trending-down align-middle me-1" />
                            2.87{" "}
                          </h6>
                        </td>
                        <td>$61,843.173</td>
                        <td>1.87732061</td>
                        <td>
                          <a
                            href="apps-crypto-buy-sell.html"
                            className="btn btn-sm btn-soft-secondary material-shadow-none"
                          >
                            Trade
                          </a>
                        </td>
                      </tr>
                      {/* end */}
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src="assets/images/svg/crypto-icons/doge.svg"
                                alt=""
                                className="avatar-xxs material-shadow"
                              />
                            </div>
                            <div>
                              <h6 className="fs-14 mb-0">Dogecoin</h6>
                            </div>
                          </div>
                        </td>
                        <td>$62,357.649</td>
                        <td>
                          <h6 className="text-success fs-13 mb-0">
                            <i className="mdi mdi-trending-up align-middle me-1" />
                            3.45{" "}
                          </h6>
                        </td>
                        <td>$54,843.173</td>
                        <td>0.95632087</td>
                        <td>
                          <a
                            href="apps-crypto-buy-sell.html"
                            className="btn btn-sm btn-soft-secondary material-shadow-none"
                          >
                            Trade
                          </a>
                        </td>
                      </tr>
                      {/* end */}
                    </tbody>
                    {/* end tbody */}
                  </table>
                  {/* end table */}
                </div>
                {/* end tbody */}
              </div>
              {/* end cardbody */}
            </div>
            {/* end card */}
          </div>
          {/* end col */}
          <div className="col-xl-4">
            <div className="card card-height-100">
              <div className="card-header align-items-center border-0 d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Trading</h4>
                <div className="flex-shrink-0">
                  <ul
                    className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#buy-tab"
                        role="tab"
                        aria-selected="false"
                      >
                        Buy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#sell-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Sell
                      </a>
                    </li>
                  </ul>
                  {/* end ul */}
                </div>
              </div>
              {/* end cardheader */}
              <div className="card-body p-0">
                <div className="tab-content p-0">
                  <div className="tab-pane active" id="buy-tab" role="tabpanel">
                    <div className="p-3 bg-warning-subtle">
                      <div className="float-end ms-2">
                        <h6 className="text-warning mb-0">
                          USD Balance :{" "}
                          <span className="text-body">$12,426.07</span>
                        </h6>
                      </div>
                      <h6 className="mb-0 text-danger">Buy Coin</h6>
                    </div>
                    <div className="p-3">
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-3">
                            <label>Currency :</label>
                            <select className="form-select">
                              <option>BTC</option>
                              <option>ETH</option>
                              <option>LTC</option>
                            </select>
                          </div>
                        </div>
                        {/* end col */}
                        <div className="col-6">
                          <div className="mb-3">
                            <label>Payment Method :</label>
                            <select className="form-select">
                              <option>Wallet Balance</option>
                              <option>Credit / Debit Card</option>
                              <option>PayPal</option>
                              <option>Payoneer</option>
                            </select>
                          </div>
                        </div>
                        {/* end col */}
                      </div>
                      {/* end row */}
                      <div>
                        <div className="input-group mb-3">
                          <label className="input-group-text">Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={0}
                          />
                        </div>
                        <div className="input-group mb-3">
                          <label className="input-group-text">Price</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="2.045585"
                          />
                          <label className="input-group-text">$</label>
                        </div>
                        <div className="input-group mb-0">
                          <label className="input-group-text">Total</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="2700.16"
                          />
                        </div>
                      </div>
                      <div className="mt-3 pt-2">
                        <div className="d-flex mb-2">
                          <div className="flex-grow-1">
                            <p className="fs-13 mb-0">
                              Transaction Fees
                              <span className="text-muted ms-1 fs-11">
                                (0.05%)
                              </span>
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <h6 className="mb-0">$1.08</h6>
                          </div>
                        </div>
                        <div className="d-flex mb-2">
                          <div className="flex-grow-1">
                            <p className="fs-13 mb-0">
                              Minimum Received
                              <span className="text-muted ms-1 fs-11">
                                (2%)
                              </span>
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <h6 className="mb-0">$7.85</h6>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="fs-13 mb-0">Estimated Rate</p>
                          </div>
                          <div className="flex-shrink-0">
                            <h6 className="mb-0">1 BTC ~ $34572.00</h6>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 pt-2">
                        <button type="button" className="btn btn-primary w-100">
                          Buy Coin
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end tabpane */}
                  <div className="tab-pane" id="sell-tab" role="tabpanel">
                    <div className="p-3 bg-warning-subtle">
                      <div className="float-end ms-2">
                        <h6 className="text-warning mb-0">
                          USD Balance :{" "}
                          <span className="text-body">$12,426.07</span>
                        </h6>
                      </div>
                      <h6 className="mb-0 text-danger">Sell Coin</h6>
                    </div>
                    <div className="p-3">
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-3">
                            <label>Currency :</label>
                            <select className="form-select">
                              <option>BTC</option>
                              <option>ETH</option>
                              <option>LTC</option>
                            </select>
                          </div>
                        </div>
                        {/* end col */}
                        <div className="col-6">
                          <div className="mb-3">
                            <label>Email :</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="example@email.com"
                            />
                          </div>
                        </div>
                        {/* end col */}
                      </div>
                      {/* end row */}
                      <div>
                        <div className="input-group mb-3">
                          <label className="input-group-text">Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={0}
                          />
                        </div>
                        <div className="input-group mb-3">
                          <label className="input-group-text">Price</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="2.045585"
                          />
                          <label className="input-group-text">$</label>
                        </div>
                        <div className="input-group mb-0">
                          <label className="input-group-text">Total</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="2700.16"
                          />
                        </div>
                      </div>
                      <div className="mt-3 pt-2">
                        <div className="d-flex mb-2">
                          <div className="flex-grow-1">
                            <p className="fs-13 mb-0">
                              Transaction Fees
                              <span className="text-muted ms-1 fs-11">
                                (0.05%)
                              </span>
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <h6 className="mb-0">$1.08</h6>
                          </div>
                        </div>
                        <div className="d-flex mb-2">
                          <div className="flex-grow-1">
                            <p className="fs-13 mb-0">
                              Minimum Received
                              <span className="text-muted ms-1 fs-11">
                                (2%)
                              </span>
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <h6 className="mb-0">$7.85</h6>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="fs-13 mb-0">Estimated Rate</p>
                          </div>
                          <div className="flex-shrink-0">
                            <h6 className="mb-0">1 BTC ~ $34572.00</h6>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 pt-2">
                        <button type="button" className="btn btn-danger w-100">
                          Sell Coin
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end tab pane */}
                </div>
                {/* end tab pane */}
              </div>
              {/* end card body */}
            </div>
            {/* end card */}
          </div>
          {/* end col */}
        </div>
      </div>
    </>
  );
};

export default Withdrawls;
