import React, { useContext } from 'react'
import { useRef } from 'react'
import { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components'

import {  Modal } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast'
import SecurityHeaders from '../../context/SecurityHeaders';
import { AuthContext } from '../../context/AuthContext';
const AddLead = ({props,page,Reload}) => {
    var username = useRef();
    var email = useRef();
    var phone = useRef();
    var whatsapp = useRef();
    var category = useRef();
    var note = useRef();
    const {user} = useContext(AuthContext)
const [sending,setsending]= useState(false)
const [toasttitle,settoasttitle] = useState(null);
const [toastmessage,settoastmessage] = useState(null)
const [MessageStatus,setMessageStatus] = useState(null)

    const SendMessage = async(e)=>{
setsending(true)

        const displayForm = ()=>{
    
            setMessageStatus(null);
            setcontact(false)
        }
           
    const messageDefault = ()=>{
      
    setTimeout(displayForm, 8000);
    
    }
    
       e.preventDefault();
      
       const data = new FormData()
       data.append("name",username.current.value)
       data.append("email",email.current.value)
       data.append("phone",phone.current.value)
       data.append("whatsapp",whatsapp.current.value)
       data.append("category",category.current.value)
       data.append("note",note.current.value)
       data.append("session",user.SessionId)
    const res = await axios.post("https://internalapi.skillsuprise.com/admin-add-lead.php", data,SecurityHeaders)
    


    
    if(res.data){
 
        Reload()
        setsending(false)
        username.current.value = "";
        email.current.value = "";
        phone.current.value = "";
        category.current.value = "";
        note.current.value = "";
        settoasttitle("Successful !")
        settoastmessage("Lead successfully added.")
        settoast(true)
        
   // setTimeout(messageDefault, 3000);
    }
    else{
   settoast(true)
        settoasttitle("Something went wrong !")
        settoastmessage("Please try again.")
    setMessageStatus("Message Sending Failed");
    }
    
    
    
    }
    

const [contact,setcontact]= useState(false);
const closeContact = ()=>{
setcontact(false)
}

const [toast,settoast] = useState(false)
    return (
        <StyledContactForm>
            <div className="w-100 p-0">






         





<div className="position-fixed container-fluid bottom-60 left-0 justify-center">

<Toast onClose={()=>settoast(false)} show={toast}  delay={3000} autohide className="bg-warning" animation={false}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>


<div className="container-fluid p-0">

<div className="flex-wrap">
<form onSubmit={SendMessage}>
<div className="flex-wrap space-between">
<input className="col  m-2" ref={username} type="text" placeholder="Full Name" required minLength={4} MA={30}></input>
<input className="col m-2" ref={email} type="email" placeholder="Email" required minLength={10}></input>


</div>


<div className="flex-wrap space-between mx-2">

<input className="col form-control col-12 col-sm-12 my-2" ref={phone} type="number" placeholder="10 Digit Phone Number" required maxLength={10} minLength={10}></input>

<input className="col form-control col-sm-12 col-12 my-2" ref={whatsapp} type="number" placeholder="WhatsApp Number" required maxLength={10} minLength={10}></input>

<select
ref={category}
    className="form-control"
   
  >
    <option value={"interested"}>Interested</option>
    <option value={"demo_scheduled"}>Demo Scheduled</option>
    <option value={"pending_payment"}>Pending Payment</option>
    <option value={"joined"}>Joined Course</option>
    <option value={"not_interested"}>Not interested</option>
  </select>
</div>











<div className="m-2">
<textarea className=" col col-12 col-lg-12 col-sm-12" ref={note} placeholder="Enter your message..." required minLength={4} maxLength={500}></textarea>
    </div>


<div className="m-2">
  {sending?
  <>  <button type="submit" disabled className="btn btn-primary w-100"> Adding Lead... </button>
  
  </>:
  <>  <button type="submit"  className="btn btn-primary w-100"> Add Lead </button>
  
  </>}  </div>
</form>


</div>



</div>



</div>
        </StyledContactForm>
    )
}

export default AddLead

const StyledContactForm = styled.div`

width: 100%;

textarea{
    min-height: 100px;
}
input, textarea{
    margin-top: 5px;
    border: 1px solid grey !important;
    border-radius: 3px;
    height: 45px;
    border-bottom: 2px solid transparent;
   
}
input:focus{
    border-bottom: 2px solid #03A9F4;
    outline: none;
}
textarea:focus{
    border-bottom: 2px solid #03A9F4;
    outline: none;
}


`