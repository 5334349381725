import axios from 'axios'
import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components'
import TextField from '@mui/material/TextField';

import { useRef } from 'react'
import ENDPOINT from '../../context/EndPoint'
import { Link } from 'react-router-dom'
import {Button, Form, Toast } from 'react-bootstrap'
import { useEffect } from 'react'
import SecurityHeaders from '../../context/SecurityHeaders';
import LogoutModal from '../../components/modals/LogoutModal';
import Localbase from 'localbase';
import DefaultModal from '../../components/modals/DefaultModal';
import { useActionsContext } from '../../context/ActionsContext';
const Signin = () => {


  const {togglesidebar,settogglesidebar} = useActionsContext()
  useEffect(() => {
    window.scrollTo(0, 0)
    settogglesidebar(true)
  }, [])
const db = new Localbase()
   const [ToastTitle,setToastTitle] = useState(null);
   const [ToastMessage,setToastMessage] = useState(null)

    const redirect = sessionStorage.getItem("next") || "/dashboard";
    const msg = sessionStorage.getItem("msg") || null;
    const [email,setemail] = useState(null)
    const [password,setpassword] = useState(null)
    
const [loginerror, setloginerror] = useState(null);


    const usercredentials = {email,password}
    const [isFetching,setisFetching] =  useState(false)
        const  loginaction= async (e)=>{
            setisFetching(true)
    e.preventDefault();
  ;
    try {
      const formData = new FormData();
      formData.append('email',email);
      formData.append('password',password);
      formData.append('login',"Submit");
      


        const res = await  axios.post(`${ENDPOINT}login.php`,formData,SecurityHeaders);
  
    setisFetching(false)
    if(res){
       // console.log(res.data)
      const status = res.data.status;
      const data = res.data;

      //  // console.log("res is "+JSON.stringify(res))
      if(res.data.status==="error"&&res.data.data==="limit exceeded"){ 
        setloginerror("limit exceeded");
     
        setlogoutall(true)
      }
   
      else if ((status==="success")&&(data!==null)){
          localStorage.setItem("user", JSON.stringify(data.data));
         
          if((sessionStorage.getItem("next")==="null")||(sessionStorage.getItem("next")==="")||(sessionStorage.getItem("next")===null)){
            sessionStorage.setItem("next",null);
      
            window.location.replace("/");
          }else{
            sessionStorage.setItem("next",null);
      
            window.location.replace(redirect);
          }
             
              sessionStorage.setItem("next",null);
      
             
      }else{

        setToastTitle(res.data.title)
        setToastMessage(res.data.msg)
  setShowA(true)
      }
   
    }
 
    
       
      } catch (err) {
        setShowA(true)
        setToastTitle("Something went wrong !")
        setToastMessage("Please try again after sometime.")
      
      }

        }






            const [showA, setShowA] = useState(false);
          
            const toggleShowA = () => setShowA(!showA);
        




const [logoutall,setlogoutall] = useState(false)



  return (
  
  <>
  
  <>






<div className="d-flex flex-wrap">
<div className="col-lg-8">
<div className="vh-100 login-bg">



</div>
</div>
<div className="col-lg-4 bg-white border">
                  <div className="p-lg-5 p-4">
                    <div>
                 
                     
                  
                      <p className="text-muted">
                        Sign in to continue.
                      </p>
                      <hr></hr>
                    </div>
                    <div className="mt-4">
                      <form onSubmit={loginaction} >
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                           onChange={(e)=>setemail(e.target.value)}
                            placeholder="Enter Email"
                          />
                        </div>
                        <div className="mb-3">
                          <div className="float-end">
                            <a
                              href="https://skillsuprise.com/reset-password"
                              target='_blank'
                              className="text-muted"
                            >
                              Forgot password?
                            </a>
                          </div>
                          <label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <input
                              type="password"
                              className="form-control pe-5 password-input"
                              placeholder="Enter password"
                              id="password-input"
                           onChange={(e)=>setpassword(e.target.value)}
                            />
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle" />
                            </button>
                          </div>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id="auth-remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </label>
                        </div>
                        <div className="mt-4">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Login to help student's succeed
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                         
                        </div>
                      </form>
                    </div>
                  
                  </div>
                </div>
</div>




  <DefaultModal className="p-3 rounded" show={showA} content={<>
  <h4>{ToastTitle}</h4>
  <hr></hr>
  <p>{ToastMessage}</p>
<div className="text-center">
  
<button onClick={()=>setShowA(false)} className='btn btn-primary'>Ok</button>
</div>
</>}/>
<LogoutModal email={email} password={password} show={logoutall} HandleClose={()=>setlogoutall(false)}/>

  </>

  </>
    )
}

export default Signin