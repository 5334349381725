import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const GetBatchAttendence = async ({payload})=>{

  const formData = new FormData();
  formData.append('session',payload.session);
  formData.append('batch_id',payload.batch_id);
  formData.append('course_url',payload.course_url);
  
  const res = await axios.post(ENDPOINT+"get-batch-attendence.php",formData,{
    headers:{
      'content-type': 'multipart/form-data'
    }
  });
  
  if(res){
     return res.data
    //  // console.log("work "+JSON.stringify(res))
  }

  }
  export default GetBatchAttendence