import React, { useContext, useEffect } from 'react'
import { useActionsContext } from '../../context/ActionsContext'
import { useParams } from 'react-router-dom'
import dateFormat from 'dateformat'
import { Dropdown } from 'react-bootstrap'
import { MoreVert } from '@material-ui/icons'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../context/EndPoint'
const EmployeeLeaveHistory = () => {
const params = useParams()
const intern_id = params.user_id
const internship_id = params.internship_id
const {user} = useContext(AuthContext)
  const {internleaves,fetchInternLeaves} = useActionsContext()
  useEffect(()=>{
fetchInternLeaves({payload:{
  intern_id:intern_id,
  internship_id:internship_id
}})
  },[])


  
const  ChangeLeaveStatus = async ({payload})=>{




const data = new FormData();
data.append('id',payload.id);
data.append("status",payload.status)
data.append('session',user.SessionId)

const res = await axios.post(ENDPOINT+"change-leave-status.php",data,{
  headers:{'content-type':'multipart/form-data'}
})
if(res){
//  // console.log("lead "+JSON.stringify(res))
  if(res.status==="success"){

  
  }
  else{

  }
  

}


  
  
 
      
      }    ;


  return (
<>
<div className="tab-pane" id="documents" role="tabpanel">
                    <div className="card">
                      <div className="card-body">
                      <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-3 col-sm-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search for order ID, customer, order status or something..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-6">
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            data-provider="flatpickr"
                            data-date-format="d M, Y"
                            data-range-date="true"
                            id="demo-datepicker"
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idStatus"
                          >
                            <option value="">Status</option>
                            <option value="all" selected="">
                              All
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="Inprogress">Inprogress</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Pickups">Pickups</option>
                            <option value="Returns">Returns</option>
                            <option value="Delivered">Delivered</option>
                          </select>
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idPayment"
                          >
                            <option value="">Select Payment</option>
                            <option value="all" selected="">
                              All
                            </option>
                            <option value="Mastercard">Mastercard</option>
                            <option value="Paypal">Paypal</option>
                            <option value="Visa">Visa</option>
                            <option value="COD">COD</option>
                          </select>
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-3 col-sm-4">
                        <div className='d-flex'>
                          <button
                            type="button"
                            className="btn me-2 btn-primary "
                            onclick="SearchData();"
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>

                          <button className='btn btn-danger w-fit h-fit' >Export Data</button>
                         
                        </div>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </form>
                </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive">
                              <table className="table table-borderless align-middle mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">Leave Type</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Requested Time</th>
                                    <th scope="col">Duration</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                  </tr>
                                </thead>
                             <>
                             {internleaves&&internleaves.leaves?
                             <>
                             {internleaves.leaves.map((item)=>(
                              <>

<tr>   <td>{item.leave_type}</td> 
                                    <td>
                                      <div className="d-flex align-items-center">
                                       
                                        <div className="ms-3 flex-grow-1">
                                          <h6 className="fs-15 mb-0">
                                            <a href="javascript:void(0)">
                                             {dateFormat(item.start_time,"dS mmm yyyy")}
                                            </a>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>{dateFormat(item.end_time,"dS mmm yyyy hh:mm tt")}</td>
                                    <td>{dateFormat(item.requested_time,"dS mmm yyyy hh:mm tt")}</td>
                                  
                                 
                                     <td>{item.duration}</td>
                                     
                                     <td>{item.status}</td>
                                    <td>
                                   <Dropdown>
                                    <Dropdown.Toggle><MoreVert/></Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={()=>ChangeLeaveStatus({payload:{status:"accepted",id:item.id}})}>Accept</Dropdown.Item>
                                      <Dropdown.Item onClick={()=>ChangeLeaveStatus({payload:{status:"rejected",id:item.id}})}>Reject</Dropdown.Item>
                                    </Dropdown.Menu>
                                   </Dropdown>
                                    </td>
                                  </tr>
                              </>
                             ))}
                             
                             </>:
                             <>
                             
                             
                             </>}
                             </>
                              </table>
                            </div>
                            <div className="text-center mt-3">
                              <a
                                href="javascript:void(0);"
                                className="text-success"
                              >
                                <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" />{" "}
                                Load more{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

</>
  )
}

export default EmployeeLeaveHistory