import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const PieChart = () => {

    const [series,setseries] = useState({
        
          series: [44, 55, 41, 17, 15],
          options: {
            chart: {
              type: 'donut',
            }
           ,
           xaxis: {
            labels: {
              show: false // Set to false to hide x-axis labels
            }
          },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        
        
        })
  return (
    <>
    
    <ReactApexChart options={series.options} series={series.series} type="donut" />
          
    </>
  )
}

export default PieChart