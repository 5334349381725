import React from 'react'
import Mode from './Mode'
import Calendar from './Calendar'
import Status from './Status'
import Payment from './Payment'

const Filters = ({currentPage}) => {
  const page = currentPage.slice(1)
  return (
    <>

<div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border d-flex flex-wrap justify-content-between ">
{page==="classes" || page==="students-support" || page==="assignment-submissions" || page==="project-submissions" || page==="placement-support" || page==="team-task-submissions" || page==="team-meetings" || page==="team-leaves" || page==="team-members-all" || page==="customer-support" || page==="course-registrations" ?
        <>
        <Calendar/>
        <Status currentPage={currentPage}/>
        </>
        :
        page==="active-batches"?
        <>
         <Mode/>
        </>
        :
        page==="team"
        ?
        <>
           <Calendar/>
        </>
        :
        page==="students"
        ?
        <>
        <Calendar/>
        <Mode/>
        </>
        :
        page==="courses" || page==="batches"
        ?
        <>
        <Mode/>
        </>
        :
        page==="payments"
        ?
        <>
        <Mode/>
        <Status currentPage={currentPage}/>
        <Calendar/>
        <Payment/>
        </>
        :
        <>
        <p className='m-0'>No Filters</p>
        </>
        }
         </div>

    </>

  )
}

export default Filters