import { CircularProgress } from '@material-ui/core';
import React from 'react'
import styled from 'styled-components'
import dateFormat from 'dateformat';
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useContext } from 'react'
import { Toast } from 'react-bootstrap'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@material-ui/core'
import axios from 'axios'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CustomModal from './CustomModal';
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../context/EndPoint';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';

const UploadClassRecordingModal = ({ item, visibility, handleClose, props,value,dispatch }) => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])




  const params = useParams();
  const id = item.id;

  const { user } = useContext(AuthContext);
  const userdata = user;



  const loginRedirect = ({ redirect }) => {

    sessionStorage.setItem("next", `/internship/${id}`)
    sessionStorage.setItem("msg", "Login to apply internship")
  }








  //code dump

  const [toasttitle, settoasttitle] = useState(null)
  const [toastmessage, settoastmessage] = useState(null)
  const [file, setfile] = useState(null)
  const [status, setstatus] = useState(false)
  const [uploadprogress, setuploadprogress] = useState(0)
  const UPLOAD_ENDPOINT = `${ENDPOINT}upload-recording.php`;
  const [toast, settoast] = useState(false)
  const onSubmit = async (e) => {
    setstatus(true)
    e.preventDefault()
    let res = await uploadFile(file);

    if (res) {
      //  // console.log(" response "+JSON.stringify(res))
    }
    if (res.data.status === "success" && res.data.message === "applied") {
      settoastmessage("Recording uploaded.")
      settoasttitle("YRecording uploaded.")
      settoast(true)
      setuploadprogress(0)
      sessionStorage.setItem("iid", null);
    } else if (res.data.status === "success" && res.data.message === "application_exists") {
      setuploadprogress(0)

      settoastmessage("You have already applied for this internship.")
      settoasttitle("Application Exists !")
      settoast(true)
    } else {
      setuploadprogress(0)

      settoastmessage("Please try again.")
      settoasttitle("Something went wrong !")
      settoast(true)
    }
  }
  const onChange = (e) => {
    setfile(e.target.files[0])

  }
  const uploadFile = async (file) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //   //  // console.log("total "+progressEvent.total)
        setuploadprogress(percentCompleted)


      }
    }
    ////  // console.log("apply internship")
    const formData = new FormData();
    const user = JSON.parse(localStorage.getItem("user"));
    formData.append('session', user.SessionId);
    formData.append('resume', file);
    formData.append('class_id', item.id);
    formData.append("course_url", item.course_url)
    formData.append("batch_id", item.batch_id)
    formData.append('title', "this is title")
    formData.append('description', "description")
    formData.append('duration', videolength)
    formData.append("class_starts", item.class_starts)
if(value.notification){
  formData.append('notification',value.notification);

}
    return await axios.post("https://skillsuprise.com/upload-recording.php", formData, config, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }
  const [loginprompt, setloginprompt] = useState(false)


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };






  const [tags, settags] = useState([])
  const [videolength, setvideolength] = useState(null);
  const [currentlength, setcurrentlength] = useState(null)
  const getDuration = () => {

    const video = document.getElementById("video");
    setvideolength((video.duration))

  }

  const updateTime = () => {
    const video = document.getElementById("video");
    setcurrentlength(video.currentTime)

  }
  const TogglePlay = () => {

    const video = document.getElementById("video");
    if (video.onPlaying) {
      video.pause()
    }
    else {
      video.play();
    }
  }



  const [currenttag, setcurrenttag] = useState(null)

  const [display, setdisplay] = useState(false)
  const updateTags = () => {
    tags.push(currenttag);
    setcurrenttag("")
    setdisplay(true)

  }

  const RemoveTag = ({ index }) => {
    setdisplay(false)
    let data = tags;
    data.splice(index, 1);
    settags(data)
    settags(null)
    settags(data)

    setdisplay(true)
    //  // console.log(display + tags)
  }
  return (


    <>


      <CustomModal visibility={visibility} handleClose={handleClose}>








        <div className="position-fixed container-fluid justify-center bottom-60 left-0">

          <Toast className="bg-warning" onClose={() => settoast(false)} show={toast} >
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
              <strong className="me-auto">{toasttitle}</strong>

            </Toast.Header>
            <Toast.Body>{toastmessage}</Toast.Body>
          </Toast>

        </div>




        <div className=" pb-0">

          <div className="modal-header">
            <h4>Upload Recording</h4>
            <Close onClick={handleClose} />
          </div>
          <hr></hr>
          <div className="">



            <div className="">
              <div className="w-100">{file && URL.createObjectURL(file) ?
                <>

                  <video controls className="w-100 mx-auto" id="video" onLoad={getDuration} onLoadedData={getDuration} src={URL.createObjectURL(file)}></video>

                </>
                : <>

                  <video className="w-100 mx-auto bg-dark" ></video>


                </>
              }
                <div className="w-100">

                  <div className="d-flex justify-content-between">
                    <p>Class Time: {dateFormat(item.class_starts, "dS mmm hh:mm tt")}</p>
                    <p>Duration : {Math.round(videolength / 60)} min</p>

                  </div>

                </div>
              </div>
              <div className="w-100">

                <p>Course : {item.course_url}</p>
                <div className="d-flex justify-content-between">
                  <p>Batch ID : {item.batch_id}</p>
                  <p>Class ID : {item.id}</p>
                </div>



                <hr />
                <form onSubmit={onSubmit} className="position-relative">
                  {file ?
                    <>
                      <div className="justify-center">


                      </div>


                      <div className="container-fluid">

                        {tags && display ?
                          <>



                            {tags.map((tag, index) => (

                              <>
                                <Chip key={tag} className="mr-1 btn  btn-danger" onClick={() => RemoveTag({ index })} label={tag} />
                              </>

                            ))}
                          </> : null}



                      </div>

                    </> :
                    <>

                    </>}





                  <div className="justify-center">
                    <input type="file" className="" placeholder="Photo/Video" onChange={onChange} />

                  </div>

                  {file ?
                    <>
                      <br />

                      <button className="btn btn-primary w-100 mt-2" type="submit">{status ? <CircularProgress className="text-white " size={20} /> : ""} {uploadprogress > 0 ?
                        <>
                          Uploading {uploadprogress}%...
                        </>
                        : "Upload Video"
                      }</button>

                    </> :
                    <>
                    </>}


                </form>




              </div>


            </div>



          </div>


        </div>

      </CustomModal>

    </>



  )
}
const Notification = (prop)=>({

  value :prop.sendNotification


})

export default connect(Notification) (UploadClassRecordingModal)


