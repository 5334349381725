import axios from "axios";
import ENDPOINT from "../../context/EndPoint";

const ChangeAccountStatus = async ({ payload }) => {
    const formData = new FormData();
    formData.append("session", payload.SessionId);
    formData.append("user_id", payload.user_id);
    formData.append("status", payload.status);

    const res = await axios.post(
      ENDPOINT + "admin-change-account-status.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
        return res.data
    }
   
  };
  export default ChangeAccountStatus